import { Stack, Typography } from "@mui/material";
import { IIcon } from "../images/IIcon";

export const NoCompletedAnalysisBlock = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="6px"
      bgcolor="#ECEEF099"
      padding="16px"
    >
      <Stack direction="row" gap="16px" alignItems="flex-start">
        <IIcon />
        <Stack gap="4px">
          <Typography
            variant="subtitle1"
            color="custom.textDarkBlue"
            sx={{ fontWeight: 500 }}
          >
            No completed analysis yet
          </Typography>
          <Typography variant="body1" color="text.secondary">
            All statistics will appear as soon as at least one company analysis
            is ready
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
