import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { Box, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useOutsideClick } from "../../../hooks/useOutSideClick";
import { TriangleIcon } from "../../images/TriangleIcon";

type Props = {
  items: string[];
  label: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
};

export const Dropdown = ({
  items,
  label,
  placeholder,
  value,
  onChange,
}: Props) => {
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeHandler = () => {
    setIsOpen(false);
  };

  const handleChange = (item: string) => {
    onChange(item);
    closeHandler();
  };

  useOutsideClick({
    elementRef: dropdownRef,
    onOutsideClick: closeHandler,
    isOpened: isOpen,
    wrapperRef: dropdownWrapperRef,
  });

  return (
    <Stack gap="4px">
      <Typography
        sx={{ fontSize: "12px", color: "#1F293299", fontWeight: 500 }}
      >
        {label}
      </Typography>

      <div
        ref={dropdownWrapperRef}
        style={{
          position: "relative",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "8px 40px 8px 16px",
            cursor: "pointer",
            border: "1px solid #CFCFCF",
            borderRadius: "8px",
            height: "40px",
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {placeholder && !value && (
            <Typography
              component="span"
              variant="button"
              color="custom.darkGrey"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {placeholder}
            </Typography>
          )}

          {value && (
            <Typography
              component="span"
              variant="button"
              color="custom.textDarkBlue"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {value}
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              right: "16px",
              top: "18px",
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          >
            <TriangleIcon />
          </Box>
        </Box>

        {isOpen && (
          <Box
            ref={dropdownRef}
            sx={{
              backgroundColor: "white",
              position: "absolute",
              zIndex: 9999,
              left: 0,
              top: 50,
              boxShadow: "0px 4px 15px 0px #00000040",
              borderRadius: "8px",
              paddingY: "8px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "124px",
                overflow: "auto",
              }}
            >
              {items.map((name) => (
                <MenuItem
                  sx={{
                    padding: "4px",
                  }}
                  key={name}
                  value={name}
                  onClick={() => handleChange(name)}
                  selected={name === value}
                >
                  <ListItemText
                    sx={{
                      padding: "8px",
                    }}
                    primaryTypographyProps={{
                      sx: {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      },
                    }}
                    primary={name}
                  />
                </MenuItem>
              ))}
            </Box>
          </Box>
        )}
      </div>
    </Stack>
  );
};
