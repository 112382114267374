import { Box, Stack, Typography } from "@mui/material";
import { NavigationBreadcrumbs } from "../../ui";
import { NoDataBlock } from "../NoDataBlock";
import { useEffect } from "react";
import { ScrappingBlock } from "./ScrappingBlock";
import { useFindExternalDataMutation } from "../../../api/companyApi";

type Props = {
  breadcrumbs: { title: string; path?: string }[];
  name: string;
  size: string;
  progress: number;
  account_id: string;
  status: string;
  refetch: () => void;
};

export const NoContactsBlock = ({
  breadcrumbs,
  name,
  size,
  status,
  progress,
  account_id,
  refetch,
}: Props) => {
  const [findExternalData, { isSuccess, isLoading }] =
    useFindExternalDataMutation();

  useEffect(() => {
    isSuccess && refetch();
  }, [isSuccess]);

  const startScrappingHandler = () => {
    if (isLoading) return;
    findExternalData(account_id);
  };

  if (["scraping_queued", "scraping_in_progress"].includes(status)) {
    return (
      <ScrappingBlock
        name={name}
        breadcrumbs={breadcrumbs}
        account_id={account_id}
        progress={progress}
        status={status}
        refetch={refetch}
      />
    );
  }

  return (
    <Stack sx={{ px: { sm: 8, md: 8, lg: 14, xl: 20 }, pt: 6, pb: 20, gap: 7 }}>
      <Stack gap="16px">
        <Stack pb="8px">
          <NavigationBreadcrumbs breadcrumbs={breadcrumbs} />
        </Stack>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            gap: 1,
          }}
        >
          <Typography variant="h4" color="custom.textDarkBlue">
            General information
          </Typography>
        </Box>
        <Stack gap="24px">
          <Typography
            variant="h1"
            color="custom.textDarkBlue"
            sx={{
              fontSize: "48px",
              lineHeight: "56px",
            }}
          >
            {name}
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" gap="24px">
              <Stack gap="8px">
                <Typography variant="body1" color="#1F293299">
                  Company size
                </Typography>
                <Typography
                  variant="h4"
                  color="custom.textDarkBlue"
                  sx={{ fontWeight: 500 }}
                >
                  {size.trim() !== "-" ? `${size} employees` : size.trim()}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <NoDataBlock btnHandler={startScrappingHandler} />
    </Stack>
  );
};
