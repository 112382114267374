import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  Box,
  Stack,
  Link,
  TextField,
  CircularProgress,
} from "@mui/material";
import { AppRoutes } from "../types/enums";
import { CustomButton } from "../components/ui/CustomButton";
import { useStateWithSearchParams } from "../hooks/useStateWithSearchParams";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation, useValidateOTPMutation } from "../api";
import { getErrorMessage } from "../utils/getErrorMessage";
import { toast } from "react-toastify";
import { CustomToast } from "../components/ui/CustomToast";

type Fields = { [key: string]: string };
type SearchParams = {
  code?: string;
  email?: string;
};

export const VerifyCodePage = () => {
  const navigate = useNavigate();
  const [currentFocus, setCurrentFocus] = useState<string>("1");
  const [formData, setFormData] = useState<Fields>({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
  });

  const inputRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
  const [code, setCode] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [isTimeout, setIsTimeout] = useState<boolean>(true);
  const [time, setTime] = useState<number>(60);
  const { searchDict } = useStateWithSearchParams<SearchParams>({
    code: undefined,
    email: undefined,
  });

  const [
    forgotPassword,
    { error: forgotPasswordError, isSuccess: forgotPasswordSuccess },
  ] = useForgotPasswordMutation();

  const [validateOTP, { isLoading }] = useValidateOTPMutation();

  useEffect(() => {
    let id: NodeJS.Timeout;

    if (isTimeout) {
      id = setInterval(
        () =>
          setTime((num) => {
            if (num === 1) {
              setIsTimeout(false);
              clearTimeout(id);
              return 60;
            }
            return num - 1;
          }),
        1000,
      );
    }

    return () => clearTimeout(id);
  }, [isTimeout]);

  useEffect(() => {
    if (forgotPasswordSuccess) {
      toast.success(
        <CustomToast
          type={"success"}
          text={`verification code sent to your email: ${email}`}
          title={"verification code sent"}
        />,
      );
    }
  }, [forgotPasswordSuccess]);

  useEffect(() => {
    if (forgotPasswordError) {
      toast.error(
        <CustomToast
          type={"error"}
          text={
            getErrorMessage(forgotPasswordError).globalError ||
            "Something went wrong during reset password. Please try again"
          }
          title={"Reset password error"}
        />,
      );
    }
  }, [forgotPasswordError]);

  useEffect(() => {
    if (!searchDict.email && !email)
      navigate(AppRoutes.LOGIN, { replace: true });

    if (searchDict.email) {
      setCode(searchDict.code);
      setEmail(searchDict.email);

      navigate(AppRoutes.VERIFY_CODE, { replace: true });
    }
  }, [searchDict.email]);

  useEffect(() => {
    if (code) {
      const values = code.split("").reduce(
        (acc, value, index) => {
          acc[index + 1] = value;
          return acc;
        },
        {} as { [key: string]: string },
      );

      setFormData(values);
    }
  }, [code]);

  useEffect(() => {
    const values = Object.values(formData);
    if (values.every((value) => !!value)) {
      setCode(values.join(""));
    }
  }, [formData]);

  useEffect(() => {
    inputRefs.current[currentFocus]?.focus();
  }, [inputRefs]);

  const inputChangeHandler = (field: string, value: string) => {
    if (value[value.length - 1] && isNaN(Number(value[value.length - 1])))
      return;

    setFormData((prev) => ({
      ...prev,
      [field]: value[value.length - 1] || "",
    }));

    const nextField = (Number(field) + 1).toString();
    if (nextField in formData && value !== "") {
      setCurrentFocus(nextField);
      inputRefs.current[nextField]?.focus();
    }
  };

  const handleFocus = (current: string, next: number) => {
    const field = String(Number(current) + next);
    setCurrentFocus(field);
    inputRefs.current[field]?.focus();
  };

  const resendCodeHandler = () => {
    if (!email) return;
    setIsTimeout(true);
    forgotPassword({ email });
  };

  const verifyCodeHandler = () => {
    if (!email || !code) return;
    validateOTP({ email, code })
      .unwrap()
      .then(() =>
        navigate(`${AppRoutes.RESET_PASSWORD}?code=${code}&email=${email}`, {
          replace: true,
        }),
      )
      .catch((err) => {
        toast.error(
          <CustomToast
            type={"error"}
            text={
              getErrorMessage(err).globalError ||
              "Something went wrong during reset password. Please try again"
            }
            title={"Reset password error"}
          />,
        );
      });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="calc(100vh - 74px)"
    >
      <Stack width="368px" textAlign="center" gap="24px">
        <Stack gap={1}>
          <Typography variant="h1" component="h1" textAlign="left">
            Check your inbox
          </Typography>
          <Typography
            variant="subtitle1"
            color="#4C545B"
            textAlign="left"
            fontWeight={400}
          >
            Please enter a verification code we’ve sent to your email{" "}
            <span style={{ fontWeight: 500 }}>{email}.</span>
          </Typography>
        </Stack>

        <Stack gap="16px">
          <Stack flexDirection="row" gap="16px">
            {["1", "2", "3", "4", "5", "6"].map((el) => (
              <TextField
                inputRef={(input) => (inputRefs.current[el] = input)}
                focused={currentFocus === el}
                onClick={() => setCurrentFocus(el)}
                key={el}
                value={formData[el]}
                variant="outlined"
                onChange={(e) => inputChangeHandler(el, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "ArrowRight" && Number(currentFocus) + 1 <= 6) {
                    handleFocus(currentFocus, 1);
                  } else if (
                    e.key === "ArrowLeft" &&
                    Number(currentFocus) - 1 >= 1
                  ) {
                    e.preventDefault();
                    handleFocus(currentFocus, -1);
                  }
                }}
                sx={{
                  width: "48px",
                  backgroundColor: "#F1F1F1",
                  color: "#1F2932",
                  fontSize: "34px",
                  borderRadius: "4px",
                  textAlign: "center",
                  "& .MuiOutlinedInput-root": {
                    border: "none",
                    padding: 0,
                    fontSize: "34px",
                    borderRadius: "4px",
                    "& fieldset": { borderColor: "#1F2932" },
                    "&:hover fieldset": {
                      cursor: "default",
                    },
                    "&.Mui-focused fieldset": { border: "2px solid #FE8836" },
                  },
                  "& input": {
                    textAlign: "center",
                    padding: "0",
                    caretColor: "transparent",
                  },
                }}
              />
            ))}
          </Stack>

          {isTimeout ? (
            <Typography
              variant="body1"
              color="#A6AAAD"
              fontWeight={500}
              align="left"
            >
              Re-send code (in {time}sec)...
            </Typography>
          ) : (
            <Typography
              variant="body1"
              color="#4C545B"
              fontWeight={500}
              align="left"
            >
              <Box
                component="span"
                sx={{
                  textDecoration: "none",
                  fontWeight: 500,
                  color: "#000000DE",
                  cursor: "pointer",
                  "&:hover": { color: "#2194D2" },
                }}
                onClick={resendCodeHandler}
              >
                Re-send code
              </Box>{" "}
              to {email}
            </Typography>
          )}
        </Stack>

        <Stack gap="8px" alignItems="flex-start">
          <CustomButton
            variant="contained"
            fullWidth
            type="button"
            customColor="#F16300"
            customGradientColor="#FE8836"
            disabled={
              Object.values(formData).some((value) => !value) || isLoading
            }
            onClick={verifyCodeHandler}
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
          >
            Verify code
          </CustomButton>
          <Typography variant="body1" color="#4C545B" fontWeight={500}>
            Back to{" "}
            <Link
              href={AppRoutes.LOGIN}
              sx={{
                textDecoration: "none",
                fontWeight: 500,
                color: "#000000DE",
                "&:hover": { color: "#2194D2" },
              }}
            >
              Sign in
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
