import { useEffect } from "react";
import {
  useCreateHubspotUrlMutation,
  useCreateSalesforceUrlMutation,
} from "../../api/companyApi";
import { toast } from "react-toastify";
import { CustomToast } from "../ui/CustomToast";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../types/enums";
import { useAppDispatch } from "../../hooks/useReduxHooks";
import { setIntegrationData } from "../../features/companySlice";

export const Integration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [createHubspotUrl] = useCreateHubspotUrlMutation();
  const [createSalesforceUrl] = useCreateSalesforceUrlMutation();

  const startStepHandler = (
    type: string,
    path: string,
    actionType: "create" | "update" | "add-contacts",
    campaignId: string,
  ) => {
    dispatch(
      setIntegrationData({
        campaignId: campaignId,
        campaignImportFlow: type,
        actionType: actionType,
      }),
    );

    navigate(actionType === "create" ? AppRoutes.PRODUCTS : path, {
      replace: true,
    });
  };

  useEffect(() => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const type = urlParams.get("type");
      const code = urlParams.get("code");
      const state = urlParams.get("state");

      const data = JSON.parse(atob(state || "")) || "";

      if (type === "hubspot" && code && data) {
        createHubspotUrl({ code })
          .unwrap()
          .then(() => {
            startStepHandler(
              type,
              data.redirect_path,
              data.action_type,
              data.campaign_id,
            );
          })
          .catch((err) => {
            toast.error(
              <CustomToast
                type="error"
                text={
                  getErrorMessage(err).globalError ||
                  "Something went wrong during connecting to HubSpot. Please try again"
                }
                title="Connection to HubSpot failed."
              />,
            );
          });
      } else if (type === "salesforce" && code) {
        createSalesforceUrl({ code })
          .unwrap()
          .then(() => {
            startStepHandler(
              type,
              data.redirect_path,
              data.action_type,
              data.campaign_id,
            );
          })
          .catch((err) => {
            toast.error(
              <CustomToast
                type="error"
                text={
                  getErrorMessage(err).globalError ||
                  "Something went wrong during connecting to Salesforce. Please try again"
                }
                title="Connection to Salesforce failed."
              />,
            );
          });
      }
    } catch (err) {}
  }, []);

  return null;
};
