import { CircularProgress, Stack, Typography } from "@mui/material";
import { CustomButton } from "../../CustomButton";
import { UploadFromDesktop } from "../../upload-data";
import { ReactNode } from "react";

type Props = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  backHandler: () => void;
  onSaveHandler: () => void;
  isSave: boolean;
  onClose: () => void;
  getCampaignId: () => Promise<string | void>;
  description?: ReactNode;
};

export const UploadCSV = ({
  isLoading,
  setIsLoading,
  backHandler,
  onSaveHandler,
  isSave,
  onClose,
  getCampaignId,
  description,
}: Props) => {
  return (
    <Stack gap="24px">
      {description}
      <Stack gap="8px">
        <Typography
          variant="body1"
          color="#4C545B"
          fontWeight={500}
          align="center"
        >
          Preferred format: <strong>.csv</strong> or <strong>Excel file</strong>
        </Typography>
        <UploadFromDesktop
          getCampaignId={getCampaignId}
          onSuccess={onClose}
          setIsLoading={setIsLoading}
          actionType="create"
        />
      </Stack>

      <Stack direction="row" gap="8px">
        <CustomButton
          onClick={backHandler}
          variant="contained"
          textColor="#4C545B"
          fullWidth
          type="button"
          customColor="#F1F1F1"
          disabled={isLoading}
        >
          Back
        </CustomButton>
        <CustomButton
          onClick={onSaveHandler}
          variant="contained"
          fullWidth
          type="button"
          customColor="#1C3C6C"
          disabled={isLoading}
          startIcon={
            isSave &&
            isLoading && (
              <CircularProgress size={20} sx={{ color: "#757575" }} />
            )
          }
        >
          Save
        </CustomButton>
      </Stack>
    </Stack>
  );
};
