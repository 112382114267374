import { Stack, Typography } from "@mui/material";
import { NoAnalyticsIcon } from "../../images";
import noDataBg from "../../../assets/images/no-data-bg.png";

export const NoAnalyticsData = () => {
  return (
    <Stack
      gap="8px"
      alignItems="center"
      justifyContent="center"
      paddingY="18px"
      sx={{
        backgroundImage: `url(${noDataBg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <NoAnalyticsIcon />
      <Typography
        variant="subtitle1"
        color="#1F2932"
        sx={{ fontSize: "20px", fontWeight: 500 }}
        textAlign="center"
      >
        Launch detailed BC analysis
      </Typography>
      <Typography
        variant="body1"
        color="#4C545B"
        maxWidth="364px"
        textAlign="center"
      >
        Analyze all accounts with attached contacts, or use the checkboxes to
        select one or several accounts you would like to start with
      </Typography>
    </Stack>
  );
};
