import { Stack, Typography } from "@mui/material";
import { NoResultIcon } from "../images/NoResultIcon";

export const NoResultFound = () => {
  return (
    <Stack
      pt="100px"
      maxWidth="400px"
      gap="32px"
      alignItems="center"
      justifyContent="center"
    >
      <NoResultIcon />
      <Stack gap="8px" alignItems="center">
        <Typography variant="h1" color="custom.textDarkBlue">
          No results found
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          You don’t have any campaign with this product yet
        </Typography>
      </Stack>
    </Stack>
  );
};
