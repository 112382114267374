import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { authApi } from "api/authApi";
import { campaignsApi } from "api/companyApi";
import authReducer from "../features/authSlice";
import companyReducer from "../features/companySlice";
import { persistStore, persistReducer } from "redux-persist";
import { accountsApi, userApi } from "../api";

const persistConfig = {
  key: "auth",
  storage,
  whitelist: ["refreshToken", "accessToken", "isAuthenticated"],
};

export const store = configureStore({
  reducer: combineReducers({
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [campaignsApi.reducerPath]: campaignsApi.reducer,
    [accountsApi.reducerPath]: accountsApi.reducer,
    auth: persistReducer(persistConfig, authReducer),
    company: companyReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      authApi.middleware,
      userApi.middleware,
      campaignsApi.middleware,
      accountsApi.middleware,
    ),
});

export const persistor = persistStore(store);

export const { resetApiState } = campaignsApi.util;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
