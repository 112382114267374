import { AccountFilters } from "../types";

export const prepareFilters = (data?: AccountFilters) => {
  if (!data) return [];

  const keys = Object.keys(data);
  return keys.map((key: string) => {
    return {
      placeholder: key.charAt(0).toUpperCase() + key.slice(1),
      query: key,
      items: data[key as keyof AccountFilters],
    };
  });
};
