import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { NavigationBreadcrumbs } from "../../ui";
import { ExternalDataIcon } from "../../images";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CustomToast } from "../../ui/CustomToast";
import { sseManager } from "../../../utils/useSSEEvent";
import { BASE_API_URL } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../hooks/useReduxHooks";

type Props = {
  breadcrumbs: { title: string; path?: string }[];
  progress: number;
  account_id: string;
  status: string;
  refetch: () => void;
  name: string;
};

interface SSEData {
  status:
    | "in_progress"
    | "completed"
    | "failed"
    | "queued"
    | "scraping_queued"
    | "scraping_in_progress";
  progress: number;
}

export const ScrappingBlock = ({
  breadcrumbs,
  progress,
  account_id,
  status,
  refetch,
  name,
}: Props) => {
  const [currentProgress, setCurrentProgress] = useState<number>(progress);
  const [currentStatus, setCurrentStatus] = useState<string>(status);
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.auth.accessToken) as string;
  const refreshToken = useAppSelector(
    (state) => state.auth.refreshToken,
  ) as string;

  useEffect(() => {
    const handleMessage = (sseData: SSEData[]) => {
      const { status, progress } = sseData[0];

      setCurrentProgress(progress);
      setCurrentStatus(status);

      if (status === "failed") {
        toast.error(
          <CustomToast
            type={"error"}
            text={"Something went wrong during analysis. Please try again"}
            title={"Analysis failed"}
          />,
        );
        refetch();
      } else if (status === "completed") {
        refetch();
        setCurrentProgress(0);
      }
    };

    if (!sseManager.isConnected) {
      sseManager.connect({
        url: `${BASE_API_URL}/accounts/${account_id}/sse-status`,
        token,
        refreshToken,
        onMessage: handleMessage,
        onError: () => {},
        dispatch: dispatch,
      });
    }

    return () => {
      sseManager.close();
    };
  }, [token]);

  return (
    <Stack
      sx={{ px: { sm: 8, md: 8, lg: 14, xl: 20 }, pt: 6, pb: 20, gap: 7 }}
      minHeight="calc(100vh - 74px)"
    >
      <Stack gap="16px">
        <Stack pb="8px">
          <NavigationBreadcrumbs breadcrumbs={breadcrumbs} />
        </Stack>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            gap: 1,
          }}
        >
          <Typography variant="h4" color="custom.textDarkBlue">
            General information
          </Typography>
        </Box>
        <Typography
          variant="h1"
          color="custom.textDarkBlue"
          sx={{
            fontSize: "48px",
            lineHeight: "56px",
          }}
        >
          {name}
        </Typography>
        <Stack alignItems="center" justifyContent="center" flexGrow={1}>
          <Stack gap="32px" alignItems="center" maxWidth="420px">
            <ExternalDataIcon />
            <Stack gap="8px" alignItems="center">
              <Typography
                variant="h1"
                color="custom.textDarkBlue"
                textAlign="center"
              >
                External data collection in progress...
              </Typography>
              <Typography
                variant="subtitle1"
                color="text.secondary"
                textAlign="center"
                maxWidth="386px"
              >
                We're collecting and processing requested external data. It may
                take some time, you'll get a notification once it’s completed.
              </Typography>
            </Stack>
            <Stack flexDirection="row" gap="8px" alignItems="center">
              <CircularProgress
                size={24}
                sx={{ color: "custom.textDarkBlue" }}
              />
              {currentStatus === "in_progress" ? (
                <Typography variant="button" color="custom.textDarkBlue">
                  Analyzing retrieved data: {currentProgress || 0}%
                </Typography>
              ) : (
                <Typography variant="button" color="custom.textDarkBlue">
                  Collecting external data
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
