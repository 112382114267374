import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { SSEManager } from "../../utils/useSSEEvent";
import { BASE_API_URL } from "../../api";
import { toast } from "react-toastify";
import { CustomToast } from "./CustomToast";

interface SSEData {
  progress: number;
  status: string;
}

export const SseProgress = ({
  task_id,
  setGetResult,
  callBack,
}: {
  task_id: string;
  setGetResult: (getResult: boolean) => void;
  callBack: () => void;
}) => {
  const [progress, setProgress] = useState<number>(0);
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.auth.accessToken) as string;
  const refreshToken = useAppSelector(
    (state) => state.auth.refreshToken,
  ) as string;

  useEffect(() => {
    const sseManager = new SSEManager();
    const handleMessage = (data: SSEData) => {
      const { progress, status } = data;
      setProgress(progress);

      if (status === "failed") {
        toast.error(
          <CustomToast
            type={"error"}
            text={`Something went wrong during the analysis of your data. Please try again`}
            title={"Analysis failed"}
          />,
        );
      } else if (status === "completed" || progress === 100) {
        setGetResult(true);
        callBack();
      }
    };

    if (!sseManager.isConnected) {
      sseManager.connect({
        url: `${BASE_API_URL}/processors/generate-campaign/${task_id}/progress`,
        token,
        refreshToken,
        onMessage: handleMessage,
        onError: () => {},
        dispatch: dispatch,
      });
    }

    return () => {
      sseManager.isConnected && sseManager.close();
    };
  }, [token]);

  return <>{progress}%</>;
};
