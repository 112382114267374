import { Stack, Typography } from "@mui/material";
import { CustomTabs } from "../../CustomTabs";
import React, { useEffect, useMemo, useState } from "react";
import { IIcon } from "../../../images/IIcon";
import { ManualEnterData } from "./ManualEnterData";
import { UploadCSV } from "./UploadCSV";
import { UploadCRM } from "./UploadCRM";
import { ProductAnalysisResponse } from "../../../../types/apiTypes";
import { useCreateCampaignMutation } from "../../../../api/companyApi";
import { Link, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../types/enums";
import { toast } from "react-toastify";
import { CustomToast } from "../../CustomToast";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { BASE_API_URL } from "../../../../api";

type Props = {
  onClose: () => void;
  initData: ProductAnalysisResponse;
  backHandler: () => void;
};

const tabList = ["Sync CRM", "Upload CSV", "Enter data"];

const CRMDescription = () => {
  return (
    <Stack gap="16px">
      <Typography variant="body1" color="#4C545B" fontWeight={500}>
        Provide basic information about targeted accounts for high overview
        insights analysis: Company name, Employee size.
      </Typography>
      <Typography variant="body1" color="#4C545B" fontWeight={500}>
        Check{" "}
        <Link
          to={AppRoutes.PRODUCTS_REQUIREMENTS}
          target="_blank"
          style={{ textDecoration: "none", color: "#0069C4" }}
        >
          Field Requirements
        </Link>{" "}
        to see the full list of accounts and contacts related fields.
      </Typography>
    </Stack>
  );
};

const CSVDescription = () => {
  return (
    <Stack gap="16px">
      <Typography variant="body1" color="#4C545B" fontWeight={500}>
        Provide basic information about targeted accounts for high overview
        insights analysis: Company name, Employee size.
      </Typography>
      <Typography variant="body1" color="#4C545B" fontWeight={500}>
        Check{" "}
        <Link
          to={AppRoutes.PRODUCTS_REQUIREMENTS}
          target="_blank"
          style={{ textDecoration: "none", color: "#0069C4" }}
        >
          Field Requirements
        </Link>{" "}
        to see the full list of accounts and contacts related fields.
      </Typography>
      <Typography variant="body1" color="#4C545B" fontWeight={500}>
        You can also check the{" "}
        <a
          href={`${BASE_API_URL}/examples/input-csv`}
          style={{ textDecoration: "none", color: "#0069C4" }}
        >
          file example
        </a>{" "}
        to know exactly how imported data should look.
      </Typography>
    </Stack>
  );
};

export const ProvideDataStep = ({ onClose, initData, backHandler }: Props) => {
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(false);

  const [
    createCampaign,
    {
      isSuccess: isCreatCampaignSuccess,
      isLoading: isCreateCampaignLoading,
      data: createCampaignResult,
    },
  ] = useCreateCampaignMutation();

  useEffect(() => {
    isCreateCampaignLoading && setIsLoading(true);
  }, [isCreateCampaignLoading]);

  useEffect(() => {
    if (isCreatCampaignSuccess && isSave && createCampaignResult) {
      toast.success(
        <CustomToast
          type={"success"}
          text={"Check product information and accounts analysis results."}
          title={"New product is added"}
        />,
      );
      setIsLoading(false);
      navigate(`${AppRoutes.PRODUCTS}/${createCampaignResult.campaign_id}`);
      onClose();
    }
  }, [isCreatCampaignSuccess]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (isLoading) return;
    setCurrentTab(newValue);
  };

  const onSaveHandler = () => {
    createCampaign({ ...initData, create_empty: true });
    setIsSave(true);
  };

  const getCampaignId = async () => {
    setIsLoading(true);
    return await createCampaign(initData)
      .unwrap()
      .then((campaign) => campaign.campaign_id)
      .catch((err) => {
        toast.error(
          <CustomToast
            type="error"
            text={
              getErrorMessage(err).globalError ||
              "The company data upload failed."
            }
            title="Upload failed"
          />,
        );
      });
  };

  const values = useMemo(() => {
    return [
      <UploadCRM
        description={<CRMDescription />}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        backHandler={backHandler}
        onSaveHandler={onSaveHandler}
        isSave={isSave}
        getCampaignId={getCampaignId}
        onClose={onClose}
      />,
      <UploadCSV
        description={<CSVDescription />}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        backHandler={backHandler}
        onSaveHandler={onSaveHandler}
        isSave={isSave}
        onClose={onClose}
        getCampaignId={getCampaignId}
      />,
      <ManualEnterData
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        backHandler={backHandler}
        onClose={onClose}
        getCampaignId={getCampaignId}
      />,
    ];
  }, [backHandler, isLoading, isSave]);

  return (
    <Stack gap="16px">
      <Typography
        variant="subtitle1"
        color="custom.textDarkBlue"
        sx={{ fontSize: "18px", fontWeight: 500 }}
      >
        Step 2 of 2: Provide data for analysis{" "}
        <span
          style={{
            color: "#1F293299",
          }}
        >
          (optional)
        </span>
      </Typography>
      <Typography variant="body1" color="#4C545B">
        Add a marketing list with the following fields or sync your CRM data.
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="6px"
        border="1px solid #FFD5A1"
        bgcolor="#FFF7E5"
        padding="16px"
      >
        <Stack direction="row" gap="16px" alignItems="flex-start">
          <IIcon />
          <Typography variant="body2" color="custom.textDarkBlue">
            Providing your target accounts and contacts marketing lists is not
            required, but will{" "}
            <span style={{ fontWeight: 500 }}>
              increase the results accuracy
            </span>{" "}
            and give you more{" "}
            <span style={{ fontWeight: 500 }}>buying committee insights.</span>
          </Typography>
        </Stack>
      </Stack>
      <CustomTabs
        isTabsCenter={true}
        currentTab={currentTab}
        onChange={handleChange}
        tabList={tabList}
      />
      {values[currentTab]}
    </Stack>
  );
};
