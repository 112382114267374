import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Slider,
  Typography,
  Stack,
  Tabs,
  Tab,
  ToggleButton,
  ToggleButtonGroup,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import GroupsIcon from "@mui/icons-material/Groups";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  useGetUserGampaignQuery,
  usePatchCampaignMembersMutation,
} from "api/companyApi";
import { CompanyInfo } from "components/features/CompanyInfo";
import { ToggleSwitch } from "components/ui/ToggleSwitch";
import { ContactStats } from "components/ui/ContactStats";
import { DataTable } from "components/ui/DataTable";
import { StackedBarChart } from "components/ui/BarChart";
import { ChartTable } from "components/ui/ChartTable";
import { CommunicationIcon } from "components/images/CommunicationImg";
import { MaterialSymbolsTable } from "components/images/TableImg";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { MageChart } from "components/images/ChartImg";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHooks";
import { useCompanyData } from "hooks/useCompanyData";
import { useChartData } from "hooks/useChartData";
import { useCampaignMembers } from "hooks/useCompanyMembers";
import { Column } from "types/genereal";
import { AdGroupOutline } from "components/images/AdGroipImg";
import { AdGroupFilled } from "components/images/AdGroupFilled";
import { SortRounded } from "components/images/SortRoundedImg";
import { useCompany } from "hooks/useCompanyHook";
import {
  clearAnalysisStatus,
  setCompany,
  setUpdate,
} from "features/companySlice";
import { CustomButton } from "components/ui/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../types/enums";
import { AnalyseCompanyDataBlock } from "../components/ui/AnalyseCompanyDataBlock";
import { BaseModal } from "components/ui/BaseModal";
import { isTextEllipsed } from "../utils/general_utils";
import { IIcon } from "../components/images/IIcon";
import {
  NavigationBreadcrumbs,
  UploadContactListBlock,
} from "../components/ui";
import { CustomLoader } from "../components/ui/CustomLoader";
import { NoContactsBlock } from "../components/features";
import { GetExternalDataBtn } from "../components/features/GetExternalDataBtn";

export const AccountPage = () => {
  const { account_id } = useParams();
  const analysisStatus = useAppSelector(
    (state) => state.company.analysisStatus,
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { updateCompany, clearCompanyData } = useCompany();
  const [campaignIsLoading, setCampaignIsLoading] = useState(true);
  const updateData = useAppSelector((state) => state.company.update);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [currentPage] = useState(0);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedChanges, setSelectedChanges] = useState<{
    committee: { include: string[]; exclude: string[] };
    cluster: { include: string[]; exclude: string[] };
  }>({
    committee: { include: [], exclude: [] },
    cluster: { include: [], exclude: [] },
  });
  const {
    campaign_id,
    product_name,
    status,
    progress,
    companyInfo,
    original,
    enriched,
    expected,
    isError: companyIsError,
    isLoading: companyLoading,
    campaignRefetch,
    isUninitialized: isMembersUninitialized,
    percentage,
    is_analyzed,
    user_data,
  } = useCompanyData(account_id!);

  useEffect(() => {
    if (campaign_id) {
      setCampaignIsLoading(false);
      dispatch(setCompany(campaign_id));
    }
  }, [campaign_id]);

  const {
    data: userData,
    error: userError,
    refetch: userRefetch,
    isUninitialized: isUserUninitialized,
  } = useGetUserGampaignQuery(undefined, {
    skip: !!campaign_id || companyLoading,
    refetchOnMountOrArgChange: true,
  });

  const [updateMembers, { isLoading: updateMembersIsLoading }] =
    usePatchCampaignMembersMutation();
  const {
    chartData,
    refetch: chartRefetch,
    isUninitialized: isChartUninitialized,
  } = useChartData(account_id!);
  const {
    members,
    totalCount,
    updateQuery,
    refetch: membersRefetch,
    isUninitialized: isCampaignUninitialized,
  } = useCampaignMembers({
    account_id: account_id!,
    page: currentPage,
    per_page: rowsPerPage,
    sort: "committee_probability",
    order: "desc",
  });

  useEffect(() => {
    if (updateData === "account") {
      campaignRefetch();
      membersRefetch();
      chartRefetch();
      dispatch(setUpdate(""));
    }
  }, [updateData]);

  const [showEnrichedData, setShowEnrichedData] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [viewTable, setViewTable] = useState<"table" | "chart">("table");
  const [page, setPage] = useState(currentPage);

  const [sortBy, setSortBy] = useState<string>("committee_probability");
  const [order, setOrder] = useState<"asc" | "desc">("desc");
  const [range, setRange] = useState<number[]>([0, 100]);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState({
    committee: new Set<string>(),
    cluster: new Set<string>(),
  });
  const [selectionStats, setSelectionStats] = useState({
    committee: {
      selected: 0,
      expected: 0,
    },
    cluster: {
      selected: 0,
      expected: 0,
    },
  });

  const marks = [
    { value: 0 },
    { value: 20 },
    { value: 40 },
    { value: 50 },
    { value: 60 },
    { value: 80 },
    { value: 100 },
  ];

  const handleQueryChange = (query: {
    page: number;
    per_page: number;
    sort: string;
    order?: "asc" | "desc";
  }) => {
    setPage(query.page);
    setRowsPerPage(query.per_page);
    setSortBy(query.sort);
    setOrder(query.order ?? "desc");
    updateQuery({
      page: query.page,
      per_page: query.per_page,
      sort: query.sort,
      order: query.order,
    });
  };

  const handleMemberSelectionChange = (changes: any) => {
    setSelectedChanges((prev) => ({
      committee: {
        include: Array.from(
          new Set([...prev.committee.include, ...changes.committee.include]),
        ),
        exclude: Array.from(
          new Set([...prev.committee.exclude, ...changes.committee.exclude]),
        ),
      },
      cluster: {
        include: Array.from(
          new Set([...prev.cluster.include, ...changes.cluster.include]),
        ),
        exclude: Array.from(
          new Set([...prev.cluster.exclude, ...changes.cluster.exclude]),
        ),
      },
    }));

    const newSelectedMembers = {
      committee: new Set([...selectedMembers.committee]),
      cluster: new Set([...selectedMembers.cluster]),
    };
    //@ts-ignore
    const initialCommitteeHighlight = new Set(
      members
        .filter((r) =>
          ["ai_included", "manually_included"].includes(r.committee_status),
        )
        .map((r) => r.id),
    );
    const initialClusterHighlight = new Set(
      members
        .filter((r) =>
          ["ai_included", "manually_included"].includes(r.cluster_status),
        )
        .map((r) => r.id),
    );

    //@ts-ignore
    changes.committee.include.forEach((id) => {
      if (!newSelectedMembers.committee.has(id)) {
        newSelectedMembers.committee.add(id);
        if (
          !initialCommitteeHighlight.has(id) ||
          changes.committee.isBackendChange
        ) {
          setSelectionStats((prev) => ({
            ...prev,
            committee: {
              ...prev.committee,
              selected: prev.committee.selected + 1,
            },
          }));
        }
      }
    });
    //@ts-ignore
    changes.committee.exclude.forEach((id) => {
      if (newSelectedMembers.committee.has(id)) {
        newSelectedMembers.committee.delete(id);
        if (
          !initialCommitteeHighlight.has(id) ||
          changes.committee.isBackendChange
        ) {
          setSelectionStats((prev) => ({
            ...prev,
            committee: {
              ...prev.committee,
              selected: prev.committee.selected - 1,
            },
          }));
        }
      }
    });

    //@ts-ignore
    changes.cluster.include.forEach((id) => {
      if (!newSelectedMembers.cluster.has(id)) {
        newSelectedMembers.cluster.add(id);

        if (
          !initialClusterHighlight.has(id) ||
          changes.cluster.isBackendChange
        ) {
          setSelectionStats((prev) => ({
            ...prev,
            cluster: {
              ...prev.cluster,
              selected: prev.cluster.selected + 1,
            },
          }));
        }
      }
    });
    //@ts-ignore
    changes.cluster.exclude.forEach((id) => {
      if (newSelectedMembers.cluster.has(id)) {
        newSelectedMembers.cluster.delete(id);

        if (
          !initialClusterHighlight.has(id) ||
          changes.cluster.isBackendChange
        ) {
          setSelectionStats((prev) => ({
            ...prev,
            cluster: {
              ...prev.cluster,
              selected: prev.cluster.selected - 1,
            },
          }));
        }
      }
    });

    setSelectedMembers(newSelectedMembers);
  };

  useEffect(() => {
    if (campaign_id) {
      setPage(currentPage);
      setRowsPerPage(25);
      setSortBy("committee_probability");
      setOrder("desc");
    }
  }, [campaign_id]);

  useEffect(() => {
    if (analysisStatus === "completed") {
      if (campaign_id) {
        if (!isUserUninitialized) userRefetch?.();
        if (!isMembersUninitialized) membersRefetch?.();
        if (!isCampaignUninitialized) campaignRefetch?.();
        if (!isChartUninitialized) chartRefetch?.();
        dispatch(clearAnalysisStatus());
      }
    }
  }, [analysisStatus]);

  const isEnrichedDataEmpty =
    (enriched.committee_members === 0 || enriched.committee_members === null) &&
    (enriched.cluster_members === 0 || enriched.cluster_members === null);

  useEffect(() => {
    if (userData?.campaign_id) {
      updateCompany(userData.campaign_id);
    } else if (userError) {
      clearCompanyData();
    }
  }, [userData, clearCompanyData, updateCompany, campaign_id]);

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newViewTable: "table" | "chart" | null,
  ) => {
    if (newViewTable !== null) {
      setViewTable(newViewTable);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowEnrichedData(event.target.checked);
  };

  const dataCards = useMemo(() => {
    const getDescriptionAndPrefix = (
      originalValue: number,
      enrichedValue: number,
    ) => {
      const totalValue = originalValue + enrichedValue;
      return showEnrichedData
        ? {
            description: `${totalValue || "-"}`,
            imported: originalValue,
            enriched: enrichedValue,
          }
        : { description: `${originalValue || "-"}` };
    };

    const preparedCardData = [
      {
        title: "Buying commitee members",
        ...getDescriptionAndPrefix(
          original.committee_members,
          enriched.committee_members,
        ),
        icon: GroupsIcon,
        iconWidth: "36px",
        iconHeight: "36px",
      },
      {
        title: "Buying cluster members",
        ...getDescriptionAndPrefix(
          original.cluster_members,
          enriched.cluster_members,
        ),
        icon: PeopleAltIcon,
        iconWidth: "27px",
        iconHeight: "36px",
      },
    ];

    const engagementScore = showEnrichedData
      ? enriched.engagement_score
      : original.engagement_score;

    if (engagementScore !== null && engagementScore !== 0) {
      preparedCardData.push({
        title: "Total engagement score",
        description: engagementScore !== 0 ? `${engagementScore}%` : `-`,
        //@ts-ignore
        icon: CommunicationIcon, //TODO check types
        iconWidth: "51px",
        iconHeight: "49px",
      });
    }

    return preparedCardData;
  }, [original, enriched, showEnrichedData]);

  const handleEditModeStart = async () => {
    setIsEditMode(true);

    const initialCommitteeMembers = new Set(
      members
        .filter((r) =>
          ["ai_included", "manually_included"].includes(r.committee_status),
        )
        .map((r) => r.id),
    );
    const initialClusterMembers = new Set(
      members
        .filter((r) =>
          ["ai_included", "manually_included"].includes(r.cluster_status),
        )
        .map((r) => r.id),
    );

    const committeeCount = Number(dataCards[0]?.description) || 0;
    const clusterCount = Number(dataCards[1]?.description) || 0;

    setSelectionStats({
      committee: {
        selected: committeeCount,
        expected: expected.committee_members,
      },
      cluster: {
        selected: clusterCount,
        expected: expected.cluster_members,
      },
    });

    setSelectedMembers({
      committee: initialCommitteeMembers,
      cluster: initialClusterMembers,
    });

    setSelectedChanges({
      committee: { include: [], exclude: [] },
      cluster: { include: [], exclude: [] },
    });
  };

  const handleDiscardChanges = () => {
    setIsDiscardModalOpen(true);
  };

  const confirmDiscardChanges = () => {
    setIsEditMode(false);

    setSelectedChanges({
      committee: { include: [], exclude: [] },
      cluster: { include: [], exclude: [] },
    });

    setSelectedMembers({
      committee: new Set(),
      cluster: new Set(),
    });

    setIsDiscardModalOpen(false);
  };

  const chartTabData = useMemo(() => {
    const calculatePercentages = (
      expectedVal: number,
      originalVal: number,
      enrichedVal: number,
      minVal: number,
      maxVal: number,
    ) => ({
      importedDataPercentage: Number(
        Math.min((originalVal / expectedVal) * 100, 100).toFixed(2),
      ),
      enrichedDataPercentage: Number(
        Math.min(
          Number(Math.min((originalVal / expectedVal) * 100, 100).toFixed(2)) +
            (enrichedVal / expectedVal) * 100,
          100,
        ).toFixed(2),
      ),
      expectedSize: expectedVal,
      minSize: minVal,
      maxSize: maxVal,
    });

    return {
      committee: calculatePercentages(
        expected.committee_members,
        original.committee_members,
        enriched.committee_members,
        expected.committee_range.min,
        expected.committee_range.max,
      ),
      cluster: calculatePercentages(
        expected.cluster_members,
        original.cluster_members,
        enriched.cluster_members,
        expected.cluster_range.min,
        expected.cluster_range.max,
      ),
    };
  }, [expected, original, enriched]);

  const columns: Column[] = [
    {
      id: "data_source",
      label: "Source",
      minWidth: "6.5%",
      sortable: true,
      iconAsc: AdGroupFilled,
      iconDesc: AdGroupOutline,
    },
    { id: "full_name", label: "Name", minWidth: "15%" },
    { id: "seniority", label: "Seniority", minWidth: "8%" },
    { id: "job_title", label: "Job title", minWidth: "20%" },
    {
      id: "role",
      label: "BC role",
      minWidth: "10%",
      tooltip: "Probable role of the contact in a buying committee.",
    },
    {
      id: "committee_probability",
      label: "BC rate",
      minWidth: "11%",
      sortable: true,
      iconAsc: SortRounded,
      tooltip: "Potential rate of being involved in buying committee.",
    },
    {
      id: "cluster_probability",
      label: "Cluster rate",
      minWidth: "11%",
      sortable: true,
      iconAsc: SortRounded,
      tooltip:
        "Potential rate of being involved in buying cluster, a narrow final decision makers group of BC.",
    },
    {
      id: "engagement_score",
      label: "Engagement",
      minWidth: "11%",
      sortable: true,
      iconAsc: SortRounded,
      tooltip:
        "Rate of the contact engagement, based on outgoing and replied calls & emails, communication recency.",
    },
  ];

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const [newMin, newMax] = newValue as number[];
    if (newMin >= newMax) return;
    setRange([newMin, newMax]);
    updateQuery({
      min_percent: newMin,
      max_percent: newMax,
      page: currentPage,
    });
    setPage(currentPage);
  };

  const handleSaveChanges = async () => {
    setIsSaveModalOpen(true);
  };

  const confirmSaveChanges = async () => {
    const committeeInit = members
      .filter((r) =>
        ["ai_included", "manually_included"].includes(r.committee_status),
      )

      .map((r) => r.id);

    const clusterInit = members
      .filter((r) =>
        ["ai_included", "manually_included"].includes(r.cluster_status),
      )

      .map((r) => r.id);

    try {
      const combinedChanges = {
        committee: {
          include: selectedChanges.committee.include.filter(
            (el) =>
              !committeeInit.includes(el) &&
              Array.from(selectedMembers.committee).includes(el),
          ),
          exclude: selectedChanges.committee.exclude.filter(
            (el) =>
              committeeInit.includes(el) &&
              !Array.from(selectedMembers.committee).includes(el),
          ),
        },
        cluster: {
          include: selectedChanges.cluster.include.filter(
            (el) =>
              !clusterInit.includes(el) &&
              Array.from(selectedMembers.cluster).includes(el),
          ),
          exclude: selectedChanges.cluster.exclude.filter(
            (el) =>
              clusterInit.includes(el) &&
              !Array.from(selectedMembers.cluster).includes(el),
          ),
        },
      };

      if (
        combinedChanges.committee.exclude.length !== 0 ||
        combinedChanges.committee.include.length !== 0 ||
        combinedChanges.cluster.exclude.length !== 0 ||
        combinedChanges.cluster.include.length !== 0
      ) {
        await updateMembers({
          account_id: account_id!,
          ...combinedChanges!,
        });
      }

      setIsEditMode(false);

      if (!isUserUninitialized) userRefetch?.();
      if (!isMembersUninitialized) membersRefetch?.();
      if (!isCampaignUninitialized) campaignRefetch?.();
      if (!isChartUninitialized) chartRefetch?.();

      setSelectedChanges({
        committee: { include: [], exclude: [] },
        cluster: { include: [], exclude: [] },
      });

      setIsSaveModalOpen(false);
    } catch (error) {
      console.error("Failed to update members:", error);
    }
  };

  if (campaignIsLoading && !companyIsError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        width="100%"
      >
        <CircularProgress size={80} sx={{ color: "#FE8836" }} />
      </Box>
    );
  }

  const committeeSelected = selectionStats.committee.selected;
  const committeeExpected = selectionStats.committee.expected;
  const isCommitteeExceeded = committeeSelected > committeeExpected;

  const clusterSelected = selectionStats.cluster.selected;
  const clusterExpected = selectionStats.cluster.expected;
  const isClusterExceeded = clusterSelected > clusterExpected;

  const breadcrumbs = [
    {
      title: product_name,
      path: `${AppRoutes.PRODUCTS}/${campaign_id}`,
    },
    {
      title: "Accounts",
      path: `${AppRoutes.ACCOUNTS_LIST}/${campaign_id}`,
    },
    {
      title: companyInfo.name,
    },
  ];

  const refetchDataHandler = () => {
    membersRefetch();
    chartRefetch();
    campaignRefetch();
  };

  if (!campaign_id) {
    navigate(AppRoutes.PRODUCTS, { replace: true });
    return <CustomLoader />;
  }

  if (totalCount <= 0 && !companyLoading) {
    return (
      <NoContactsBlock
        account_id={account_id!}
        status={status}
        progress={progress}
        name={companyInfo.name}
        size={companyInfo.size}
        breadcrumbs={breadcrumbs}
        refetch={refetchDataHandler}
      />
    );
  }

  return (
    <Stack sx={{ px: { sm: 8, md: 8, lg: 14, xl: 20 }, pt: 6, pb: 20, gap: 7 }}>
      <BaseModal
        open={isDiscardModalOpen}
        onClose={() => setIsDiscardModalOpen(false)}
        title="Discard account"
        sx={{ padding: "32px", maxWidth: "368px", width: "90%" }}
      >
        <Stack gap="32px" maxWidth="304px" width="100%">
          <Typography component="span" variant="body1" color="text.secondary">
            Are you sure you want to clear data about “Company”?
          </Typography>
          <Stack direction="row" gap="8px">
            <CustomButton
              onClick={() => setIsDiscardModalOpen(false)}
              variant="contained"
              textColor="#4C545B"
              fullWidth
              type="button"
              customColor="#F1F1F1"
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={confirmDiscardChanges}
              variant="contained"
              fullWidth
              type="button"
              customColor="#CF3E00"
            >
              Discard
            </CustomButton>
          </Stack>
        </Stack>
      </BaseModal>
      <BaseModal
        open={isSaveModalOpen}
        onClose={() => setIsSaveModalOpen(false)}
        title="Save all changes"
        sx={{ padding: "32px", maxWidth: "368px", width: "90%" }}
      >
        <Stack gap="32px" maxWidth="340px" width="100%">
          <Typography component="span" variant="body1" color="text.secondary">
            Do you want to save changes in the table?
          </Typography>

          {(isCommitteeExceeded || isClusterExceeded) && (
            <Stack direction="row" gap="8px">
              <IIcon />
              <Stack>
                {isCommitteeExceeded && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="custom.textDarkBlue"
                  >
                    The buying committee exceeded the limit by{" "}
                    {committeeSelected - committeeExpected} on{" "}
                    {committeeSelected}/{committeeExpected}
                  </Typography>
                )}
                {isClusterExceeded && (
                  <Typography
                    component="span"
                    variant="body2"
                    color="custom.textDarkBlue"
                  >
                    The buying cluster exceeded the limit by{" "}
                    {clusterSelected - clusterExpected} on {clusterSelected}/
                    {clusterExpected}
                  </Typography>
                )}
              </Stack>
            </Stack>
          )}

          <Stack direction="row" gap="8px">
            <CustomButton
              onClick={() => setIsSaveModalOpen(false)}
              variant="contained"
              textColor="#4C545B"
              fullWidth
              type="button"
              customColor="#F1F1F1"
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={confirmSaveChanges}
              variant="contained"
              fullWidth
              type="button"
              customColor="#1C3C6C"
              startIcon={
                updateMembersIsLoading && (
                  <CircularProgress size={20} sx={{ color: "#FFFFFF" }} />
                )
              }
            >
              Save
            </CustomButton>
          </Stack>
        </Stack>
      </BaseModal>
      <Stack gap="16px">
        <Stack pb="8px">
          <NavigationBreadcrumbs breadcrumbs={breadcrumbs} />
        </Stack>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            gap: 1,
          }}
        >
          <Typography variant="h4" color="custom.textDarkBlue">
            General information
          </Typography>
          {isEnrichedDataEmpty ? (
            <Tooltip
              placement="bottom"
              arrow
              title="Approximation based the imported data"
            >
              <HelpOutlineIcon sx={{ color: "#1C3C6C" }} />
            </Tooltip>
          ) : null}
        </Box>
        <CompanyInfo
          {...companyInfo}
          id={account_id!}
          campaign_id={campaign_id}
          refetch={refetchDataHandler}
          is_analyzed={is_analyzed}
          status={status}
        />
      </Stack>
      <Stack gap="40px">
        {![
          "completed",
          "scraping_queued",
          "scraping_in_progress",
          "in_progress",
        ].includes(status) && (
          <Stack gap="16px">
            <AnalyseCompanyDataBlock
              companyName={companyInfo.name}
              progress={progress}
              account_id={account_id!}
              status={status}
              successCallBack={refetchDataHandler}
            />
          </Stack>
        )}

        {!user_data && (
          <UploadContactListBlock
            campaign_id={campaign_id}
            successCallBack={refetchDataHandler}
          />
        )}

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack gap="6px">
            <Typography variant="h4" color="custom.textDarkBlue">
              Contacts analysis results
            </Typography>
            <Box>
              <ToggleSwitch
                label="Show additional data"
                checked={showEnrichedData}
                onChange={handleToggleChange}
              />
            </Box>
          </Stack>
          <GetExternalDataBtn
            account_id={account_id!}
            refetch={refetchDataHandler}
            status={status}
          />
        </Stack>
        <Stack gap={3}>
          <Grid container spacing={3}>
            {dataCards.map((item, index) => (
              <Grid key={index} size={{ xs: 12, md: 4, sm: 6 }} component="div">
                <ContactStats {...item} />
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Stack>
      <Stack gap={3}>
        <Typography variant="h4" color="custom.textDarkBlue">
          Percentage of identified members
        </Typography>
        <Box>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            aria-label="Buying options"
            sx={{
              borderBottom: "1px solid",
              borderColor: "#D9D9D9",
            }}
          >
            <Tab
              label="Buying committee"
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24px",
                "&.Mui-selected": { color: "#1C3C6C" },
              }}
            />
            <Tab
              label="Buying cluster"
              sx={{
                fontSize: "20px",
                fontWeight: 500,
                lineHeight: "24px",
                "&.Mui-selected": { color: "#1C3C6C" },
              }}
            />
          </Tabs>
          <Box mt={2}>
            {selectedTab === 0 && (
              <StackedBarChart
                {...chartTabData.committee}
                original={percentage.committee.original}
                enriched={percentage.committee.enriched}
              />
            )}
            {selectedTab === 1 && (
              <StackedBarChart
                {...chartTabData.cluster}
                original={percentage.cluster.original}
                enriched={percentage.cluster.enriched}
              />
            )}
          </Box>
        </Box>
      </Stack>
      <Stack gap={3}>
        <Typography variant="h4" color="custom.textDarkBlue">
          Buying committee & cluster members identified
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            minHeight={"68px"}
          >
            {!isEditMode ? (
              <>
                <Stack flexDirection="row" gap={2}>
                  <Stack gap={1}>
                    <Typography variant="caption" color="custom.textDarkBlue">
                      View
                    </Typography>
                    <ToggleButtonGroup
                      value={viewTable}
                      exclusive
                      onChange={handleViewChange}
                      aria-label="view toggle"
                      sx={{ height: "40px" }}
                    >
                      <ToggleButton
                        value="table"
                        aria-label="table view"
                        sx={{ width: "55px" }}
                      >
                        <MaterialSymbolsTable active={viewTable === "table"} />
                      </ToggleButton>
                      <ToggleButton
                        value="chart"
                        aria-label="chart view"
                        sx={{
                          width: "55px",
                        }}
                      >
                        <MageChart active={viewTable === "chart"} />
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Stack>
                  {viewTable === "table" && (
                    <>
                      <Stack sx={{ width: "288px" }} gap={1}>
                        <Typography
                          variant="caption"
                          color="custom.textDarkBlue"
                        >
                          Buying committee % range
                        </Typography>
                        <Stack
                          flexDirection="row"
                          gap={2}
                          alignItems={"center"}
                          paddingTop={"4px"}
                        >
                          <Typography
                            variant="body1"
                            color="custom.textDarkBlue"
                          >
                            0
                          </Typography>
                          <Slider
                            value={range}
                            onChange={handleSliderChange}
                            step={5}
                            marks={marks}
                            valueLabelDisplay="auto"
                            min={0}
                            max={100}
                            sx={{
                              color: "#1C3C6C",
                              "& .MuiSlider-rail": {
                                bgcolor: "#D9D9D9",
                              },
                              "& .MuiSlider-thumb": {
                                width: 20,
                                height: 20,

                                "&:focus, &:hover, &.Mui-active": {
                                  boxShadow:
                                    "0px 0px 0px 8px rgba(0, 0, 255, 0.2)",
                                },
                              },
                              "& .MuiSlider-valueLabel": {
                                width: 41,
                                height: 30,
                                borderRadius: "4px",
                                backgroundColor: "#757575",
                              },
                            }}
                          />
                          <Typography
                            variant="body1"
                            color="custom.textDarkBlue"
                          >
                            100
                          </Typography>
                        </Stack>
                      </Stack>
                      <Box
                        marginTop={3}
                        marginLeft={3}
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        gap={3}
                      >
                        <Typography variant="body1" color="text.secondary">
                          Imported: {companyInfo.contactsImported}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          Total: {totalCount}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Stack>
              </>
            ) : (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  gap={8}
                >
                  <Typography variant="body1" color="text.secondary">
                    Selected BC vs. expected BC size:
                  </Typography>
                  <Typography color="custom.textDarkBlue">
                    Buying committee{" "}
                    <Typography color="text.secondary" component="span">
                      {selectionStats.committee.selected}/
                      {selectionStats.committee.expected}
                    </Typography>
                  </Typography>
                  <Typography color="custom.textDarkBlue">
                    Buying cluster{" "}
                    <Typography color="text.secondary" component="span">
                      {selectionStats.cluster.selected}/
                      {selectionStats.cluster.expected}
                    </Typography>
                  </Typography>
                </Box>
              </>
            )}
            {viewTable === "table" ? (
              <Box
                display="flex"
                flexDirection="row"
                alignItems={"center"}
                gap={3}
              >
                {!isEditMode ? (
                  <Tooltip
                    title={
                      "You can edit the analysis results by manually choosing the members based on available" +
                      " information and experience. Your selections will be saved for further analysis and" +
                      " data updates."
                    }
                    placement="bottom"
                    arrow
                    sx={{
                      background: "#596177E5",
                      fontSize: "12px",
                      lineHeight: "16px",
                      fontWeight: 400,
                      "&:hover": {
                        visibility: "visible",
                        opacity: 1,
                      },
                    }}
                  >
                    <div
                      onMouseEnter={(
                        event: React.MouseEvent<HTMLDivElement>,
                      ) => {
                        isTextEllipsed(event.currentTarget);
                      }}
                    >
                      <CustomButton
                        customSize="s"
                        customColor="#1C3C6C"
                        onClick={handleEditModeStart}
                        sx={{ marginLeft: 2 }}
                      >
                        Edit list
                      </CustomButton>
                    </div>
                  </Tooltip>
                ) : (
                  <Box display="flex" gap={1}>
                    <CustomButton
                      sx={{
                        width: "161px",
                        maxWidth: "100%",
                        fontWeight: 500,
                      }}
                      customColor="#F1F1F1"
                      textColor="text.secondary"
                      onClick={handleDiscardChanges}
                    >
                      Discard changes
                    </CustomButton>
                    <CustomButton
                      sx={{ width: "100%", maxWidth: "141px" }}
                      customColor="#1C3C6C"
                      onClick={handleSaveChanges}
                    >
                      Save changes
                    </CustomButton>
                  </Box>
                )}
              </Box>
            ) : (
              <Box mt="auto" display="flex" flexDirection="row" gap={3}>
                <Box display="flex" alignItems="center" gap={2}>
                  <Box
                    sx={{
                      width: 24,
                      height: 20,
                      backgroundColor: "#83AFD3",
                    }}
                  />
                  <Typography variant="body1" color="#1F2932">
                    Buying committee
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" gap={2}>
                  <Box
                    sx={{
                      width: 24,
                      height: 20,
                      backgroundColor: "#0860A8",
                    }}
                  />
                  <Typography variant="body1" color="#1F2932">
                    Buying cluster
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box mt={4} width="100%" textAlign="center">
            {viewTable === "table" ? (
              <DataTable
                key={campaign_id}
                columns={columns}
                rows={members}
                totalRows={totalCount}
                page={page}
                rowsPerPage={rowsPerPage}
                sortBy={sortBy}
                order={order}
                onQueryChange={handleQueryChange}
                isEditMode={isEditMode}
                onSelectionChange={handleMemberSelectionChange}
                selectedMembers={selectedMembers}
                selectionStats={selectionStats}
                selectedChanges={selectedChanges}
              />
            ) : (
              <Box style={{ margin: "0 auto" }}>
                <ChartTable items={chartData} />
              </Box>
            )}
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};
