import { IconProps } from "../../types/genereal";
import { IconWrapper } from "components/ui/IconWrapper";

export const PersonInCircleIcon = ({
  width = "24",
  height = "24",
  color = "#1D3E70",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.35 17.1C7.2 16.45 8.15 15.9377 9.2 15.563C10.25 15.1883 11.35 15.0007 12.5 15C13.65 14.9993 14.75 15.187 15.8 15.563C16.85 15.939 17.8 16.4513 18.65 17.1C19.2333 16.4167 19.6877 15.6417 20.013 14.775C20.3383 13.9083 20.5007 12.9833 20.5 12C20.5 9.78333 19.721 7.89567 18.163 6.337C16.605 4.77833 14.7173 3.99933 12.5 4C10.2827 4.00067 8.395 4.78 6.837 6.338C5.279 7.896 4.5 9.78333 4.5 12C4.5 12.9833 4.66267 13.9083 4.988 14.775C5.31333 15.6417 5.76733 16.4167 6.35 17.1ZM12.5 13C11.5167 13 10.6873 12.6627 10.012 11.988C9.33667 11.3133 8.99933 10.484 9 9.5C9.00067 8.516 9.33833 7.68667 10.013 7.012C10.6877 6.33733 11.5167 6 12.5 6C13.4833 6 14.3127 6.33767 14.988 7.013C15.6633 7.68833 16.0007 8.51733 16 9.5C15.9993 10.4827 15.662 11.312 14.988 11.988C14.314 12.664 13.4847 13.0013 12.5 13ZM12.5 22C11.1167 22 9.81667 21.7373 8.6 21.212C7.38334 20.6867 6.325 19.9743 5.425 19.075C4.525 18.1757 3.81267 17.1173 3.288 15.9C2.76333 14.6827 2.50067 13.3827 2.5 12C2.49933 10.6173 2.762 9.31733 3.288 8.1C3.814 6.88267 4.52633 5.82433 5.425 4.925C6.32367 4.02567 7.382 3.31333 8.6 2.788C9.818 2.26267 11.118 2 12.5 2C13.882 2 15.182 2.26267 16.4 2.788C17.618 3.31333 18.6763 4.02567 19.575 4.925C20.4737 5.82433 21.1863 6.88267 21.713 8.1C22.2397 9.31733 22.502 10.6173 22.5 12C22.498 13.3827 22.2353 14.6827 21.712 15.9C21.1887 17.1173 20.4763 18.1757 19.575 19.075C18.6737 19.9743 17.6153 20.687 16.4 21.213C15.1847 21.739 13.8847 22.0013 12.5 22Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  );
};
