import { Stack, Typography } from "@mui/material";
import React from "react";
import { CustomButton } from "../ui/CustomButton";
import { AppRoutes } from "../../types/enums";
import { useNavigate } from "react-router-dom";
import { CurrentCampaign } from "types/apiTypes";
import { QuestionMarkInCircleIcon } from "../images/QuestionMarkInCircleIcon";
import { ReuseTooltip } from "../ui";

export const StatisticsBlock = ({
  campaign,
}: {
  campaign: CurrentCampaign;
}) => {
  const {
    id,
    avg_engagement_score,
    total_accounts,
    total_accounts_analyzed,
    total_committee_members,
    total_cluster_members,
  } = campaign;

  const navigate = useNavigate();

  const data = [
    {
      title: `${total_accounts_analyzed ?? "--"} of ${total_accounts ?? "--"}`,
      description: "accounts analyzed",
      tooltip: "",
    },
    {
      title: total_committee_members ?? "--",
      description: "committee members detected",
      tooltip:
        "Potential buying committee members identified in available data.",
    },
    {
      title: total_cluster_members ?? "--",
      description: "cluster members identified",
      tooltip:
        "Potential buying cluster members, identified in available data, as a narrow final decision makers group" +
        " inside buying committee.",
    },
    {
      title: avg_engagement_score ? `${avg_engagement_score}%` : "--",
      description: "average engagement score",
      tooltip:
        "Engagement rate of all identified potential BC members, based on outgoing and replied calls & emails," +
        " communication recency over all time.",
    },
  ];
  return (
    <Stack
      sx={{
        maxWidth: "380px",
        minWidth: "280px",
        width: "100%",
        padding: "24px 16px",
        gap: "24px",
        borderRadius: "6px",
        boxShadow: "0 4px 15.8px 0 #0000001A",
      }}
    >
      <Typography
        variant="h4"
        color="custom.textDarkBlue"
        sx={{
          borderBottom: "1px solid #D9D9D9",
          paddingBottom: "16px",
        }}
      >
        Statistics
      </Typography>
      <Stack gap="12px">
        {data.map(({ title, description, tooltip }) => (
          <Stack key={description}>
            <Stack
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              gap="16px"
            >
              <Typography variant="h4" color="custom.textDarkBlue">
                {title}
              </Typography>
              <ReuseTooltip text={tooltip}>
                <QuestionMarkInCircleIcon />
              </ReuseTooltip>
            </Stack>
            <Typography
              variant="button"
              color="#3F4041B2"
              sx={{ fontWeight: 400 }}
            >
              {description}
            </Typography>
          </Stack>
        ))}
      </Stack>
      <CustomButton
        variant="contained"
        fullWidth
        type="button"
        customColor="#1C3C6C"
        sx={{ height: "38px" }}
        onClick={() => navigate(`${AppRoutes.ACCOUNTS_LIST}/${id}`)}
        disabled={total_accounts === 0}
      >
        View accounts
      </CustomButton>
    </Stack>
  );
};
