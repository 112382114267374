import { Divider, Stack, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { NavigationBreadcrumbs } from "../components/ui";
import { CustomButton } from "../components/ui/CustomButton";
import { LinksIcon, MingcutePdfIcon, PenIcon } from "../components/images";
import {
  CommitteMemberRoleBlock,
  DeleteProductBtn,
  NoCompletedAnalysisBlock,
  ProductDetailsModal,
  ProductNotes,
  StatisticsBlock,
} from "../components/products";
import { useState } from "react";
import { useGetCurrentCampaignQuery } from "../api/companyApi";
import { UploadProductDataBlock } from "../components/ui/UploadProductDataBlock";

export const ProductPage = () => {
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const { campaign_id } = useParams();

  const { data, refetch } = useGetCurrentCampaignQuery(
    {
      campaign_id: campaign_id!,
    },
    { skip: !campaign_id, refetchOnMountOrArgChange: true },
  );

  if (!data) return null;

  const {
    company_name,
    product_name,
    product_abstract,
    target_audience,
    notes,
    roles,
    source_pdf_name,
    source_url,
    total_accounts,
    total_accounts_analyzed,
    notes_updated_at,
  } = data;

  const productDescription = [
    {
      title: "Product description",
      description: product_abstract,
    },
    {
      title: "Target audience",
      description: target_audience,
    },
  ];

  const breadcrumbs = [
    {
      title: product_name,
    },
  ];

  return (
    <Stack
      sx={{ px: { sm: 8, md: 8, lg: 14, xl: 20 }, pt: 6, pb: 6 }}
      gap="40px"
    >
      <Stack gap="24px">
        <NavigationBreadcrumbs breadcrumbs={breadcrumbs} />
        <Stack
          direction="row"
          gap="80px"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack gap="24px">
            <Stack direction="row" alignItems="center" gap="16px">
              <Typography variant="h4" color="custom.textDarkBlue">
                {product_name}
              </Typography>
              <Stack
                sx={{
                  minWidth: "8px",
                  minHeight: "8px",
                  backgroundColor: "#4C545B",
                  borderRadius: "50%",
                }}
              ></Stack>
              <Typography variant="h4" color="#797F84">
                {company_name}
              </Typography>
            </Stack>

            <Stack direction="row" gap="8px" minWidth="340px">
              <CustomButton
                onClick={() => setIsEditModal(true)}
                variant="outlined"
                customColor="#FFFFFF"
                textColor="#1D3E70"
                customSize="fit"
                type="button"
                startIcon={<PenIcon />}
                sx={{ height: "38px", borderColor: "#1D3E70" }}
              >
                Edit
              </CustomButton>
              <DeleteProductBtn campaign_id={campaign_id!} />
            </Stack>

            {total_accounts_analyzed === 0 && total_accounts > 0 && (
              <NoCompletedAnalysisBlock />
            )}

            <Stack gap="24px">
              {productDescription.map(({ title, description }) => (
                <Stack gap="8px" key={title}>
                  <Typography
                    variant="subtitle1"
                    color="custom.textDarkBlue"
                    sx={{ fontWeight: 500 }}
                  >
                    {title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {description}
                  </Typography>
                </Stack>
              ))}
              <Stack gap="8px">
                <Typography
                  variant="subtitle1"
                  color="custom.textDarkBlue"
                  sx={{ fontWeight: 500 }}
                >
                  Sources
                </Typography>
                <Stack direction="row" alignItems="center" gap="10px">
                  {source_url && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="10px"
                      width="50%"
                    >
                      <LinksIcon />
                      <Link
                        to={source_url}
                        target="_blank"
                        style={{ color: "#0069C4" }}
                      >
                        {source_url.length > 30
                          ? `${source_url.substring(0, 30)}...`
                          : source_url}
                      </Link>
                    </Stack>
                  )}

                  {source_pdf_name && (
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap="10px"
                      width="50%"
                    >
                      <MingcutePdfIcon />
                      <span
                        style={{
                          color: "#0069C4",
                          maxWidth: "320px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {source_pdf_name.length > 30
                          ? `${source_pdf_name.substring(0, 30)}...`
                          : source_pdf_name}
                      </span>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {total_accounts > 0 ? (
            <StatisticsBlock campaign={data} />
          ) : (
            <UploadProductDataBlock
              refetch={refetch}
              campaign_id={campaign_id!}
            />
          )}
        </Stack>
      </Stack>
      <ProductNotes
        campaign_id={campaign_id!}
        notes={notes ?? "No additional data yet"}
        last_updated={notes_updated_at}
        successHandler={() => {
          refetch();
        }}
      />
      <Divider />

      <CommitteMemberRoleBlock roles={roles} />

      {isEditModal && (
        <ProductDetailsModal
          initProductUrl={source_url || ""}
          initPdfFileName={source_pdf_name || ""}
          initData={{
            company_name,
            product_abstract,
            target_audience,
            product_name,
          }}
          campaign_id={campaign_id!}
          isOpen={isEditModal}
          onClose={() => setIsEditModal(false)}
          onSubmit={() => {
            setIsEditModal(false);
            refetch();
          }}
        />
      )}
    </Stack>
  );
};
