import { Stack, Typography } from "@mui/material";
import { CustomButton } from "../ui/CustomButton";
import { useState } from "react";
import { ChangePasswordModal } from "../ui/modal";
import { AppRoutes } from "../../types/enums";
import { useNavigate } from "react-router-dom";

type Props = {
  email: string;
  has_password: boolean;
};

export const ProfileChangePasswordBlock = ({ email, has_password }: Props) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openModalHandler = () => {
    has_password
      ? setIsOpen(true)
      : navigate(`${AppRoutes.FORGOT_PASSWORD}?email=${email}`);
  };

  return (
    <Stack gap="16px">
      <Stack gap="4px">
        <Typography
          variant="subtitle1"
          color="custom.textDarkBlue"
          fontWeight={500}
        >
          Password
        </Typography>
        <Typography variant="body1" color="custom.textDarkBlue">
          Change your password to keep your account secure.
        </Typography>
      </Stack>
      <CustomButton
        onClick={openModalHandler}
        variant="contained"
        textColor="#4C545B"
        type="button"
        customColor="#F1F1F1"
        customSize="fit"
      >
        Change password
      </CustomButton>
      {isOpen && (
        <ChangePasswordModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          email={email}
        />
      )}
    </Stack>
  );
};
