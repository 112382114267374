import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../types/enums";

export const useLogout = () => {
  const navigate = useNavigate();
  return () => {
    localStorage.clear();
    navigate(AppRoutes.LOGIN, { replace: true });
  };
};
