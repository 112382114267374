import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { ChangeEvent, ReactNode } from "react";

type Props = {
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  prefixIcon?: ReactNode;
};

export const CustomInput = ({
  value,
  placeholder,
  onChange,
  prefixIcon,
}: Props) => {
  return (
    <TextField
      sx={{
        width: "100%",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "#CFCFCF",
          },
          "&:hover": {
            borderColor: "#CFCFCF",
          },
          "&.Mui-focused": {
            borderColor: "#CFCFCF",
          },
        },
      }}
      value={value}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      placeholder={placeholder}
      slotProps={{
        input: {
          startAdornment: prefixIcon && (
            <Box display="flex" alignItems="center" justifyContent="center">
              {prefixIcon}
            </Box>
          ),
        },
      }}
    />
  );
};
