import React, { useEffect, useState } from "react";
import { Typography, Box, Stack, CircularProgress, Link } from "@mui/material";
import { useForgotPasswordMutation } from "api/authApi";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../types/enums";
import { ReuseInput } from "../components/ui";
import { EMAIL_REGEX } from "../utils/regex";
import { CustomButton } from "../components/ui/CustomButton";
import { getErrorMessage } from "../utils/getErrorMessage";
import { toast } from "react-toastify";
import { CustomToast } from "../components/ui/CustomToast";
import { useStateWithSearchParams } from "../hooks/useStateWithSearchParams";

type Errors = { [key: string]: boolean };

const validation: { [key: string]: (value: string) => boolean } = {
  email: (value: string) => !value || !EMAIL_REGEX.test(value),
};

type Fields = {
  email: string;
};

type SearchParams = {
  email?: string;
};

export const ForgotPasswordPage = () => {
  const [
    forgotPassword,
    { isLoading, error: forgotPasswordError, isSuccess: forgotPasswordSuccess },
  ] = useForgotPasswordMutation();

  const [errorMessage, setErrorMessage] = useState<Fields>({ email: "" });

  const [formData, setFormData] = useState<Fields>({ email: "" });

  const [error, setError] = useState<Errors>({
    email: false,
  });

  const { searchDict } = useStateWithSearchParams<SearchParams>({
    email: undefined,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (searchDict.email) {
      navigate(AppRoutes.FORGOT_PASSWORD, { replace: true });
      setFormData({ email: searchDict.email });
    }
  }, [searchDict?.email]);

  useEffect(() => {
    if (forgotPasswordSuccess) {
      navigate(`${AppRoutes.VERIFY_CODE}?email=${formData.email}`);
    }
  }, [forgotPasswordSuccess]);

  useEffect(() => {
    if (forgotPasswordError) {
      if (!getErrorMessage(forgotPasswordError).globalError) {
        setErrorMessage((err) => ({
          ...err,
          ...getErrorMessage(forgotPasswordError).fieldErrors,
        }));
      } else {
        toast.error(
          <CustomToast
            type={"error"}
            text={
              getErrorMessage(forgotPasswordError).globalError ||
              "Something went wrong during reset password. Please try again"
            }
            title={"Reset password error"}
          />,
        );
      }
    }
  }, [forgotPasswordError]);

  const setErrorHandler = (field: string, value: boolean) => {
    setError((prev) => ({ ...prev, [field]: value }));
  };

  const inputChangeHandler = (field: string, value: string) => {
    setErrorMessage((prev) => ({ ...prev, [field]: "" }));
    setFormData((prev) => ({ ...prev, [field]: value }));
    setError((prev) => ({
      ...prev,
      [field]: validation[field](value),
    }));
  };

  const sendForgotPasswordEmail = () => {
    forgotPassword({ email: formData.email });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="calc(100vh - 74px)"
    >
      <Stack width="368px" textAlign="center" gap="32px">
        <Stack gap={1} pb={2}>
          <Typography variant="h1" component="h1" textAlign="left">
            Reset password
          </Typography>
          <Typography variant="subtitle1" color="text.primary" textAlign="left">
            Please enter your email address to get verification code.
          </Typography>
        </Stack>

        <ReuseInput
          name="email"
          handleInputChange={inputChangeHandler}
          errorMessage={errorMessage.email || "Valid email is required"}
          value={formData.email || ""}
          label="Email"
          placeholder="example@email.com"
          isError={error.email || !!errorMessage.email}
          setErrorHandler={setErrorHandler}
        />
        <Stack gap="8px" alignItems="flex-start">
          <CustomButton
            variant="contained"
            fullWidth
            type="button"
            customColor="#F16300"
            customGradientColor="#FE8836"
            disabled={
              isLoading ||
              Object.values(error).includes(true) ||
              !formData.email
            }
            startIcon={
              isLoading && <CircularProgress size={20} color="inherit" />
            }
            onClick={sendForgotPasswordEmail}
          >
            Get the code
          </CustomButton>

          <Typography variant="body1" color="#4C545B" fontWeight={500}>
            Back to{" "}
            <Link
              href={AppRoutes.LOGIN}
              sx={{
                textDecoration: "none",
                fontWeight: 500,
                color: "#000000DE",
                "&:hover": {
                  color: "#2194D2",
                },
              }}
            >
              Sign in
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
