import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "../../dropdown/Dropdown";
import { ReuseInput } from "../../ReuseInput";
import { NAME_REGEX, URL_REGEX } from "../../../../utils/regex";
import { CustomButton } from "../../CustomButton";
import { useEnterCompanyManuallyMutation } from "../../../../api/companyApi";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { toast } from "react-toastify";
import { CustomToast } from "../../CustomToast";
import { AppRoutes } from "../../../../types/enums";
import { useGetAccountSizeQuery } from "../../../../api";
import { AccountSizes } from "../../../../types/apiTypes";
import { setCreateProductAnalyses } from "../../../../features/companySlice";
import { useAppDispatch } from "../../../../hooks/useReduxHooks";

export type Fields = {
  company_name: string;
  company_url: string;
};

const validation: { [key: string]: (value: string) => boolean } = {
  company_name: (value: string) => !NAME_REGEX.test(value),
  company_url: (value: string) => !!value && !URL_REGEX.test(value),
};

export type Errors = { [key: string]: boolean };

type Props = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  backHandler: () => void;
  onClose: () => void;
  getCampaignId?: () => Promise<string | void>;
};

export const ManualEnterData = ({
  isLoading,
  setIsLoading,
  backHandler,
  onClose,
  getCampaignId,
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isCurrentLoading, setIsCurrentLoading] = useState<boolean>(false);
  const [size, setSize] = useState<AccountSizes>({ id: "", display: "" });
  const [formData, setFormData] = useState<Fields>({
    company_name: "Company name",
    company_url: "",
  });

  const [errorMessage, setErrorMessage] = useState<Fields>({
    company_name: "",
    company_url: "",
  });

  const [error, setError] = useState<Errors>({
    company_name: false,
    company_url: false,
  });

  const [enterCompanyManually] = useEnterCompanyManuallyMutation();
  const { data: companySizes } = useGetAccountSizeQuery();

  useEffect(() => {
    isCurrentLoading && setIsLoading && setIsLoading(isCurrentLoading);
  }, [isCurrentLoading]);

  useEffect(() => {
    if (companySizes) {
      setSize(companySizes[0]);
    }
  }, [companySizes]);

  const inputChangeHandler = (field: string, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setError((prev) => ({
      ...prev,
      [field]: validation[field](value),
    }));
  };

  const setErrorHandler = (field: string, value: boolean) => {
    setError((prev) => ({ ...prev, [field]: value }));
  };

  const submitHandler = async () => {
    let id: string = "";
    setIsCurrentLoading(true);
    dispatch(setCreateProductAnalyses(true));

    if (getCampaignId) {
      id = (await getCampaignId()) || "";
    }
    enterCompanyManually({
      campaign_id: id,
      name: formData.company_name,
      website_url: formData.company_url || undefined,
      size: size.id,
    })
      .unwrap()
      .then(() => {
        toast.success(
          <CustomToast
            type={"success"}
            text={"Check product information and accounts analysis results."}
            title={"New product is added"}
          />,
        );
        navigate(`${AppRoutes.PRODUCTS}/${id}`);
        onClose();
      })
      .catch((err) => {
        if (getErrorMessage(err).fieldErrors) {
          const [field, value] = Object.entries(
            getErrorMessage(err).fieldErrors,
          );
          setErrorMessage((el) => ({
            ...el,
            ...getErrorMessage(err).fieldErrors,
          }));
          setError((prev) => ({
            ...prev,
            [typeof field === "string" ? field : ""]: !!value,
          }));
        } else {
          toast.error(
            <CustomToast
              type={"error"}
              text={
                getErrorMessage(err).globalError ||
                "Something went wrong during processing. Please try again"
              }
              title={"Changes are not saved"}
            />,
          );
        }
      })
      .finally(() => {
        dispatch(setCreateProductAnalyses(false));
        setIsCurrentLoading(false);
      });
  };

  const sizeChangeHandler = (value: string) => {
    const item = companySizes?.find((el) => el.display === value);
    item && setSize(item);
  };

  return (
    <Stack gap="24px">
      <Stack gap="16px">
        <Typography variant="body1" color="#4C545B" fontWeight={500}>
          Provide basic information about targeted accounts for high overview
          insights analysis.
        </Typography>
        <Typography variant="body1" color="#4C545B" fontWeight={500}>
          Check{" "}
          <Link
            to={AppRoutes.PRODUCTS_REQUIREMENTS}
            target="_blank"
            style={{ textDecoration: "none", color: "#0069C4" }}
          >
            Field Requirements
          </Link>{" "}
          to see the full list of accounts and contacts related fields.
        </Typography>
      </Stack>
      <Stack gap="24px" paddingY="16px">
        <ReuseInput
          name="company_name"
          handleInputChange={inputChangeHandler}
          errorMessage={
            errorMessage.company_name ||
            "Must contain only letters, numbers, and basic punctuation"
          }
          value={formData.company_name || ""}
          label="Company name *"
          maxLength={100}
          placeholder=" e.g., INFUSE"
          isError={error.company_name}
          setErrorHandler={setErrorHandler}
        />
        <Box sx={{ paddingBottom: "16px" }}>
          <Dropdown
            value={size?.display || ""}
            onChange={sizeChangeHandler}
            label="Employee size *"
            placeholder={"Choose employee size"}
            items={companySizes?.map(({ display }) => display) || []}
          />
        </Box>
        <Stack>
          <ReuseInput
            name="company_url"
            handleInputChange={inputChangeHandler}
            errorMessage={
              errorMessage.company_url || (
                <Stack component="span" width="100%">
                  <span>
                    Invalid URL: Please check the format and try again.
                  </span>
                  <span>Example: https://www.example.com</span>
                </Stack>
              )
            }
            value={formData.company_url || ""}
            label="Company website url"
            placeholder="https://www.example.com"
            isError={error.company_url}
            setErrorHandler={setErrorHandler}
          />
          {error.company_url && <Stack sx={{ height: "20px" }}></Stack>}
        </Stack>
      </Stack>
      <Stack direction="row" gap="8px">
        <CustomButton
          onClick={backHandler}
          variant="contained"
          textColor="#4C545B"
          fullWidth
          type="button"
          customColor="#F1F1F1"
          disabled={isCurrentLoading || isCurrentLoading}
        >
          Back
        </CustomButton>
        <CustomButton
          onClick={submitHandler}
          variant="contained"
          fullWidth
          type="button"
          customColor="#1C3C6C"
          disabled={isLoading || isCurrentLoading}
          startIcon={
            (isLoading || isCurrentLoading) && (
              <CircularProgress size={20} color="inherit" />
            )
          }
        >
          Analyse
        </CustomButton>
      </Stack>
    </Stack>
  );
};
