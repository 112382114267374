import { Box } from "@mui/material";
import {
  AverageRateBlock,
  DiscoveryRate,
  GapsBlock,
  NoAnalyticsData,
} from "./";
import { useStateWithSearchParams } from "../../../hooks/useStateWithSearchParams";
import { useGetAnalyticsQuery } from "../../../api";
import { Order } from "../../../types";
import { useParams } from "react-router-dom";
import { useDebounceValue } from "../../../hooks/useDebounceValue";
import { CustomLoader } from "../../ui/CustomLoader";
import { useAppDispatch, useAppSelector } from "../../../hooks/useReduxHooks";
import { useEffect } from "react";
import { setUpdateAnalytics } from "../../../features/companySlice";
import { toast } from "react-toastify";
import { CustomToast } from "../../ui/CustomToast";
import { getErrorMessage } from "../../../utils/getErrorMessage";

type SearchParams = {
  sizes?: string;
  industries?: string;
  regions?: string;
  search?: string;
  sort?: string;
  page?: string;
  per_page?: string;
  selected?: string;
  limit?: string;
  date?: string;
};

export const AnalyticBlock = ({ tabs }: { tabs?: string }) => {
  const updateAnalytics = useAppSelector(
    (state) => state.company.updateAnalytics,
  );
  const dispatch = useAppDispatch();
  const { campaign_id } = useParams();
  const { searchDict } = useStateWithSearchParams<SearchParams>({
    industries: undefined,
    regions: undefined,
    sizes: undefined,
    search: undefined,
    page: undefined,
    per_page: undefined,
    selected: undefined,
    limit: undefined,
    date: undefined,
  });

  const searchValueDebounce = useDebounceValue(searchDict.search || "");

  const { data, refetch, error, isLoading } = useGetAnalyticsQuery(
    {
      campaign_id: campaign_id!,
      limit: searchDict.limit ? Number(searchDict?.limit) : undefined,
      size: searchDict.sizes?.split(","),
      search: searchValueDebounce || undefined,
      industry: searchDict.industries?.split(","),
      region: searchDict.regions?.split(","),
      sort: tabs,
      order: searchDict.sort?.split("-")[1] as Order,
      start_date: searchDict.date?.split(",")[0],
      end_date: searchDict.date?.split(",")[1],
    },
    { skip: !campaign_id, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (error) {
      toast.error(
        <CustomToast
          type="error"
          text={
            getErrorMessage(error).globalError ||
            "Detailed BC analysis upload Failed"
          }
          title="Upload failed"
        />,
      );
    }
  }, [error]);

  useEffect(() => {
    if (updateAnalytics) {
      refetch();
      dispatch(setUpdateAnalytics(false));
    }
  }, [updateAnalytics]);

  if (isLoading) return <CustomLoader />;

  if (!data?.is_analyzed) return <NoAnalyticsData />;

  return (
    <Box
      sx={{
        gap: "24px",
        display: "grid",
        gridTemplateColumns: "6fr 188px",
        minHeight: "210px",
      }}
    >
      <Box
        sx={{
          gap: "24px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(374px, 1fr))",
        }}
      >
        <DiscoveryRate analytics={data} />
        <GapsBlock analytics={data} />
      </Box>
      <AverageRateBlock
        analytics={data}
        campaign_id={campaign_id!}
        refetch={refetch}
      />
    </Box>
  );
};
