import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { SseProgress } from "../../SseProgress";
import React, { useEffect, useState } from "react";
import {
  useGenerateCampaignMutation,
  useGetGenerateCampaignResultQuery,
} from "../../../../api/companyApi";
import { ProductAnalysisResponse } from "../../../../types/apiTypes";
import { IconWrapper } from "../../IconWrapper";

type Props = {
  pdfFile: File | null;
  productUrl: string;
  setResultHandler: (result: ProductAnalysisResponse) => void;
};

export const AnalysePDFBlock = ({
  pdfFile,
  setResultHandler,
  productUrl,
}: Props) => {
  const [getResult, setGetResult] = useState<boolean>(false);
  const [generateCampaign, { data: generateData }] =
    useGenerateCampaignMutation();

  const { data: generateCampaignResult } = useGetGenerateCampaignResultQuery(
    generateData?.task_id || "",
    {
      skip: !getResult || !generateData?.task_id,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    generateCampaign({
      url: productUrl || undefined,
      pdf_file: pdfFile || undefined,
    });
  }, []);

  useEffect(() => {
    if (generateCampaignResult) {
      setResultHandler(generateCampaignResult);
      setGetResult(false);
    }
  }, [generateCampaignResult]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Stack direction="row" gap="8px" alignItems="center">
        <IconWrapper width="34px" height="34px">
          <CircularProgress size={24} sx={{ color: "custom.subTitleModal" }} />
        </IconWrapper>
        <Typography
          color="#1D3E70"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          Analyzing the product:{" "}
        </Typography>
        <Typography color="#1D3E70">
          {generateData ? (
            <SseProgress
              task_id={generateData.task_id}
              setGetResult={setGetResult}
              callBack={() => {}}
            />
          ) : (
            "0%"
          )}
        </Typography>
      </Stack>
    </Box>
  );
};
