import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { CustomInput } from "../CustomInput";
import { SearchIcon } from "../../images/SearchIcon";
import { ExportCompany } from "../../../types/apiTypes";
import { Option } from "./ExportAccountsDataBtn";

type Props = {
  option: string;
  value: string;
  setValue: (value: string) => void;
  companyList: ExportCompany[];
  selectAllHandler: () => void;
  searchValueDebounce: string;
  analyzedItemList: ExportCompany[];
  selectedItemList: ExportCompany[];
  handleChange: (item: ExportCompany) => void;
  setOption: (el: string) => void;
};

export const SelectedExportOptions = ({
  option,
  value,
  setValue,
  companyList,
  selectAllHandler,
  searchValueDebounce,
  selectedItemList,
  analyzedItemList,
  handleChange,
  setOption,
}: Props) => {
  return (
    <>
      <FormControl sx={{ padding: "0 12px", alignItems: "flex-start" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={option}
          value={option}
          name="radio-buttons-group"
          onChange={(e, el) => setOption(el)}
        >
          <FormControlLabel
            value={Option.all}
            control={
              <Radio
                sx={{
                  color: "#B3B3B3",
                  "&.Mui-checked": {
                    color: "#1C3C6C",
                  },
                  "&.Mui-disabled": {
                    color: "#E3E5E8",
                  },
                  "&.MuiRadio-root:hover": {
                    backgroundColor: "#EDEFF3",
                  },
                }}
              />
            }
            label={Option.all}
          />
          <FormControlLabel
            value={Option.selected}
            control={
              <Radio
                sx={{
                  color: "#B3B3B3",
                  "&.Mui-checked": {
                    color: "#1C3C6C",
                  },
                  "&.Mui-disabled": {
                    color: "#E3E5E8",
                  },
                  "&.MuiRadio-root:hover": {
                    backgroundColor: "#EDEFF3",
                  },
                }}
              />
            }
            label={`${Option.selected}${
              option === Option.selected
                ? `: ${
                    selectedItemList.length === analyzedItemList.length
                      ? "All"
                      : selectedItemList.length
                  }`
                : ""
            }`}
          />
        </RadioGroup>
      </FormControl>
      {option === Option.selected && (
        <Stack paddingY="8px" gap="8px">
          <Stack gap="8px">
            <Typography component="span" variant="body1" color="text.secondary">
              Please choose data sources you want to export
            </Typography>
            <CustomInput
              value={value}
              placeholder="Search"
              prefixIcon={<SearchIcon />}
              onChange={setValue}
            />
          </Stack>

          {companyList && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "236px",
                overflow: "auto",
                marginRight: "4px",
              }}
              className="scrollable_container"
            >
              {companyList.filter(({ name }) =>
                name.includes(searchValueDebounce),
              ).length > 0 &&
                !searchValueDebounce && (
                  <MenuItem
                    sx={{
                      padding: "4px",
                    }}
                    value="Select all"
                    onClick={selectAllHandler}
                  >
                    <Checkbox
                      checked={
                        selectedItemList.length === analyzedItemList.length
                      }
                      sx={{
                        color: "#1C3C6C",
                        "&.Mui-checked": {
                          color: "#1C3C6C",
                        },
                      }}
                    />
                    <ListItemText primary="Select all" />
                  </MenuItem>
                )}

              {companyList
                .filter(({ name }) => name.includes(searchValueDebounce))
                .map((el) => (
                  <MenuItem
                    sx={{
                      padding: "4px",
                    }}
                    key={el.id}
                    value={el.name}
                    onClick={() => handleChange(el)}
                    disabled={!el.is_analyzed}
                  >
                    <Checkbox
                      checked={
                        !!selectedItemList.find((item) => item.id === el.id)
                      }
                      sx={{
                        color: "#1C3C6C",
                        "&.Mui-checked": {
                          color: "#1C3C6C",
                        },
                      }}
                    />
                    <ListItemText primary={el.name} />
                  </MenuItem>
                ))}

              {companyList.filter(({ name }) =>
                name.includes(searchValueDebounce),
              ).length === 0 &&
                searchValueDebounce && (
                  <Typography
                    component="span"
                    variant="body1"
                    color="text.secondary"
                    pl="12px"
                  >
                    No company with such name found
                  </Typography>
                )}
            </Box>
          )}
        </Stack>
      )}
    </>
  );
};
