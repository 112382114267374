import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../types/enums";

export const useNavigateOnNoData = (
  isFilters: boolean,
  isFetching: boolean,
  data?: any[],
) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isFilters && !isFetching && data?.length === 0) {
      navigate(AppRoutes.PRODUCTS);
    }
  }, [isFilters, isFetching, data, navigate]);
};
