import { CircularProgress, FormHelperText, Stack } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { CustomButton } from "../CustomButton";
import React, { useEffect, useRef, useState } from "react";
import { useUploadCampaignFileMutation } from "../../../api/companyApi";
import { toast } from "react-toastify";
import { CustomToast } from "../CustomToast";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { useAppDispatch } from "../../../hooks/useReduxHooks";
import { setCreateProductAnalyses } from "../../../features/companySlice";
import { AppRoutes } from "../../../types/enums";
import { ActionType } from "../../../types";
import { useNavigate } from "react-router-dom";

type Props = {
  campaign_id?: string;
  onSuccess: () => void;
  startFlow?: boolean;
  disabled?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  getCampaignId?: () => Promise<string | void>;
  actionType?: ActionType;
};

export const UploadFromDesktop = ({
  campaign_id,
  onSuccess,
  startFlow,
  setIsLoading,
  disabled,
  getCampaignId,
  actionType = "update",
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [isCurrentLoading, setIsCurrentLoading] = useState<boolean>(false);

  const [uploadFile, { isLoading, error }] = useUploadCampaignFileMutation();

  useEffect(() => {
    isCurrentLoading && setIsLoading && setIsLoading(isCurrentLoading);
  }, [isCurrentLoading]);

  useEffect(() => {
    if (startFlow) {
      fileInputRef.current?.click();
    }
  }, [startFlow]);

  const validateAndOpenFileDialog = () => {
    if (isLoading) return;
    fileInputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setShowErrorMessage(false);
    const file = event.target.files?.[0];
    if (!file) return;

    const allowedExtensions = ["csv", "xls", "xlsx"];
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      setShowErrorMessage(true);
      return;
    }

    if (event.target.files && event.target.files.length > 0 && file) {
      let id: string = "";
      setIsCurrentLoading(true);
      actionType === "create" && dispatch(setCreateProductAnalyses(true));

      if (!campaign_id && getCampaignId) {
        id = (await getCampaignId()) || "";
      }
      uploadFile({ campaign_id: campaign_id || id, file })
        .unwrap()
        .then(() => {
          toast.success(
            <CustomToast
              type={"success"}
              text={"Company data uploading was successful."}
              title={"Upload success"}
            />,
          );
          actionType === "create" &&
            navigate(`${AppRoutes.PRODUCTS}/${campaign_id || id}`);
          onSuccess();
        })
        .catch((err) => {
          toast.error(
            <CustomToast
              type={"error"}
              text={
                getErrorMessage(err).globalError ||
                "Something went wrong. Please try again"
              }
              title={"Upload failed"}
            />,
          );
        })
        .finally(() => {
          dispatch(setCreateProductAnalyses(false));
          setIsCurrentLoading(false);
          setIsLoading && setIsLoading(false);
        });
    }
  };

  return (
    <Stack sx={{ position: "relative", paddingBottom: "12px" }}>
      <CustomButton
        variant="contained"
        customColor="#1C3C6C"
        fullWidth
        type="button"
        onClick={validateAndOpenFileDialog}
        sx={{ height: "48px" }}
        disabled={disabled || isCurrentLoading}
      >
        {isCurrentLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <UploadFileIcon fontSize="medium" />
        )}
        Upload from desktop
      </CustomButton>
      <FormHelperText
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          bottom: -10,
          left: 0,
          width: "100%",
          fontSize: "12px",
          lineHeight: "20px",
          color: "#D32F2F",
        }}
      >
        {error && <span>{getErrorMessage(error).globalError}</span>}
        {showErrorMessage && <span>Supported extensions: csv, xls, xlsx</span>}
      </FormHelperText>
      <input
        type="file"
        hidden
        ref={fileInputRef}
        onChange={handleFileChange}
      />
    </Stack>
  );
};
