import { Stack, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { EngagementScoreValues } from "../../../../types/apiTypes";

type Props = {
  field: string;
  item: EngagementScoreValues;
  onChange: (field: string, value: EngagementScoreValues) => void;
};

const titles: { [key: string]: string } = {
  calls_made: "Calls made",
  calls_answered: "Calls answered",
  emails_sent: "Emails sent",
  emails_answered: "Emails answered",
  meetings_set: "Meetings set",
  meetings_attended: "Meetings attended",
  recency: "Interaction recency",
};

export const EngagementSettingsItem = ({ field, item, onChange }: Props) => {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const weight = Number(e.target.value);
    if (e.target.value && isNaN(weight)) return;

    if (weight > 100) return onChange(field, { ...item, weight: 100 });
    if (weight === 0) return onChange(field, { enabled: false, weight: 0 });
    onChange(field, { ...item, weight });
  };

  const onSwitchChange = () => {
    if (item.weight === 0 && !item.enabled)
      return onChange(field, { weight: 1, enabled: true });

    onChange(field, { ...item, enabled: !item.enabled });
  };

  return (
    <Stack
      flexDirection="row"
      gap="16px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack
        flexDirection="row"
        gap="16px"
        alignItems="center"
        justifyContent="space-between"
        maxWidth="200px"
        width="100%"
      >
        <Typography variant="body1" color="#1F2932">
          {titles[field]}
        </Typography>
        <Switch
          checked={item.enabled}
          onChange={onSwitchChange}
          inputProps={{ "aria-label": "controlled" }}
          sx={{
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: "#1C3C6C",
              "&:hover": {
                backgroundColor: "#1C3C6C80",
              },
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: "#1C3C6C80",
            },
          }}
        />
      </Stack>
      <Stack flexDirection="row" gap="8px" alignItems="center" width="96px">
        <TextField
          value={item.weight}
          variant="outlined"
          onChange={onInputChange}
          disabled={!item.enabled}
          sx={{
            width: "54px",
            color: "#1F2932",
            borderRadius: "8px",
            textAlign: "center",
            "& .MuiOutlinedInput-root": {
              padding: 0,
              "&:hover": {
                borderColor: !item.enabled ? "#CFCFCF" : "auto",
              },
            },
            "& input": {
              textAlign: "center",
              padding: "0",
            },
          }}
        />
        <Typography variant="subtitle1" color="#1F2932" fontWeight={500}>
          %
        </Typography>
      </Stack>
    </Stack>
  );
};
