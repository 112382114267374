import {
  Box,
  CircularProgress,
  Stack,
  SvgIconTypeMap,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { tableSortLabelClasses } from "@mui/material/TableSortLabel";
import { EllipsisTooltip } from "../ui/CustomTooltip";
import React, { useCallback, useMemo, useState } from "react";
import { isTextEllipsed } from "../../utils/general_utils";
import { SortRounded } from "../images/SortRoundedImg";
import { useStateWithSearchParams } from "../../hooks/useStateWithSearchParams";
import { AccountListItem, Order } from "../../types";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import Checkbox from "@mui/material/Checkbox";
import { CompanyMenu } from "../features/CompanyMenu";
import { ProgressBar } from "../ui/ProgressBar";
import { AppRoutes } from "../../types/enums";
import { Link } from "react-router-dom";
import { DotIcon, UpdateIcon } from "../images";

interface ColumnList {
  id: string;
  key: keyof AccountListItem;
  label: string;
  minWidth?: string;
  sortable?: boolean;
  icon?: React.ElementType; // Иконка по умолчанию
  iconAsc?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  iconDesc?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  type:
    | "checkbox"
    | "text"
    | "menu"
    | "penetration_score"
    | "total_engagement_score";
  flexAlign: "flex-start" | "center" | "flex-end";
  textAlign: "left" | "center" | "right";
}

interface DataTableProps {
  columns?: ColumnList[];
  rows: AccountListItem[];
  totalRows?: number;
  page?: number;
  rowsPerPage?: number;
  order?: "asc" | "desc";
  refetch: () => void;
}

const columnsList: ColumnList[] = [
  {
    id: "data_source",
    key: "index",
    label: "",
    minWidth: "4%",
    sortable: true,
    iconAsc: SortRounded,
    type: "text",
    flexAlign: "flex-start",
    textAlign: "left",
  },
  {
    id: "first_name",
    key: "company",
    label: "Company",
    minWidth: "21%",
    type: "text",
    flexAlign: "flex-start",
    textAlign: "left",
  },
  {
    id: "last_name",
    key: "industry",
    label: "Industry",
    minWidth: "21%",
    type: "text",
    flexAlign: "flex-start",
    textAlign: "left",
  },
  {
    id: "seniority",
    key: "size",
    label: "Size",
    minWidth: "8%",
    sortable: true,
    iconAsc: SortRounded,
    type: "text",
    flexAlign: "flex-end",
    textAlign: "right",
  },
  {
    id: "job_title",
    key: "region",
    label: "Region",
    minWidth: "8%",
    sortable: true,
    iconAsc: SortRounded,
    type: "text",
    flexAlign: "flex-start",
    textAlign: "left",
  },
  {
    id: "role",
    key: "total_engagement_score",
    label: "Total engagement",
    minWidth: "14%",
    sortable: true,
    iconAsc: SortRounded,
    type: "total_engagement_score",
    flexAlign: "flex-end",
    textAlign: "right",
  },
  {
    id: "role",
    key: "penetration_score",
    label: "Identification rate",
    minWidth: "14%",
    sortable: true,
    iconAsc: SortRounded,
    type: "penetration_score",
    flexAlign: "flex-end",
    textAlign: "right",
  },
  {
    id: "menu",
    key: "id",
    label: "",
    minWidth: "4%",
    type: "menu",
    flexAlign: "center",
    textAlign: "center",
  },
  {
    id: "checkbox",
    key: "id",
    label: "",
    minWidth: "4%",
    type: "checkbox",
    flexAlign: "center",
    textAlign: "center",
  },
];

type SearchParams = {
  sort?: string;
  selected?: string;
  page?: string;
  per_page?: string;
};

type CellBodyProps = {
  column: ColumnList;
  row: AccountListItem;
  cellIndex: number;
};

const CellBody = ({ row, column, cellIndex }: CellBodyProps) => {
  const [showTableTooltip, setShowTableTooltip] = useState(false);

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    setShowTableTooltip(isTextEllipsed(target));
  };

  return (
    <span
      style={{
        display: "flex",
        justifyContent: `${
          (row.is_new || row.status === "updated") && cellIndex === 1
            ? "space-between"
            : column.flexAlign
        }`,
        gap: "16px",
        paddingRight: `${(row.is_new || row.status === "updated") && cellIndex === 1 ? "0" : "8px"}`,
      }}
    >
      <Tooltip
        title={showTableTooltip ? row[column.key]?.toString() || "" : ""}
        placement="bottom"
        arrow
        sx={{
          background: "#596177E5",
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 400,
          textAlign: column.textAlign,
        }}
      >
        <span
          onMouseEnter={handleMouseEnter}
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
          }}
        >
          {row[column.key]}
        </span>
      </Tooltip>
      {cellIndex === 1 && row.is_new && row.status !== "updated" && (
        <Stack
          minWidth="24px"
          minHeight="24px"
          alignItems="center"
          justifyContent="center"
        >
          <EllipsisTooltip tooltip="New account">
            <DotIcon />
          </EllipsisTooltip>
        </Stack>
      )}

      {cellIndex === 1 && row.status === "updated" && (
        <Stack
          minWidth="24px"
          minHeight="24px"
          alignItems="center"
          justifyContent="center"
        >
          <EllipsisTooltip tooltip="Updated account">
            <UpdateIcon color="#1C3C6C" />
          </EllipsisTooltip>
        </Stack>
      )}
    </span>
  );
};

const headerTooltip: { [key: string]: string } = {
  "Total engagement":
    "Engagement rate of all identified potential BC members, based on outgoing and replied calls & emails," +
    " communication recency over all time.",
  "Identification rate":
    "Rate of potential BC members, identified in available data, to expected BC size.",
};

export const AccountsListTable = ({
  columns = columnsList,
  rows,
  totalRows,
  refetch,
}: DataTableProps) => {
  const [showedList, setShowedList] = useState<string[]>([]);

  const { searchDict, setSearchValue } = useStateWithSearchParams<SearchParams>(
    {
      sort: undefined,
      selected: undefined,
      page: undefined,
      per_page: undefined,
    },
  );

  const handleChangePage = (_: unknown, newPage: number) => {
    setSearchValue("page", String(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setSearchValue("per_page", String(newRowsPerPage));
  };

  const handleSort = (columnId: string) => {
    const currentSort = searchDict.sort;
    if (currentSort) {
      const [sort, order] = currentSort.split("-");

      if (sort === columnId) {
        setSearchValue(
          "sort",
          `${sort}-${order === Order.ASC ? Order.DESC : Order.ASC}`,
        );
        return;
      }
    }
    setSearchValue("sort", `${columnId}-${Order.ASC}`);
  };

  const checkBoxHandler = useCallback(
    (id: string) => {
      const selectedList = searchDict.selected?.split(",");
      if (selectedList?.includes(id))
        setSearchValue(
          "selected",
          selectedList.filter((el) => el !== id).join(","),
        );
      else {
        selectedList?.push(id);
        setSearchValue("selected", selectedList?.join(",") || id);
      }
    },
    [searchDict.selected, setSearchValue],
  );

  const mapper = useMemo(() => {
    return {
      checkbox: (row: AccountListItem) => {
        const selectedList = searchDict.selected?.split(",");
        return (
          <Checkbox
            onClick={() => checkBoxHandler(row.id)}
            checked={selectedList?.includes(row.id) || false}
            disabled={[
              "completed",
              "in_progress",
              "queued",
              "scraping_queued",
              "scraping_in_progress",
            ].includes(row.status)}
            sx={{
              color: "#B3B3B3",
              "&.Mui-checked": {
                color: "#1C3C6C",
              },
              "&.Mui-disabled": {
                color: "#E3E5E8",
              },
              "&.MuiCheckbox-root: hover": {
                backgroundColor: "#EDEFF3",
              },
            }}
          />
        );
      },
      menu: (row: AccountListItem) => {
        return (
          <CompanyMenu
            total_companies={rows.length}
            refetch={refetch}
            id={row.id}
            companyName={row.company}
          />
        );
      },
      penetration_score: (row: AccountListItem, showedList?: string[]) => {
        let isShowed = false;
        if (!showedList?.includes(row.id)) {
          setShowedList((state) => [...state, row.id]);
        } else {
          isShowed = true;
        }
        return (
          <ProgressBar data={row} isShowed={isShowed} callBack={refetch} />
        );
      },
      total_engagement_score: (row: AccountListItem) => {
        if (row.total_engagement_score === null) return null;
        return (
          <Stack
            alignItems="center"
            onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
              const target = event.currentTarget;
              isTextEllipsed(target);
            }}
          >
            <Stack alignItems="center">
              <Stack
                sx={{ position: "relative", width: "42px", height: "42px" }}
                justifyContent="center"
              >
                <CircularProgress
                  size={42}
                  thickness={4}
                  variant="determinate"
                  sx={{
                    color: "#D4D4D4",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    zIndex: 1,
                    opacity: 1,
                  }}
                  value={100}
                />
                <CircularProgress
                  size={42}
                  thickness={4}
                  variant="determinate"
                  sx={{
                    color: "#3279B3",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    zIndex: 2,
                    opacity: 1,
                  }}
                  value={row.total_engagement_score || 0}
                />
                <Typography
                  variant="body1"
                  align="center"
                  sx={{
                    flexShrink: 1,
                    fontSize: 10,
                    fontWeight: 600,
                  }}
                >
                  {row.total_engagement_score || 0}%
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      },
      text: () => null,
    };
  }, [checkBoxHandler]);

  const tableBody = useMemo(() => {
    return rows.map((row, rowIndex) => (
      <TableRow key={row.id}>
        {columns.map((column, cellIndex) => (
          <TableCell
            key={`${column.label}-${rowIndex}-${cellIndex}`}
            sx={{
              width: column.minWidth,
              height: "56px",
              paddingTop: 0,
              paddingBottom: 0,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: "0.3px",
              color: "#1F2932",
              textAlign: column.textAlign,

              backgroundColor: !row.is_new
                ? cellIndex === 0
                  ? "#E1E6EF"
                  : "transparent"
                : cellIndex === 0
                  ? "#D8DDE6"
                  : "#F7F9FD",
              borderBottom: "1px solid #0000001F",
              paddingRight: 0,
              paddingLeft: `${
                [
                  "checkbox",
                  "total_engagement_score",
                  "penetration_score",
                ].includes(column.type)
                  ? "0"
                  : "16px"
              }`,
            }}
          >
            {mapper[column.type](row, showedList) || (
              <>
                {cellIndex === 1 ? (
                  <Link
                    to={`${AppRoutes.ACCOUNTS}/${row.id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <CellBody row={row} cellIndex={cellIndex} column={column} />
                  </Link>
                ) : (
                  <div>
                    <CellBody row={row} cellIndex={cellIndex} column={column} />
                  </div>
                )}
              </>
            )}
          </TableCell>
        ))}
      </TableRow>
    ));
  }, [rows, mapper]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {rows.length <= 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="170px"
          sx={{
            borderRadius: 4,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#697184",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            No data available
          </Typography>
        </Box>
      ) : (
        <Box>
          <TableContainer>
            <Table
              sx={{
                border: "none",
                background: "white",
                boxShadow: 0,
                tableLayout: "fixed",
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column, index) => {
                    const order =
                      (searchDict.sort?.split("-")[1] as "asc" | "desc") ||
                      "asc";
                    const sort = searchDict.sort?.split("-")[0] || "";

                    const isSorted = column.sortable && column.key === sort;
                    //@ts-ignore
                    const IconComponent: SvgIconComponent | undefined =
                      column.sortable && column.iconAsc;

                    return (
                      <TableCell
                        key={`${column.key}-${index}`}
                        sx={{
                          backgroundColor:
                            index === 0 ? "#E1E6EF" : "transparent",
                          borderTopLeftRadius: `${index === 0 ? "20px" : "0"}`,
                          width: column.minWidth,
                          padding: `${index === 0 ? "2px" : "16px 8px"}`,
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "26px",
                          letterSpacing: "0.25px",
                          color: "#1F2932",
                          textAlign: column.textAlign,
                        }}
                      >
                        {column.sortable ? (
                          <TableSortLabel
                            active={isSorted}
                            direction={isSorted ? order : "desc"}
                            onClick={() => handleSort(column.key as string)}
                            hideSortIcon={!IconComponent}
                            IconComponent={IconComponent || (() => null)}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: column.flexAlign,
                              gap: "1px",
                              width: "100%",

                              [`.${tableSortLabelClasses.icon}`]: {
                                opacity: isSorted ? 1 : 0.5,
                                transition: "opacity 0.3s ease",
                                flexShrink: 0,
                                marginLeft: 1,
                              },
                            }}
                          >
                            <EllipsisTooltip
                              tooltip={headerTooltip[column.label]}
                            >
                              {column.label}
                            </EllipsisTooltip>
                          </TableSortLabel>
                        ) : (
                          <EllipsisTooltip
                            tooltip={headerTooltip[column.label]}
                          >
                            {column.label}
                          </EllipsisTooltip>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>{rows.length > 0 ? tableBody : <></>}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalRows || 0}
            rowsPerPage={Number(searchDict.per_page) || 25}
            page={Number(searchDict.page) || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
};
