import { IconProps } from "../../types/genereal";
import { IconWrapper } from "components/ui/IconWrapper";

export const NoAnalyticsIcon = ({ width = "87", height = "74" }: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width="87"
        height="74"
        viewBox="0 0 87 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse cx="43.5" cy="54" rx="36.5" ry="12" fill="#F4F5F6" />
        <path
          d="M53 54.125V42.706H60.125V54.125H53ZM39.9375 54.125V20.875H47.0625V54.125H39.9375ZM26.875 54.125V32.294H34V54.125H26.875Z"
          fill="#1F2932"
        />
        <circle cx="16.5" cy="9" r="4" fill="#99A7BD" />
        <circle cx="8.5" cy="40" r="2" fill="#99A7BD" />
        <line
          x1="72.5"
          y1="35.5"
          x2="80.5"
          y2="35.5"
          stroke="#99A7BD"
          strokeWidth="5"
        />
      </svg>
    </IconWrapper>
  );
};
