import { Stack, Typography } from "@mui/material";
import { IncreaseIcon } from "../images/IncreaseIcon";
import { AddContactsDataModal } from "./modal";

export const UploadContactListBlock = ({
  campaign_id,
  successCallBack,
}: {
  campaign_id: string;
  successCallBack: () => void;
}) => {
  return (
    <Stack
      direction="row"
      gap="16px"
      borderRadius="6px"
      bgcolor="#ECEEF099"
      padding="16px"
      alignItems="flex-start"
    >
      <IncreaseIcon />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
      >
        <Stack gap="4px">
          <Typography
            variant="subtitle1"
            color="custom.textDarkBlue"
            sx={{ fontWeight: 500 }}
          >
            Improve your buying committee discovery!
          </Typography>
          <Typography variant="body1" color="text.secondary" maxWidth="808px">
            Provide your contacts list for targeted accounts to enhance the
            analysis. Utilize INFUSE data and external data sources to complete
            your buying committee discovery and raise the possibility of
            securing buy-in.
          </Typography>
        </Stack>
        <AddContactsDataModal
          campaign_id={campaign_id}
          successHandler={successCallBack}
        />
      </Stack>
    </Stack>
  );
};
