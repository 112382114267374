import { IconProps } from "../../types/genereal";
import { Stack } from "@mui/system";

export const IIcon = ({
  width = "2",
  height = "20",
  color = "#FE8836",
}: IconProps) => {
  return (
    <Stack direction="row" sx={{ minWidth: width, minHeight: height }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 2 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="2" height="2" fill={color} />
        <rect y="4" width="2" height="16" fill={color} />
      </svg>
    </Stack>
  );
};
