import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CompanyState, IntegrationData } from "../types/companyTypes";

const initialState: CompanyState = {
  account_id: localStorage.getItem("accountId") || "",
  company: localStorage.getItem("campaignId") || "",
  analysisStatus: "idle",
  loadingAnalyse: "",
  uploadModal: "",
  update: "",
  updateAnalytics: false,
  integrationData: null,
  createProductAnalyses: false,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setUpdate: (state, action: PayloadAction<string>) => {
      state.update = action.payload;
    },
    setCompany: (state, action: PayloadAction<string>) => {
      state.company = action.payload;
      localStorage.setItem("campaignId", action.payload);
    },
    setAccount: (state, action: PayloadAction<string>) => {
      state.account_id = action.payload;
      localStorage.setItem("accountId", action.payload);
    },
    clearCompany: (state) => {
      state.company = "";
      localStorage.removeItem("campaignId");
      localStorage.removeItem("accountId");
    },
    setAnalysisStatus: (state, action: PayloadAction<string>) => {
      state.analysisStatus = action.payload;
    },
    clearAnalysisStatus: (state) => {
      state.analysisStatus = "idle";
    },
    setAnalysisLoading: (state, action: PayloadAction<string>) => {
      state.loadingAnalyse = action.payload;
    },
    clearAnalysisLoading: (state) => {
      state.loadingAnalyse = "";
    },
    setUploadModal: (state, action: PayloadAction<string>) => {
      state.uploadModal = action.payload;
    },
    setUpdateAnalytics: (state, action: PayloadAction<boolean>) => {
      state.updateAnalytics = action.payload;
    },
    setIntegrationData: (
      state,
      action: PayloadAction<IntegrationData | null>,
    ) => {
      state.integrationData = action.payload;
    },
    setCreateProductAnalyses: (state, action: PayloadAction<boolean>) => {
      state.createProductAnalyses = action.payload;
    },
  },
});

export const {
  setCompany,
  setAccount,
  clearCompany,
  setAnalysisStatus,
  clearAnalysisStatus,
  setAnalysisLoading,
  clearAnalysisLoading,
  setUploadModal,
  setUpdate,
  setUpdateAnalytics,
  setIntegrationData,
  setCreateProductAnalyses,
} = companySlice.actions;
export default companySlice.reducer;
