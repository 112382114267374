import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const NoResultIcon = ({ width = "160", height = "145" }: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 160 145"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse cx="79.5" cy="116" rx="73.5" ry="29" fill="#ECEEF0" />
        <path
          d="M57.0834 130.833C50.7431 130.833 45.3394 128.598 40.8722 124.128C36.4049 119.658 34.1698 114.254 34.1668 107.917C34.1637 101.579 36.3988 96.1757 40.8722 91.7054C45.3455 87.2351 50.7493 85 57.0834 85C63.4176 85 68.8229 87.2351 73.2993 91.7054C77.7756 96.1757 80.0093 101.579 80.0001 107.917C79.9909 114.254 77.7558 119.659 73.2947 124.132C68.8336 128.606 63.4298 130.839 57.0834 130.833ZM119.417 126.25L90.0834 96.9167C89.1668 95.9236 88.1936 94.9122 87.1638 93.8825C86.1341 92.8528 85.1212 91.8781 84.1251 90.9583C87.0279 89.125 89.3577 86.6806 91.1147 83.625C92.8716 80.5695 93.7501 77.2083 93.7501 73.5417C93.7501 67.8125 91.7456 62.9435 87.7368 58.9346C83.7279 54.9257 78.8573 52.9197 73.1251 52.9167C67.3929 52.9136 62.5238 54.9196 58.518 58.9346C54.5122 62.9496 52.5062 67.8186 52.5001 73.5417C52.5001 74 52.5199 74.44 52.5597 74.8617C52.5994 75.2833 52.6559 75.7218 52.7293 76.1771C51.3543 76.3299 49.8463 76.6354 48.2055 77.0938C46.5647 77.5521 45.0934 78.0868 43.7918 78.6979C43.639 77.8576 43.5244 77.0174 43.448 76.1771C43.3716 75.3368 43.3334 74.4583 43.3334 73.5417C43.3334 65.2153 46.2179 58.1692 51.9868 52.4033C57.7556 46.6375 64.8018 43.7531 73.1251 43.75C81.4484 43.7469 88.4961 46.6314 94.268 52.4033C100.04 58.1753 102.923 65.2214 102.917 73.5417C102.917 76.8264 102.402 79.94 101.372 82.8825C100.342 85.825 98.9094 88.517 97.073 90.9583L125.833 119.833L119.417 126.25ZM48.948 119.26L57.0834 111.125L65.1043 119.26L68.4272 116.052L60.2917 107.917L68.4272 99.7813L65.2188 96.5729L57.0834 104.708L48.948 96.5729L45.7397 99.7813L53.8751 107.917L45.7397 116.052L48.948 119.26Z"
          fill="#1F2932"
        />
        <circle cx="117" cy="44" r="9" fill="#99A7BD" />
        <line
          x1="6"
          y1="67.5"
          x2="18"
          y2="67.5"
          stroke="#99A7BD"
          strokeWidth="5"
        />
        <circle cx="104.5" cy="21.5" r="5.5" fill="#99A7BD" />
        <circle cx="22.5" cy="82.5" r="4.5" fill="#99A7BD" />
      </svg>
    </IconWrapper>
  );
};
