import { Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHooks";
import { ImportButton } from "../ui/ImportButton";
import { StarsIcon } from "../images";
import { setIntegrationData } from "../../features/companySlice";

export const StartNewCampaignBlock = () => {
  const dispatch = useAppDispatch();
  const integrationData = useAppSelector(
    (state) => state.company.integrationData,
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="6px"
      bgcolor="#ECEEF099"
      padding="16px"
    >
      <Stack direction="row" gap="16px" alignItems="flex-start">
        <StarsIcon />
        <Stack gap="4px">
          <Typography
            variant="subtitle1"
            color="custom.textDarkBlue"
            sx={{ fontWeight: 500 }}
          >
            Get highly accurate analysis results in 2 quick steps
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Start new analysis by adding your product data and importing target
            accounts and contacts list
          </Typography>
        </Stack>
      </Stack>

      <ImportButton
        onClick={() =>
          dispatch(
            setIntegrationData({
              campaignId: integrationData?.campaignId || "",
              actionType: integrationData?.actionType || "",
              campaignImportFlow: "open",
            }),
          )
        }
        buttonText="Start new analysis"
      />
    </Stack>
  );
};
