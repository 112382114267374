import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHooks";
import { BaseModal } from "../../BaseModal";
import { CustomButton } from "components/ui/CustomButton";
import { setIntegrationData } from "../../../../features/companySlice";
import { AddContactsDataBody } from "./AddContactsDataBody";

const importOptions = ["hubspot", "salesforce"];

export const AddContactsDataModal = ({
  campaign_id,
  successHandler,
}: {
  campaign_id: string;
  successHandler: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const integrationData = useAppSelector(
    (state) => state.company.integrationData,
  );

  useEffect(() => {
    if (
      integrationData?.actionType === "add-contacts" &&
      importOptions.includes(integrationData?.campaignImportFlow || "")
    ) {
      setIsOpen(true);
    }
  }, [integrationData?.campaignImportFlow]);

  useEffect(() => {
    if (!isOpen) {
      integrationData?.campaignImportFlow === "add-contacts" &&
        dispatch(setIntegrationData(null));
    }
  }, [isOpen]);

  const onCloseHandler = () => {
    setIsOpen(false);
  };

  const onSuccess = () => {
    successHandler();
    dispatch(setIntegrationData(null));
    setIsLoading(false);
  };

  return (
    <>
      <CustomButton
        onClick={() => setIsOpen(true)}
        customSize="fit"
        customColor="#F16300"
        customGradientColor="#FE8836"
        disabled={isLoading}
      >
        {isLoading && <CircularProgress size={24} color="inherit" />}
        Upload contacts list
      </CustomButton>

      <BaseModal
        open={isOpen}
        onClose={onCloseHandler}
        title="Add contacts data for in-depth analysis"
        sx={{ padding: "32px", maxWidth: "438px", width: "90%" }}
      >
        {isOpen && (
          <AddContactsDataBody
            campaign_id={campaign_id}
            successHandler={onSuccess}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            onClose={() => {
              dispatch(setIntegrationData(null));
              setIsOpen(false);
            }}
          />
        )}
      </BaseModal>
    </>
  );
};
