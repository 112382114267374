import { IconProps } from "../../types/genereal";
import { IconWrapper } from "components/ui/IconWrapper";

export const PenIcon = ({
  width = "24",
  height = "24",
  color = "#1D3E70",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.16667 17.8333H7.35417L15.5 9.6875L14.3125 8.5L6.16667 16.6458V17.8333ZM4.5 19.5V15.9583L15.5 4.97917C15.6667 4.82639 15.8508 4.70833 16.0525 4.625C16.2542 4.54167 16.4658 4.5 16.6875 4.5C16.9092 4.5 17.1244 4.54167 17.3333 4.625C17.5422 4.70833 17.7228 4.83333 17.875 5L19.0208 6.16667C19.1875 6.31944 19.3092 6.5 19.3858 6.70833C19.4625 6.91667 19.5006 7.125 19.5 7.33333C19.5 7.55556 19.4619 7.7675 19.3858 7.96917C19.3097 8.17083 19.1881 8.35472 19.0208 8.52083L8.04167 19.5H4.5ZM14.8958 9.10417L14.3125 8.5L15.5 9.6875L14.8958 9.10417Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  );
};
