import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const NotesIcon = ({
  width = "24",
  height = "24",
  color = "#1D3E70",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.75 5.7474C5.75 5.49323 5.85097 5.24947 6.03069 5.06975C6.21041 4.89003 6.45417 4.78906 6.70833 4.78906H16.2917C16.5458 4.78906 16.7896 4.89003 16.9693 5.06975C17.149 5.24947 17.25 5.49323 17.25 5.7474C17.25 6.00156 17.149 6.24532 16.9693 6.42504C16.7896 6.60476 16.5458 6.70573 16.2917 6.70573H6.70833C6.45417 6.70573 6.21041 6.60476 6.03069 6.42504C5.85097 6.24532 5.75 6.00156 5.75 5.7474ZM5.75 9.58073C5.75 9.32656 5.85097 9.08281 6.03069 8.90309C6.21041 8.72336 6.45417 8.6224 6.70833 8.6224H16.2917C16.5458 8.6224 16.7896 8.72336 16.9693 8.90309C17.149 9.08281 17.25 9.32656 17.25 9.58073C17.25 9.83489 17.149 10.0787 16.9693 10.2584C16.7896 10.4381 16.5458 10.5391 16.2917 10.5391H6.70833C6.45417 10.5391 6.21041 10.4381 6.03069 10.2584C5.85097 10.0787 5.75 9.83489 5.75 9.58073ZM6.70833 12.4557C6.45417 12.4557 6.21041 12.5567 6.03069 12.7364C5.85097 12.9161 5.75 13.1599 5.75 13.4141C5.75 13.6682 5.85097 13.912 6.03069 14.0917C6.21041 14.2714 6.45417 14.3724 6.70833 14.3724H16.2917C16.5458 14.3724 16.7896 14.2714 16.9693 14.0917C17.149 13.912 17.25 13.6682 17.25 13.4141C17.25 13.1599 17.149 12.9161 16.9693 12.7364C16.7896 12.5567 16.5458 12.4557 16.2917 12.4557H6.70833ZM5.75 17.2474C5.75 16.9932 5.85097 16.7495 6.03069 16.5698C6.21041 16.39 6.45417 16.2891 6.70833 16.2891H10.5417C10.7958 16.2891 11.0396 16.39 11.2193 16.5698C11.399 16.7495 11.5 16.9932 11.5 17.2474C11.5 17.5016 11.399 17.7453 11.2193 17.925C11.0396 18.1048 10.7958 18.2057 10.5417 18.2057H6.70833C6.45417 18.2057 6.21041 18.1048 6.03069 17.925C5.85097 17.7453 5.75 17.5016 5.75 17.2474Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.91602 3.83203C1.91602 3.06953 2.21892 2.33827 2.75808 1.7991C3.29725 1.25993 4.02852 0.957031 4.79102 0.957031H18.2077C18.9702 0.957031 19.7014 1.25993 20.2406 1.7991C20.7798 2.33827 21.0827 3.06953 21.0827 3.83203V19.1654C21.0827 19.9279 20.7798 20.6591 20.2406 21.1983C19.7014 21.7375 18.9702 22.0404 18.2077 22.0404H4.79102C4.02852 22.0404 3.29725 21.7375 2.75808 21.1983C2.21892 20.6591 1.91602 19.9279 1.91602 19.1654V3.83203ZM4.79102 2.8737H18.2077C18.4618 2.8737 18.7056 2.97466 18.8853 3.15439C19.065 3.33411 19.166 3.57787 19.166 3.83203V19.1654C19.166 19.4195 19.065 19.6633 18.8853 19.843C18.7056 20.0227 18.4618 20.1237 18.2077 20.1237H4.79102C4.53685 20.1237 4.29309 20.0227 4.11337 19.843C3.93365 19.6633 3.83268 19.4195 3.83268 19.1654V3.83203C3.83268 3.57787 3.93365 3.33411 4.11337 3.15439C4.29309 2.97466 4.53685 2.8737 4.79102 2.8737Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  );
};
