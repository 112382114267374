import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";
import {
  CampaignRequest,
  CampaignId,
  FileUploadResponse,
  CampaignDetailsResponse,
  CampaignChartResponse,
  CampaignMembersResponse,
  CampaignMembersQuery,
  CampaignLatest,
  CampaignLatestClear,
  UrlResponse,
  HubspotUrlRequest,
  ProductAnalysisResponse,
  ProductAnalysisRequest,
  FileUploadNewResponse,
  CampaignPatchMembersResponse,
  CampaignPatchMembersQuery,
  Campaign,
  CurrentCampaign,
  CampaignSummary,
  Response,
  UpdateCampaign,
  EnterCompanyManually,
  ValidateCampaign,
  EngagementScoreConfig,
} from "types/apiTypes";
import { RequestMethods } from "types/enums";
import { ActionType, Query } from "../types";

export const campaignsApi = createApi({
  reducerPath: "campaignsApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    // POST /api/campaigns
    createCampaign: builder.mutation<CampaignId, CampaignRequest>({
      query: (body) => ({
        url: "/campaigns",
        method: RequestMethods.POST,
        body,
      }),
    }),

    // POST /api/campaigns/{campaign_id}/upload_file
    uploadCampaignFile: builder.mutation<
      FileUploadNewResponse,
      { campaign_id: string; file: File }
    >({
      query: ({ campaign_id, file }) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: `/campaigns/${campaign_id}/upload_file`,
          method: RequestMethods.POST,
          body: formData,
        };
      },
    }),

    uploadCampaignExtendFile: builder.mutation<
      FileUploadNewResponse,
      { account_id: string; file: File }
    >({
      query: ({ account_id, file }) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: `/accounts/${account_id}/extend`,
          method: RequestMethods.POST,
          body: formData,
        };
      },
    }),

    createSalesforceImport: builder.mutation<
      FileUploadResponse,
      { campaign_id: string }
    >({
      query: ({ campaign_id }) => {
        return {
          url: `/campaigns/${campaign_id}/import-from-salesforce`,
          method: RequestMethods.POST,
        };
      },
    }),

    getSalesforceUrl: builder.mutation<
      UrlResponse,
      {
        campaign_id: string;
        action_type: ActionType;
        redirect_path: string;
      }
    >({
      query: (params) => ({
        url: "/integrations/salesforce",
        method: RequestMethods.GET,
        params,
      }),
    }),

    createSalesforceUrl: builder.mutation<
      FileUploadResponse,
      HubspotUrlRequest
    >({
      query: (body) => ({
        url: `/integrations/salesforce`,
        method: RequestMethods.POST,
        body,
      }),
    }),

    createHubspotImport: builder.mutation<
      FileUploadResponse,
      { campaign_id: string }
    >({
      query: ({ campaign_id }) => {
        return {
          url: `/campaigns/${campaign_id}/import-from-hubspot`,
          method: RequestMethods.POST,
        };
      },
    }),

    getHubspotUrl: builder.mutation<
      UrlResponse,
      {
        campaign_id: string;
        action_type: ActionType;
        redirect_path: string;
      }
    >({
      query: (params) => ({
        url: "/integrations/hubspot",
        method: RequestMethods.GET,
        params,
      }),
    }),

    createHubspotUrl: builder.mutation<FileUploadResponse, HubspotUrlRequest>({
      query: (body) => ({
        url: `/integrations/hubspot`,
        method: RequestMethods.POST,
        body,
      }),
    }),

    // GET /api/campaigns/{campaign_id}
    getCampaignDetails: builder.query<
      CampaignDetailsResponse,
      { account_id: string }
    >({
      query: ({ account_id }) => ({
        url: `/accounts/${account_id}`,
        method: RequestMethods.GET,
      }),
    }),

    // GET /api/campaigns/latest
    getUserGampaign: builder.query<CampaignLatest, void>({
      query: () => ({
        url: `/campaigns/latest`,
        method: RequestMethods.GET,
      }),
      transformResponse: (response: CampaignLatest): CampaignLatest => {
        if (response.message) {
          return { error: response.message };
        }
        return {
          campaign_id: response.campaign_id,
        };
      },
      keepUnusedDataFor: 0,
    }),

    // GET /api/campaigns/latest
    clearUserGampaign: builder.mutation<CampaignLatestClear, void>({
      query: () => ({
        url: `/campaigns/latest`,
        method: RequestMethods.DELETE,
      }),
    }),

    // GET /api/campaigns/{campaign_id}/members
    getCampaignMembers: builder.query<
      CampaignMembersResponse,
      CampaignMembersQuery
    >({
      query: ({
        account_id,
        page,
        per_page,
        sort,
        order,
        is_enriched,
        min_percent,
        max_percent,
      }) => ({
        url: `/accounts/${account_id}/members`,
        method: RequestMethods.GET,
        params: {
          page,
          per_page,
          sort,
          order,
          is_enriched,
          min_percent,
          max_percent,
        },
      }),
    }),

    patchCampaignMembers: builder.mutation<
      CampaignPatchMembersResponse,
      CampaignPatchMembersQuery
    >({
      query: ({ account_id, ...body }) => ({
        url: `/accounts/${account_id}/members`,
        method: RequestMethods.PATCH,
        body,
      }),
    }),

    getCampaignChart: builder.query<
      CampaignChartResponse,
      { account_id: string }
    >({
      query: ({ account_id }) => ({
        url: `/accounts/${account_id}/chart`,
        method: RequestMethods.GET,
      }),
    }),

    generateCampaign: builder.mutation<
      { task_id: string },
      ProductAnalysisRequest
    >({
      query: ({ url, pdf_file }) => {
        const formData = new FormData();

        formData.append("pdf_file", pdf_file || "");

        return {
          url: "/processors/generate-campaign",
          method: RequestMethods.POST,
          body: formData,
          params: url ? { url } : undefined,
          headers: {
            accept: "application/json",
          },
        };
      },
    }),

    getGenerateCampaignResult: builder.query<ProductAnalysisResponse, string>({
      query: (task_id) => ({
        url: `/processors/generate-campaign/${task_id}/result`,
        method: RequestMethods.GET,
      }),
    }),

    getCampaigns: builder.query<Campaign[], Query>({
      query: (params) => ({
        url: "/campaigns",
        method: RequestMethods.GET,
        params,
      }),
    }),

    deleteProduct: builder.mutation<CampaignLatestClear, string>({
      query: (campaign_id) => ({
        url: `/campaigns/${campaign_id}`,
        method: RequestMethods.DELETE,
      }),
    }),

    getCurrentCampaign: builder.query<CurrentCampaign, { campaign_id: string }>(
      {
        query: ({ campaign_id }) => ({
          url: `/campaigns/${campaign_id}`,
          method: RequestMethods.GET,
        }),
      },
    ),

    getCampaignSummary: builder.query<CampaignSummary, { campaign_id: string }>(
      {
        query: ({ campaign_id }) => ({
          url: `/campaigns/${campaign_id}/summary`,
          method: RequestMethods.GET,
        }),
      },
    ),

    patchCampaign: builder.mutation<Response, UpdateCampaign>({
      query: ({ campaign_id, ...body }) => ({
        url: `/campaigns/${campaign_id}`,
        method: RequestMethods.PATCH,
        body,
      }),
    }),
    enterCompanyManually: builder.mutation<Response, EnterCompanyManually>({
      query: ({ campaign_id, ...body }) => ({
        url: `/campaigns/${campaign_id}/enter-manually`,
        method: RequestMethods.POST,
        body,
      }),
    }),
    validateCampaignData: builder.mutation<Response, ValidateCampaign>({
      query: (body) => ({
        url: "/validators/campaign",
        method: RequestMethods.POST,
        body,
      }),
    }),
    findExternalData: builder.mutation<Response, string>({
      query: (account_id) => ({
        url: `/accounts/${account_id}/find-external-data`,
        method: RequestMethods.POST,
      }),
    }),
    getEngagementScoreConfig: builder.query<EngagementScoreConfig, CampaignId>({
      query: ({ campaign_id }) => ({
        url: `/campaigns/${campaign_id}/engagement-config`,
        method: RequestMethods.GET,
      }),
    }),
    changeEngagementScoreConfig: builder.mutation<
      EngagementScoreConfig,
      EngagementScoreConfig & CampaignId
    >({
      query: ({ campaign_id, ...rest }) => ({
        url: `/campaigns/${campaign_id}/engagement-config`,
        method: RequestMethods.POST,
        body: rest,
      }),
    }),
  }),
});

export const {
  useCreateCampaignMutation,
  useUploadCampaignFileMutation,
  useCreateHubspotImportMutation,
  useGetHubspotUrlMutation,
  useCreateHubspotUrlMutation,
  useCreateSalesforceImportMutation,
  useGetSalesforceUrlMutation,
  useCreateSalesforceUrlMutation,
  useGetCampaignDetailsQuery,
  useGetUserGampaignQuery,
  useGetCampaignMembersQuery,
  usePatchCampaignMembersMutation,
  useGetCampaignChartQuery,
  useGenerateCampaignMutation,
  useGetGenerateCampaignResultQuery,
  useUploadCampaignExtendFileMutation,
  useGetCampaignsQuery,
  useDeleteProductMutation,
  useGetCurrentCampaignQuery,
  useGetCampaignSummaryQuery,
  usePatchCampaignMutation,
  useEnterCompanyManuallyMutation,
  useValidateCampaignDataMutation,
  useFindExternalDataMutation,
  useGetEngagementScoreConfigQuery,
  useChangeEngagementScoreConfigMutation,
} = campaignsApi;
