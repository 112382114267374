import { Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { CustomButton } from "../ui/CustomButton";
import { PeopleIcon } from "../images";
import { CompanyIcon } from "../images/CompanyIcon";
import { Campaign } from "../../types/apiTypes";
import { Link, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../types/enums";
import { formatDateRange } from "../../utils/formatDateRange";

type ItemProps = {
  title: string;
  text: string;
  icon: ReactNode;
};

const Item = ({ title, text, icon }: ItemProps) => {
  return (
    <Stack direction="row" gap="16px" alignItems="flex-start">
      {icon}
      <Stack gap="4px">
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ fontWeight: 500 }}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          color="custom.textDarkBlue"
          sx={{ fontWeight: 500 }}
        >
          {text}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const ProductListItem = ({ product }: { product: Campaign }) => {
  const {
    id,
    product_abstract,
    product_name,
    company_name,
    total_accounts,
    latest_account_update,
  } = product;
  const navigate = useNavigate();

  const list = [
    {
      title: "Accounts attached",
      text: `${total_accounts}`,
      icon: <PeopleIcon />,
    },
    {
      title: "Company",
      text: company_name,
      icon: <CompanyIcon />,
    },
  ];

  return (
    <Stack
      gap="16px"
      sx={{
        border: "1px solid #D9D9D9",
        padding: "24px",
        borderRadius: "8px",
      }}
    >
      <Link
        to={`${AppRoutes.PRODUCTS}/${id}`}
        style={{ textDecoration: "none" }}
      >
        <Typography
          variant="subtitle1"
          color="custom.textDarkBlue"
          sx={{ fontWeight: 500 }}
        >
          {product_name}
        </Typography>
      </Link>

      <Stack direction="row" spacing="40px">
        {list.map(({ title, text, icon }) => (
          <Item key={title} title={title} text={text} icon={icon} />
        ))}
      </Stack>
      <Typography variant="body1" color="text.secondary">
        {product_abstract}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography variant="body1" color="custom.textDarkBlue">
          Last update: {formatDateRange(latest_account_update, true)}
        </Typography>
        <Stack direction="row" gap="8px" minWidth="340px">
          <CustomButton
            variant="contained"
            fullWidth
            type="button"
            customColor="#1C3C6C"
            sx={{ height: "38px" }}
            onClick={() => navigate(`${AppRoutes.ACCOUNTS_LIST}/${id}`)}
            disabled={total_accounts === 0}
          >
            View accounts
          </CustomButton>
          <CustomButton
            variant="outlined"
            customColor="#FFFFFF"
            textColor="#1D3E70"
            fullWidth
            type="button"
            sx={{ height: "38px", borderColor: "#1D3E70" }}
            onClick={() => navigate(`${AppRoutes.PRODUCTS}/${id}`)}
          >
            Сampaign details
          </CustomButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
