import { IconProps } from "../../types/genereal";
import { Stack } from "@mui/material";

export const ExportDataIcon = ({
  width = "24",
  height = "24",
  color = "#1D3E70",
}: IconProps) => {
  return (
    <Stack direction="row" sx={{ minWidth: width, minHeight: height }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.0499 22.375L3.6499 20.95L6.5999 18H4.3499V16H9.9999V21.65H7.9999V19.425L5.0499 22.375ZM11.9999 22V20H17.9999V9H12.9999V4H5.9999V14H3.9999V4C3.9999 3.45 4.1959 2.97933 4.5879 2.588C4.9799 2.19667 5.45057 2.00067 5.9999 2H13.9999L19.9999 8V20C19.9999 20.55 19.8042 21.021 19.4129 21.413C19.0216 21.805 18.5506 22.0007 17.9999 22H11.9999Z"
          fill={color}
        />
      </svg>
    </Stack>
  );
};
