import React from "react";
import { Button, ButtonProps, SxProps, Theme } from "@mui/material";
import { lighten } from "@mui/material/styles";

type CustomButtonSize = "s" | "m" | "l" | "xl" | "fit";

interface CustomButtonProps extends Omit<ButtonProps, "size" | "color"> {
  children: React.ReactNode;
  customSize?: CustomButtonSize;
  customColor?: string;
  customGradientColor?: string;
  textColor?: string;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  customSize = "m",
  customColor = "#333333",
  customGradientColor,
  textColor = "#fff",
  fullWidth = false,
  sx,
  ...props
}) => {
  const sizeWidth = {
    s: "98px",
    m: "116px",
    l: "218px",
    xl: "294px",
    fit: "fit-content",
  };

  const sizeStyles = {
    width: fullWidth ? "100%" : sizeWidth[customSize],
    maxWidth: fullWidth ? "100%" : sizeWidth[customSize],
  };

  const backgroundStyle = customGradientColor
    ? `linear-gradient(to right,  ${customGradientColor}, ${customColor})`
    : customColor;

  return (
    <Button
      variant="contained"
      fullWidth={fullWidth}
      component=""
      sx={{
        height: "42px",
        background: backgroundStyle,
        borderRadius: "8px",
        padding: "8px 20px",
        boxShadow: 0,
        color: textColor,
        gap: 1,
        ":hover": {
          background: customGradientColor
            ? `linear-gradient(to right, ${lighten(
                customGradientColor,
                0.05,
              )}, ${lighten(customColor, 0.05)})`
            : `${customColor}CC`,
        },
        "& .MuiButton-startIcon": {
          marginRight: 0,
        },
        ...sizeStyles,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
