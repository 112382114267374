import { IconProps } from "../../types/genereal";

export const SearchIcon = ({
  width = "16",
  height = "16",
  color = "#1F2932",
  opacity = "0.6",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7556 16L9.15556 10.4C8.71111 10.7556 8.2 11.037 7.62222 11.2444C7.04445 11.4519 6.42963 11.5556 5.77778 11.5556C4.16296 11.5556 2.79644 10.9961 1.67822 9.87733C0.56 8.75852 0.000593063 7.392 4.70312e-07 5.77778C-0.000592122 4.16356 0.558815 2.79704 1.67822 1.67822C2.79763 0.559407 4.16415 0 5.77778 0C7.39141 0 8.75822 0.559407 9.87822 1.67822C10.9982 2.79704 11.5573 4.16356 11.5556 5.77778C11.5556 6.42963 11.4519 7.04444 11.2444 7.62222C11.037 8.2 10.7556 8.71111 10.4 9.15556L16 14.7556L14.7556 16ZM5.77778 9.77778C6.88889 9.77778 7.83348 9.38904 8.61156 8.61156C9.38963 7.83407 9.77837 6.88948 9.77778 5.77778C9.77719 4.66607 9.38844 3.72178 8.61156 2.94489C7.83467 2.168 6.89007 1.77896 5.77778 1.77778C4.66548 1.77659 3.72119 2.16563 2.94489 2.94489C2.16859 3.72415 1.77956 4.66844 1.77778 5.77778C1.776 6.88711 2.16504 7.8317 2.94489 8.61156C3.72474 9.39141 4.66904 9.78015 5.77778 9.77778Z"
        fill={color}
        fillOpacity={opacity}
      />
    </svg>
  );
};
