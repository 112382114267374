import { IconProps } from "../../types/genereal";
import { IconWrapper } from "components/ui/IconWrapper";

export const DefaultProfileImageIcon = ({
  width = "98",
  height = "98",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 42 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="42" height="42" rx="21" fill="#BDBDBD" />
        <path
          d="M21 21C23.21 21 25 19.21 25 17C25 14.79 23.21 13 21 13C18.79 13 17 14.79 17 17C17 19.21 18.79 21 21 21ZM21 23C18.33 23 13 24.34 13 27V29H29V27C29 24.34 23.67 23 21 23Z"
          fill="white"
        />
      </svg>
    </IconWrapper>
  );
};
