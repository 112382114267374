import { Box, Divider, Stack, TextField, Typography } from "@mui/material";
import { MingcutePdfLine } from "../../images/PdfFileImg";
import { CustomButton } from "../../ui/CustomButton";
import { SwitchImg } from "../../images/SwitchImg";
import { DeleteImg } from "../../images/deleteImg";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { PenIcon } from "../../images";
import { AnalyzingProductBlock } from "../AnalyzingProductBlock";
import { toast } from "react-toastify";
import { CustomToast } from "../../ui/CustomToast";
import { URL_REGEX } from "../../../utils/regex";
import { Fields } from "./ProductDetailsModal";

type Props = {
  campaign_id: string;
  setErrorHandler: (field: string, value: boolean) => void;
  pdfFile: File | null;
  setPdfFile: (file: File | null) => void;
  productUrl: string;
  setProductUrl: (url: string) => void;
  setResultHandler: (result: Fields) => void;
  initProductUrl: string;
};

export const ImportedDataBlock = ({
  campaign_id,
  setErrorHandler,
  setPdfFile,
  pdfFile,
  productUrl,
  setProductUrl,
  setResultHandler,
  initProductUrl,
}: Props) => {
  const [productUrlError, setProductUrlError] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!isEditModal) setProductUrl(initProductUrl);
  }, [isEditModal]);

  useEffect(() => {
    if (URL_REGEX.test(productUrl)) {
      setProductUrlError(false);
      setErrorHandler("url", false);
    } else {
      setProductUrlError(true);
      setErrorHandler("url", true);
    }

    if (!productUrl) {
      setProductUrlError(false);
      setErrorHandler("url", false);
    }
  }, [productUrl]);

  const urlInputHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setProductUrl(e.target.value);
  };

  const uploadFileHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type === "application/pdf") {
        setPdfFile(file);
      } else {
        toast.error(
          <CustomToast
            type={"error"}
            text={
              "You have chosen unsupported file extension. Please choose .pdf file and try again"
            }
            title={"Failed upload file"}
          />,
        );
        setPdfFile(null);
      }
    }
  };

  return (
    <Stack gap={1} pt="42px" pb="32px">
      <Stack direction="row" gap="8px">
        <TextField
          helperText={
            productUrlError && (
              <Stack>
                <span>Invalid URL: Please check the format and try again.</span>
                <span>Example: https://www.example.com</span>
              </Stack>
            )
          }
          error={productUrlError}
          fullWidth
          label="Product description URL"
          value={productUrl}
          onChange={urlInputHandler}
          placeholder="www.example.com"
          disabled={!isEditModal}
          sx={{
            "& .MuiInputBase-root.Mui-disabled": {
              pointerEvents: "none",
              backgroundColor: "#f5f5f5",
            },
          }}
        />
        {isEditModal ? (
          <CustomButton
            onClick={() => setIsEditModal(false)}
            variant="outlined"
            customColor="#FFFFFF"
            textColor="#1D3E70"
            customSize="fit"
            type="button"
            sx={{ height: "40px", borderColor: "#1D3E70", minWidth: "90px" }}
          >
            Cancel
          </CustomButton>
        ) : (
          <CustomButton
            onClick={() => setIsEditModal(true)}
            variant="outlined"
            customColor="#FFFFFF"
            textColor="#1D3E70"
            customSize="fit"
            type="button"
            startIcon={<PenIcon />}
            sx={{ height: "40px", borderColor: "#1D3E70", minWidth: "90px" }}
          >
            Edit
          </CustomButton>
        )}
      </Stack>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Divider sx={{ flexGrow: 1, color: "#CFCFCF" }} />
        <Typography
          variant="button"
          sx={{
            marginX: "8px",
            color: "#4C545B",
          }}
        >
          or / and
        </Typography>
        <Divider sx={{ flexGrow: 1, color: "#CFCFCF" }} />
      </Box>

      <Box>
        <Stack gap="8px" alignItems="center" justifyContent="center">
          {pdfFile && (
            <Box display="flex" alignItems="center" gap={1} width="100%">
              <Box display="flex" alignItems="center" flexGrow={1} minWidth={0}>
                <MingcutePdfLine />
                <Typography
                  color="#1D3E70"
                  pl={1}
                  sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {pdfFile.name}
                </Typography>
              </Box>
              <CustomButton
                variant="outlined"
                customColor=""
                customSize="s"
                onClick={() => fileInputRef.current?.click()}
                sx={{
                  minWidth: "auto",
                  p: 1,
                  width: "40px",
                  height: "40px",
                  border: "1px solid #1D3E70",
                }}
              >
                <SwitchImg />
              </CustomButton>
              <CustomButton
                variant="outlined"
                customColor=""
                customSize="s"
                onClick={() => setPdfFile(null)}
                sx={{
                  minWidth: "auto",
                  p: 1,
                  width: "40px",
                  height: "40px",
                  border: "1px solid #1D3E70",
                }}
              >
                <DeleteImg />
              </CustomButton>
            </Box>
          )}
        </Stack>

        {!pdfFile && (
          <>
            <CustomButton
              variant="outlined"
              fullWidth
              customColor=""
              textColor="#1D3E70"
              onClick={() => fileInputRef.current?.click()}
              sx={{ height: "48px", border: "1px solid #1D3E70" }}
            >
              <MingcutePdfLine />
              Upload PDF
            </CustomButton>
            <input
              type="file"
              hidden
              ref={fileInputRef}
              accept=".pdf"
              onChange={uploadFileHandler}
            />
          </>
        )}

        {(pdfFile || (productUrl !== initProductUrl && productUrl)) && (
          <Stack alignItems="center" justifyContent="center" pt={2}>
            <AnalyzingProductBlock
              productUrl={productUrl}
              pdfFile={pdfFile}
              campaign_id={campaign_id}
              setResultHandler={setResultHandler}
            />
          </Stack>
        )}
      </Box>
    </Stack>
  );
};
