import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const IncreaseIcon = ({
  width = "34",
  height = "34",
  color = "#1C3C6C",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.25768 22.25L7.91602 20.9083L15.0077 13.7687L18.841 17.6021L23.8243 12.6667H21.3327V10.75H27.0827V16.5H25.166V14.0083L18.841 20.3333L15.0077 16.5L9.25768 22.25Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  );
};
