import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { AccountListItem } from "../../types";
import React, { useEffect, useState } from "react";
import { BASE_API_URL } from "../../api/baseQuery";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { SSEManager } from "../../utils/useSSEEvent";
import { toast } from "react-toastify";
import { CustomToast } from "./CustomToast";
import { isTextEllipsed } from "../../utils/general_utils";

interface SSEData {
  status: "in_progress" | "completed" | "failed";
  progress: number;
}

const TooltipItem = ({
  text,
  value,
  color,
}: {
  text: string;
  value: string;
  color: string;
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap="14px"
    >
      <Stack direction="row" alignItems="center" gap="6px">
        <Box
          sx={{
            width: "18px",
            height: "14px",
            backgroundColor: color,
          }}
        ></Box>
        <Typography variant="body2" align="center" color="white">
          {text}
        </Typography>
      </Stack>
      <Typography variant="body2" align="center" color="white" fontWeight={600}>
        {value}
      </Typography>
    </Stack>
  );
};

const LinerProgress = ({
  data,
}: {
  data: AccountListItem;
  isShowed: boolean;
}) => {
  if (data.status !== "completed") return null;

  const userData = data.total_user_data || 0;
  const infuseData = data.total_enriched_data || 0;

  return (
    <Tooltip
      title={
        <Stack gap="6px" paddingY="4px">
          <TooltipItem value={`${userData}%`} text="My data" color="#1C3C6C" />
          <TooltipItem
            value={`${infuseData}%`}
            text="INFUSE data"
            color="#FE8836"
          />
        </Stack>
      }
      placement="bottom"
      arrow
      sx={{
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 400,
        "&:hover": {
          visibility: "visible",
          opacity: 1,
        },
      }}
    >
      <Stack
        alignItems="center"
        onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
          const target = event.currentTarget;
          isTextEllipsed(target);
        }}
      >
        <Stack alignItems="center">
          <Stack
            sx={{ position: "relative", width: "42px", height: "42px" }}
            justifyContent="center"
          >
            <CircularProgress
              size={42}
              thickness={4}
              variant="determinate"
              sx={{
                color: "#D4D4D4",
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 1,
                opacity: 1,
              }}
              value={100}
            />
            <CircularProgress
              size={42}
              thickness={4}
              variant="determinate"
              sx={{
                color: "#1C3C6C",
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 3,
                opacity: 1,
              }}
              value={userData}
            />
            <CircularProgress
              size={42}
              thickness={4}
              variant="determinate"
              sx={{
                color: "#FE8836",
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 2,
              }}
              value={userData + infuseData}
            />
            <Typography
              variant="body1"
              align="center"
              sx={{
                flexShrink: 1,
                fontSize: 10,
                fontWeight: 600,
              }}
            >
              {data.penetration_score || 0}%
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Tooltip>
  );
};

const AnalyseProgress = ({
  data,
  setIsAnalyse,
  callBack,
}: {
  data: AccountListItem;
  setIsAnalyse: (isAnalyse: boolean) => void;
  callBack: () => void;
}) => {
  const [progress, setProgress] = useState<number>(data.progress);
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.auth.accessToken) as string;
  const refreshToken = useAppSelector(
    (state) => state.auth.refreshToken,
  ) as string;

  useEffect(() => {
    const sseManager = new SSEManager();
    const handleMessage = (sseData: SSEData[]) => {
      const { status, progress } = sseData[0];
      setProgress(progress);

      if (status === "failed") {
        toast.error(
          <CustomToast
            type={"error"}
            text={`Something went wrong during analysis of ${data.company}. Please try again`}
            title={"Analysis failed"}
          />,
        );
        setIsAnalyse(false);
      } else if (status === "completed") {
        toast.success(
          <CustomToast
            type={"success"}
            text={`The company ${data.company} was successfully analyzed.`}
            title={"Analysis success"}
          />,
        );
        setIsAnalyse(false);
        callBack();
      }
    };

    if (!sseManager.isConnected && data.status !== "queued") {
      sseManager.connect({
        url: `${BASE_API_URL}/accounts/${data.id}/sse-status`,
        token,
        refreshToken,
        onMessage: handleMessage,
        onError: () => {},
        dispatch: dispatch,
      });
    }

    return () => {
      sseManager?.isConnected && sseManager.close();
    };
  }, [token, data.status]);

  return (
    <Stack
      direction="row"
      gap="8px"
      alignItems="center"
      justifyContent="flex-end"
    >
      {data.status === "queued" ? (
        <Typography variant="button" sx={{ fontWeight: 500 }}>
          Queued
        </Typography>
      ) : (
        <>
          <CircularProgress size={24} sx={{ color: "custom.subTitleModal" }} />
          <Typography variant="button" sx={{ fontWeight: 500 }}>
            Analyzing: {`${progress}%`}
          </Typography>
        </>
      )}
    </Stack>
  );
};

const options = ["queued", "in_progress"];

export const ProgressBar = ({
  data,
  isShowed,
  callBack,
}: {
  data: AccountListItem;
  isShowed: boolean;
  callBack: () => void;
}) => {
  const [isAnalyse, setIsAnalyse] = useState<boolean>(
    options.includes(data.status),
  );

  useEffect(() => {
    if (options.includes(data.status)) {
      setIsAnalyse(true);
    }
  }, [data.status]);

  return isAnalyse ? (
    <AnalyseProgress
      data={data}
      setIsAnalyse={setIsAnalyse}
      callBack={callBack}
    />
  ) : (
    <LinerProgress data={data} isShowed={isShowed} />
  );
};
