// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: "Inter";
}

.scrollable_container::-webkit-scrollbar {
  box-sizing: border-box;
  background: #fafafa;
  width: 8px;
}

.scrollable_container::-webkit-scrollbar-track {
  cursor: pointer;
  background: #fafafa;
}

.scrollable_container::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #a3a3a3;
  width: 8px;
  min-height: 10px;
  border-radius: 8px;
}

.scrollable_container::-webkit-scrollbar-thumb:hover {
  background: #959494;
}
`, "",{"version":3,"sources":["webpack://./src/styles/base.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,oBAAoB;AACtB;;AAEA;EACE,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,UAAU;EACV,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: \"Inter\";\n}\n\n.scrollable_container::-webkit-scrollbar {\n  box-sizing: border-box;\n  background: #fafafa;\n  width: 8px;\n}\n\n.scrollable_container::-webkit-scrollbar-track {\n  cursor: pointer;\n  background: #fafafa;\n}\n\n.scrollable_container::-webkit-scrollbar-thumb {\n  cursor: pointer;\n  background: #a3a3a3;\n  width: 8px;\n  min-height: 10px;\n  border-radius: 8px;\n}\n\n.scrollable_container::-webkit-scrollbar-thumb:hover {\n  background: #959494;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
