import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { MoreVertIcon } from "../images/MoreVertIcon";
import { UpdateIcon, TrashCanIcon } from "../images";
import { EyeIcon } from "../images/EyeIcon";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes, UploadFlow } from "../../types/enums";
import { BaseModal } from "components/ui/BaseModal";
import { CustomButton } from "../ui/CustomButton";
import { useEffect, useMemo, useState } from "react";
import { useDiscardAccountMutation, useUpdateAccountMutation } from "../../api";
import { toast } from "react-toastify";
import { CustomToast } from "../ui/CustomToast";
import { IIcon } from "../images/IIcon";
import { UploadFromHubSpot } from "../ui/upload-data/UploadFromHubSpot";
import { UploadFromSalesforce } from "../ui/upload-data/UploadFromSalesforce";
import { useStateWithSearchParams } from "../../hooks/useStateWithSearchParams";
import { useAppDispatch } from "../../hooks/useReduxHooks";
import { setUpdateAnalytics } from "../../features/companySlice";
import { UpdateDataBtn } from "../ui";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "rgb(55, 65, 81)",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px," +
      " rgba(0, 0, 0, 0.05) 0px 0px 0px 1px," +
      " rgba(0, 0, 0, 0.1) 0px 10px 15px -3px," +
      " rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[300],
    }),
  },
}));

type SearchParams = {
  page?: string;
};

export const CompanyMenu = ({
  id,
  refetch,
  companyName,
  total_companies,
}: {
  id: string;
  companyName: string;
  refetch: () => void;
  total_companies: number;
}) => {
  const { campaign_id } = useParams();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentFlow, setCurrentFlow] = useState<string>("");
  const [isFlowLoading, setIsFlowLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { searchDict, setSearchValue } = useStateWithSearchParams<SearchParams>(
    {
      page: undefined,
    },
  );

  const [discardAccount, { isSuccess, error }] = useDiscardAccountMutation();
  const [updateAccount, { isLoading }] = useUpdateAccountMutation();

  useEffect(() => {
    if (currentFlow) {
      setCurrentFlow("");
    }
  }, [currentFlow]);

  useEffect(() => {
    if (error) {
      toast.error(
        <CustomToast
          type={"error"}
          text={`Something went wrong during discard of ${companyName}. Please try again`}
          title={"Discard failed"}
        />,
      );
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        <CustomToast
          type={"success"}
          text={`The company ${companyName} was successfully discard.`}
          title={"Discard success"}
        />,
      );
      if (searchDict.page && total_companies) {
        setSearchValue("page", undefined);
      }
      refetch();
      dispatch(setUpdateAnalytics(true));
    }
  }, [isSuccess]);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateSuccessHandler = () => {
    refetch();
    setAnchorEl(null);
  };

  const detailsHandler = (id: string) => {
    navigate(`${AppRoutes.ACCOUNTS}/${id}`);
  };

  const updateHandler = (id: string) => {
    updateAccount(id)
      .unwrap()
      .then(() => {
        toast.success(
          <CustomToast
            type={"success"}
            text={`The company ${companyName} was successfully updated.`}
            title={"Update success"}
          />,
        );
        refetch();
        dispatch(setUpdateAnalytics(true));
      })
      .catch((error) => {
        if (error?.status === 428) {
          setCurrentFlow(error.data.type);
        } else {
          toast.error(
            <CustomToast
              type={"error"}
              text={`Something went wrong while updating ${companyName}. Please try again`}
              title={"Update failed"}
            />,
          );
          refetch();
        }
      });
  };

  const discardHandler = (id: string) => {
    discardAccount(id);
    setIsOpen(false);
    setAnchorEl(null);
  };

  const options = useMemo(
    () => [
      {
        label: "Details",
        icon: <EyeIcon />,
        onClick: detailsHandler,
      },
      {
        label: "Update",
        icon:
          isLoading || isFlowLoading ? (
            <CircularProgress
              size={24}
              sx={{ color: "custom.subTitleModal" }}
            />
          ) : (
            <UpdateIcon />
          ),
        onClick: updateHandler,
      },
      {
        label: "Discard",
        icon: <TrashCanIcon />,
        onClick: () => setIsOpen(true),
      },
    ],
    [isLoading, isFlowLoading],
  );

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon width="24" />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "white",
          },
        }}
      >
        {options.map(({ label, onClick, icon }) => (
          <MenuItem
            key={label}
            sx={{ display: "flex", gap: "12px", alignItems: "center" }}
            onClick={() => onClick(id)}
          >
            {icon}
            {label}
          </MenuItem>
        ))}
      </StyledMenu>
      <BaseModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Discard account"
        sx={{ padding: "32px", maxWidth: "368px", width: "90%" }}
      >
        <Stack gap="32px" maxWidth="304px" width="100%">
          <Typography component="span" variant="body1" color="text.secondary">
            Are you sure you want to clear data about “{companyName}”?
          </Typography>
          <Stack direction="row" gap="8px">
            <IIcon />
            <Typography
              component="span"
              variant="body2"
              color="custom.textDarkBlue"
            >
              This will discard the company from the analysis but won't
              influence your original data source
            </Typography>
          </Stack>
          <Stack direction="row" gap="8px">
            <CustomButton
              onClick={() => setIsOpen(false)}
              variant="contained"
              textColor="#4C545B"
              fullWidth
              type="button"
              customColor="#F1F1F1"
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={() => discardHandler(id)}
              variant="contained"
              fullWidth
              type="button"
              customColor="#CF3E00"
            >
              Discard
            </CustomButton>
          </Stack>
        </Stack>
      </BaseModal>
      <Box hidden>
        <UpdateDataBtn
          shouldBeOpen={currentFlow === UploadFlow.FILE}
          onSuccess={updateSuccessHandler}
        />
        <UploadFromHubSpot
          actionType="update"
          campaign_id={campaign_id!}
          onSuccess={updateSuccessHandler}
          startFlow={currentFlow === UploadFlow.HUBSPOT}
          setIsLoading={setIsFlowLoading}
        />
        <UploadFromSalesforce
          actionType="update"
          campaign_id={campaign_id!}
          onSuccess={updateSuccessHandler}
          startFlow={currentFlow === UploadFlow.SALESFORCE}
          setIsLoading={setIsFlowLoading}
        />
      </Box>
    </div>
  );
};
