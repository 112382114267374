import { Box, Stack, Typography } from "@mui/material";
import { AccordionArrowDown } from "../../images/AccordionArrowDown";
import { CustomInput } from "../CustomInput";
import { SearchIcon } from "../../images/SearchIcon";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import { CustomButton } from "../CustomButton";
import { useEffect, useState } from "react";
import { useStateWithSearchParams } from "../../../hooks/useStateWithSearchParams";
import { useDebounceValue } from "../../../hooks/useDebounceValue";

type Props = {
  items: string[];
  query: string;
  placeholder: string;
  selectedAccordion: string;
  setSelectedAccordion: (accordion: string) => void;
};

type SearchParams = {
  [key: string]: string | undefined;
};

export const AccordionBody = ({
  placeholder,
  query,
  items,
  selectedAccordion,
  setSelectedAccordion,
}: Props) => {
  const [value, setValue] = useState<string>("");
  const searchValueDebounce = useDebounceValue(value);

  const { searchDict, setSearchValue } = useStateWithSearchParams<SearchParams>(
    { [query]: undefined }
  );

  const [selectedItemList, setSelectedItemList] = useState<string[]>([]);

  useEffect(() => {
    setSelectedItemList(
      searchDict[query]?.length ? searchDict[query]?.split(",") || [] : []
    );
  }, [searchDict[query]]);

  const handleChange = (item: string) => {
    let isInList = false;

    const newList = selectedItemList.filter((name) => {
      if (name === item) {
        isInList = true;
        return false;
      }

      return true;
    });

    !isInList && newList.push(item);
    setSelectedItemList(newList);
  };

  const cancelHandler = () => {
    setSelectedAccordion("");
    setValue("");
    setSearchValue(query, undefined);
  };

  const submitHandler = () => {
    setSelectedAccordion("");
    setValue("");
    setSearchValue(query, selectedItemList.join(","));
  };

  const isOpen = selectedAccordion === query;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px 24px 12px 16px",
          cursor: "pointer",
          position: "relative",
        }}
        onClick={() => setSelectedAccordion(isOpen ? "" : query)}
      >
        <Typography component="span" variant="button" color="text.secondary">
          {placeholder}
        </Typography>
        <Stack direction="row" gap="12px" alignItems="center">
          {!!(searchDict[query]?.split(",")[0] || []).length && (
            <Box
              sx={{
                backgroundColor: "#1B3967",
                color: "white",
                borderRadius: "20px",
                fontSize: "12px",
                lineHeight: "20px",
                paddingX: "6px",
                minWidth: "20px",
                textAlign: "center",
              }}
            >
              {searchDict[query]?.split(",").length}
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          >
            <AccordionArrowDown />
          </Box>
        </Stack>
      </Box>
      {isOpen && (
        <>
          <Box padding="8px 16px">
            <CustomInput
              value={value}
              placeholder="Search"
              prefixIcon={<SearchIcon />}
              onChange={setValue}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxHeight: "254px",
              overflow: "auto",
              marginRight: "4px",
            }}
            className="scrollable_container"
          >
            {items
              .filter((el) => el.includes(searchValueDebounce))
              .map((option) => (
                <MenuItem
                  sx={{
                    padding: "4px",
                  }}
                  key={option}
                  value={option}
                  onClick={() => handleChange(option)}
                >
                  <Checkbox
                    checked={selectedItemList.includes(option)}
                    sx={{
                      color: "#1C3C6C",
                      "&.Mui-checked": {
                        color: "#1C3C6C",
                      },
                    }}
                  />
                  <ListItemText primary={option} />
                </MenuItem>
              ))}
          </Box>

          <Stack
            direction="row"
            justifyContent="space-between"
            gap={1}
            padding="8px 16px"
          >
            <CustomButton
              onClick={cancelHandler}
              variant="contained"
              textColor="#4C545B"
              fullWidth
              type="button"
              customColor="#F1F1F1"
            >
              Cancel
            </CustomButton>
            <CustomButton
              onClick={submitHandler}
              variant="contained"
              fullWidth
              type="button"
              customColor="#1C3C6C"
            >
              Apply
            </CustomButton>
          </Stack>
        </>
      )}
    </>
  );
};
