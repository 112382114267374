import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useStateWithSearchParams } from "../hooks/useStateWithSearchParams";
import { DropdownWithSearch } from "../components/ui/dropdown";
import { SelectedFilterList } from "../components/features/SelectedFilterList";
import { useNavigate, useParams } from "react-router-dom";
import { CustomButton } from "../components/ui/CustomButton";
import {
  useAnalyseAllMutation,
  useAnalyseMutation,
  useGetAccountsFiltersQuery,
  useGetAccountsListQuery,
} from "../api";
import { Order } from "../types";
import { useCallback, useEffect, useState } from "react";
import { ResetAllFiltersButton } from "../components/features/ResetAllFiltersButton";
import { prepareFilters } from "../utils/prepareFilters";
import { InputWithQuery } from "../components/ui/InputWithQuery";
import { useNavigateOnNoData } from "../hooks/useNavigateOnNoData";
import { toast } from "react-toastify";
import { CustomToast } from "../components/ui/CustomToast";
import { getErrorMessage } from "../utils/getErrorMessage";
import { AppRoutes } from "../types/enums";
import { useAppDispatch, useAppSelector } from "../hooks/useReduxHooks";
import { setUpdate, setUpdateAnalytics } from "../features/companySlice";
import { useDebounceValue } from "../hooks/useDebounceValue";
import { AccountsListTable } from "../components/accounts";

type SearchParams = {
  sizes?: string;
  industries?: string;
  regions?: string;
  search?: string;
  sort?: string;
  page?: string;
  per_page?: string;
  selected?: string;
};

export const AccountsListPage = () => {
  const { campaign_id } = useParams();
  const updateData = useAppSelector((state) => state.company.update);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isAnalyseAllowed, setIsAnalyseAllowed] = useState<boolean>(true);

  const [analyse, { isSuccess: isAnalysed }] = useAnalyseMutation();

  const [analyseAll, { isSuccess: isAnalysedAll, isLoading: isAnalysingAll }] =
    useAnalyseAllMutation();

  const { searchDict, setSearchValue } = useStateWithSearchParams<SearchParams>(
    {
      industries: undefined,
      regions: undefined,
      sizes: undefined,
      search: undefined,
      sort: undefined,
      page: undefined,
      per_page: undefined,
      selected: undefined,
    },
  );

  const searchValueDebounce = useDebounceValue(searchDict.search || "");

  const filters = useGetAccountsFiltersQuery(
    {
      campaign_id: campaign_id!,
    },
    { skip: !campaign_id },
  );

  const { data, refetch, isFetching, error } = useGetAccountsListQuery(
    {
      campaign_id: campaign_id!,
      size: searchDict?.sizes?.split(","),
      search: searchValueDebounce || undefined,
      industry: searchDict?.industries?.split(","),
      region: searchDict?.regions?.split(","),
      page: searchDict?.page ? Number(searchDict?.page) : undefined,
      per_page: searchDict?.per_page ? Number(searchDict?.per_page) : undefined,
      sort: searchDict?.sort?.split("-")[0],
      order: searchDict?.sort?.split("-")[1] as Order,
    },
    { skip: !campaign_id, refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (
      searchValueDebounce ||
      searchDict?.per_page ||
      searchDict?.sort ||
      searchDict?.sizes ||
      searchDict?.industries ||
      searchDict?.regions
    )
      setSearchValue("page", undefined);
  }, [
    searchValueDebounce,
    searchDict?.per_page,
    searchDict?.sort,
    searchDict?.sizes,
    searchDict?.industries,
    searchDict?.regions,
  ]);

  useEffect(() => {
    if (updateData === "list") {
      refetch();
      dispatch(setUpdate(""));
      dispatch(setUpdateAnalytics(true));
    }
  }, [updateData]);

  useEffect(() => {
    if (error) {
      toast.error(
        <CustomToast
          type={"error"}
          text={getErrorMessage(error).globalError || ""}
          title={"Something went wrong"}
        />,
      );
      navigate(AppRoutes.PRODUCTS);
    }
  }, [error]);

  useEffect(() => {
    if (isAnalysingAll) {
      setIsAnalyseAllowed(false);
    }
  }, [isAnalysingAll]);

  useEffect(() => {
    if (
      data?.items.some(
        (el) =>
          ![
            "completed",
            "queued",
            "in_progress",
            "scraping_queued",
            "scraping_in_progress",
          ].includes(el.status),
      )
    ) {
      setIsAnalyseAllowed(true);
    } else {
      setIsAnalyseAllowed(false);
    }
  }, [data?.items]);

  useEffect(() => {
    if (isAnalysed || isAnalysedAll) {
      setSearchValue("selected", undefined);
      refetch();
    }
  }, [isAnalysed, isAnalysedAll]);

  const removeFilterHandler = (key: string, filter: string) => {
    const filters = searchDict[key as keyof SearchParams];
    if (!filters) return;

    setSearchValue(
      key as keyof SearchParams,
      filters
        .split(",")
        .filter((el) => el !== filter)
        .join(","),
    );
  };

  const isFilters =
    prepareFilters(filters.data).some(
      ({ query }) => searchDict[query as keyof SearchParams],
    ) || !!searchDict.selected;

  const analyseHandler = useCallback(() => {
    if (searchDict.selected) {
      analyse({ account_ids: searchDict.selected.split(",") });
    } else {
      analyseAll({ campaign_id: campaign_id! });
    }
  }, [searchDict.selected, campaign_id]);

  useNavigateOnNoData(
    isFilters || !!searchValueDebounce || !!searchDict.page,
    isFetching,
    data?.items,
  );

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", gap: "24px" }}>
        <InputWithQuery />
        {prepareFilters(filters.data).map(({ placeholder, query, items }) => (
          <DropdownWithSearch
            key={query}
            placeholder={placeholder}
            query={query}
            items={items}
          />
        ))}
        <Stack direction="row" gap="12px" marginLeft="auto" alignItems="center">
          {isFilters && (
            <>
              <ResetAllFiltersButton campaign_id={campaign_id!} />
              {searchDict.selected && (
                <Typography
                  component="span"
                  variant="button"
                  color="custom.darkGrey"
                  noWrap={true}
                  width="90px"
                >
                  Selected: {searchDict.selected.split(",").length}
                </Typography>
              )}
            </>
          )}
          <CustomButton
            disabled={!isAnalyseAllowed}
            sx={{ textWrap: "nowrap" }}
            variant="contained"
            type="button"
            customColor="#F16300"
            onClick={analyseHandler}
          >
            {searchDict.selected ? "Analyze" : "Analyze all"}
          </CustomButton>
        </Stack>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap="16px"
        alignItems="flex-start"
      >
        <Stack direction="row" flexWrap="wrap" gap="8px">
          {prepareFilters(filters.data).map(({ placeholder, query }) => (
            <SelectedFilterList
              key={query}
              filters={
                searchDict[query as keyof SearchParams]?.split(",") || []
              }
              label={placeholder}
              removeHandler={removeFilterHandler}
            />
          ))}
        </Stack>
      </Stack>
      {isFetching && !data ? (
        <Box
          display="flex"
          height="25vh"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={80} sx={{ color: "#FE8836" }} />
        </Box>
      ) : (
        <AccountsListTable
          rows={data?.items || []}
          totalRows={data?.count}
          refetch={() => {
            refetch();
            dispatch(setUpdateAnalytics(true));
          }}
        />
      )}
    </>
  );
};
