import { memo, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import {
  addDays,
  endOfMonth,
  isSameDay,
  isToday,
  setDefaultOptions,
  startOfMonth,
  subMonths,
  subYears,
} from "date-fns";
import { Box, Stack, Typography } from "@mui/material";
import { CustomButton } from "../CustomButton";
import { DoubleChevronLeftIcon, ChevronLeftIcon } from "../../images";
import { useStateWithSearchParams } from "../../../hooks/useStateWithSearchParams";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const rangeOptions = [
  "All time",
  "Current month",
  "Last month",
  "Last 3 months",
  "Last year",
];

const getDateRange = (option: string): [Date | null, Date | null] => {
  const today = new Date();

  switch (option) {
    case "Current month":
      return [startOfMonth(subMonths(today, 0)), today];
    case "Last month":
      return [
        startOfMonth(subMonths(today, 1)),
        endOfMonth(subMonths(today, 1)),
      ];
    case "Last 3 months":
      return [startOfMonth(subMonths(today, 3)), today];
    case "Last year":
      return [startOfMonth(subYears(today, 1)), today];
    case "All time":
      return [null, null];
    default:
      return [null, null];
  }
};

const RangeBtnBlock = ({
  currentOption,
  setCurrentOption,
  setDateRange,
}: {
  setDateRange: (date: [Date | null, Date | null]) => void;
  setCurrentOption: (option: string) => void;
  currentOption: string;
  clearDate: () => void;
}) => {
  const changeOptionHandler = (option: string) => {
    setCurrentOption(option);
    setDateRange(getDateRange(option));
  };

  return (
    <Stack
      sx={{
        gap: "8px",
        position: "absolute",
        height: "402px",
        width: "152px",
        top: "-24px",
        left: "-178px",
        padding: "12px",
        borderRight: "1px solid #d9d9d9",
      }}
    >
      {rangeOptions.map((el) => (
        <CustomButton
          onClick={() => changeOptionHandler(el)}
          key={el}
          variant="text"
          type="button"
          sx={{
            gap: 0,
            textWrap: "nowrap",
            color: `${currentOption === el ? "#1C3C6C" : "#4C545B"}`,
            cursor: `${currentOption === el ? "auto" : "pointer"}`,
            backgroundColor: `${
              currentOption === el ? "#F1F1F1" : "transparent"
            }`,
            "&:hover": {
              color: "#1C3C6C",
              backgroundColor: "#F1F1F1",
            },
          }}
          fullWidth
        >
          {el}
        </CustomButton>
      ))}
    </Stack>
  );
};

setDefaultOptions({ weekStartsOn: 1 });

const weekdays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

type SearchParams = {
  date?: string;
};

const formatDateToYYYYMMDD = (date: Date, separ: string): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return [year, month, day].join(separ);
};

const parseDateFromYYYYMMDD = (dateString: string): Date => {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day);
};

const updateCurrentOption = (
  startDate: Date | null,
  endDate: Date | null,
  setCurrentOption: (option: string) => void,
) => {
  const today = new Date();

  if (!startDate || !endDate) {
    setCurrentOption("All time");
    return;
  }

  const currentMonthStart = startOfMonth(subMonths(today, 0));
  const lastMonthStart = startOfMonth(subMonths(today, 1));
  const lastMonthEnd = endOfMonth(subMonths(today, 1));
  const lastThreeMonthsStart = startOfMonth(subMonths(today, 3));
  const lastYearStart = startOfMonth(subYears(today, 1));

  if (isSameDay(startDate, currentMonthStart) && isToday(endDate)) {
    setCurrentOption("Current month");
  } else if (
    isSameDay(startDate, lastMonthStart) &&
    isSameDay(endDate, lastMonthEnd)
  ) {
    setCurrentOption("Last month");
  } else if (isSameDay(startDate, lastThreeMonthsStart) && isToday(endDate)) {
    setCurrentOption("Last 3 months");
  } else if (isSameDay(startDate, lastYearStart) && isToday(endDate)) {
    setCurrentOption("Last year");
  } else {
    setCurrentOption("");
  }
};

export const CalendarBody = memo(({ close }: { close: () => void }) => {
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [currentOption, setCurrentOption] = useState<string>("All time");
  const [startDate, endDate] = dateRange;
  const datePickerRef = useRef<DatePicker>(null);

  const { searchDict, setSearchValue } = useStateWithSearchParams<SearchParams>(
    {
      date: undefined,
    },
  );

  useEffect(() => {
    if (startDate && endDate) {
      updateCurrentOption(startDate, endDate, setCurrentOption);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (searchDict.date) {
      const [start, end] = searchDict.date.split(",");

      setDateRange([parseDateFromYYYYMMDD(start), parseDateFromYYYYMMDD(end)]);
    }
  }, [searchDict.date]);

  const clearDate = () => {
    datePickerRef?.current?.clear();
  };

  useEffect(() => {
    const el = document.getElementsByClassName("react-datepicker__day-name");
    for (let i = 0; i < el.length; i++) {
      // @ts-ignore
      el[i].innerText = weekdays[i];
    }
  }, []);

  const successHandler = (startDate: Date | null, endDate: Date | null) => {
    close();
    if (currentOption === "All time") return;

    const start = formatDateToYYYYMMDD(startDate!, "-");
    const end = formatDateToYYYYMMDD(endDate!, "-");

    setSearchValue("date", `${start},${end}`);
  };

  const cancelHandler = () => {
    setSearchValue("date", undefined);
    close();
  };

  const dateChangeHandler = (update: [Date | null, Date | null]) => {
    setDateRange(update);
  };

  return (
    <Box sx={{ position: "absolute", left: 0, top: 0 }}>
      <Stack
        sx={{
          overflowY: "auto",
          scrollbarWidth: "none",
          position: "relative",
          justifyContent: "flex-end",
          borderRadius: "6px",
          boxShadow: "0 32px 32px -24px #0000001a",
          width: "484px",
          height: "477px",
        }}
      >
        <Stack direction="row" justifyContent="center">
          <DatePicker
            inline
            ref={datePickerRef}
            selectsRange={true}
            open={true}
            startDate={startDate}
            endDate={endDate}
            onChange={dateChangeHandler}
            maxDate={addDays(new Date(), 0)}
            showPopperArrow={false}
            renderDayContents={(number) => <span>{number}</span>}
            renderCustomHeader={({
              monthDate,
              decreaseMonth,
              increaseMonth,
              decreaseYear,
              increaseYear,
              nextMonthButtonDisabled,
              nextYearButtonDisabled,
            }) => {
              return (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  paddingBottom="8px"
                >
                  <Stack direction="row" alignItems="center">
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          opacity: 0.7,
                        },
                      }}
                      onClick={decreaseYear}
                    >
                      <DoubleChevronLeftIcon />
                    </Stack>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          opacity: 0.7,
                        },
                      }}
                      onClick={decreaseMonth}
                    >
                      <ChevronLeftIcon />
                    </Stack>
                  </Stack>

                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "#1F2932",
                      fontWeight: 500,
                      userSelect: "none",
                    }}
                  >
                    {`${monthDate.getFullYear()} ${
                      months[monthDate.getMonth()]
                    }`}
                  </Typography>

                  <Stack direction="row" alignItems="center">
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        cursor: nextMonthButtonDisabled ? "auto" : "pointer",
                        opacity: nextMonthButtonDisabled ? 0.3 : 1,
                        "&:hover": {
                          opacity: nextMonthButtonDisabled ? 0.3 : 0.7,
                        },
                      }}
                      onClick={
                        nextMonthButtonDisabled ? undefined : increaseMonth
                      }
                    >
                      <ChevronLeftIcon rotate="180" />
                    </Stack>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      sx={{
                        cursor: nextYearButtonDisabled ? "auto" : "pointer",
                        opacity: nextYearButtonDisabled ? 0.3 : 1,
                        "&:hover": {
                          opacity: nextYearButtonDisabled ? 0.3 : 0.7,
                        },
                      }}
                      onClick={
                        nextYearButtonDisabled ? undefined : increaseYear
                      }
                    >
                      <DoubleChevronLeftIcon rotate="180" />
                    </Stack>
                  </Stack>
                  <RangeBtnBlock
                    clearDate={clearDate}
                    setDateRange={setDateRange}
                    setCurrentOption={setCurrentOption}
                    currentOption={currentOption}
                  />
                </Stack>
              );
            }}
          />
        </Stack>
        <Stack
          direction="row"
          gap="8px"
          padding="16px"
          borderTop="1px solid #d9d9d9"
        >
          <CustomButton
            onClick={cancelHandler}
            variant="contained"
            textColor="#4C545B"
            fullWidth
            type="button"
            customColor="#F1F1F1"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={() => successHandler(startDate, endDate)}
            variant="contained"
            fullWidth
            type="button"
            customColor="#1C3C6C"
            disabled={(!startDate && !!endDate) || (!endDate && !!startDate)}
          >
            Apply
          </CustomButton>
        </Stack>
      </Stack>
    </Box>
  );
});
