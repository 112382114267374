import { RefObject, useEffect } from "react";

type UseOutsideClick = {
  elementRef: RefObject<HTMLDivElement | null>;
  wrapperRef?: RefObject<HTMLDivElement | null>;
  onOutsideClick(e: MouseEvent | TouchEvent): void;
  isOpened: boolean;
};
export const useOutsideClick = (args: UseOutsideClick) => {
  const { elementRef, wrapperRef, isOpened, onOutsideClick } = args;

  useEffect(() => {
    if (!elementRef?.current) return;
    if (!isOpened) return;
    const handleClick = (e: MouseEvent | TouchEvent) => {
      if (!(e.target instanceof Node)) return;
      if (!elementRef.current || !wrapperRef?.current) return;
      if (
        !elementRef.current.contains(e.target) &&
        !wrapperRef.current.contains(e.target)
      ) {
        onOutsideClick(e);
      }
    };
    document.addEventListener("mouseup", handleClick);
    document.addEventListener("touchend", handleClick);
    return () => {
      document.removeEventListener("mouseup", handleClick);
      document.removeEventListener("touchend", handleClick);
    };
  }, [onOutsideClick, elementRef, wrapperRef]);
};
