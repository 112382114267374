import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const StarsIcon = ({
  width = "34",
  height = "34",
  color = "#1C3C6C",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2327_18510)">
          <path
            d="M24.2083 5.95703L23.0007 8.59245L20.3749 9.79036L23.0007 10.9979L24.2083 13.6237L25.4062 10.9979L28.0416 9.79036L25.4062 8.59245M14.6249 8.83203L12.2291 14.1029L6.95825 16.4987L12.2291 18.8945L14.6249 24.1654L17.0208 18.8945L22.2916 16.4987L17.0208 14.1029M24.2083 19.3737L23.0007 21.9995L20.3749 23.207L23.0007 24.4049L24.2083 27.0404L25.4062 24.4049L28.0416 23.207L25.4062 21.9995"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_2327_18510">
            <rect
              width="23"
              height="23"
              fill="white"
              transform="translate(6 5)"
            />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
};
