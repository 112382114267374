import { Divider, Stack, Typography } from "@mui/material";
import { UpdateDataBtn } from "../ui";
import { ExportAccountsDataBtn } from "../ui/export-data";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { setIntegrationData } from "../../features/companySlice";
import { useParams } from "react-router-dom";

type Props = {
  company_name: string;
  product_name: string;
  tabs?: string;
};

export const ProductTitleBlock = ({
  company_name,
  product_name,
  tabs,
}: Props) => {
  const dispatch = useAppDispatch();
  const { campaign_id } = useParams();
  const integrationData = useAppSelector(
    (state) => state.company.integrationData,
  );

  const successHandler = () => {
    dispatch(setIntegrationData(null));
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack direction="row" gap="24px">
        <Stack gap="8px">
          <Typography variant="body1" color="#1F293299">
            Product
          </Typography>
          <Typography
            variant="h4"
            color="custom.textDarkBlue"
            sx={{ fontWeight: 500 }}
          >
            {product_name}
          </Typography>
        </Stack>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ alignSelf: "stretch" }}
        />
        <Stack gap="8px">
          <Typography variant="body1" color="#1F293299">
            Company name
          </Typography>
          <Typography
            variant="h4"
            color="custom.textDarkBlue"
            sx={{ fontWeight: 500 }}
          >
            {company_name}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap="8px">
        <UpdateDataBtn
          onSuccess={successHandler}
          shouldBeOpen={
            integrationData?.campaignId === campaign_id &&
            integrationData?.actionType === "update"
          }
          importFlow={
            integrationData?.campaignId === campaign_id &&
            integrationData?.actionType === "update"
              ? integrationData?.campaignImportFlow
              : undefined
          }
        />
        <ExportAccountsDataBtn tabs={tabs} />
      </Stack>
    </Stack>
  );
};
