import { Box, Tab, Tabs } from "@mui/material";
import React from "react";

type Props = {
  currentTab: number;
  onChange: (event: React.SyntheticEvent, tab: number) => void;
  tabList: string[];
  isTabsCenter?: boolean;
};

export const CustomTabs = ({
  currentTab,
  onChange,
  tabList,
  isTabsCenter,
}: Props) => {
  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={onChange}
        sx={{
          borderBottom: "1px solid",
          borderColor: "#D9D9D9",
        }}
      >
        {tabList.map((label) => (
          <Tab
            key={label}
            label={label}
            sx={{
              width: isTabsCenter
                ? `${Math.floor(100 / tabList.length)}%`
                : "fit-content",
              textWrap: "nowrap",
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "24px",
              "&.Mui-selected": { color: "#1C3C6C" },
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};
