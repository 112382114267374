import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { NAME_REGEX, USER_NAME_REGEX } from "utils/regex";
import { ReuseInput, ReuseInputWithEdit } from "../ui";
import { Dropdown } from "../ui/dropdown/Dropdown";
import { industryOptions } from "../../utils/industryOptions";
import { CustomButton } from "../ui/CustomButton";
import { equals } from "../../utils/equals";
import { useChangeUserInfoMutation, useLazyGetUserQuery } from "../../api";
import { toast } from "react-toastify";
import { CustomToast } from "../ui/CustomToast";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { UserInfo } from "../../types/apiTypes";

type Errors = { [key: string]: boolean };

const validation: { [key: string]: (value: string) => boolean } = {
  first_name: (value: string) => !value.trim() || !USER_NAME_REGEX.test(value),
  last_name: (value: string) => !value.trim() || !USER_NAME_REGEX.test(value),
  company_name: (value: string) => !value.trim() || !NAME_REGEX.test(value),
  industry: (value: string) => !value,
};

type Props = {
  first_name: string;
  last_name: string;
  company_name: string;
  industry: string;
  email: string;
};

export const ProfileData = ({
  first_name,
  last_name,
  company_name,
  industry,
  email,
}: Props) => {
  const [isInitMode, setIsInitMode] = useState<boolean>(true);
  const [formData, setFormData] = useState<UserInfo>({
    first_name,
    last_name,
    company_name,
    industry,
  });

  const [error, setError] = useState<Errors>({
    first_name: false,
    last_name: false,
    company_name: false,
    industry: false,
  });

  const [errorMessage, setErrorMessage] = useState<UserInfo>({
    first_name: "",
    last_name: "",
    company_name: "",
    industry: "",
  });

  const [changeUserInfo, { isLoading }] = useChangeUserInfoMutation();

  const [fetchUser] = useLazyGetUserQuery();

  useEffect(() => {
    setFormData({ first_name, last_name, company_name, industry });
  }, [first_name, last_name, company_name, industry]);

  const setErrorHandler = (field: string, value: boolean) => {
    setError((prev) => ({ ...prev, [field]: value }));
  };

  const inputChangeHandler = (field: string, value: string) => {
    setIsInitMode(false);
    setErrorMessage((prev) => ({ ...prev, [field]: "" }));
    setError((prev) => ({ ...prev, [field]: validation[field](value) }));
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const changeDataHandler = () => {
    const userInfo = {
      first_name: formData.first_name.trim(),
      last_name: formData.last_name.trim(),
      company_name: formData.company_name.trim(),
      industry: formData.industry.trim(),
    };
    changeUserInfo(userInfo)
      .unwrap()
      .then(() => {
        toast.success(
          <CustomToast
            type={"success"}
            text={"Your data has been successfully changed!"}
            title={"Data changed successfully"}
          />,
        );
        setIsInitMode(true);
        setFormData(userInfo);
        fetchUser();
      })
      .catch((error) => {
        if (getErrorMessage(error).fieldErrors) {
          setErrorMessage((err) => ({
            ...err,
            ...getErrorMessage(error).fieldErrors,
          }));
        } else {
          toast.error(
            <CustomToast
              type={"error"}
              text={
                getErrorMessage(error).globalError ||
                "Something went wrong. Please try again"
              }
              title={"Data change failed"}
            />,
          );
        }
      });
  };

  const isDisabled =
    isLoading ||
    equals(
      {
        first_name,
        last_name,
        company_name,
        industry,
      },
      formData,
    ) ||
    Object.values(error).some((value) => value) ||
    Object.values(errorMessage).some((value) => value);

  return (
    <Stack gap="32px">
      <Stack gap="24px">
        <Typography
          variant="subtitle1"
          color="custom.textDarkBlue"
          fontWeight={500}
          paddingBottom="12px"
        >
          Personal data
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            alignItems: "flex-end",
            rowGap: "48px",
            columnGap: "24px",
          }}
        >
          <ReuseInputWithEdit
            initValue={first_name}
            name="first_name"
            handleInputChange={inputChangeHandler}
            errorMessage={
              errorMessage.first_name ||
              "Must contain only letters, numbers, basic punctuation and symbols"
            }
            value={formData.first_name || ""}
            label="First name"
            placeholder="First name"
            isError={error.first_name || !!errorMessage.first_name}
            setErrorHandler={setErrorHandler}
            isEditMode={isInitMode}
          />

          <ReuseInputWithEdit
            initValue={last_name}
            name="last_name"
            handleInputChange={inputChangeHandler}
            errorMessage={
              errorMessage.last_name ||
              "Must contain only letters, numbers, basic punctuation and symbols"
            }
            value={formData.last_name || ""}
            label="Last name"
            placeholder="Last name"
            isError={error.last_name || !!errorMessage.last_name}
            setErrorHandler={setErrorHandler}
            isEditMode={isInitMode}
          />
          <Box paddingRight="84px">
            <ReuseInput
              name="email"
              handleInputChange={() => {}}
              errorMessage={""}
              value={email}
              label="Email"
              placeholder="Email"
              isError={false}
              setErrorHandler={() => {}}
              isDisabled={true}
            />
          </Box>
        </Box>

        <Stack gap="12px">
          <Typography
            variant="subtitle1"
            color="custom.textDarkBlue"
            fontWeight={500}
          >
            Company information
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              alignItems: "flex-end",
              gap: "24px",
            }}
          >
            <ReuseInputWithEdit
              initValue={company_name}
              name="company_name"
              handleInputChange={inputChangeHandler}
              errorMessage={
                errorMessage.company_name ||
                "Must contain only letters, numbers, basic punctuation and symbols"
              }
              value={formData.company_name || ""}
              label="Company name"
              placeholder="Company name"
              isError={error.company_name || !!errorMessage.company_name}
              setErrorHandler={setErrorHandler}
              isEditMode={isInitMode}
            />
            <Dropdown
              value={formData.industry || ""}
              onChange={(value) => inputChangeHandler("industry", value)}
              label="Industry"
              placeholder="Choose industry"
              items={industryOptions?.map(({ label }) => label) || []}
            />
          </Box>
        </Stack>
      </Stack>

      <CustomButton
        variant="contained"
        customColor="#1C3C6C"
        type="button"
        customSize="fit"
        sx={{ height: "42px", borderColor: "#1D3E70" }}
        disabled={isDisabled}
        onClick={changeDataHandler}
        startIcon={isLoading && <CircularProgress size={20} color="inherit" />}
      >
        Save changes
      </CustomButton>
    </Stack>
  );
};
