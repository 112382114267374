import { CircularProgress, Stack, Typography } from "@mui/material";
import { CustomButton } from "../../ui/CustomButton";
import { BaseModal } from "../../ui/BaseModal";
import { WarnBlock } from "./WarnBlock";
import { CustomTabs } from "../../ui/CustomTabs";
import React, { useEffect, useState } from "react";
import { DescriptionBlock } from "./DescriptionBlock";
import { ImportedDataBlock } from "./ImportedDataBlock";
import { usePatchCampaignMutation } from "../../../api/companyApi";
import { toast } from "react-toastify";
import { CustomToast } from "../../ui/CustomToast";
import { ReuseModal } from "../../ui";
import { NAME_REGEX, NO_SCRIPT_REGEX } from "../../../utils/regex";
import { getErrorMessage } from "../../../utils/getErrorMessage";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onSubmit: () => void;
  campaign_id: string;
  initProductUrl: string;
  initPdfFileName: string;
  initData: Fields;
};

const tabList = ["Description", "Imported data"];

export type Fields = {
  company_name: string;
  product_abstract: string;
  target_audience: string;
  product_name: string;
};

export type Errors = { [key: string]: boolean };

const isDataChanged = (
  initData: Fields,
  formData: Fields,
  excludeKey?: string,
): boolean => {
  return Object.keys(initData)
    .filter((key) => key !== excludeKey)
    .some(
      (key) => initData[key as keyof Fields] !== formData[key as keyof Fields],
    );
};

const initialValues: Fields = {
  company_name: "",
  product_abstract: "",
  target_audience: "",
  product_name: "",
};

const validation: { [key: string]: (value: string) => boolean } = {
  company_name: (value: string) => !NAME_REGEX.test(value),
  product_abstract: (value: string) => !value || !NO_SCRIPT_REGEX.test(value),
  target_audience: (value: string) => !value || !NO_SCRIPT_REGEX.test(value),
  product_name: (value: string) => !NAME_REGEX.test(value),
};

export const ProductDetailsModal = ({
  isOpen,
  onClose,
  onSubmit,
  campaign_id,
  initData,
  initProductUrl,
  initPdfFileName,
}: Props) => {
  const [productUrl, setProductUrl] = useState<string>(initProductUrl);
  const [isDiscardModal, setIsDiscardModal] = useState<boolean>(false);
  const [isSubmitModal, setIsSubmitModal] = useState<boolean>(false);

  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [submitPdfFile, setSubmitPdfFile] = useState<File | null>(null);
  const [submitProductUrl, setSubmitProductUrl] =
    useState<string>(initProductUrl);
  const [formData, setFormData] = useState<Fields>(initialValues);

  const [errorMessage, setErrorMessage] = useState<Fields>(initialValues);

  const [error, setError] = useState<Errors>({
    company_name: false,
    product_abstract: false,
    target_audience: false,
    product_name: false,
    url: false,
  });

  const [currentTab, setCurrentTab] = useState<number>(0);

  const [
    patchCampaign,
    { isSuccess: patchCampaignSuccess, error: patchCampaignError, isLoading },
  ] = usePatchCampaignMutation();

  useEffect(() => {
    if (patchCampaignError) {
      if (getErrorMessage(patchCampaignError).fieldErrors) {
        setErrorMessage((err) => ({
          ...err,
          ...getErrorMessage(patchCampaignError).fieldErrors,
        }));
      } else {
        toast.error(
          <CustomToast
            type={"error"}
            text={
              getErrorMessage(patchCampaignError).globalError ||
              "Something went wrong during processing. Please try again"
            }
            title={"Changes are not saved"}
          />,
        );
      }
    }
  }, [patchCampaignError]);

  useEffect(() => {
    if (patchCampaignSuccess) {
      toast.success(
        <CustomToast
          type={"success"}
          text="This data will be the basis for further analysis of the companies"
          title={"Changes are saved"}
        />,
      );
      onSubmit();
    }
  }, [patchCampaignSuccess]);

  useEffect(() => {
    setFormData(initData);
  }, [initData]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const inputChangeHandler = (field: string, value: string) => {
    setErrorMessage((prev) => ({ ...prev, [field]: "" }));
    setFormData((prev) => ({ ...prev, [field]: value }));
    setError((prev) => ({
      ...prev,
      [field]: validation[field](value),
    }));
  };

  const setErrorHandler = (field: string, value: boolean) => {
    setError((prev) => ({ ...prev, [field]: value }));
  };

  const setResultHandler = (result: Fields) => {
    setFormData(result);
    setSubmitPdfFile(pdfFile);
    setSubmitProductUrl(productUrl);
    setPdfFile(null);
    setCurrentTab(0);
  };

  const submitHandler = () => {
    patchCampaign({
      campaign_id,
      ...formData,
      source_url: submitProductUrl || undefined,
      source_pdf_name: submitPdfFile?.name || initPdfFileName,
    });
  };

  const checkIfBtnIsDisable = () => {
    const data = isDataChanged(initData, formData);
    return !data || Object.values(error).includes(true);
  };

  const submitIsNotAllowed = checkIfBtnIsDisable();

  const closeHandler = () => {
    if (submitIsNotAllowed) {
      onClose();
    } else {
      setIsDiscardModal(true);
    }
  };

  return (
    <BaseModal
      open={isOpen}
      onClose={closeHandler}
      title="Product details"
      sx={{ padding: "32px", maxWidth: "540px", width: "94%" }}
      btnBlock={
        <Stack direction="row" gap="8px" paddingTop="8px">
          <CustomButton
            onClick={closeHandler}
            variant="contained"
            textColor="#4C545B"
            fullWidth
            type="button"
            customColor="#F1F1F1"
          >
            Cancel
          </CustomButton>
          <CustomButton
            onClick={submitHandler}
            variant="contained"
            fullWidth
            type="button"
            customColor="#1C3C6C"
            disabled={submitIsNotAllowed || isLoading}
            startIcon={
              isLoading && (
                <CircularProgress size={20} sx={{ color: "#757575" }} />
              )
            }
          >
            Save changes
          </CustomButton>
        </Stack>
      }
    >
      <Stack gap="16px">
        <Typography variant="subtitle1" color="text.secondary">
          Edit information about the product
        </Typography>
        <WarnBlock />
        <CustomTabs
          isTabsCenter={true}
          currentTab={currentTab}
          onChange={handleChange}
          tabList={tabList}
        />
        {currentTab ? (
          <ImportedDataBlock
            setResultHandler={setResultHandler}
            campaign_id={campaign_id}
            setPdfFile={setPdfFile}
            pdfFile={pdfFile}
            productUrl={productUrl}
            setProductUrl={setProductUrl}
            setErrorHandler={setErrorHandler}
            initProductUrl={submitProductUrl}
          />
        ) : (
          <DescriptionBlock
            formData={formData}
            inputChangeHandler={inputChangeHandler}
            error={error}
            setErrorHandler={setErrorHandler}
            errorMessage={errorMessage}
          />
        )}
      </Stack>

      <ReuseModal
        isOpen={isDiscardModal}
        onClose={() => setIsDiscardModal(false)}
        onSubmit={() => {
          setIsDiscardModal(false);
          onClose();
        }}
        title="Discard changes"
        text="Are you sure you want to discard all changes?"
        maxWidth="430px"
      />

      <ReuseModal
        icon="warn"
        isOpen={isSubmitModal}
        onClose={() => setIsSubmitModal(false)}
        onSubmit={() => {
          setIsSubmitModal(false);
          submitHandler();
        }}
        title="You are about to restart the analysis"
        text="All previously analysed data will be lost. Would you like to proceed?"
        maxWidth="430px"
        submitBtnText="Continue"
      />
    </BaseModal>
  );
};
