import { CloudUpload } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { CustomButton } from "../ui/CustomButton";
import { useEffect, useState } from "react";
import { useFindExternalDataMutation } from "../../api/companyApi";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { toast } from "react-toastify";
import { CustomToast } from "../ui/CustomToast";
import { sseManager } from "../../utils/useSSEEvent";
import { BASE_API_URL } from "../../api";

interface SSEData {
  status:
    | "in_progress"
    | "completed"
    | "failed"
    | "queued"
    | "scraping_queued"
    | "scraping_in_progress";
  progress: number;
}

type Props = {
  account_id: string;
  refetch: () => void;
  status: string;
};

const ExternalFlow = ({ account_id, refetch }: Props) => {
  const dispatch = useAppDispatch();

  const token = useAppSelector((state) => state.auth.accessToken) as string;
  const refreshToken = useAppSelector(
    (state) => state.auth.refreshToken,
  ) as string;

  useEffect(() => {
    const handleMessage = (sseData: SSEData[]) => {
      const { status } = sseData[0];

      if (status === "failed") {
        toast.error(
          <CustomToast
            type={"error"}
            text={`Something went wrong during analysis. Please try again`}
            title={"Analysis failed"}
          />,
        );
        refetch();
      } else if ("completed" === status) {
        toast.success(
          <CustomToast
            type={"success"}
            text={"Check new analysis enriched by external data"}
            title={"External data uploaded"}
          />,
        );
        refetch();
      }
    };

    if (!sseManager.isConnected) {
      sseManager.connect({
        url: `${BASE_API_URL}/accounts/${account_id}/sse-status`,
        token,
        refreshToken,
        onMessage: handleMessage,
        onError: () => {},
        dispatch: dispatch,
      });
    }

    return () => {
      sseManager.close();
    };
  }, [token]);

  return null;
};

export const GetExternalDataBtn = ({ account_id, refetch, status }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [
    findExternalData,
    { isSuccess, isLoading: findExternalDataIsLoading },
  ] = useFindExternalDataMutation();

  useEffect(() => {
    isSuccess && refetch();
  }, [isSuccess]);

  useEffect(() => {
    findExternalDataIsLoading && setIsLoading(findExternalDataIsLoading);
  }, [findExternalDataIsLoading]);

  useEffect(() => {
    setIsLoading(!["completed", "failed", "created"].includes(status));
  }, [status]);

  const startScrappingHandler = () => {
    if (isLoading) return;
    findExternalData(account_id);
  };

  return (
    <>
      <CustomButton
        onClick={startScrappingHandler}
        customSize="l"
        type="submit"
        customColor="#1C3C6C"
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <CloudUpload fontSize="small" />
        )}
        Upload external data
      </CustomButton>

      {["scraping_queued", "scraping_in_progress"].includes(status) && (
        <ExternalFlow
          status={status}
          refetch={refetch}
          account_id={account_id}
        />
      )}
    </>
  );
};
