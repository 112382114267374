import { CircularProgress, Stack } from "@mui/material";
import { CustomButton } from "../../CustomButton";
import { UploadFromSalesforce } from "../../upload-data/UploadFromSalesforce";
import { UploadFromHubSpot } from "../../upload-data/UploadFromHubSpot";
import { useAppSelector } from "../../../../hooks/useReduxHooks";
import { ReactNode } from "react";

type Props = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  backHandler: () => void;
  onSaveHandler: () => void;
  isSave: boolean;
  getCampaignId: () => Promise<string | void>;
  onClose: () => void;
  description?: ReactNode;
};

export const UploadCRM = ({
  isLoading,
  setIsLoading,
  backHandler,
  onSaveHandler,
  isSave,
  getCampaignId,
  onClose,
  description,
}: Props) => {
  const integrationData = useAppSelector(
    (state) => state.company.integrationData,
  );

  const successHandler = () => {
    onClose();
  };

  return (
    <Stack gap="24px">
      {description}
      <Stack gap="8px">
        <UploadFromHubSpot
          actionType="create"
          campaign_id={integrationData?.campaignId}
          getCampaignId={getCampaignId}
          startFlow={integrationData?.campaignImportFlow === "hubspot"}
          onSuccess={successHandler}
          disabled={isLoading}
          setIsLoading={setIsLoading}
        />
        <UploadFromSalesforce
          actionType="create"
          campaign_id={integrationData?.campaignId}
          getCampaignId={getCampaignId}
          startFlow={integrationData?.campaignImportFlow === "salesforce"}
          onSuccess={successHandler}
          disabled={isLoading}
          setIsLoading={setIsLoading}
        />
      </Stack>

      <Stack direction="row" gap="8px">
        <CustomButton
          onClick={backHandler}
          variant="contained"
          textColor="#4C545B"
          fullWidth
          type="button"
          customColor="#F1F1F1"
          disabled={isLoading}
        >
          Back
        </CustomButton>
        <CustomButton
          onClick={onSaveHandler}
          variant="contained"
          fullWidth
          type="button"
          customColor="#1C3C6C"
          disabled={isLoading}
          startIcon={
            isSave &&
            isLoading && (
              <CircularProgress size={20} sx={{ color: "#757575" }} />
            )
          }
        >
          Save
        </CustomButton>
      </Stack>
    </Stack>
  );
};
