import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const CompanyIcon = ({ width = "34", height = "34" }: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="34" height="34" rx="6" fill="#F7F7F7" />
        <rect width="34" height="34" rx="6" fill="#FE8836" fillOpacity="0.05" />
        <path
          d="M23.2503 19.375H21.3337V21.2917H23.2503M23.2503 15.5417H21.3337V17.4583H23.2503M25.167 23.2083H17.5003V21.2917H19.417V19.375H17.5003V17.4583H19.417V15.5417H17.5003V13.625H25.167M15.5837 11.7083H13.667V9.79167H15.5837M15.5837 15.5417H13.667V13.625H15.5837M15.5837 19.375H13.667V17.4583H15.5837M15.5837 23.2083H13.667V21.2917H15.5837M11.7503 11.7083H9.83366V9.79167H11.7503M11.7503 15.5417H9.83366V13.625H11.7503M11.7503 19.375H9.83366V17.4583H11.7503M11.7503 23.2083H9.83366V21.2917H11.7503M17.5003 11.7083V7.875H7.91699V25.125H27.0837V11.7083H17.5003Z"
          fill="#FE8836"
        />
      </svg>
    </IconWrapper>
  );
};
