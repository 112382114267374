import { useState } from "react";
import { Typography, Box, Stack } from "@mui/material";
import { useLoginMutation, useSignupMutation } from "api/authApi";
import { SignupFormData } from "types/authTypes";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/useReduxHooks";
import { setAuth, setRegistrationModalOpen } from "features/authSlice";
import { useHandleError } from "hooks/useHandleError";
import { SignupForm } from "components/forms/signupForm";
import { useHandleSuccess } from "hooks/useHandleSuccess";
import { clearCompany } from "features/companySlice";
import { AppRoutes } from "../types/enums";

export const SignupView = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [
    signup,
    { isLoading: loadingSignup, error: signupError, reset: resetSignup },
  ] = useSignupMutation();

  const [
    login,
    {
      isLoading: loadingLogin,
      isSuccess: loadingSuccess,
      error: loginError,
      reset: resetLogin,
    },
  ] = useLoginMutation();

  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [globalError, setGlobalErrors] = useState<string | null>(null);

  useHandleError(signupError, {
    setFieldErrors,
    setGlobalErrors,
    reset: resetSignup,
  });

  useHandleError(loginError, {
    setFieldErrors,
    setGlobalErrors,
    reset: resetLogin,
  });

  useHandleSuccess(loadingSuccess, () => {
    dispatch(setRegistrationModalOpen(true));
  });

  const handleSignup = async (data: SignupFormData) => {
    try {
      setFieldErrors({});
      setGlobalErrors(null);

      const signupResult = await signup(data).unwrap();
      dispatch(clearCompany());
      if (signupResult) {
        const loginResult = await login({
          email: data.email,
          password: data.password,
        }).unwrap();

        if (loginResult) {
          dispatch(
            setAuth({
              accessToken: loginResult.access_token,
              refreshToken: loginResult.refresh_token,
            }),
          );
          navigate(AppRoutes.PRODUCTS, { replace: true });
        }
      }
    } catch (error) {
      console.error("Authentication error:", error);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="calc(100vh - 74px)"
    >
      <Stack width="368px" p={4} borderRadius="8px" textAlign="center" gap={4}>
        <Stack gap={1}>
          <Typography variant="h1" component="h1" textAlign="left">
            Sign up
          </Typography>
          <Typography variant="subtitle1" color="#4C545B" textAlign="left">
            Create an account to start analysis
          </Typography>
        </Stack>

        <SignupForm
          onSubmit={handleSignup}
          loading={loadingSignup || loadingLogin}
          fieldErrors={fieldErrors}
          globalError={globalError}
          setFieldErrors={setFieldErrors}
          setGlobalErrors={setGlobalErrors}
        />
      </Stack>
    </Box>
  );
};
