import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";

type SearchParams = {
  [key: string]: string | undefined;
};

export const useStateWithSearchParams = <T extends SearchParams>(
  initialState: T
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchDict = useMemo(() => {
    const dict: T = { ...initialState };
    searchParams.forEach((value, key) => {
      // @ts-ignore
      dict[key] = value;
    });
    return dict;
  }, [searchParams, initialState]);

  const setSearchValue = (key: keyof T, value: string | undefined) => {
    const newParams = new URLSearchParams(searchParams);

    if (value === undefined || value === "") {
      newParams.delete(key as string);
    } else {
      newParams.set(key as string, value);
    }

    setSearchParams(newParams);
  };

  return { searchDict, setSearchValue };
};
