import { useUpdateAccountMutation } from "../../api";
import { toast } from "react-toastify";
import { CustomToast } from "./CustomToast";
import { UploadFlow } from "../../types/enums";
import { UploadFromHubSpot } from "./upload-data/UploadFromHubSpot";
import { UploadFromSalesforce } from "./upload-data/UploadFromSalesforce";
import { Box, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomButton } from "./CustomButton";
import { UpdateDataBtn } from "./UpdateDataBtn";

export const UpdateAccount = ({
  id,
  campaign_id,
  refetch,
  companyName,
  status,
}: {
  id: string;
  campaign_id: string;
  companyName: string;
  refetch: () => void;
  status: string;
}) => {
  const [currentFlow, setCurrentFlow] = useState<string>("");
  const [isFlowLoading, setIsFlowLoading] = useState<boolean>(false);

  useEffect(() => {
    if (currentFlow) {
      setCurrentFlow("");
    }
  }, [currentFlow]);

  const [updateAccount, { isLoading }] = useUpdateAccountMutation();

  const updateHandler = () => {
    updateAccount(id)
      .unwrap()
      .then(() => {
        toast.success(
          <CustomToast
            type={"success"}
            text={`The company ${companyName} was successfully updated.`}
            title={"Update success"}
          />,
        );
        refetch();
      })
      .catch((error) => {
        if (error?.status === 428) {
          setCurrentFlow(error.data.type);
        } else {
          toast.error(
            <CustomToast
              type={"error"}
              text={`Something went wrong while updating ${companyName}. Please try again`}
              title={"Update failed"}
            />,
          );
        }
      });
  };

  const updateSuccessHandler = () => {
    refetch();
  };

  return (
    <>
      <CustomButton
        onClick={updateHandler}
        customSize="fit"
        customColor="#1C3C6C"
        sx={{ height: "42px", minWidth: "160px" }}
        startIcon={
          (isLoading || isFlowLoading) && (
            <CircularProgress size={20} sx={{ color: "#FFFFFF" }} />
          )
        }
        disabled={
          isLoading ||
          isFlowLoading ||
          !["completed", "failed", "created"].includes(status)
        }
      >
        Update your data
      </CustomButton>
      <Box hidden>
        <UpdateDataBtn
          shouldBeOpen={currentFlow === UploadFlow.FILE}
          onSuccess={updateSuccessHandler}
        />
        <UploadFromHubSpot
          campaign_id={campaign_id!}
          onSuccess={refetch}
          startFlow={currentFlow === UploadFlow.HUBSPOT}
          setIsLoading={setIsFlowLoading}
        />
        <UploadFromSalesforce
          campaign_id={campaign_id!}
          onSuccess={refetch}
          startFlow={currentFlow === UploadFlow.SALESFORCE}
          setIsLoading={setIsFlowLoading}
        />
      </Box>
    </>
  );
};
