import { CircularProgress, Stack, Typography } from "@mui/material";
import { AnalyticCard } from "./AnalyticCard";
import { Analytics } from "../../../types/apiTypes";
import { EngagementScoreSettingModal } from "../../ui/modal";
import { useState } from "react";
import { SettingsIcon } from "../../images";
import { useGetEngagementScoreConfigQuery } from "../../../api/companyApi";

export const AverageRateBlock = ({
  analytics,
  campaign_id,
  refetch,
}: {
  analytics: Analytics;
  campaign_id: string;
  refetch: () => void;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    data,
    isLoading,
    refetch: refetchSettings,
  } = useGetEngagementScoreConfigQuery({
    campaign_id,
  });

  const openSettingsHandler = () => {
    if (isLoading) return;
    setIsOpen(true);
  };

  const successHandler = () => {
    refetch();
    refetchSettings();
  };

  return (
    <AnalyticCard
      tooltip="Average engagement rate of analyzed accounts based on outgoing and received calls & emails analysis,
       communication recency"
      header={
        <Typography variant="button" color="#1F293299" lineHeight="20px">
          Average engagement rate
        </Typography>
      }
      body={
        <Stack
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          position="relative"
        >
          <button
            onClick={openSettingsHandler}
            style={{
              position: "absolute",
              top: -10,
              right: -2,
              padding: "4px",
              border: isOpen ? "1px solid #1C3C6C" : "none",
              borderRadius: "8px",
              cursor: "pointer",
              background: "none",
            }}
          >
            {isLoading ? (
              <CircularProgress size={20} sx={{ color: "#1C3C6C" }} />
            ) : (
              <SettingsIcon />
            )}
          </button>
          <Stack
            sx={{ position: "relative", width: "112px", height: "112px" }}
            justifyContent="center"
          >
            <CircularProgress
              size={112}
              thickness={6}
              variant="determinate"
              sx={{
                color: "#3279B3",
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 2,
                opacity: 1,
              }}
              value={analytics.average_engagement_score}
            />
            <CircularProgress
              size={112}
              thickness={6}
              variant="determinate"
              sx={{
                color: "#F7F7F7",
                position: "absolute",
                left: 0,
                top: 0,
                zIndex: 1,
              }}
              value={100}
            />
            <Typography
              variant="h3"
              align="center"
              sx={{
                flexShrink: 1,
                fontSize: "26px",
                color: "#181818",
              }}
            >
              {analytics.average_engagement_score}%
            </Typography>
          </Stack>
          {isOpen && data && (
            <EngagementScoreSettingModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              campaign_id={campaign_id}
              settings={data}
              successHandler={successHandler}
            />
          )}
        </Stack>
      }
    />
  );
};
