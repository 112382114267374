import { Box, CircularProgress } from "@mui/material";

export const CustomLoader = () => {
  return (
    <Box
      display="flex"
      height="25vh"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress size={80} sx={{ color: "#FE8836" }} />
    </Box>
  );
};
