import { IconProps } from "../../types/genereal";

export const ToastSuccessIcon = ({
  width = "40",
  height = "40",
  color = "#27AE60",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.6497 12.633L16.6663 23.6163L10.683 17.6497L8.33301 19.9997L16.6663 28.333L29.9997 14.9997L27.6497 12.633ZM19.9997 3.33301C10.7997 3.33301 3.33301 10.7997 3.33301 19.9997C3.33301 29.1997 10.7997 36.6663 19.9997 36.6663C29.1997 36.6663 36.6663 29.1997 36.6663 19.9997C36.6663 10.7997 29.1997 3.33301 19.9997 3.33301ZM19.9997 33.333C12.633 33.333 6.66634 27.3663 6.66634 19.9997C6.66634 12.633 12.633 6.66634 19.9997 6.66634C27.3663 6.66634 33.333 12.633 33.333 19.9997C33.333 27.3663 27.3663 33.333 19.9997 33.333Z"
        fill={color}
      />
    </svg>
  );
};
