export function DataPersonImg() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 11.5791V16.6317C5.5 18.0271 8.51642 19.158 12.2368 19.158C12.3766 19.158 12.5156 19.1564 12.6537 19.153"
        stroke="#2194D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 6.52637V11.579C5.5 12.9744 8.51642 14.1053 12.2368 14.1053C13.1421 14.1053 14.0053 14.0379 14.7943 13.9167M18.9737 11.579V6.52637"
        stroke="#0860A8"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 6.52632C5.5 7.92168 8.51642 9.05263 12.2368 9.05263C15.9573 9.05263 18.9737 7.92168 18.9737 6.52632C18.9737 5.13095 15.9573 4 12.2368 4C8.51642 4 5.5 5.13095 5.5 6.52632Z"
        stroke="#1C3C6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 17.5263H20.5526M18.0263 15V20.0526"
        stroke="#1C3C6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
