import { Outlet } from "react-router-dom";
import { Box, Container } from "@mui/material";
import { Header } from "components/features/Header";

export const Layout = () => {
  return (
    <Box>
      <Header />
      <Container component="main" maxWidth="xl">
        <Outlet />
      </Container>
    </Box>
  );
};
