import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const SmallChevronLeftIcon = ({
  width = "24",
  height = "24",
  color = "#4C545B",
  rotate = "0",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height} rotate={rotate}>
      <svg
        width="8"
        height="16"
        viewBox="0 0 8 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.36534 11.6119L5.65801 12.3185L1.80534 8.46721C1.74324 8.4055 1.69395 8.33211 1.66032 8.25128C1.62669 8.17045 1.60937 8.08376 1.60937 7.99621C1.60937 7.90866 1.62669 7.82197 1.66032 7.74114C1.69395 7.6603 1.74324 7.58692 1.80534 7.52521L5.65801 3.67188L6.36468 4.37854L2.74868 7.99521L6.36534 11.6119Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  );
};
