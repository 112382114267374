import { Stack, Typography } from "@mui/material";
import { IIcon } from "../../images/IIcon";

export const WarnBlock = () => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="6px"
      border="1px solid #FFA1A1"
      bgcolor="#FFE5E5"
      padding="16px"
    >
      <Stack direction="row" gap="16px" alignItems="flex-start">
        <IIcon />
        <Stack gap="12px">
          <Typography variant="body2" color="custom.textDarkBlue">
            <strong>Warning:</strong>
          </Typography>
          <Typography variant="body2" color="custom.textDarkBlue">
            Keep in mind that editing information about product will{" "}
            <strong>delete old data</strong> and{" "}
            <strong>restart analysis</strong> for all accounts analyzed before,
            except for manually selected buying committee members.
          </Typography>
          <Typography variant="body2" color="custom.textDarkBlue">
            If you want to add notes without changes, you can use the{" "}
            <strong>Notes</strong> field.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
