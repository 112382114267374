import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";

type Props = {
  value: string;
  errorMessage: ReactNode;
  label: string;
  name: string;
  placeholder: string;
  maxLength?: number;
  handleInputChange: (field: string, value: string) => void;
  multiline?: boolean;
  isError: boolean;
  setErrorHandler: (field: string, value: boolean) => void;
  isPassword?: boolean;
  isDisabled?: boolean;
};

export const ReuseInput = ({
  value,
  label,
  name,
  placeholder,
  maxLength,
  errorMessage,
  handleInputChange,
  multiline,
  isError,
  setErrorHandler,
  isPassword,
  isDisabled,
}: Props) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  useEffect(() => {
    setErrorHandler(name, isError || (!!maxLength && value.length > maxLength));
  }, [value, isError]);

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange(name, e.target.value);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <TextField
        type={isPassword ? (showPassword ? "text" : "password") : "text"}
        fullWidth
        name={name}
        placeholder={placeholder}
        label={label}
        value={value}
        variant="outlined"
        error={isError}
        onChange={inputChangeHandler}
        multiline={multiline}
        disabled={isDisabled}
        sx={{
          textarea: {
            minHeight: "100px",
            resize: "vertical",
          },
          "& .MuiOutlinedInput-root": {
            "&:hover": {
              borderColor: isDisabled ? "#CFCFCF" : "auto",
            },
          },
        }}
        InputProps={{
          endAdornment: isPassword ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <VisibilityOffOutlined />
                ) : (
                  <VisibilityOutlined />
                )}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />

      <Box position="relative">
        <FormHelperText
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            fontSize: "12px",
            color: isError ? "#D32F2F" : "#00000099",
          }}
        >
          <span style={{ width: "100%" }}>{isError && errorMessage}</span>
          {!!maxLength && <span>{`${value.length}/${maxLength}`}</span>}
        </FormHelperText>
      </Box>
    </Box>
  );
};
