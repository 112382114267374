import { Divider, IconButton, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { LogoutIcon, PersonInCircleIcon } from "../images";
import { useOutsideClick } from "../../hooks/useOutSideClick";
import { CustomButton } from "../ui/CustomButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../types/enums";
import { useGetUserQuery } from "../../api";
import { ProfileAvatar } from "./ProfileAvatar";
import { useLogout } from "../../hooks/useLogout";

export const ProfileButton = () => {
  const { pathname } = useLocation();
  const logout = useLogout();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { data: user } = useGetUserQuery();

  const closeHandler = () => {
    setIsOpen(false);
  };

  useOutsideClick({
    elementRef: dropdownRef,
    onOutsideClick: closeHandler,
    isOpened: isOpen,
    wrapperRef: dropdownWrapperRef,
  });

  const logoutHandler = () => {
    logout();
  };

  const goToProfileHandler = () => {
    pathname !== AppRoutes.PROFILE && navigate(AppRoutes.PROFILE);
    setIsOpen(false);
  };

  return (
    <Stack
      ref={dropdownWrapperRef}
      position="relative"
      gap="16px"
      alignItems="flex-start"
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        style={{
          padding: 0,
          border: "none",
          cursor: "pointer",
          background: "none",
        }}
      >
        <ProfileAvatar
          avatar_url={user?.avatar_url}
          height="42px"
          width="42px"
        />
      </button>
      {isOpen && (
        <Stack
          ref={dropdownRef}
          sx={{
            backgroundColor: "white",
            position: "absolute",
            zIndex: 9999,
            right: 0,
            top: 80,
            boxShadow: "0px 4px 15px 0px #00000040",
            borderRadius: "8px",
            padding: "24px 16px 8px 16px",
            width: "320px",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <IconButton
            onClick={() => setIsOpen(false)}
            size="small"
            sx={{
              ml: "auto",
              color: "#828282",
              position: "absolute",
              top: "8px",
              right: "8px",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Stack gap="12px" alignItems="center">
            <ProfileAvatar avatar_url={user?.avatar_url} />
            <Stack gap="4px" alignItems="center">
              <Typography
                variant="h4"
                color="custom.textDarkBlue"
                fontWeight={500}
                sx={{ letterSpacing: "0.3px", textAlign: "center" }}
              >
                {`${user?.first_name} ${user?.last_name}`}
              </Typography>
              <Typography
                variant="caption"
                color="#4C545B"
                sx={{ textAlign: "center" }}
              >
                {`${user?.company_name}`}
              </Typography>
            </Stack>
            <CustomButton
              onClick={goToProfileHandler}
              variant="outlined"
              customColor="#FFFFFF"
              textColor="#1D3E70"
              customSize="fit"
              type="button"
              sx={{ height: "42px", borderColor: "#1D3E70" }}
              startIcon={<PersonInCircleIcon />}
            >
              My profile
            </CustomButton>
          </Stack>
          <Divider
            style={{ paddingTop: "8px", alignSelf: "stretch" }}
            flexItem
          />
          <CustomButton
            onClick={logoutHandler}
            variant="text"
            type="button"
            sx={{
              gap: 0,
              textWrap: "nowrap",
              color: "#4C545B",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "#F1F1F1",
              },
              "& .MuiButton-startIcon": {
                marginRight: "4px",
              },
            }}
            startIcon={<LogoutIcon />}
          >
            Log out
          </CustomButton>
        </Stack>
      )}
    </Stack>
  );
};
