import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const AlertInCircleIcon = ({
  width = "24",
  height = "24",
  color = "#D32F2F",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 7H11V13H13V7ZM12 15C11.7167 15 11.479 15.096 11.287 15.288C11.095 15.48 10.9993 15.7173 11 16C11.0007 16.2827 11.0967 16.52 11.288 16.712C11.4793 16.904 11.7167 17 12 17C12.2833 17 12.5207 16.904 12.712 16.712C12.9033 16.52 12.9993 16.2827 13 16C13.0007 15.7173 12.9047 15.4797 12.712 15.287C12.5193 15.0943 12.282 14.9987 12 15ZM12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.82433 20.6873 6.88267 21.212 8.1C21.7367 9.31733 21.9993 10.6173 22 12C22.0007 13.3827 21.738 14.6827 21.212 15.9C20.686 17.1173 19.9737 18.1757 19.075 19.075C18.1763 19.9743 17.118 20.6867 15.9 21.212C14.682 21.7373 13.382 22 12 22C10.618 22 9.318 21.7373 8.1 21.212C6.882 20.6867 5.82366 19.9743 4.925 19.075C4.02633 18.1757 3.31367 17.1173 2.787 15.9C2.26033 14.6827 1.998 13.3827 2 12C2.002 10.6173 2.26467 9.31733 2.788 8.1C3.31133 6.88267 4.02366 5.82433 4.925 4.925C5.82633 4.02567 6.88467 3.313 8.1 2.787C9.31533 2.261 10.6153 1.99867 12 2ZM12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  );
};
