import { fetchEventSource } from "@microsoft/fetch-event-source";
import { fetchNewAccessToken } from "api/baseQuery";
import { resetAuth, setAuthTokens } from "features/authSlice";
import { AppDispatch } from "app/store";

interface FetchEventSourceOptions {
  url: string;
  token: string;
  refreshToken: string | null;
  dispatch: AppDispatch;
  onMessage: (data: any) => void;
  onError?: (error: any) => void;
  onDone?: () => void;
}

export class SSEManager {
  private static instance: SSEManager | null = null;
  private abortController: AbortController | null = null;
  public isConnected = false;
  private currentUrl: string | null = null;

  static getInstance(): SSEManager {
    if (!this.instance) {
      this.instance = new SSEManager();
    }
    return this.instance;
  }

  connect(options: FetchEventSourceOptions) {
    const { url, token, refreshToken, onMessage, dispatch, onError, onDone } =
      options;
    if (this.isConnected && this.currentUrl === url) {
      console.warn("SSE connection already established.");
      return;
    }
    this.close();
    this.isConnected = true;
    this.currentUrl = url;

    this.abortController = new AbortController();

    const startConnection = async (accessToken: string) => {
      try {
        await fetchEventSource(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          signal: this.abortController!.signal,
          onopen: async (response) => {
            const handleTokenRefresh = async () => {
              const currentRefreshToken = refreshToken;
              if (!currentRefreshToken) {
                console.error("No refresh token available. Resetting auth.");
                dispatch(resetAuth());
                this.close();
                return;
              }
              const newTokens = await fetchNewAccessToken(currentRefreshToken);
              if (newTokens?.access_token) {
                const {
                  access_token: newAccessToken,
                  refresh_token: newRefreshToken,
                } = newTokens;
                dispatch(
                  setAuthTokens({
                    accessToken: newAccessToken,
                    refreshToken: newRefreshToken,
                  }),
                );
                console.log("Success Refrech Token");

                startConnection(newAccessToken);
              } else {
                console.error("Failed to refresh token. Resetting auth.");
                dispatch(resetAuth());
                this.close();
              }
            };
            if (response.ok) {
              this.isConnected = true;
            } else if (response.status === 401 && refreshToken) {
              handleTokenRefresh();
              console.log("401 SSE");
            }
          },
          onmessage: (event) => {
            try {
              const validJsonStr = event.data
                .replace(/UUID\('([^']+)'\)/g, '"$1"') // Convert UUID to string
                .replace(/'/g, '"'); // Replace single quotes with double quotes
              const data = JSON.parse(validJsonStr);
              onMessage(data);
            } catch (err) {
              console.log(err);
            }
          },
          onerror: (error) => {
            console.error("SSE connection error:", error);
            this.close();
            if (onError) onError(error);
          },
          onclose: () => {
            console.log("SSE connection closed.");
            this.isConnected = false;
            if (onDone) onDone();
          },
        });
      } catch (error) {
        console.error("Failed to connect SSE:", error);
        if (onError) onError(error);
        this.close();
      }
    };
    startConnection(token);
  }
  close() {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }
    this.isConnected = false;
    console.log("SSE connection terminated.");
  }
}

export const sseManager = SSEManager.getInstance();
