import { CircularProgress, Stack, Typography } from "@mui/material";
import { CustomButton } from "./CustomButton";
import { useAnalyseMutation, BASE_API_URL } from "../../api";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHooks";
import { sseManager } from "utils/useSSEEvent";
import { toast } from "react-toastify";
import { CustomToast } from "./CustomToast";
import { NoSearchIcon } from "../images";

interface SSEData {
  status:
    | "in_progress"
    | "completed"
    | "failed"
    | "queued"
    | "scraping_queued"
    | "scraping_in_progress";
  progress: number;
}

export const AnalyseCompanyDataBlock = ({
  account_id,
  successCallBack,
  status,
  progress,
  companyName,
}: {
  account_id: string;
  successCallBack?: () => void;
  status: string;
  progress: number;
  companyName: string;
}) => {
  const [currentProgress, setCurrentProgress] = useState<number>(progress);
  const [isAnalyse, setIsAnalyse] = useState<boolean>(false);

  const [analyse, { isSuccess, isLoading }] = useAnalyseMutation();
  const dispatch = useAppDispatch();

  const AnalyseHandler = () => {
    if (isLoading) return;
    analyse({ account_ids: [account_id] });
  };

  useEffect(() => {
    if (["in_progress", "queued"].includes(status)) {
      setIsAnalyse(true);
    }
  }, [status]);

  const token = useAppSelector((state) => state.auth.accessToken) as string;
  const refreshToken = useAppSelector(
    (state) => state.auth.refreshToken,
  ) as string;

  useEffect(() => {
    const handleMessage = (sseData: SSEData[]) => {
      const { status, progress } = sseData[0];

      setCurrentProgress(progress);

      if (status === "failed") {
        setIsAnalyse(false);
        toast.error(
          <CustomToast
            type={"error"}
            text={`Something went wrong during analysis of ${companyName}. Please try again`}
            title={"Analysis failed"}
          />,
        );
      } else if (status === "completed") {
        toast.success(
          <CustomToast
            type={"success"}
            text={`The company ${companyName} was successfully analyzed.`}
            title={"Analysis success"}
          />,
        );
        successCallBack && successCallBack();
        setIsAnalyse(false);
        setCurrentProgress(0);
      }
    };

    if (!sseManager.isConnected && isAnalyse) {
      sseManager.connect({
        url: `${BASE_API_URL}/accounts/${account_id}/sse-status`,
        token,
        refreshToken,
        onMessage: handleMessage,
        onError: () => {
          setIsAnalyse(false);
        },
        dispatch: dispatch,
      });
    }

    return () => {
      sseManager.close();
    };
  }, [token, isAnalyse]);

  useEffect(() => {
    if (isSuccess) {
      setIsAnalyse(true);
    }
  }, [isSuccess]);

  return (
    <Stack
      direction="row"
      gap="16px"
      borderRadius="6px"
      bgcolor="#ECEEF099"
      padding="16px"
      alignItems="flex-start"
    >
      <NoSearchIcon />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        flexGrow={1}
      >
        <Stack gap="4px">
          <Typography
            variant="subtitle1"
            color="custom.textDarkBlue"
            sx={{ fontWeight: 500 }}
          >
            You haven’t analyzed this company yet
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Start analysis to get more details about Buying committee members
          </Typography>
        </Stack>
        <CustomButton
          onClick={AnalyseHandler}
          customSize="fit"
          customColor="#F16300"
          customGradientColor="#FE8836"
          disabled={["scraping_queued", "scraping_in_progress"].includes(
            status,
          )}
        >
          {isLoading || isAnalyse ? (
            <>
              <CircularProgress size={24} sx={{ color: "#FFFFFF" }} />{" "}
              Analyzing: {currentProgress}%
            </>
          ) : (
            <>Analyze company data</>
          )}
        </CustomButton>
      </Stack>
    </Stack>
  );
};
