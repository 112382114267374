import { IconProps } from "../../types/genereal";

export const UserWithPanIcon = ({
  width = "24",
  height = "24",
  color = "#1C3C6C",
  opacity = "0.3",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M7 19H5C4.73478 19 4.48043 18.8946 4.29289 18.7071C4.10536 18.5196 4 18.2652 4 18V17C4 16.2044 4.31607 15.4413 4.87868 14.8787C5.44129 14.3161 6.20435 14 7 14H8M12 8C12 8.79565 11.6839 9.55871 11.1213 10.1213C10.5587 10.6839 9.79565 11 9 11C8.20435 11 7.44129 10.6839 6.87868 10.1213C6.31607 9.55871 6 8.79565 6 8C6 7.20435 6.31607 6.44129 6.87868 5.87868C7.44129 5.31607 8.20435 5 9 5C9.79565 5 10.5587 5.31607 11.1213 5.87868C11.6839 6.44129 12 7.20435 12 8ZM19.441 9.559C19.6182 9.7361 19.7589 9.9464 19.8548 10.1779C19.9507 10.4093 20.0001 10.6574 20.0001 10.908C20.0001 11.1586 19.9507 11.4067 19.8548 11.6381C19.7589 11.8696 19.6182 12.0799 19.441 12.257L13.372 18.326L10 19L10.674 15.628L16.744 9.558C16.9211 9.3809 17.1313 9.24042 17.3627 9.14458C17.5941 9.04874 17.8421 8.9994 18.0925 8.9994C18.3429 8.9994 18.5909 9.04874 18.8223 9.14458C19.0537 9.24042 19.2639 9.3809 19.441 9.558V9.559Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
