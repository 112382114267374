import Grid from "@mui/material/Grid2";
import { Box, Stack, Typography } from "@mui/material";
import { EyeIcon } from "../images/EyeIcon";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../types/enums";
import { Penetration } from "../../types";
import { FlagComponent } from "../ui/FlagComponent";
import { EllipsisTooltip } from "../ui/CustomTooltip";

const ItemList = ({
  id,
  index,
  name,
  industry,
  region,
  region_code,
  penetration_score,
  total_engagement_score,
}: Penetration) => {
  return (
    <Grid
      container
      sx={{
        alignItems: "center",
        border: "1px solid #E0E0E0",
        padding: "14px 16px",
        borderRadius: "8px",
      }}
    >
      <Grid size={0.5}>
        <Stack direction="row" gap="4px" alignItems="center">
          <Typography
            variant="body1"
            sx={{
              color: "#1F2932",
              minWidth: "10px",
            }}
          >
            {index}
          </Typography>
        </Stack>
      </Grid>
      <Grid size={2.12}>
        <Link
          to={`${AppRoutes.ACCOUNTS}/${id}`}
          style={{ textDecoration: "none" }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "#1F2932",
              paddingRight: "16px",
            }}
          >
            {name}
          </Typography>
        </Link>
      </Grid>

      <Grid size={2.62}>
        <Typography
          variant="body1"
          sx={{
            color: "#1F2932",
            paddingRight: "16px",
          }}
        >
          {industry}
        </Typography>
      </Grid>
      <Grid size={2.01}>
        <Stack direction="row" gap="8px" alignItems="center">
          <FlagComponent country={region_code} />
          <Typography
            variant="body1"
            sx={{
              color: "#1F2932",
              paddingRight: "16px",
            }}
          >
            {region}
          </Typography>
        </Stack>
      </Grid>
      <Grid size={1.6}>
        <Typography
          variant="body1"
          sx={{
            color: "#1F2932",
          }}
        >
          {penetration_score !== null && `${penetration_score}%`}
        </Typography>
      </Grid>
      <Grid size={1.85}>
        <Typography
          variant="body1"
          sx={{
            color: "#1F2932",
          }}
        >
          {total_engagement_score !== null && `${total_engagement_score}%`}
        </Typography>
      </Grid>
      <Grid size={1.3}>
        <Link
          to={`${AppRoutes.ACCOUNTS}/${id}`}
          style={{ textDecoration: "none" }}
        >
          <Stack direction="row" gap="12px" alignItems="center">
            <EyeIcon />
            <Typography
              variant="body1"
              sx={{
                color: "#1F2932",
              }}
            >
              Details
            </Typography>
          </Stack>
        </Link>
      </Grid>
    </Grid>
  );
};

type PenetrationRateTableProps = {
  data: Penetration[];
};

export const PenetrationRateTable = ({ data }: PenetrationRateTableProps) => {
  if (data.length <= 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="170px"
        sx={{
          borderRadius: 4,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "#697184",
            fontSize: "16px",
            fontWeight: 500,
          }}
        >
          No data available
        </Typography>
      </Box>
    );
  }

  return (
    <Stack gap="14px" paddingY="24px">
      <Grid container padding="0 16px">
        <Grid size={0.5}></Grid>
        <Grid size={2.12}>
          <Typography
            variant="body1"
            noWrap={true}
            sx={{
              color: "#697184",
            }}
          >
            Name
          </Typography>
        </Grid>

        <Grid size={2.62}>
          <Typography
            variant="body1"
            noWrap={true}
            sx={{
              color: "#697184",
            }}
          >
            Industry
          </Typography>
        </Grid>
        <Grid size={2.01}>
          <Typography
            variant="body1"
            noWrap={true}
            sx={{
              color: "#697184",
            }}
          >
            Region
          </Typography>
        </Grid>
        <Grid size={1.6}>
          <EllipsisTooltip tooltip="Rate of potential BC members, identified in available data, to expected BC size.">
            <Typography
              variant="body1"
              noWrap={true}
              sx={{
                color: "#697184",
              }}
            >
              Identification rate
            </Typography>
          </EllipsisTooltip>
        </Grid>
        <Grid size={1.85}>
          <EllipsisTooltip
            tooltip="Engagement rate of all identified potential BC members, based on outgoing and replied calls &
               emails, communication recency over a selected period of time."
          >
            <Typography
              variant="body1"
              noWrap={true}
              sx={{
                color: "#697184",
              }}
            >
              Total engagement
            </Typography>
          </EllipsisTooltip>
        </Grid>
        <Grid size={1.3}></Grid>
      </Grid>
      {data.map(
        ({
          id,
          index,
          name,
          normalized_name,
          industry,
          region,
          region_code,
          penetration_score,
          total_engagement_score,
        }) => (
          <ItemList
            key={index}
            index={index}
            id={id}
            name={name}
            normalized_name={normalized_name}
            industry={industry}
            region={region}
            penetration_score={penetration_score}
            total_engagement_score={total_engagement_score}
            region_code={region_code}
          />
        ),
      )}
    </Stack>
  );
};
