import React, { ReactNode, useState } from "react";
import { Tooltip } from "@mui/material";
import { isTextEllipsed } from "utils/general_utils";

interface EllipsisTooltipProps {
  children: ReactNode;
  tooltip?: string;
  align?: "flex-start" | "center" | "flex-end";
}

export const EllipsisTooltip = ({
  children,
  tooltip,
  align = "flex-start",
}: EllipsisTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipContent, setTooltipContent] = useState<string>("");

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    if (tooltip) return;

    if (isTextEllipsed(target)) {
      setShowTooltip(true);
      setTooltipContent(target.textContent || "");
    } else {
      setTooltipContent("");
    }
  };

  return (
    <Tooltip
      title={tooltip ? tooltip : showTooltip ? tooltipContent : ""}
      placement="bottom"
      arrow
      sx={{
        background: "#596177E5",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 400,
      }}
    >
      <span
        onMouseEnter={handleMouseEnter}
        style={{
          justifyContent: align,
          maxWidth: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
};
