import { useEffect, useState } from "react";
import { Typography, Box, Stack, Divider } from "@mui/material";
import { useLoginMutation } from "api/authApi";
import { LoginForm } from "components/forms/LoginForm";
import { LoginFormData } from "types/authTypes";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/useReduxHooks";
import { setAuth } from "features/authSlice";
import { useHandleError } from "hooks/useHandleError";
import { useHandleSuccess } from "hooks/useHandleSuccess";
import { AppRoutes } from "../types/enums";
import { LoginWithMicrosoftBtn } from "../components/ui";

export const LoginView = () => {
  const dispatch = useAppDispatch();
  const [login, { isLoading, error: loginError, isSuccess, reset, data }] =
    useLoginMutation();
  const navigate = useNavigate();
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [globalError, setGlobalErrors] = useState<string | null>(null);

  useHandleError(loginError, {
    setFieldErrors,
    setGlobalErrors,
    reset,
  });

  const handleLogin = (data: LoginFormData) => {
    setFieldErrors({});
    setGlobalErrors(null);
    login(data);
  };

  useEffect(() => {
    if (isSuccess) {
      navigate(AppRoutes.PRODUCTS);
    }
  }, [isSuccess, navigate]);

  useHandleSuccess(isSuccess, () => {
    if (!data) return;
    dispatch(
      setAuth({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
      }),
    );
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="calc(100vh - 74px)"
    >
      <Stack
        width="368px"
        p={4}
        borderRadius="8px"
        textAlign="center"
        gap="16px"
      >
        <Stack gap={1} pb={2}>
          <Typography variant="h1" component="h1" textAlign="left">
            Sign in
          </Typography>
          <Typography variant="subtitle1" color="text.primary" textAlign="left">
            Access your account securely
          </Typography>
        </Stack>
        <LoginWithMicrosoftBtn />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Divider sx={{ flexGrow: 1, color: "#CFCFCF" }} />
          <Typography
            variant="button"
            sx={{
              marginX: "8px",
              color: "#4C545B",
            }}
          >
            or / and
          </Typography>
          <Divider sx={{ flexGrow: 1, color: "#CFCFCF" }} />
        </Box>
        <LoginForm
          onSubmit={handleLogin}
          loading={isLoading}
          fieldErrors={fieldErrors}
          globalError={globalError}
          setFieldErrors={setFieldErrors}
          setGlobalErrors={setGlobalErrors}
        />
      </Stack>
    </Box>
  );
};
