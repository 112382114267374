import { Stack, Typography } from "@mui/material";
import { CustomButton } from "../../CustomButton";
import React, { useEffect, useState } from "react";
import { NAME_REGEX, NO_SCRIPT_REGEX } from "../../../../utils/regex";
import { ProductAnalysisResponse } from "../../../../types/apiTypes";
import { ReuseInput } from "../../ReuseInput";
import { useValidateCampaignDataMutation } from "../../../../api/companyApi";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { toast } from "react-toastify";
import { CustomToast } from "../../CustomToast";
import { equals } from "../../../../utils/equals";

type Props = {
  initData: ProductAnalysisResponse;
  setResultData: (data: ProductAnalysisResponse) => void;
  nextStepHandler: () => void;
  backHandler: () => void;
};

type Errors = { [key: string]: boolean };

const validation: { [key: string]: (value: string) => boolean } = {
  company_name: (value: string) => !NAME_REGEX.test(value),
  product_abstract: (value: string) => !value || !NO_SCRIPT_REGEX.test(value),
  target_audience: (value: string) => !value || !NO_SCRIPT_REGEX.test(value),
  product_name: (value: string) => !NAME_REGEX.test(value),
};

const initialValues: ProductAnalysisResponse = {
  company_name: "",
  product_abstract: "",
  target_audience: "",
  product_name: "",
};

export const DescriptionStep = ({
  initData,
  setResultData,
  nextStepHandler,
  backHandler,
}: Props) => {
  const [errorMessage, setErrorMessage] =
    useState<ProductAnalysisResponse>(initialValues);

  const [formData, setFormData] =
    useState<ProductAnalysisResponse>(initialValues);

  const [error, setError] = useState<Errors>({
    company_name: false,
    product_abstract: false,
    target_audience: false,
    product_name: false,
  });

  const [
    validateCampaignData,
    {
      isSuccess,
      error: validateCampaignDataError,
      isLoading: validateCampaignDataIsLoading,
    },
  ] = useValidateCampaignDataMutation();

  useEffect(() => {
    if (validateCampaignDataError) {
      if (getErrorMessage(validateCampaignDataError).fieldErrors) {
        setErrorMessage((err) => ({
          ...err,
          ...getErrorMessage(validateCampaignDataError).fieldErrors,
        }));
      } else {
        toast.error(
          <CustomToast
            type={"error"}
            text={
              getErrorMessage(validateCampaignDataError).globalError ||
              "Something went wrong during processing. Please try again"
            }
            title={"Changes are not saved"}
          />,
        );
      }
    }
  }, [validateCampaignDataError]);

  useEffect(() => {
    if (isSuccess) {
      nextStepHandler();
      setResultData(formData);
    }
  }, [isSuccess]);

  const inputChangeHandler = (field: string, value: string) => {
    setErrorMessage((prev) => ({ ...prev, [field]: "" }));
    setFormData((prev) => ({ ...prev, [field]: value }));
    setError((prev) => ({
      ...prev,
      [field]: validation[field](value),
    }));
  };

  useEffect(() => {
    setFormData(initData);
  }, [initData]);

  const setErrorHandler = (field: string, value: boolean) => {
    setError((prev) => ({ ...prev, [field]: value }));
  };

  const submitHandler = () => {
    if (equals(formData, initData)) {
      nextStepHandler();
      return;
    }
    validateCampaignData(formData);
  };

  return (
    <Stack gap="16px">
      <Typography variant="body1" color="#4C545B" fontSize="16px">
        Does the generated product description and target audience match your
        expectations?
      </Typography>
      <Stack
        gap="42px"
        paddingY="24px"
        sx={{
          mt: 2,
          pr: 1,
          maxHeight: "calc(100vh - 360px)",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#aaa",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "12px",
            backgroundColor: "transparent",
          },
        }}
      >
        <ReuseInput
          name="company_name"
          handleInputChange={inputChangeHandler}
          errorMessage={
            errorMessage.company_name ||
            "Must contain only letters, numbers, and basic punctuation"
          }
          value={formData.company_name || ""}
          label="Company name *"
          maxLength={100}
          placeholder=" e.g., INFUSE"
          isError={error.company_name || !!errorMessage.company_name}
          setErrorHandler={setErrorHandler}
        />
        <ReuseInput
          name="product_name"
          handleInputChange={inputChangeHandler}
          errorMessage={
            errorMessage.product_name ||
            "Must contain only letters, numbers, and basic punctuation"
          }
          value={formData.product_name || ""}
          label="Product name *"
          maxLength={100}
          placeholder=" e.g., INFUSE"
          isError={error.product_name || !!errorMessage.product_name}
          setErrorHandler={setErrorHandler}
        />
        <ReuseInput
          name="product_abstract"
          handleInputChange={inputChangeHandler}
          errorMessage={
            errorMessage.product_abstract ||
            "This field is required and cannot include scripts."
          }
          value={formData.product_abstract || ""}
          label="Product description *"
          maxLength={1000}
          multiline={true}
          placeholder=" e.g., INFUSE is a global high-performance demand partner delivering demand strategies,
         programs, and outcomes for the most admired B2B brands"
          isError={error.product_abstract || !!errorMessage.product_abstract}
          setErrorHandler={setErrorHandler}
        />
        <ReuseInput
          name="target_audience"
          handleInputChange={inputChangeHandler}
          errorMessage={
            errorMessage.target_audience ||
            "This field is required and cannot include scripts."
          }
          value={formData.target_audience || ""}
          label="Product target audience *"
          maxLength={500}
          placeholder=" e.g., Demand specialists, marketers, sales, executives. Manager+ seniority.
         Located in NOAM, EMEA, LATAM, APAC."
          multiline={true}
          isError={error.target_audience || !!errorMessage.target_audience}
          setErrorHandler={setErrorHandler}
        />
      </Stack>
      <Stack direction="row" gap="8px">
        <CustomButton
          onClick={backHandler}
          variant="contained"
          textColor="#4C545B"
          fullWidth
          type="button"
          customColor="#F1F1F1"
          disabled={validateCampaignDataIsLoading}
        >
          Back
        </CustomButton>
        <CustomButton
          onClick={submitHandler}
          variant="contained"
          fullWidth
          type="button"
          customColor="#1C3C6C"
          disabled={
            validateCampaignDataIsLoading || Object.values(error).includes(true)
          }
        >
          Confirm
        </CustomButton>
      </Stack>
    </Stack>
  );
};
