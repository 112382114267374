import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const DotIcon = ({
  width = "24",
  height = "24",
  color = "#4E8432",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 11.9982C8 12.7232 8.1777 13.3949 8.53665 14.0062C8.8956 14.6175 9.37894 15.108 9.99378 15.4633C10.6086 15.8187 11.2768 16 12.0018 16C12.7268 16 13.3949 15.8223 14.0098 15.4633C14.6246 15.1044 15.108 14.6211 15.4633 14.0062C15.8187 13.3914 16 12.7232 16 11.9982C16 11.2732 15.8223 10.6051 15.4633 9.99023C15.1044 9.37539 14.6211 8.89205 14.0098 8.53665C13.3985 8.18125 12.7268 8 12.0018 8C11.2768 8 10.6086 8.18125 9.99378 8.53665C9.37894 8.89205 8.89205 9.37894 8.53665 9.99023C8.18125 10.6015 8 11.2732 8 11.9982Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  );
};
