import { Breadcrumbs, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../types/enums";

type Props = {
  title: string;
  path?: string;
};
export const NavigationBreadcrumbs = ({
  breadcrumbs,
}: {
  breadcrumbs: Props[];
}) => {
  return (
    <Stack>
      <Breadcrumbs separator={">"} aria-label="breadcrumb">
        <Link
          to={AppRoutes.PRODUCTS}
          style={{ textDecoration: "none", color: "#1F2932" }}
        >
          Products
        </Link>
        {breadcrumbs.map(({ title, path }, index) => {
          if (breadcrumbs.length - 1 === index) {
            return (
              <Typography key={title} sx={{ color: "#1F293299" }}>
                {title}
              </Typography>
            );
          }
          return (
            <Link
              key={title}
              to={path || ""}
              style={{ textDecoration: "none", color: "#1F2932" }}
            >
              {title}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
};
