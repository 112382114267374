import { CustomInput } from "./CustomInput";
import { SearchIcon } from "../images/SearchIcon";
import { Box } from "@mui/material";
import { useStateWithSearchParams } from "../../hooks/useStateWithSearchParams";

type SearchParams = {
  search?: string;
};

export const InputWithQuery = ({ maxWidth }: { maxWidth?: string }) => {
  const { searchDict, setSearchValue } = useStateWithSearchParams<SearchParams>(
    {
      search: undefined,
    },
  );

  const searchInputHandler = (value: string) => {
    setSearchValue("search", value);
  };

  return (
    <Box sx={{ maxWidth: maxWidth ? maxWidth : "284px" }}>
      <CustomInput
        value={searchDict.search || ""}
        placeholder="Search"
        prefixIcon={<SearchIcon />}
        onChange={searchInputHandler}
      />
    </Box>
  );
};
