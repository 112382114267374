import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";
import {
  LoginRequest,
  LoginResponse,
  SignupRequest,
  Response,
  UrlResponse,
} from "types/apiTypes";
import { RequestMethods } from "types/enums";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (body) => ({
        url: "/auth/login",
        method: RequestMethods.POST,
        body,
      }),
    }),
    signup: builder.mutation<Response, SignupRequest>({
      query: (body) => ({
        url: "/auth/register",
        method: RequestMethods.POST,
        body,
      }),
    }),
    getUrlForMicrosoftLogin: builder.query<UrlResponse, void>({
      query: () => ({
        url: "/auth/infuse",
        method: RequestMethods.GET,
      }),
    }),
    loginWithMicrosoft: builder.mutation<
      LoginResponse,
      { code: string; csrf_token: string }
    >({
      query: ({ code, csrf_token }) => ({
        url: `/auth/infuse?code=${code}&csrf_token=${csrf_token}`,
        method: RequestMethods.POST,
      }),
    }),
    forgotPassword: builder.mutation<Response, { email: string }>({
      query: (body) => ({
        url: "/auth/forgot-password",
        method: RequestMethods.POST,
        body,
      }),
    }),
    validateOTP: builder.mutation<Response, { email: string; code: string }>({
      query: (body) => ({
        url: "/auth/otp/validate",
        method: RequestMethods.POST,
        body,
      }),
    }),
    resetPassword: builder.mutation<
      Response,
      { email: string; code: string; new_password: string }
    >({
      query: (body) => ({
        url: "/auth/otp/reset-password",
        method: RequestMethods.POST,
        body,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useSignupMutation,
  useLazyGetUrlForMicrosoftLoginQuery,
  useLoginWithMicrosoftMutation,
  useForgotPasswordMutation,
  useValidateOTPMutation,
  useResetPasswordMutation,
} = authApi;
