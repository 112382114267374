import { Box, Divider, Stack, Typography } from "@mui/material";
import { CustomButton } from "../../CustomButton";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { CustomToast } from "../../CustomToast";
import { URL_REGEX } from "../../../../utils/regex";
import { IIcon } from "../../../images/IIcon";
import { MingcutePdfLine } from "../../../images/PdfFileImg";
import { SwitchImg } from "../../../images/SwitchImg";
import { DeleteImg } from "../../../images/deleteImg";
import { AnalysePDFBlock } from "./AnalysePDFBlock";
import { ReuseInput } from "../../ReuseInput";
import { ProductAnalysisResponse } from "../../../../types/apiTypes";

type Fields = {
  product_url: string;
};

type Props = {
  onClose: () => void;
  onCancel?: () => void;
  initProductUrl: string;
  initPdfFile: File | null;
  setResultData: (
    data: ProductAnalysisResponse,
    file: File | null,
    url: string,
  ) => void;
  nextStepHandler: () => void;
  setIsAnalysisPDF: (value: boolean) => void;
};

type Errors = { [key: string]: boolean };

const initValues: ProductAnalysisResponse = {
  company_name: "",
  product_name: "",
  product_abstract: "",
  target_audience: "",
};

const validation: { [key: string]: (value: string) => boolean } = {
  product_url: (value: string) => !!value && !URL_REGEX.test(value),
};

export const UploadPDFStep = ({
  onClose,
  initProductUrl,
  initPdfFile,
  setResultData,
  nextStepHandler,
  setIsAnalysisPDF,
}: Props) => {
  const [analyze, setAnalyze] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [pdfFile, setPdfFile] = useState<File | null>(initPdfFile);
  const [formData, setFormData] = useState<Fields>({
    product_url: initProductUrl,
  });

  const [error, setError] = useState<Errors>({
    product_url: false,
    product_abstract: false,
    target_audience: false,
    product_name: false,
    url: false,
  });

  useEffect(() => {
    setIsAnalysisPDF(analyze);
  }, [analyze]);

  const inputChangeHandler = (field: string, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setError((prev) => ({
      ...prev,
      [field]: validation[field](value),
    }));
  };

  const setErrorHandler = (field: string, value: boolean) => {
    setError((prev) => ({ ...prev, [field]: value }));
  };

  const submitHandler = () => {
    if (initPdfFile === pdfFile && initProductUrl === formData.product_url) {
      nextStepHandler();
      return;
    }
    setAnalyze(true);
  };

  const closeHandler = () => {
    onClose();
  };

  const uploadFileHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.type === "application/pdf") {
        setPdfFile(file);
      } else {
        toast.error(
          <CustomToast
            type={"error"}
            text={
              "You have chosen unsupported file extension. Please choose .pdf file and try again"
            }
            title={"Failed upload file"}
          />,
        );
        setPdfFile(null);
      }
    }
  };

  const setResultHandler = (result: ProductAnalysisResponse) => {
    setResultData(result, pdfFile, formData.product_url);
    setAnalyze(false);
    nextStepHandler();
  };

  const removeFileHandler = () => {
    setPdfFile(null);
    setResultData(initValues, null, initProductUrl);
  };

  return (
    <Stack gap="16px">
      <Stack
        gap="16px"
        sx={{
          pr: 1,
          maxHeight: "calc(100vh - 360px)",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#aaa",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "12px",
            backgroundColor: "transparent",
          },
        }}
      >
        <Typography variant="body1" color="#4C545B" fontSize="16px">
          Identify your buying committee in 2 easy steps
        </Typography>
        <Typography
          variant="subtitle1"
          color="custom.textDarkBlue"
          sx={{ fontSize: "18px", fontWeight: 500 }}
        >
          Step 1 of 2: Add product description
        </Typography>
        <Typography variant="body1" color="#4C545B">
          Please provide a URL link and/or a PDF file with a description of your
          product.
        </Typography>
        <Stack direction="row" gap="16px" alignItems="flex-start">
          <IIcon />
          <Typography variant="body2" color="custom.textDarkBlue">
            Accuracy of the analysis will depend on the quality of information
            you provide.
          </Typography>
        </Stack>
        {analyze ? (
          <AnalysePDFBlock
            pdfFile={pdfFile}
            setResultHandler={setResultHandler}
            productUrl={formData.product_url}
          />
        ) : (
          <>
            <Stack paddingTop="24px">
              <ReuseInput
                name="product_url"
                handleInputChange={inputChangeHandler}
                errorMessage={
                  <Stack component="span" alignItems="center" width="100%">
                    <span>
                      Invalid URL: Please check the format and try again.
                    </span>
                    <span>Example: https://www.example.com</span>
                  </Stack>
                }
                value={formData.product_url}
                label="Company website url"
                placeholder=" e.g., INFUSE"
                isError={error.product_url}
                setErrorHandler={setErrorHandler}
              />
              {error.product_url && <Stack sx={{ height: "20px" }}></Stack>}
            </Stack>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Divider sx={{ flexGrow: 1, color: "#CFCFCF" }} />
              <Typography
                variant="button"
                sx={{
                  marginX: "8px",
                  color: "#4C545B",
                }}
              >
                and / or
              </Typography>
              <Divider sx={{ flexGrow: 1, color: "#CFCFCF" }} />
            </Box>

            <Box>
              <Stack gap="8px" alignItems="center" justifyContent="center">
                {pdfFile && (
                  <Box display="flex" alignItems="center" gap={1} width="100%">
                    <Box
                      display="flex"
                      alignItems="center"
                      flexGrow={1}
                      minWidth={0}
                    >
                      <MingcutePdfLine />
                      <Typography
                        color="#1D3E70"
                        pl={1}
                        sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {pdfFile.name}
                      </Typography>
                    </Box>
                    <CustomButton
                      variant="outlined"
                      customColor=""
                      customSize="s"
                      onClick={() => fileInputRef.current?.click()}
                      sx={{
                        minWidth: "auto",
                        p: 1,
                        width: "40px",
                        height: "40px",
                        border: "1px solid #1D3E70",
                      }}
                    >
                      <SwitchImg />
                    </CustomButton>
                    <CustomButton
                      variant="outlined"
                      customColor=""
                      customSize="s"
                      onClick={removeFileHandler}
                      sx={{
                        minWidth: "auto",
                        p: 1,
                        width: "40px",
                        height: "40px",
                        border: "1px solid #1D3E70",
                      }}
                    >
                      <DeleteImg />
                    </CustomButton>
                  </Box>
                )}
              </Stack>

              {!pdfFile && (
                <>
                  <CustomButton
                    variant="outlined"
                    fullWidth
                    customColor=""
                    textColor="#1D3E70"
                    onClick={() => fileInputRef.current?.click()}
                    sx={{ height: "48px", border: "1px solid #1D3E70" }}
                  >
                    <MingcutePdfLine />
                    Upload PDF
                  </CustomButton>
                </>
              )}
            </Box>
          </>
        )}
        <input
          type="file"
          hidden
          ref={fileInputRef}
          accept=".pdf"
          onChange={uploadFileHandler}
        />
      </Stack>
      <Stack direction="row" gap="8px">
        <CustomButton
          onClick={closeHandler}
          variant="contained"
          textColor="#4C545B"
          fullWidth
          type="button"
          customColor="#F1F1F1"
          disabled={analyze}
        >
          Back
        </CustomButton>
        <CustomButton
          onClick={submitHandler}
          variant="contained"
          fullWidth
          type="button"
          customColor="#1C3C6C"
          disabled={
            analyze || (!pdfFile && !formData.product_url) || error.product_url
          }
        >
          Confirm
        </CustomButton>
      </Stack>
    </Stack>
  );
};
