import { Stack } from "@mui/material";
import { IconProps } from "../../types/genereal";

type Props = IconProps & {
  children: React.ReactNode;
};

export const IconWrapper = ({ children, width, height, rotate }: Props) => {
  return (
    <Stack
      sx={{ width, height, transform: `rotate(${rotate}deg)` }}
      alignItems="center"
      justifyContent="center"
    >
      {children}
    </Stack>
  );
};
