export const industryOptions = [
  { value: "it", label: "Information Technology" },
  { value: "security", label: "Computer & Network Security" },
  { value: "consulting", label: "Consulting Manufacturing" },
  { value: "telecom", label: "Telecommunication" },
  { value: "engineering", label: "Engineering Financial Services" },
  { value: "healthcare", label: "Healthcare" },
  { value: "research", label: "Research" },
  { value: "retail", label: "Retail" },
  { value: "transportation", label: "Transportation" },
  { value: "other", label: "Other" },
];
