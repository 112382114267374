import { IconProps } from "../../types/genereal";
import { Stack } from "@mui/material";

export const LineWithStarsIcon = ({
  width = "40",
  height = "40",
  color = "#1C3C6C",
}: IconProps) => {
  return (
    <Stack direction="row" sx={{ minWidth: width, minHeight: height }}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.5003 15.332C28.8087 15.332 27.8637 17.012 28.2487 18.2604L24.107 22.4137C23.757 22.3087 23.2437 22.3087 22.8937 22.4137L19.9187 19.4387C20.3153 18.1904 19.3703 16.4987 17.667 16.4987C15.9753 16.4987 15.0187 18.1787 15.4153 19.4387L10.0953 24.747C8.84699 24.362 7.16699 25.307 7.16699 26.9987C7.16699 28.282 8.21699 29.332 9.50033 29.332C11.192 29.332 12.137 27.652 11.752 26.4037L17.0603 21.0837C17.4103 21.1887 17.9237 21.1887 18.2737 21.0837L21.2487 24.0587C20.852 25.307 21.797 26.9987 23.5003 26.9987C25.192 26.9987 26.1487 25.3187 25.752 24.0587L29.9053 19.917C31.1537 20.302 32.8337 19.357 32.8337 17.6654C32.8337 16.382 31.7837 15.332 30.5003 15.332Z"
          fill={color}
        />
        <path
          d="M23.5003 16.5L24.597 14.085L27.0003 13L24.597 11.915L23.5003 9.5L22.427 11.915L20.0003 13L22.427 14.085L23.5003 16.5ZM10.0837 18.8333L10.667 16.5L13.0003 15.9167L10.667 15.3333L10.0837 13L9.50033 15.3333L7.16699 15.9167L9.50033 16.5L10.0837 18.8333Z"
          fill={color}
        />
      </svg>
    </Stack>
  );
};
