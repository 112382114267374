import { Box } from "@mui/material";
import { LoginHeader } from "components/features/LoginHeader";
import { Outlet } from "react-router-dom";

export const LoginLayout = () => {
  return (
    <Box>
      <LoginHeader />
      <Outlet />
    </Box>
  );
};
