import { CustomButton } from "../CustomButton";
import React, { useEffect, useState } from "react";
import { ExportDataIcon } from "../../images/ExportDataIcon";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../hooks/useReduxHooks";
import { useDebounceValue } from "../../../hooks/useDebounceValue";
import { ExportCompany } from "../../../types/apiTypes";
import { useExportDataMutation, useExportListQuery } from "../../../api";
import { toast } from "react-toastify";
import { CustomToast } from "../CustomToast";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { openLinkHandler } from "../../../utils/openLinkHandler";
import { INPUT_REGEX } from "../../../utils/regex";
import {
  Box,
  FormHelperText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { SubBlock } from "./SubBlock";
import { ReuseModal } from "../ReuseModal";
import { isTextEllipsed } from "../../../utils/general_utils";
import { SelectedExportOptions } from "./SelectedExportOption";
import { useStateWithSearchParams } from "../../../hooks/useStateWithSearchParams";
import { Order } from "../../../types";

export enum Option {
  all = "From all companies",
  selected = "From selected companies",
}

type SearchParams = {
  sizes?: string;
  industries?: string;
  regions?: string;
  search?: string;
  sort?: string;
  page?: string;
  per_page?: string;
  selected?: string;
  limit?: string;
  date?: string;
};

export const ExportAccountsDataBtn = ({ tabs }: { tabs?: string }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { campaign_id } = useParams();
  const campaignId = useAppSelector((state) => state.company.company);
  const update = useAppSelector((state) => state.company.updateAnalytics);

  const [isError, setIsError] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("Export-data");
  const [option, setOption] = useState<string>(Option.all);

  const [value, setValue] = useState<string>("");
  const searchValueDebounce = useDebounceValue(value);
  const [selectedItemList, setSelectedItemList] = useState<ExportCompany[]>([]);
  const [analyzedItemList, setAnalyzedItemList] = useState<ExportCompany[]>([]);

  const { searchDict } = useStateWithSearchParams<SearchParams>({
    industries: undefined,
    regions: undefined,
    sizes: undefined,
    search: undefined,
    page: undefined,
    per_page: undefined,
    selected: undefined,
    limit: undefined,
    date: undefined,
  });

  const [exportData, { isLoading, isSuccess, error, data }] =
    useExportDataMutation();

  const { data: companyList, refetch } = useExportListQuery(
    {
      campaign_id: campaign_id || campaignId,
      limit: searchDict.limit ? Number(searchDict?.limit) : undefined,
      size: searchDict.sizes?.split(","),
      search: searchDict.search,
      industry: searchDict.industries?.split(","),
      region: searchDict.regions?.split(","),
      sort: tabs,
      order: searchDict.sort?.split("-")[1] as Order,
      start_date: searchDict.date?.split(",")[0],
      end_date: searchDict.date?.split(",")[1],
    },
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (isOpen) {
      setOption(Option.all);
      setFileName("Export-data");
    }
  }, [isOpen]);

  useEffect(() => {
    if (option === Option.all) {
      setValue("");
      setSelectedItemList(analyzedItemList);
    }
  }, [option]);

  useEffect(() => {
    isOpen && refetch();
    update && refetch();
  }, [isOpen, update]);

  useEffect(() => {
    if (companyList) {
      const analyzedList = companyList.filter(({ is_analyzed }) => is_analyzed);
      setSelectedItemList(analyzedList);
      setAnalyzedItemList(analyzedList);
    }
  }, [companyList]);

  useEffect(() => {
    if (error) {
      toast.error(
        <CustomToast
          type={"error"}
          text={
            getErrorMessage(error).globalError ||
            "Something went wrong. Please try again"
          }
          title={"Export data failed"}
        />,
      );
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess && data) {
      openLinkHandler(data.url, fileName);
      setIsOpen(false);
    }
  }, [isSuccess]);

  const handleChange = (item: ExportCompany) => {
    let isInList = false;

    const newList = selectedItemList.filter(({ id }) => {
      if (id === item.id) {
        isInList = true;
        return false;
      }

      return true;
    });

    !isInList && newList.push(item);
    setSelectedItemList(newList);
  };

  const selectAllHandler = () => {
    if (!companyList) return setSelectedItemList([]);
    setSelectedItemList(
      analyzedItemList.length === selectedItemList.length
        ? []
        : analyzedItemList,
    );
  };

  const submitHandler = () => {
    exportData({
      campaign_id: campaign_id || campaignId,
      file_name: fileName || undefined,
      account_ids:
        selectedItemList.length > 0
          ? selectedItemList.map(({ id }) => id)
          : undefined,
      only_committee: false,
      filters: {
        limit: searchDict.limit ? Number(searchDict?.limit) : undefined,
        size: searchDict.sizes?.split(","),
        search: searchDict.search,
        industry: searchDict.industries?.split(","),
        region: searchDict.regions?.split(","),
        sort: tabs,
        order: searchDict.sort?.split("-")[1] as Order,
        start_date: searchDict.date?.split(",")[0],
        end_date: searchDict.date?.split(",")[1],
      },
    });
  };

  const inputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsError(!INPUT_REGEX.test(e.target.value));
    setFileName(e.target.value);
  };
  return (
    <>
      <Tooltip
        title={
          analyzedItemList.length === 0
            ? "You haven’t analysed any company yet"
            : null
        }
        placement="bottom"
        arrow
        sx={{
          background: "#596177E5",
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 400,
          "&:hover": {
            visibility: "visible",
            opacity: 1,
          },
        }}
      >
        <div
          style={{ height: "42px" }}
          onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
            isTextEllipsed(event.currentTarget);
          }}
        >
          <CustomButton
            onClick={() => setIsOpen(true)}
            variant="outlined"
            customColor="#FFFFFF"
            textColor="#1D3E70"
            customSize="fit"
            type="button"
            startIcon={
              <ExportDataIcon
                color={analyzedItemList.length === 0 ? "#BFC1C1" : undefined}
              />
            }
            sx={{ height: "42px", borderColor: "#1D3E70", minWidth: "168px" }}
            disabled={analyzedItemList.length === 0}
          >
            Export to CSV
          </CustomButton>
        </div>
      </Tooltip>

      <ReuseModal
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={() => setIsOpen(false)}
        title="Export data to CSV"
        onSubmit={submitHandler}
        maxWidth="436px"
        submitBtnColor="#1C3C6C"
        submitBtnText="Export"
        isDisabled={
          isError ||
          (selectedItemList.length === 0 && option === Option.selected)
        }
      >
        <Stack gap="16px">
          <SubBlock
            title=""
            text="Which data set of potential BC members you would like to export?"
          />
          <SelectedExportOptions
            option={option}
            value={value}
            setOption={setOption}
            analyzedItemList={analyzedItemList}
            selectedItemList={selectedItemList}
            companyList={companyList || []}
            handleChange={handleChange}
            searchValueDebounce={searchValueDebounce}
            selectAllHandler={selectAllHandler}
            setValue={setValue}
          />
          <SubBlock
            title="File name"
            text="What should be the CSV file name?"
          />
          <Stack direction="row" gap="8px" alignItems="center" pt="16px">
            <Box
              sx={{
                position: "relative",
                width: "100%",
              }}
            >
              <TextField
                error={isError}
                fullWidth
                label="File name*"
                value={fileName}
                onChange={inputChangeHandler}
                placeholder="Example"
                sx={{
                  "& .MuiInputBase-root.Mui-disabled": {
                    pointerEvents: "none",
                    backgroundColor: "#f5f5f5",
                  },
                }}
              />
              {isError && (
                <FormHelperText
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "absolute",
                    bottom: -20,
                    left: 0,
                    width: "100%",
                    fontSize: "12px",
                    lineHeight: "20px",
                    color: isError ? "#D32F2F" : "#00000099",
                  }}
                >
                  {`Only letters, numbers, and symbols except < > : " / \ | ? *.`}
                </FormHelperText>
              )}
            </Box>

            <Typography
              component="span"
              variant="body1"
              sx={{ fontWeight: 500, lineHeight: "26px" }}
              color="#4C545B"
            >
              .csv
            </Typography>
          </Stack>
        </Stack>
      </ReuseModal>
    </>
  );
};
