export function BigDataOutlined() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 16.25C18.5 13.9225 14.1613 12.75 12 12.75C9.83875 12.75 5.5 13.9225 5.5 16.25V18H18.5V16.25Z"
        fill="#1C3C6C"
      />
      <path
        d="M15.25 8.5C15.25 10.4425 13.8038 12 12 12C11.138 12 10.3114 11.6313 9.7019 10.9749C9.09241 10.3185 8.75 9.42826 8.75 8.5C8.75 7.57174 9.09241 6.6815 9.7019 6.02513C10.3114 5.36875 11.138 5 12 5C13.8038 5 15.25 6.5575 15.25 8.5Z"
        fill="#1C3C6C"
      />
    </svg>
  );
}
