import { Stack, Typography } from "@mui/material";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../types/enums";
import { NavigationBreadcrumbs } from "../components/ui";
import { ProductTitleBlock } from "../components/products/ProductTitleBlock";
import { CustomTabs } from "../components/ui/CustomTabs";
import { CustomLoader } from "../components/ui/CustomLoader";
import { useGetCampaignSummaryQuery } from "../api/companyApi";
import { AnalyticBlock } from "../components/accounts";

const tabList = [
  { label: "List", path: "list" },
  { label: "BC identification", path: "rate" },
  { label: "Total engagement", path: "score" },
];

const tabs: { [key: string]: string } = {
  rate: "penetration_score",
  score: "total_engagement_score",
};

export const AccountsLayout = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { campaign_id } = useParams();

  const { data, isLoading } = useGetCampaignSummaryQuery(
    {
      campaign_id: campaign_id!,
    },
    { skip: !campaign_id, refetchOnMountOrArgChange: true },
  );

  const tabIndex = tabList.findIndex(
    ({ path }) => path === pathname.split(AppRoutes.HOME)[2],
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(
      `${AppRoutes.ACCOUNTS}/${tabList[newValue].path}/${campaign_id}${search}`,
    );
  };

  const breadcrumbs = [
    {
      title: data?.product_name ?? "company name",
      path: `${AppRoutes.PRODUCTS}/${campaign_id}`,
    },
    {
      title: "Accounts",
    },
  ];

  if (isLoading) {
    return <CustomLoader />;
  }

  return (
    <Stack sx={{ px: { sm: 8, md: 8, lg: 14, xl: 20 }, pt: 6, pb: 6 }} gap={3}>
      <NavigationBreadcrumbs breadcrumbs={breadcrumbs} />
      <Typography
        variant="h4"
        color="custom.textDarkBlue"
        sx={{ fontWeight: 500 }}
      >
        Accounts
      </Typography>
      <ProductTitleBlock
        tabs={tabs[pathname.split(AppRoutes.HOME)[2]]}
        product_name={data?.product_name ?? ""}
        company_name={data?.company_name ?? ""}
      />
      <AnalyticBlock tabs={tabs[pathname.split(AppRoutes.HOME)[2]]} />
      <CustomTabs
        currentTab={tabIndex}
        onChange={handleChange}
        tabList={tabList.map(({ label }) => label)}
      />
      <Outlet />
    </Stack>
  );
};
