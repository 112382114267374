import { CircularProgress, Stack, Typography } from "@mui/material";
import { IIcon } from "../images/IIcon";
import { CustomButton } from "./CustomButton";
import { BaseModal } from "./BaseModal";
import { ReactNode } from "react";
import { AlertIcon } from "../images/AlertIcon";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  icon?: "warn";
  title: string;
  text?: string;
  customText?: ReactNode;
  warnText?: string[];
  cancelBtnText?: string;
  submitBtnText?: string;
  submitBtnColor?: string;
  maxWidth?: string;
  isLoading?: boolean;
  children?: ReactNode;
  width?: string;
  isDisabled?: boolean;
};

const icons = {
  warn: <AlertIcon />,
};

export const ReuseModal = ({
  isOpen,
  onClose,
  onCancel,
  onSubmit,
  icon,
  title,
  text,
  customText,
  warnText,
  cancelBtnText,
  submitBtnText,
  submitBtnColor = "#CF3E00",
  maxWidth = "304px",
  isLoading,
  children,
  width,
  isDisabled,
}: Props) => {
  return (
    <BaseModal
      open={isOpen}
      onClose={onClose}
      title={title}
      sx={{
        padding: "32px",
        width: width || "90%",
        maxWidth: width || maxWidth,
      }}
      icon={icon && icons[icon]}
    >
      <Stack gap="32px" maxWidth={width || maxWidth} width={width || "100%"}>
        {text && (
          <Typography component="span" variant="body1" color="text.secondary">
            {text}
          </Typography>
        )}

        {customText}

        {warnText && (
          <Stack direction="row" gap="8px">
            <IIcon />
            <Stack>
              {warnText.map((warn) => (
                <Typography
                  key={warn}
                  component="span"
                  variant="body2"
                  color="custom.textDarkBlue"
                >
                  {warn}
                </Typography>
              ))}
            </Stack>
          </Stack>
        )}

        {children}

        <Stack direction="row" gap="8px">
          <CustomButton
            onClick={onCancel || onClose}
            variant="contained"
            textColor="#4C545B"
            fullWidth
            type="button"
            customColor="#F1F1F1"
          >
            {cancelBtnText || "Cancel"}
          </CustomButton>
          {onSubmit && (
            <CustomButton
              disabled={isDisabled}
              onClick={onSubmit}
              variant="contained"
              fullWidth
              type="button"
              customColor={submitBtnColor}
              startIcon={
                isLoading && (
                  <CircularProgress
                    size={24}
                    sx={{ color: isDisabled ? "#757575" : "#FFFFFF" }}
                  />
                )
              }
            >
              {submitBtnText || "Discard"}
            </CustomButton>
          )}
        </Stack>
      </Stack>
    </BaseModal>
  );
};
