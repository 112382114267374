import { Box, CircularProgress, Stack } from "@mui/material";
import { useStateWithSearchParams } from "../hooks/useStateWithSearchParams";
import { useParams } from "react-router-dom";
import { useGetAccountsFiltersQuery, useGetPenetrationViewQuery } from "../api";
import { Order } from "../types";
import { PenetrationRateTable } from "../components/accounts";
import { DropdownWithAccordion } from "../components/ui/dropdown";
import { ResetAllFiltersButton } from "../components/features/ResetAllFiltersButton";
import { prepareFilters } from "../utils/prepareFilters";
import { TopTabs } from "../components/ui/TopTabs";
import { InputWithQuery } from "../components/ui/InputWithQuery";
import React from "react";
import { useNavigateOnNoData } from "../hooks/useNavigateOnNoData";
import { useDebounceValue } from "../hooks/useDebounceValue";

type SearchParams = {
  sizes?: string;
  industries?: string;
  regions?: string;
  search?: string;
  sort?: string;
  page?: string;
  per_page?: string;
  selected?: string;
  limit?: string;
};

export const AccountsPenetrationPage = () => {
  const { campaign_id } = useParams();

  const { searchDict } = useStateWithSearchParams<SearchParams>({
    industries: undefined,
    regions: undefined,
    sizes: undefined,
    search: undefined,
    sort: undefined,
    page: undefined,
    per_page: undefined,
    selected: undefined,
    limit: "10",
  });

  const searchValueDebounce = useDebounceValue(searchDict.search || "");

  const filters = useGetAccountsFiltersQuery(
    {
      campaign_id: campaign_id!,
    },
    { skip: !campaign_id },
  );

  const { data, isFetching } = useGetPenetrationViewQuery(
    {
      campaign_id: campaign_id!,
      limit: searchDict.limit ? Number(searchDict?.limit) : undefined,
      size: searchDict.sizes?.split(","),
      search: searchValueDebounce || undefined,
      industry: searchDict.industries?.split(","),
      region: searchDict.regions?.split(","),
      page: searchDict.page ? Number(searchDict?.page) : undefined,
      per_page: searchDict.per_page ? Number(searchDict?.per_page) : undefined,
      sort: searchDict.sort?.split("-")[0],
      order: searchDict.sort?.split("-")[1] as Order,
    },
    { skip: !campaign_id, refetchOnMountOrArgChange: true },
  );

  const isFilters =
    prepareFilters(filters.data).some(
      ({ query }) => searchDict[query as keyof SearchParams],
    ) || !!searchDict.selected?.split(",");

  useNavigateOnNoData(isFilters || !!searchValueDebounce, isFetching, data);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <TopTabs />
        <Stack direction="row" gap="8px">
          <InputWithQuery />
          <DropdownWithAccordion
            isActive={prepareFilters(filters.data).some(
              ({ query }) => searchDict[query as keyof SearchParams],
            )}
            data={prepareFilters(filters.data)}
            selected={prepareFilters(filters.data).reduce(
              (acc, { query }) =>
                acc +
                (searchDict[query as keyof SearchParams]?.split(",")?.length ||
                  0),
              0,
            )}
          />
          {isFilters && <ResetAllFiltersButton campaign_id={campaign_id!} />}
        </Stack>
      </Stack>

      {isFetching ? (
        <Box
          display="flex"
          height="25vh"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={80} sx={{ color: "#FE8836" }} />
        </Box>
      ) : (
        <PenetrationRateTable data={data || []} />
      )}
    </>
  );
};
