export enum RequestMethods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export enum AppRoutes {
  HOME = "/",
  ACCOUNTS = "/accounts",
  ACCOUNTS_LIST = "/accounts/list",
  ACCOUNTS_RATE = "/accounts/rate",
  ACCOUNTS_SCORE = "/accounts/score",
  NO_DATA = "/no_data",
  PRODUCTS = "/products",
  PRODUCTS_REQUIREMENTS = "/products/requirements",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  VERIFY_CODE = "/verify-code",
  RESET_PASSWORD = "/reset-password",
  PROFILE = "/profile",
}

export enum UploadFlow {
  FILE = "uploaded_from_file",
  HUBSPOT = "hubspot_disconnected",
  SALESFORCE = "salesforce_disconnected",
}
