import { CircularProgress, Stack, Typography } from "@mui/material";
import { CustomButton } from "../ui/CustomButton";
import { NoDataIcon } from "../images";
import React, { ReactNode } from "react";

type Props = {
  title?: string;
  text?: string;
  btnText?: string;
  maxWidth?: string;
  icon?: ReactNode;
  btnHandler: () => void;
  isLoading?: boolean;
};

export const NoDataBlock = ({
  title = "No insights data available",
  text = "Gain new buying committee insights with external data request",
  icon = <NoDataIcon />,
  btnText = "Get external data",
  btnHandler,
  maxWidth = "328px",
  isLoading,
}: Props) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Stack gap="32px" alignItems="center" maxWidth={maxWidth}>
        {icon}
        <Stack gap="8px" alignItems="center">
          <Typography
            variant="h1"
            color="custom.textDarkBlue"
            textAlign="center"
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            textAlign="center"
          >
            {text}
          </Typography>
        </Stack>
        <CustomButton
          onClick={btnHandler}
          customSize="fit"
          type="submit"
          customColor="#F16300"
          customGradientColor="#FE8836"
          disabled={isLoading}
          startIcon={
            isLoading && <CircularProgress size={20} color="inherit" />
          }
        >
          {btnText}
        </CustomButton>
      </Stack>
    </Stack>
  );
};
