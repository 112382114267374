import { IconProps } from "../../types/genereal";
import { IconWrapper } from "components/ui/IconWrapper";

export const SnowflakeIcon = ({
  width = "10",
  height = "11",
  color = "#1C3C6C",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 10 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.18182 10.3636L4.31818 6.61364L1.14773 8.625L0.329546 7.19318L3.67045 5.45454L0.329546 3.71591L1.14773 2.28409L4.31818 4.29545L4.18182 0.545454H5.81818L5.68182 4.29545L8.85227 2.28409L9.67045 3.71591L6.32955 5.45454L9.67045 7.19318L8.85227 8.625L5.68182 6.61364L5.81818 10.3636H4.18182Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  );
};
