import { IconProps } from "../../types/genereal";
import { IconWrapper } from "components/ui/IconWrapper";

export const RestartIcon = ({
  width = "24",
  height = "24",
  color = "#1D3E70",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2606_16143)">
          <path
            d="M20.9998 10.12H14.2198L16.9598 7.3C14.2298 4.6 9.80976 4.5 7.07976 7.2C6.43116 7.84059 5.91619 8.60358 5.56472 9.44471C5.21325 10.2858 5.03226 11.1884 5.03226 12.1C5.03226 13.0116 5.21325 13.9142 5.56472 14.7553C5.91619 15.5964 6.43116 16.3594 7.07976 17C9.80976 19.7 14.2298 19.7 16.9598 17C18.3198 15.65 18.9998 14.08 18.9998 12.1H20.9998C20.9998 14.08 20.1198 16.65 18.3598 18.39C14.8498 21.87 9.14976 21.87 5.63976 18.39C2.13976 14.92 2.10976 9.28 5.61976 5.81C7.30267 4.14275 9.57581 3.2074 11.9448 3.2074C14.3137 3.2074 16.5868 4.14275 18.2698 5.81L20.9998 3V10.12Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_2606_16143">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </IconWrapper>
  );
};
