import { Button, Chip, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";

type Props = {
  filters: string[];
  label: string;
  removeHandler: (key: string, filter: string) => void;
};

export const SelectedFilterList = ({
  filters,
  removeHandler,
  label,
}: Props) => {
  const [isExtended, setIsExtended] = useState<boolean>(false);

  useEffect(() => {
    if (filters.length === 0) setIsExtended(false);
  }, [filters]);

  if (filters.length === 0) return null;

  return (
    <Stack direction="column" gap="4px" width="fit-content">
      <Typography variant="button" color="text.secondary">
        {label}
      </Typography>
      <Stack direction="row" gap="4px" alignItems="center" flexWrap="wrap">
        {(isExtended ? filters : filters.slice(0, 2)).map((el) => (
          <Chip
            onDelete={() => removeHandler(label.toLowerCase(), el)}
            key={el}
            sx={{
              display: "flex",
              gap: "16px",
              width: "fit-content",
              "& .MuiChip-deleteIcon": {
                color: "#1D3E70",
                "&:hover": {
                  color: "#264a83",
                },
              },
            }}
            label={el}
          />
        ))}

        {filters.length > 2 && (
          <Button
            onClick={() => setIsExtended(!isExtended)}
            variant="text"
            sx={{
              color: "#4C545B",
              textWrap: "nowrap",
              backgroundColor: "transparent",
              marginLeft: "16px",
              "&:hover": {
                color: "#82848d",
              },
            }}
          >
            {isExtended ? "Hide" : "View all"}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
