import { ReactNode } from "react";
import { isTextEllipsed } from "../../utils/general_utils";
import { Tooltip } from "@mui/material";

export const ReuseTooltip = ({
  text,
  children,
}: {
  text: string;
  children: ReactNode;
}) => {
  if (!text) return null;
  return (
    <Tooltip
      title={text}
      placement="bottom"
      arrow
      sx={{
        background: "#596177E5",
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 400,
        "&:hover": {
          visibility: "visible",
          opacity: 1,
        },
      }}
    >
      <div
        onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
          isTextEllipsed(event.currentTarget);
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};
