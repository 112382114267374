import { useEffect, useState } from "react";
import { ReuseModal } from "../ReuseModal";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useExportDataMutation } from "../../../api";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { CustomToast } from "../CustomToast";
import { toast } from "react-toastify";
import { INPUT_REGEX } from "../../../utils/regex";
import { openLinkHandler } from "../../../utils/openLinkHandler";
import { SubBlock } from "./SubBlock";

export const ExportAccountDataModal = ({
  account_id,
  campaign_id,
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  account_id: string;
  campaign_id: string;
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("Export-data");
  const [option, setOption] = useState<string>("All contacts");

  const [exportData, { isLoading, isSuccess, error, data }] =
    useExportDataMutation();

  useEffect(() => {
    if (error) {
      toast.error(
        <CustomToast
          type={"error"}
          text={
            getErrorMessage(error).globalError ||
            "Something went wrong. Please try again"
          }
          title={"Export data failed"}
        />,
      );
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess && data) {
      openLinkHandler(data.url, fileName);
      setIsOpen(false);
    }
  }, [isSuccess]);

  const submitHandler = () => {
    exportData({
      campaign_id,
      file_name: fileName || undefined,
      account_ids: [account_id],
      only_committee: option === "Probable BC members",
    });
  };

  const inputChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsError(!INPUT_REGEX.test(e.target.value));
    setFileName(e.target.value);
  };

  return (
    <ReuseModal
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={() => setIsOpen(false)}
      title="Export data to CSV"
      onSubmit={submitHandler}
      maxWidth="430px"
      submitBtnColor="#1C3C6C"
      submitBtnText="Export"
      isDisabled={isError}
    >
      <Stack gap="16px">
        <SubBlock
          title=""
          text="Which data set of contacts you would like to export?"
        />
        <FormControl sx={{ padding: "0 12px", alignItems: "flex-start" }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue={option}
            value={option}
            name="radio-buttons-group"
            onChange={(e, el) => setOption(el)}
          >
            <FormControlLabel
              value="All contacts"
              control={
                <Radio
                  sx={{
                    color: "#B3B3B3",
                    "&.Mui-checked": {
                      color: "#1C3C6C",
                    },
                    "&.Mui-disabled": {
                      color: "#E3E5E8",
                    },
                    "&.MuiRadio-root:hover": {
                      backgroundColor: "#EDEFF3",
                    },
                  }}
                />
              }
              label="All contacts"
            />
            <FormControlLabel
              value="Probable BC members"
              control={
                <Radio
                  sx={{
                    color: "#B3B3B3",
                    "&.Mui-checked": {
                      color: "#1C3C6C",
                    },
                    "&.Mui-disabled": {
                      color: "#E3E5E8",
                    },
                    "&.MuiRadio-root:hover": {
                      backgroundColor: "#EDEFF3",
                    },
                  }}
                />
              }
              label="Probable BC members"
            />
          </RadioGroup>
        </FormControl>
        <SubBlock title="File name" text="What should be the CSV file name?" />
        <Stack direction="row" gap="8px" alignItems="center" pt="16px">
          <Box
            sx={{
              position: "relative",
              width: "100%",
            }}
          >
            <TextField
              error={isError}
              fullWidth
              label="File name*"
              value={fileName}
              onChange={inputChangeHandler}
              placeholder="Example"
              sx={{
                "& .MuiInputBase-root.Mui-disabled": {
                  pointerEvents: "none",
                  backgroundColor: "#f5f5f5",
                },
              }}
            />
            {isError && (
              <FormHelperText
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "absolute",
                  bottom: -20,
                  left: 0,
                  width: "100%",
                  fontSize: "12px",
                  lineHeight: "20px",
                  color: isError ? "#D32F2F" : "#00000099",
                }}
              >
                {`Only letters, numbers, and symbols except < > : " / \ | ? *.`}
              </FormHelperText>
            )}
          </Box>

          <Typography
            component="span"
            variant="body1"
            sx={{ fontWeight: 500, lineHeight: "26px" }}
            color="#4C545B"
          >
            .csv
          </Typography>
        </Stack>
      </Stack>
    </ReuseModal>
  );
};
