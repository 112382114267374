import { Stack, Typography } from "@mui/material";
import { NotesIcon, PenIcon } from "../images";
import { formatDateRange } from "../../utils/formatDateRange";
import React, { useEffect, useState } from "react";
import { ReuseInput, ReuseModal } from "../ui";
import { toast } from "react-toastify";
import { CustomToast } from "../ui/CustomToast";
import { useUpdateNotesMutation } from "../../api";
import { NO_SCRIPT_REGEX } from "../../utils/regex";

type Fields = {
  notes: string;
};

export const ProductNotes = ({
  campaign_id,
  notes,
  last_updated,
  successHandler,
}: {
  campaign_id: string;
  notes: string;
  last_updated: string;
  successHandler: () => void;
}) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const [formData, setFormData] = useState<Fields>({
    notes: notes,
  });

  const [isError, setIsError] = useState({
    notes: false,
  });

  const [updateNotes, { isSuccess, error, isLoading }] =
    useUpdateNotesMutation();

  useEffect(() => {
    if (error) {
      toast.error(
        <CustomToast
          type={"error"}
          text={
            "Something went wrong during discard the campaign. Please try again"
          }
          title={"Changes are not saved"}
        />,
      );
      setIsOpenEditModal(false);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        <CustomToast
          type={"success"}
          text={"New notes about the product are saved successfully"}
          title={"Сhanges are saved"}
        />,
      );
      setIsOpenEditModal(false);
      successHandler();
    }
  }, [isSuccess]);

  const submitHandler = () => {
    updateNotes({ campaign_id, notes: formData.notes.trim() });
  };

  const regex: { [key: string]: RegExp } = {
    notes: NO_SCRIPT_REGEX,
  };

  const inputChangeHandler = (field: string, value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
    setIsError((prev) => ({
      ...prev,
      [field]: regex[field] ? !regex[field].test(value) : false,
    }));
  };

  const setErrorHandler = (field: string, value: boolean) => {
    setIsError((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Stack
      borderRadius="6px"
      border="1px solid #9FBDD899"
      bgcolor="#F7F9FD"
      padding="16px"
      gap="8px"
      justifyContent="space-between"
      maxWidth="696px"
    >
      <Stack flexDirection="row" justifyContent="space-between">
        <Stack flexDirection="row" gap="8px">
          <NotesIcon />
          <Typography
            variant="subtitle1"
            color="custom.textDarkBlue"
            fontWeight={500}
          >
            Notes
          </Typography>
        </Stack>
        <Stack
          flexDirection="row"
          gap="6px"
          sx={{ cursor: "pointer" }}
          onClick={() => setIsOpenEditModal(true)}
        >
          <PenIcon />
          <Typography variant="subtitle1" color="#1D3E70" fontWeight={500}>
            Edit
          </Typography>
        </Stack>
      </Stack>

      <Stack gap="16px">
        <Typography
          variant="subtitle1"
          color="#4C545B"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {notes || "No additional data yet"}
        </Typography>
        {notes && last_updated && (
          <Typography variant="body1" color="custom.textDarkBlue">
            Last update: {formatDateRange(last_updated, true)}
          </Typography>
        )}
      </Stack>
      <ReuseModal
        maxWidth="540px"
        isOpen={isOpenEditModal}
        onClose={() => {
          setIsOpenEditModal(false);
        }}
        onCancel={() => {
          setIsOpenEditModal(false);
          setFormData({ notes });
        }}
        onSubmit={submitHandler}
        title="Notes"
        text="Add notes for the product to keep the information up to date without influencing
         the analysis-sensitive data."
        submitBtnText="Save"
        submitBtnColor="#1C3C6C"
        isDisabled={
          (isError.notes && formData.notes?.length > 0) ||
          isLoading ||
          formData.notes === notes
        }
        isLoading={isLoading}
      >
        <ReuseInput
          name="notes"
          handleInputChange={inputChangeHandler}
          errorMessage="Notes cannot contain scripts."
          value={formData.notes}
          label="Notes"
          maxLength={3000}
          placeholder=""
          multiline={true}
          isError={isError.notes && formData.notes?.length > 0}
          setErrorHandler={setErrorHandler}
        />
      </ReuseModal>
    </Stack>
  );
};
