import {
  Modal,
  Box,
  IconButton,
  Typography,
  Fade,
  SxProps,
  Theme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Stack } from "@mui/system";
import { ReactNode, useEffect, useRef, useState } from "react";

interface BaseModalProps {
  open: boolean;
  onClose: () => void;
  onBack?: () => void;
  onBackText?: string;
  title: string;
  icon?: ReactNode;
  children?: ReactNode;
  width?: { xs?: string | number; sm?: string | number; md: string | number };
  sx?: SxProps<Theme>;
  withMaxHeight?: boolean;
  btnBlock?: ReactNode;
}

export const BaseModal: React.FC<BaseModalProps> = ({
  open,
  onClose,
  onBack,
  onBackText,
  title,
  icon,
  children,
  width,
  sx,
  btnBlock,
}) => {
  const boxRef = useRef(null);
  const [hasScrollbar, setHasScrollbar] = useState(false);

  useEffect(() => {
    const checkScrollbar = () => {
      if (boxRef.current) {
        const { scrollHeight, clientHeight } = boxRef.current;
        setHasScrollbar(scrollHeight > clientHeight);
      }
    };

    checkScrollbar();
    window.addEventListener("resize", checkScrollbar);

    return () => window.removeEventListener("resize", checkScrollbar);
  }, [children]); // Re-run when children change

  return (
    <Modal open={open} onClose={onClose}>
      <Fade in={open} timeout={300} easing="exit">
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: width,
            border: "1px solid var(--gray, #D9D9D9)",
            bgcolor: "white",
            boxShadow: "0px 6px 18.1px 0px #0000000D",
            borderRadius: "8px",

            gap: 4,
            p: 4,
            ...sx,
          }}
        >
          <Box
            sx={{
              height: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {onBack && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton size="small" onClick={onBack}>
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <Typography variant="body1">{onBackText}</Typography>
              </Box>
            )}
            <IconButton
              onClick={onClose}
              size="small"
              sx={{ ml: "auto", color: "#828282" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box display="flex" alignItems="center" gap={1}>
            {icon && <Stack sx={{ justifyContent: "center" }}>{icon}</Stack>}
            <Typography
              variant="h4"
              component="h4"
              sx={{
                textAlign: "left",
                color: "custom.textDarkBlue",
                fontWeight: 500,
              }}
            >
              {title}
            </Typography>
          </Box>

          <Box
            ref={boxRef}
            sx={{
              mt: 1,
              pr: hasScrollbar ? "8px" : 0,
              maxHeight: "calc(100vh - 210px)",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                width: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#aaa",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "12px",
                backgroundColor: "transparent",
              },
            }}
          >
            {children}
          </Box>
          {btnBlock}
        </Box>
      </Fade>
    </Modal>
  );
};
