import { DefaultProfileImageIcon } from "../images";

type Props = {
  avatar_url?: string | null;
  height?: string;
  width?: string;
};

export const ProfileAvatar = ({
  avatar_url,
  width = "52px",
  height = "52px",
}: Props) => {
  return avatar_url ? (
    <img
      src={avatar_url}
      alt="Profile image"
      width={width}
      height={height}
      style={{ borderRadius: "50%" }}
    />
  ) : (
    <DefaultProfileImageIcon width={width} height={height} />
  );
};
