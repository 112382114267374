export type PaginationList<T> = {
  count: number;
  total_pages: number;
  items: T[];
};

export type AccountListItem = {
  id: string;
  index: number;
  company: string;
  industry: string;
  region: string;
  status: string;
  progress: number;
  penetration_score: number | null;
  total_engagement_score: number | null;
  employees_min: number;
  employees_max: number;
  size: string;
  is_new: boolean;
  total_user_data: number;
  total_enriched_data: number;
};

export type AccountList = PaginationList<AccountListItem>;

export enum Order {
  ASC = "asc",
  DESC = "desc",
}

export enum AccountSort {
  INDEX = "index",
  SIZE = "size",
  REGION = "region",
  ENGAGEMENT_SCORE = "engagement_score",
  PENETRATION_SCORE = "penetration_score",
  CREATED_AT = "created_at",
}

export type Query = {
  campaign_id?: string;
  page?: number;
  per_page?: number;
  sort?: string;
  order?: Order;
  search?: string;
  industry?: string[];
  region?: string[];
  size?: string[];
  limit?: number;
  start_date?: string;
  end_date?: string;
};

export type CampaignId = { campaign_id: string };

export type ListQuery = Query & CampaignId;

export type AccountFilters = {
  industries: string[];
  regions: string[];
  sizes: string[];
};

export type Penetration = {
  index: number;
  id: string;
  name: string;
  normalized_name: string;
  industry: string;
  region: string;
  penetration_score: number;
  total_engagement_score: number;
  region_code: string;
};

export type AnalyseList = {
  account_ids: string[];
};

export type Engagement = {
  index: number;
  id: string;
  name: string;
  normalized_name: string;
  penetration_score: number;
  total_engagement_score: number;
  total_calls: number;
  total_calls_responded: number;
  total_emails: number;
  total_emails_responded: number;
};

export type ActionType = "create" | "update" | "add-contacts" | "";
