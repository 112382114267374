import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const ChevronLeftIcon = ({
  width = "24",
  height = "24",
  color = "#1F2932",
  opacity = "0.6",
  rotate = "0",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height} rotate={rotate}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.7051 16.59L11.1251 12L15.7051 7.41L14.2951 6L8.29508 12L14.2951 18L15.7051 16.59Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    </IconWrapper>
  );
};
