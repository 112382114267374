import { CustomButton } from "./CustomButton";
import { Stack } from "@mui/material";
import { useStateWithSearchParams } from "../../hooks/useStateWithSearchParams";

type SearchParams = {
  limit?: string;
};

export const TopTabs = () => {
  const { searchDict, setSearchValue } = useStateWithSearchParams<SearchParams>(
    {
      limit: "10",
    }
  );

  return (
    <Stack direction="row" gap="8px">
      {[10, 50, 100].map((el) => (
        <CustomButton
          key={el}
          onClick={() => setSearchValue("limit", String(el))}
          variant="text"
          type="button"
          sx={{
            gap: 0,
            textWrap: "nowrap",
            color: `${
              Number(searchDict?.limit) === el ? "#1C3C6C" : "#4C545B"
            }`,
            cursor: `${Number(searchDict?.limit) === el ? "auto" : "pointer"}`,
            backgroundColor: `${
              Number(searchDict?.limit) === el ? "#F1F1F1" : "transparent"
            }`,
            "&:hover": {
              color: "#1C3C6C",
              backgroundColor: "#F1F1F1",
            },
          }}
          customSize="fit"
        >
          Top {el}
        </CustomButton>
      ))}
    </Stack>
  );
};
