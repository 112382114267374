import { useEffect } from "react";
import {
  useLazyGetUrlForMicrosoftLoginQuery,
  useLoginWithMicrosoftMutation,
} from "api";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "hooks/useReduxHooks";
import { setAuth } from "features/authSlice";
import { CustomButton } from "./CustomButton";
import { MicrosoftIcon } from "../images";
import { AppRoutes } from "../../types/enums";
import { openLinkHandler } from "../../utils/openLinkHandler";
import { CircularProgress } from "@mui/material";
import { getErrorMessage } from "../../utils/getErrorMessage";
import { toast } from "react-toastify";
import { CustomToast } from "./CustomToast";

export const LoginWithMicrosoftBtn = () => {
  const dispatch = useAppDispatch();
  const [
    getUrlForMicrosoftLogin,
    { data, isSuccess: getUrlSuccess, isLoading: isGetUrlLoading },
  ] = useLazyGetUrlForMicrosoftLoginQuery();

  const [
    loginWithMicrosoft,
    { isLoading, error: loginError, isSuccess, data: loginWithMicrosoftData },
  ] = useLoginWithMicrosoftMutation();

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (code && state) {
      const csrf_token = JSON.parse(atob(state || ""))?.csrf_token;

      csrf_token && loginWithMicrosoft({ code, csrf_token });
    }
  }, []);

  useEffect(() => {
    if (getUrlSuccess && data) {
      openLinkHandler(data.url);
    }
  }, [getUrlSuccess]);

  useEffect(() => {
    if (loginError) {
      toast.error(
        <CustomToast
          type={"error"}
          text={
            getErrorMessage(loginError).globalError ||
            `Something went wrong. Please try again`
          }
          title={"Login failed"}
        />,
      );
    }
  }, [loginError]);

  useEffect(() => {
    if (isSuccess) {
      navigate(AppRoutes.PRODUCTS);
      loginWithMicrosoftData &&
        dispatch(
          setAuth({
            accessToken: loginWithMicrosoftData.access_token,
            refreshToken: loginWithMicrosoftData.refresh_token,
          }),
        );
    }
  }, [isSuccess, navigate]);

  return (
    <CustomButton
      onClick={() => getUrlForMicrosoftLogin()}
      variant="outlined"
      customColor="#FFFFFF"
      textColor="#1D3E70"
      fullWidth={true}
      type="button"
      startIcon={
        isLoading || isGetUrlLoading ? (
          <CircularProgress size={24} sx={{ color: "#1D3E70" }} />
        ) : (
          <MicrosoftIcon />
        )
      }
      sx={{ height: "48px", borderColor: "#1D3E70" }}
    >
      Sign in with Microsoft
    </CustomButton>
  );
};
