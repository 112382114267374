import { IconProps } from "../../types/genereal";

export const MoreVertIcon = ({
  width = "4",
  height = "18",
  color = "#1C3C6C",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 4 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99906 2.92C2.25367 2.92 2.49785 2.81886 2.67789 2.63882C2.85792 2.45879 2.95906 2.21461 2.95906 1.96C2.95906 1.70539 2.85792 1.46121 2.67789 1.28118C2.49785 1.10114 2.25367 1 1.99906 1C1.74445 1 1.50027 1.10114 1.32024 1.28118C1.14021 1.46121 1.03906 1.70539 1.03906 1.96C1.03906 2.21461 1.14021 2.45879 1.32024 2.63882C1.50027 2.81886 1.74445 2.92 1.99906 2.92ZM1.99906 9.96C2.25367 9.96 2.49785 9.85886 2.67789 9.67882C2.85792 9.49879 2.95906 9.25461 2.95906 9C2.95906 8.74539 2.85792 8.50121 2.67789 8.32118C2.49785 8.14114 2.25367 8.04 1.99906 8.04C1.74445 8.04 1.50027 8.14114 1.32024 8.32118C1.14021 8.50121 1.03906 8.74539 1.03906 9C1.03906 9.25461 1.14021 9.49879 1.32024 9.67882C1.50027 9.85886 1.74445 9.96 1.99906 9.96ZM1.99906 17C2.25367 17 2.49785 16.8989 2.67789 16.7188C2.85792 16.5388 2.95906 16.2946 2.95906 16.04C2.95906 15.7854 2.85792 15.5412 2.67789 15.3612C2.49785 15.1811 2.25367 15.08 1.99906 15.08C1.74445 15.08 1.50027 15.1811 1.32024 15.3612C1.14021 15.5412 1.03906 15.7854 1.03906 16.04C1.03906 16.2946 1.14021 16.5388 1.32024 16.7188C1.50027 16.8989 1.74445 17 1.99906 17Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
