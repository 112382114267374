import { CustomButton } from "./CustomButton";
import { useCallback, useEffect, useState } from "react";
import { ReuseModal } from "./ReuseModal";
import { UploadFromDesktop } from "./upload-data";
import { useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { UploadFromHubSpot } from "./upload-data/UploadFromHubSpot";
import { UploadFromSalesforce } from "./upload-data/UploadFromSalesforce";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { setUpdate } from "../../features/companySlice";

type Props = {
  importFlow?: string;
  shouldBeOpen?: boolean;
  onSuccess?: () => void;
};

export const UpdateDataBtn = ({
  importFlow,
  shouldBeOpen = false,
  onSuccess,
}: Props) => {
  const { campaign_id } = useParams();
  const dispatch = useAppDispatch();
  const campaignId = useAppSelector((state) => state.company.company);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (shouldBeOpen) setIsOpen(true);
  }, [shouldBeOpen]);

  const successHandler = useCallback(() => {
    setIsOpen(false);
    dispatch(setUpdate(campaign_id ? "list" : "account"));
    onSuccess && onSuccess();
    setIsLoading(false);
  }, [campaign_id]);

  return (
    <>
      <CustomButton
        onClick={() => setIsOpen(true)}
        customSize="fit"
        customColor="#1C3C6C"
        sx={{ height: "42px", minWidth: "160px" }}
        startIcon={
          isLoading && <CircularProgress size={20} sx={{ color: "#757575" }} />
        }
        disabled={isLoading}
      >
        Update your data
      </CustomButton>

      <ReuseModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Update data"
        text="Update companies and contacts data to keep track of the recent records and communication updates"
        warnText={["We recommend running an update at least once a week"]}
        maxWidth="430px"
        isDisabled={isLoading}
      >
        <Stack gap="8px">
          <UploadFromHubSpot
            startFlow={importFlow === "hubspot"}
            campaign_id={campaign_id || campaignId}
            onSuccess={successHandler}
            setIsLoading={setIsLoading}
            disabled={isLoading}
          />
          <UploadFromSalesforce
            startFlow={importFlow === "salesforce"}
            campaign_id={campaign_id || campaignId}
            onSuccess={successHandler}
            setIsLoading={setIsLoading}
            disabled={isLoading}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            <Divider color="#CFCFCF" sx={{ flexGrow: 1 }} />
            <Typography
              variant="button"
              sx={{
                color: "#4C545B",
              }}
            >
              or
            </Typography>
            <Divider color="#CFCFCF" sx={{ flexGrow: 1 }} />
          </Box>
          <Typography
            variant="body2"
            color="custom.textDarkBlue"
            textAlign="center"
          >
            Max file size: <strong>10MB</strong>. Preferred format:{" "}
            <strong>.csv</strong> or <strong>Excel file</strong>
          </Typography>
          <UploadFromDesktop
            setIsLoading={setIsLoading}
            campaign_id={campaign_id || campaignId}
            onSuccess={successHandler}
            disabled={isLoading}
          />
        </Stack>
      </ReuseModal>
    </>
  );
};
