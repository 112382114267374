import { CustomButton } from "../ui/CustomButton";
import { AppRoutes } from "../../types/enums";
import { useLocation, useNavigate } from "react-router-dom";

export const ResetAllFiltersButton = ({
  campaign_id,
}: {
  campaign_id: string;
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const resetAllFilters = () => {
    navigate(`${AppRoutes.ACCOUNTS}/${pathname.split("/")[2]}/${campaign_id}`, {
      replace: true,
    });
  };

  return (
    <CustomButton
      onClick={resetAllFilters}
      variant="text"
      type="button"
      sx={{
        textWrap: "nowrap",
        color: "#1C3C6C",
        textDecoration: "underline",
        backgroundColor: "transparent",
        "&:hover": {
          color: "#305896",
          textDecoration: "underline",
          backgroundColor: "transparent",
        },
      }}
    >
      Reset all
    </CustomButton>
  );
};
