import { IconProps } from "../../types/genereal";

export const ToastErrorIcon = ({
  width = "40",
  height = "40",
  color = "#D32F2F",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.333 24.9997H21.6663V28.333H18.333V24.9997ZM18.333 11.6663H21.6663V21.6663H18.333V11.6663ZM19.983 3.33301C10.783 3.33301 3.33301 10.7997 3.33301 19.9997C3.33301 29.1997 10.783 36.6663 19.983 36.6663C29.1997 36.6663 36.6663 29.1997 36.6663 19.9997C36.6663 10.7997 29.1997 3.33301 19.983 3.33301ZM19.9997 33.333C12.633 33.333 6.66634 27.3663 6.66634 19.9997C6.66634 12.633 12.633 6.66634 19.9997 6.66634C27.3663 6.66634 33.333 12.633 33.333 19.9997C33.333 27.3663 27.3663 33.333 19.9997 33.333Z"
        fill={color}
      />
    </svg>
  );
};
