import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { SseProgress } from "../ui/SseProgress";
import { useEffect, useState } from "react";
import {
  useGenerateCampaignMutation,
  useGetGenerateCampaignResultQuery,
} from "../../api/companyApi";
import { RestartIcon } from "../images";
import { Fields } from "./ProductDetailsModal";

type Props = {
  pdfFile: File | null;
  productUrl: string;
  campaign_id: string;
  setResultHandler: (result: Fields) => void;
};

export const AnalyzingProductBlock = ({
  pdfFile,
  campaign_id,
  setResultHandler,
  productUrl,
}: Props) => {
  const [getResult, setGetResult] = useState<boolean>(false);
  const [startAnalyze, setStartAnalyze] = useState<boolean>(false);

  const [generateCampaign, { data: generateData }] =
    useGenerateCampaignMutation();

  const { data: generateCampaignResult } = useGetGenerateCampaignResultQuery(
    generateData?.task_id || "",
    {
      skip: !getResult || !generateData?.task_id,
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (startAnalyze) {
      generateCampaign({
        url: productUrl || undefined,
        pdf_file: pdfFile || undefined,
      });
    }
  }, [startAnalyze, campaign_id]);

  useEffect(() => {
    if (generateCampaignResult) {
      setResultHandler(generateCampaignResult);
      setStartAnalyze(false);
      setGetResult(false);
    }
  }, [generateCampaignResult]);

  return startAnalyze ? (
    <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
      <Stack direction="row" gap="8px">
        <Typography
          color="#1D3E70"
          sx={{
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          Analyzing the product:{" "}
        </Typography>
        <Typography
          color="#1D3E70"
          sx={{
            minWidth: "30px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {generateData ? (
            <SseProgress
              task_id={generateData.task_id}
              setGetResult={setGetResult}
              callBack={() => {}}
            />
          ) : (
            "0%"
          )}
        </Typography>
        <CircularProgress size={24} sx={{ color: "custom.subTitleModal" }} />
      </Stack>
    </Box>
  ) : (
    <Stack
      direction="row"
      sx={{ cursor: "pointer" }}
      gap="4px"
      onClick={() => setStartAnalyze(true)}
    >
      <RestartIcon />
      <Typography
        color="#1D3E70"
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        Restart analysis
      </Typography>
    </Stack>
  );
};
