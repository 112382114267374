import { LinearProgress } from "@mui/material";

type Props = {
  value: number;
  color: string;
  zIndex: number;
};

export const ProgressBar = ({ value, color, zIndex }: Props) => {
  return (
    <LinearProgress
      variant="determinate"
      value={value}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: zIndex,
        height: "48px",
        width: "100%",
        backgroundColor: "transparent",
        "& .MuiLinearProgress-bar": {
          backgroundColor: color,
        },
      }}
    />
  );
};
