export const URL_REGEX =
  /^(https?:\/\/|www\.)[a-zA-Z0-9-]+\.[a-zA-Z]{2,}([\/#?]?.*)$/;

export const INPUT_REGEX = /^[^<>:"/\\|?*\u0000-\u001F]+$/;
export const NAME_REGEX = /^[a-zA-Z0-9\u0080-\u024F\s.,\-\'&]+$/;
export const USER_NAME_REGEX = /^[\p{L} -]+$/u;
export const NO_SCRIPT_REGEX = /^(?!.*<[^>]+>)[^<]*$/;
export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>\-_+=\/\[\]';~])[A-Za-z\d!@#$%^&*(),.?":{}|<>\-_+=\/\[\]';~]{8,32}$/;
