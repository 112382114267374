import { useCallback, memo, ReactNode } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Tooltip,
  Box,
  Typography,
  Checkbox,
  Stack,
} from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import { CampaignMember } from "types/companyTypes";

import { tableSortLabelClasses } from "@mui/material/TableSortLabel";
import { StarFourPoints } from "components/images/StarImg";
import { isTextEllipsed } from "utils/general_utils";
import { EllipsisTooltip } from "./CustomTooltip";
import { DataPlusLine } from "components/images/DataPlusImg";
import { DataPersonImg } from "components/images/DataPersonImg";
import { BigDataOutlined } from "components/images/DataBigImg";
import { Column } from "types/genereal";
import { AlertInCircleIcon } from "../images";
import { IconWrapper } from "./IconWrapper";

type SelectionType = "committee" | "cluster";
type SelectedMembers = { committee: Set<string>; cluster: Set<string> };

interface SelectionChanges {
  committee: {
    include: Array<string>;
    exclude: Array<string>;
    isBackendChange?: boolean;
  };
  cluster: {
    include: Array<string>;
    exclude: Array<string>;
    isBackendChange?: boolean;
  };
}
interface DataTableProps {
  columns: Column[];
  rows: CampaignMember[];
  totalRows: number;
  page: number;
  rowsPerPage: number;
  sortBy: string;
  order: "asc" | "desc";
  onQueryChange: (query: {
    page: number;
    per_page: number;
    sort: string;
    order?: "asc" | "desc";
  }) => void;
  isEditMode?: boolean;
  onSelectionChange?: (changes: SelectionChanges) => void;
  selectedMembers: SelectedMembers;
  selectionStats: {
    committee: { selected: number; expected: number };
    cluster: { selected: number; expected: number };
  };
  selectedChanges: {
    committee: {
      include: string[];
      exclude: string[];
    };
    cluster: {
      include: string[];
      exclude: string[];
    };
  };
}

const UserWithPanTooltip = () => {
  return (
    <Tooltip
      title={"You marked this member"}
      placement="bottom"
      arrow
      sx={{
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: 400,
        "&:hover": {
          visibility: "visible",
          opacity: 1,
        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
          const target = event.currentTarget;
          isTextEllipsed(target);
        }}
      >
        <Typography
          component="span"
          variant="body2"
          color="#B3BECE"
          noWrap={true}
          width="38px"
        >
          Edited
        </Typography>
      </Stack>
    </Tooltip>
  );
};

export const DataTable = memo(
  ({
    columns,
    rows,
    totalRows,
    page,
    rowsPerPage,
    sortBy,
    order,
    onQueryChange,
    isEditMode = false,
    onSelectionChange,
    selectedMembers,
    selectionStats,
  }: DataTableProps) => {
    const isSelected = useCallback(
      (id: string, type: string) => {
        return selectedMembers[type as keyof SelectedMembers].has(id);
      },
      [selectedMembers],
    );

    const isDisabled = useCallback(
      (id: string, type: string) => {
        if (type === "committee") return false;

        return !selectedMembers.committee.has(id);
      },
      [selectionStats, selectedMembers],
    );

    const handleSelectionChange = useCallback(
      (rowId: string, type: SelectionType) => {
        if (!onSelectionChange) return;

        const isCurrentlySelected = selectedMembers[type].has(rowId);

        if (!isCurrentlySelected && isDisabled(rowId, type)) {
          return;
        }

        const changes: SelectionChanges = {
          committee: { include: [], exclude: [], isBackendChange: true },
          cluster: { include: [], exclude: [], isBackendChange: true },
        };

        changes[type] = isCurrentlySelected
          ? { include: [], exclude: [rowId], isBackendChange: true }
          : { include: [rowId], exclude: [], isBackendChange: true };

        if (type === "committee" && isCurrentlySelected) {
          changes.cluster = {
            include: [],
            exclude: [rowId],
            isBackendChange: true,
          };
        }

        onSelectionChange(changes);
      },
      [selectedMembers, onSelectionChange, isDisabled],
    );

    const handleChangePage = (_: unknown, newPage: number) => {
      onQueryChange({
        page: newPage,
        per_page: rowsPerPage,
        sort: sortBy,
        order,
      });
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      onQueryChange({
        page: 0,
        per_page: Number(event.target.value) || 25,
        sort: sortBy,
        order,
      });
    };

    const handleSort = (columnId: string) => {
      const isAsc = sortBy === columnId && order === "desc";
      const newOrder = isAsc ? "asc" : "desc";
      onQueryChange({
        page,
        per_page: rowsPerPage,
        sort: columnId,
        order: newOrder,
      });
    };

    const DataSourceCell = (row: CampaignMember) => {
      const iconMap: Record<string, JSX.Element> = {
        "Your data": <BigDataOutlined />,
        "INFUSE data": <DataPersonImg />,
        "External data": <DataPlusLine />,
      };
      return (
        <EllipsisTooltip tooltip={row.data_source} align="center">
          {iconMap[row.data_source] || <DataPlusLine />}
        </EllipsisTooltip>
      );
    };

    const TextCell = (row: CampaignMember, field: string) => {
      return (
        <Stack
          justifyContent="center"
          alignItems="flex-start"
          paddingLeft="16px"
        >
          <EllipsisTooltip>
            {row[field as keyof CampaignMember]}
          </EllipsisTooltip>
        </Stack>
      );
    };

    const FullNameCell = (row: CampaignMember, field: string) => {
      return (
        <Stack
          flexDirection="row"
          gap="4px"
          justifyContent="space-between"
          alignItems="center"
          paddingLeft="16px"
        >
          <EllipsisTooltip>
            {row[field as keyof CampaignMember]}
          </EllipsisTooltip>
          {row.warning_tooltip && (
            <EllipsisTooltip tooltip={row.warning_tooltip}>
              <AlertInCircleIcon />
            </EllipsisTooltip>
          )}
        </Stack>
      );
    };

    const ProbabilityCell = (row: CampaignMember, field: string) => {
      const type = field.split("_")[0] as SelectionType;
      if (isEditMode) {
        const currentlySelected = isSelected(row.id, type);
        const disabled = isDisabled(row.id, type);

        return (
          <Stack
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            paddingRight="16px"
          >
            {["manually_included", "manually_excluded"].includes(
              row[`${type}_status`],
            ) && <UserWithPanTooltip />}
            <Checkbox
              checked={currentlySelected}
              onChange={() => handleSelectionChange(row.id, type)}
              disabled={disabled}
              color="primary"
              checkedIcon={<StarFourPoints />}
              sx={{
                color: "#1C3C6C",
                "&.Mui-checked": {
                  color: "#1C3C6C",
                },
              }}
            />
            <span
              style={{
                minWidth: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {row[`${type}_probability`] !== null &&
                `${row[`${type}_probability`]}%`}
            </span>
          </Stack>
        );
      }

      const highlight = ["ai_included", "manually_included"].includes(
        row[`${type}_status`],
      );

      return (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          gap="8px"
          paddingRight="16px"
        >
          {["manually_included", "manually_excluded"].includes(
            row[`${type}_status`],
          ) && <UserWithPanTooltip />}

          {highlight && !isEditMode && (
            <EllipsisTooltip
              tooltip={"High membership probability within expected size"}
            >
              <IconWrapper width="24px" height="24px">
                <StarFourPoints />
              </IconWrapper>
            </EllipsisTooltip>
          )}
          {
            <span
              style={{
                minWidth: "26px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {row[`${type}_probability`] !== null &&
                `${row[`${type}_probability`]}%`}
            </span>
          }
        </Stack>
      );
    };

    const EngagementScoreCell = (row: CampaignMember) => {
      return (
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          paddingRight="16px"
        >
          {row.engagement_score !== null && `${row.engagement_score}%`}
        </Stack>
      );
    };

    const cells: {
      [key: string]: (row: CampaignMember, field: string) => ReactNode;
    } = {
      data_source: DataSourceCell,
      full_name: FullNameCell,
      job_title: TextCell,
      seniority: TextCell,
      role: TextCell,
      committee_probability: ProbabilityCell,
      cluster_probability: ProbabilityCell,
      engagement_score: EngagementScoreCell,
    };

    return (
      <Box>
        {rows.length <= 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="170px"
            sx={{ borderRadius: 4 }}
          >
            <Typography
              variant="body1"
              sx={{
                color: "#697184",
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              No data available
            </Typography>
          </Box>
        ) : (
          <Box>
            <TableContainer>
              <Table
                sx={{
                  border: "none",
                  background: "white",
                  boxShadow: 0,
                  tableLayout: "fixed",
                }}
              >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => {
                      const isSorted = sortBy === column.id;
                      //@ts-ignore
                      const IconComponent: SvgIconComponent | undefined =
                        isSorted && order === "asc"
                          ? column.iconAsc
                          : column.label === "Source"
                            ? column.iconDesc
                            : column.iconAsc;

                      return (
                        <TableCell
                          key={column.id}
                          sx={{
                            width: column.minWidth,
                            padding: "16px",
                            fontSize: "14px",
                            fontWeight: 500,
                            lineHeight: "26px",
                            letterSpacing: "0.25px",
                            color: "#1F2932",
                            textAlign: "left",
                          }}
                        >
                          {column.sortable ? (
                            <TableSortLabel
                              active={isSorted}
                              direction={isSorted ? order : "desc"}
                              onClick={() => handleSort(column.id)}
                              hideSortIcon={!IconComponent}
                              IconComponent={IconComponent || (() => null)}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "1px",
                                width: "100%",
                                [`.${tableSortLabelClasses.icon}`]: {
                                  opacity: isSorted ? 1 : 0.5,
                                  transition: "opacity 0.3s ease",
                                  flexShrink: 0,
                                  marginLeft: 1,
                                },
                              }}
                            >
                              <EllipsisTooltip tooltip={column.tooltip}>
                                {column.label}
                              </EllipsisTooltip>
                            </TableSortLabel>
                          ) : (
                            <EllipsisTooltip tooltip={column.tooltip}>
                              {column.label}
                            </EllipsisTooltip>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.id}
                      style={{
                        backgroundColor:
                          row.data_source === "Your data"
                            ? "#00000000"
                            : "#f4f5f9",
                      }}
                      sx={{
                        borderBottom: "1px solid #0000001F",
                      }}
                    >
                      {columns.map((column) => (
                        <TableCell
                          key={`${row.id}-${column.id}`}
                          style={
                            column.id === "data_source"
                              ? { textAlign: "center", verticalAlign: "middle" }
                              : {}
                          }
                          sx={{
                            width: column.minWidth,
                            height: "56px",
                            paddingTop: 0,
                            paddingBottom: 0,
                            padding: 0,
                            fontSize: "14px",
                            lineHeight: "20px",
                            letterSpacing: "0.3px",
                            fontWeight:
                              ["ai_included", "manually_included"].includes(
                                row.committee_status,
                              ) ||
                              ["ai_included", "manually_included"].includes(
                                row.cluster_status,
                              )
                                ? 550
                                : 400,
                            color: "#1F2932",
                          }}
                        >
                          {cells[`${column.id}`] &&
                            cells[`${column.id}`](row, column.id)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Box>
    );
  },
);
