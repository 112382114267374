import { Box, Stack, Typography } from "@mui/material";
import { AnalyticCard } from "./AnalyticCard";
import { SmallChevronLeftIcon } from "../../images";
import { useEffect, useRef, useState } from "react";
import { Analytics } from "../../../types/apiTypes";
import { CustomButton } from "../../ui/CustomButton";

type Props = {
  type: string;
  high_impact: string[];
  low_representation: string[];
};

const GAP = 5.5;

const groupStringsByLength = (arr: string[], allowedContentWidth: number) => {
  let result = [];
  let tempGroup = [];
  let tempLength = 0;

  for (let str of arr) {
    if (tempLength + str.length > allowedContentWidth) {
      result.push(tempGroup);
      tempGroup = [];
      tempLength = 0;
    }
    tempGroup.push(str);
    tempLength = tempLength + str.length + GAP;
  }

  if (tempGroup.length > 0) {
    result.push(tempGroup);
  }
  return result;
};

const CharWidth = 8;

const Pagination = ({
  data,
  page,
  setPage,
}: {
  data: string[];
  page: number;
  setPage: (page: number) => void;
}) => {
  const [list, setList] = useState<string[][]>([]);

  const [allowedContentWidth, setAllowedContentWidth] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setPage(Math.ceil(list.length / 2));
  }, [list]);

  useEffect(() => {
    setList(
      groupStringsByLength(data, Math.ceil(allowedContentWidth / CharWidth)),
    );
  }, [data, allowedContentWidth]);

  useEffect(() => {
    if (containerRef.current) {
      setAllowedContentWidth(Math.floor(containerRef.current.clientWidth));
    }

    const adjustText = () => {
      if (!containerRef.current) return;
      setAllowedContentWidth(Math.floor(containerRef.current.clientWidth));
    };

    adjustText();
    window.addEventListener("resize", adjustText);
    return () => window.removeEventListener("resize", adjustText);
  }, [containerRef.current]);

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        columnGap: "24px",
        rowGap: "8px",
      }}
      ref={containerRef}
    >
      {list.slice(page * 2, page * 2 + 2).map((item) =>
        item.map((el, ind) => (
          <Typography
            key={el + ind}
            variant="subtitle1"
            color="#363636"
            sx={{ fontWeight: 500 }}
          >
            {el}
          </Typography>
        )),
      )}
    </div>
  );
};

const PaginationGaps = ({
  totalPages,
  setPage,
  page,
}: {
  totalPages: number;
  setPage: (page: number) => void;
  page: number;
}) => {
  return (
    <Stack
      gap="6px"
      alignItems="center"
      flexDirection="row"
      justifyContent="center"
    >
      {new Array(totalPages).fill("").map((_, i) => (
        <div
          onClick={() => {
            if (i === page) return;
            setPage(i);
          }}
          key={i}
          style={{
            cursor: i === page ? "auto" : "pointer",
          }}
        >
          <Box
            sx={{
              width: i === page ? "24px" : "8px",
              cursor: i === page ? "auto" : "pointer",
              backgroundColor: i === page ? "#1B3967" : "#D9D9D9",
              borderRadius: "4px",
              height: "4px",
              "&:hover": {
                backgroundColor: "#1B3967",
              },
            }}
          ></Box>
        </div>
      ))}
    </Stack>
  );
};

const GapsContent = ({ high_impact, low_representation, type }: Props) => {
  const [isRelevant, setIsRelevant] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    setIsRelevant(false);
    setPage(0);
  }, [high_impact, low_representation]);

  if (high_impact.length === 0 && low_representation.length === 0) {
    return (
      <Stack alignItems="center" justifyContent="center" flexGrow={1}>
        <Typography
          variant="subtitle1"
          color="#363636"
          textAlign="center"
          maxWidth="240px"
          fontWeight={500}
        >
          No data yet
        </Typography>
        <Typography
          variant="body1"
          color="#797F84"
          textAlign="center"
          maxWidth="240px"
        >
          Gaps will appear once we’ll have more contacts details
        </Typography>
      </Stack>
    );
  }

  if (high_impact.length > 0 && low_representation.length > 0) {
    return (
      <Stack
        gap="8px"
        justifyContent="space-between"
        flexGrow={1}
        minWidth="300px"
        alignItems="flex-start"
      >
        <Stack gap="8px" width="100%">
          <Stack
            gap="8px"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <Typography variant="body1" color="#1F293299">
              {isRelevant
                ? `High impact ${type} gaps`
                : `Weakly represented relevant ${type}`}
            </Typography>
          </Stack>
          {high_impact.length === 0 || low_representation.length === 0 ? (
            <Pagination
              page={page}
              setPage={setTotalPages}
              data={
                low_representation.length === 0
                  ? high_impact
                  : low_representation
              }
            />
          ) : (
            <Pagination
              page={page}
              setPage={setTotalPages}
              data={isRelevant ? high_impact : low_representation}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          position="relative"
        >
          <Stack
            flexDirection="row"
            gap="8px"
            sx={{ cursor: "pointer" }}
            onClick={() => setIsRelevant(!isRelevant)}
          >
            {!isRelevant && (
              <>
                <SmallChevronLeftIcon color="#4C545B" />
                <Typography variant="body1" color="#4C545B">
                  High impact
                </Typography>
              </>
            )}
          </Stack>
          <Box
            sx={{
              position: "absolute",
              left: 0,
              top: "12px",
              width: "100%",
            }}
          >
            <PaginationGaps
              totalPages={totalPages}
              setPage={setPage}
              page={page}
            />
          </Box>

          <Stack
            flexDirection="row"
            sx={{ cursor: "pointer" }}
            gap="8px"
            onClick={() => setIsRelevant(!isRelevant)}
          >
            {isRelevant && (
              <>
                <Typography variant="body1" color="#4C545B">
                  Relevant
                </Typography>
                <SmallChevronLeftIcon color="#4C545B" rotate="180" />
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      gap="8px"
      justifyContent="space-between"
      flexGrow={1}
      minWidth="300px"
    >
      <Stack gap="8px" width="100%">
        <Stack
          gap="8px"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="body1" color="#1F293299">
            {high_impact.length > 0
              ? `High impact ${type} gaps`
              : `Weakly represented relevant ${type}`}
          </Typography>
        </Stack>
        <Pagination
          page={page}
          setPage={setTotalPages}
          data={high_impact.length > 0 ? high_impact : low_representation}
        />
      </Stack>
      <PaginationGaps totalPages={totalPages} setPage={setPage} page={page} />
    </Stack>
  );
};

const tabs = ["Gaps in BC titles", "Gaps in BC roles"];

export const GapsBlock = ({ analytics }: { analytics: Analytics }) => {
  const { role_gaps, title_gaps } = analytics;
  const [currentOption, setCurrentOption] =
    useState<string>("Gaps in BC titles");

  return (
    <AnalyticCard
      flexGrow={1}
      tooltip="Possible gaps in buying committee roles and titles in available analyzed data. Gaps are identified
       based on product analysis and typical BC members profile"
      header={
        <Stack direction="row" gap="8px">
          {tabs.map((option) => (
            <CustomButton
              key={option}
              onClick={() => setCurrentOption(option)}
              variant="text"
              type="button"
              sx={{
                gap: 0,
                textWrap: "nowrap",
                color: `${currentOption === option ? "#1C3C6C" : "#4C545B"}`,
                cursor: `${currentOption === option ? "auto" : "pointer"}`,
                backgroundColor: `${
                  currentOption === option ? "#F1F1F1" : "transparent"
                }`,
                "&:hover": {
                  color: "#1C3C6C",
                  backgroundColor: "#F1F1F1",
                },
                height: "34px",
              }}
              customSize="fit"
            >
              {option}
            </CustomButton>
          ))}
        </Stack>
      }
      body={
        currentOption === "Gaps in BC titles" ? (
          <GapsContent
            type="titles"
            high_impact={title_gaps.high_impact}
            low_representation={title_gaps.low_representation}
          />
        ) : (
          <GapsContent
            type="roles"
            high_impact={role_gaps.high_impact}
            low_representation={role_gaps.low_representation}
          />
        )
      }
    />
  );
};
