import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { UpdateAccount } from "../ui";
import { ExportAccountDataBtn } from "../ui/export-data";

interface Props {
  name: string;
  size: string;
  contactsImported: string;
  industry: string;
  id: string;
  campaign_id: string;
  refetch: () => void;
  is_analyzed: boolean;
  status: string;
}

export const CompanyInfo = ({
  name,
  size,
  contactsImported,
  industry,
  id,
  campaign_id,
  refetch,
  is_analyzed,
  status,
}: Props) => {
  const details = [
    {
      label: "Company size",
      value: size.trim() !== "-" ? `${size} employees` : size.trim(),
    },
    {
      label: "Contacts imported",
      value:
        contactsImported.trim() !== "-"
          ? `${contactsImported} contacts`
          : contactsImported,
    },
    {
      label: "Industry",
      value: industry,
    },
  ];

  return (
    <Stack gap="24px">
      <Typography
        variant="h1"
        color="custom.textDarkBlue"
        sx={{
          fontSize: "48px",
          lineHeight: "56px",
        }}
      >
        {name}
      </Typography>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap="24px">
          <Divider
            orientation="vertical"
            flexItem
            sx={{ alignSelf: "stretch" }}
          />
          {details.map(({ label, value }, index) => (
            <React.Fragment key={label}>
              <Stack gap="8px">
                <Typography variant="body1" color="#1F293299">
                  {label}
                </Typography>
                <Typography
                  variant="h4"
                  color="custom.textDarkBlue"
                  sx={{ fontWeight: 500 }}
                >
                  {value}
                </Typography>
              </Stack>
              {details.length - 1 !== index && (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ alignSelf: "stretch" }}
                />
              )}
            </React.Fragment>
          ))}
        </Stack>
        <Stack direction="row" gap="8px" alignItems="flex-end">
          <UpdateAccount
            id={id}
            campaign_id={campaign_id}
            companyName={name}
            refetch={refetch}
            status={status}
          />
          <ExportAccountDataBtn
            account_id={id}
            campaign_id={campaign_id}
            is_analyzed={is_analyzed}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
