import { createApi } from "@reduxjs/toolkit/query/react";
import { RequestMethods } from "types/enums";
import { baseQueryWithReauth } from "./baseQuery";
import {
  AccountFilters,
  AccountList,
  ListQuery,
  AnalyseList,
  CampaignId,
  Engagement,
  Penetration,
  Query,
} from "../types";
import {
  ExportData,
  ExportCompany,
  Response,
  UrlResponse,
  Analytics,
  AccountSizes,
} from "../types/apiTypes";

const prepareQuery = (params: Query) => {
  if (!params || Object.keys(params).length === 0) return "";

  const queryList = Object.entries(params).flatMap(([key, value]) =>
    Array.isArray(value)
      ? value.map((el) => `${key}=${encodeURIComponent(el)}`)
      : value !== undefined && value !== null
        ? [`${key}=${encodeURIComponent(value)}`]
        : [],
  );

  return queryList.length ? `?${queryList.join("&")}` : "";
};

export const accountsApi = createApi({
  reducerPath: "accountsApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getAccountsList: builder.query<AccountList, ListQuery>({
      query: ({ campaign_id, ...rest }) => ({
        url: `/campaigns/${campaign_id}/list-view${prepareQuery(rest)}`,
        method: RequestMethods.GET,
      }),
    }),
    getAccountsFilters: builder.query<AccountFilters, ListQuery>({
      query: ({ campaign_id, ...rest }) => ({
        url: `/campaigns/${campaign_id}/filters`,
        method: RequestMethods.GET,
        params: rest,
      }),
    }),
    getPenetrationView: builder.query<Penetration[], ListQuery>({
      query: ({ campaign_id, ...rest }) => ({
        url: `/campaigns/${campaign_id}/penetration-view${prepareQuery(rest)}`,
        method: RequestMethods.GET,
      }),
    }),
    getEngagementView: builder.query<Engagement[], ListQuery>({
      query: ({ campaign_id, ...rest }) => ({
        url: `/campaigns/${campaign_id}/engagement-view${prepareQuery(rest)}`,
        method: RequestMethods.GET,
      }),
    }),
    analyseAll: builder.mutation<Response, CampaignId>({
      query: (body) => ({
        url: "/accounts/analyse-all",
        method: RequestMethods.POST,
        body,
      }),
    }),
    analyse: builder.mutation<Response, AnalyseList>({
      query: (body) => ({
        url: "/accounts/analyse",
        method: RequestMethods.POST,
        body,
      }),
    }),
    discardAccount: builder.mutation<Response, string>({
      query: (account_id: string) => ({
        url: `/accounts/${account_id}`,
        method: RequestMethods.DELETE,
      }),
    }),
    discardCampaign: builder.mutation<Response, string>({
      query: (campaign_id: string) => ({
        url: `/campaigns/${campaign_id}`,
        method: RequestMethods.DELETE,
      }),
    }),
    updateAccount: builder.mutation<Response, string>({
      query: (account_id: string) => ({
        url: `/accounts/${account_id}`,
        method: RequestMethods.POST,
      }),
    }),
    exportData: builder.mutation<UrlResponse, ExportData>({
      query: (body) => ({
        url: `/exports`,
        method: RequestMethods.POST,
        body,
      }),
    }),
    exportList: builder.query<ExportCompany[], ListQuery>({
      query: ({ campaign_id, ...rest }) => ({
        url: `/campaigns/${campaign_id}/export-view${prepareQuery(rest)}`,
        method: RequestMethods.GET,
      }),
    }),
    getAnalytics: builder.query<Analytics, ListQuery>({
      query: ({ campaign_id, ...rest }) => ({
        url: `/campaigns/${campaign_id}/analytics${prepareQuery(rest)}`,
        method: RequestMethods.GET,
      }),
    }),
    updateNotes: builder.mutation<
      Response,
      { campaign_id: string; notes: string }
    >({
      query: ({ campaign_id, notes }) => ({
        url: `/campaigns/${campaign_id}/notes`,
        method: RequestMethods.PATCH,
        body: { notes },
      }),
    }),
    getAccountSize: builder.query<AccountSizes[], void>({
      query: () => ({
        url: "/references/account-sizes",
        method: RequestMethods.GET,
      }),
    }),
  }),
});

export const {
  useGetAccountsListQuery,
  useGetAccountsFiltersQuery,
  useGetPenetrationViewQuery,
  useGetEngagementViewQuery,
  useAnalyseAllMutation,
  useAnalyseMutation,
  useDiscardAccountMutation,
  useDiscardCampaignMutation,
  useUpdateAccountMutation,
  useExportDataMutation,
  useExportListQuery,
  useGetAnalyticsQuery,
  useUpdateNotesMutation,
  useGetAccountSizeQuery,
} = accountsApi;
