import { Stack, Typography } from "@mui/material";
import { QuestionMarkInCircleIcon } from "../images/QuestionMarkInCircleIcon";
import React from "react";
import { ReuseTooltip } from "../ui/ReuseTooltip";
import { Role } from "../../types/apiTypes";
import { EllipsisTooltip } from "../ui/CustomTooltip";

const header = [
  { value: "Role in BC", tooltip: "" },
  {
    value: "Potential titles",
    tooltip:
      "Potential titles are generated based on your product description" +
      " and will be actualized based on accounts analysis results.",
  },
  {
    value: "Decision involvement",
    tooltip:
      "The approximate significance rate of the participant's role in the final purchase decision." +
      " The set of available roles and the significance rate depends on the accounts' employee size.",
  },
];

const RoleList = ({ roles }: { roles: Role[] }) => {
  return (
    <Stack
      gap="24px"
      sx={{
        display: "grid",
        gridTemplateColumns: "1.4fr 2fr 1.4fr",
      }}
    >
      {header.map(({ value, tooltip }, index) => (
        <Stack
          key={value}
          alignItems={header.length - 1 === index ? "flex-end" : "flex-start"}
        >
          <EllipsisTooltip tooltip={tooltip}>
            <Typography variant="body1" color="#1F293299">
              {value}
            </Typography>
          </EllipsisTooltip>
        </Stack>
      ))}
      {roles.map(({ id, label, tooltip, title, weight }) => (
        <React.Fragment key={id}>
          <Stack direction="row" gap="8px" alignItems="center">
            <Typography
              variant="subtitle1"
              color="custom.textDarkBlue"
              sx={{ fontWeight: 500 }}
            >
              {label ?? "--"}
            </Typography>
            <Stack alignItems="flex-start">
              <ReuseTooltip text={tooltip}>
                <QuestionMarkInCircleIcon />
              </ReuseTooltip>
            </Stack>
          </Stack>

          <Typography variant="body1" color="text.secondary">
            {title ?? "--"}
          </Typography>
          <Typography
            variant="subtitle1"
            color="#4C545B"
            align="right"
            sx={{ fontWeight: 500, fontSize: "italic" }}
          >
            {weight ?? "--"}
          </Typography>
        </React.Fragment>
      ))}
    </Stack>
  );
};

export const CommitteMemberRoleBlock = ({ roles }: { roles: Role[] }) => {
  return (
    <Stack gap="32px">
      <Stack gap="16px">
        <Typography
          variant="h4"
          color="custom.textDarkBlue"
          sx={{ fontWeight: 500 }}
        >
          Typical buying committee member by role
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          Suggested titles are based on created product description and will be
          updated based on accounts analysis results
        </Typography>
      </Stack>
      <RoleList roles={roles} />
    </Stack>
  );
};
