import AddIcon from "@mui/icons-material/Add";
import { CustomButton } from "./CustomButton";
import { useAppSelector } from "hooks/useReduxHooks";
import { CircularProgress } from "@mui/material";

interface ImportButtonProps {
  onClick: () => void;
  buttonText: string;
}

const importOptions = ["hubspot", "salesforce"];

export const ImportButton = ({ onClick, buttonText }: ImportButtonProps) => {
  const customSize =
    buttonText === "Add company and contacts data" ? "xl" : "l";

  const integrationData = useAppSelector(
    (state) => state.company.integrationData,
  );
  const createProductAnalyses = useAppSelector(
    (state) => state.company.createProductAnalyses,
  );

  const isLoading =
    importOptions.includes(integrationData?.campaignImportFlow || "") &&
    integrationData?.actionType === "create";

  return (
    <CustomButton
      onClick={onClick}
      customSize={customSize}
      type="submit"
      customColor="#F16300"
      disabled={isLoading || createProductAnalyses}
      customGradientColor="#FE8836"
    >
      {isLoading || createProductAnalyses ? (
        <CircularProgress size={20} color="inherit" />
      ) : (
        <AddIcon fontSize="small" />
      )}

      {buttonText}
    </CustomButton>
  );
};
