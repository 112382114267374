import {
  NoResultFound,
  ProductListItem,
  StartNewCampaignBlock,
} from "../components/products";
import { Stack, Typography } from "@mui/material";
import { InputWithQuery } from "../components/ui/InputWithQuery";
import { useGetCampaignsQuery } from "../api/companyApi";
import { useStateWithSearchParams } from "../hooks/useStateWithSearchParams";
import { CustomLoader } from "../components/ui/CustomLoader";
import { useDebounceValue } from "../hooks/useDebounceValue";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { AppRoutes } from "../types/enums";
import { Integration } from "../components/features/Integration";
import { NoDataBlock } from "../components/features";
import { useAppDispatch, useAppSelector } from "../hooks/useReduxHooks";
import { setUploadModal } from "../features/companySlice";

type SearchParams = {
  search?: string;
};

export const ProductsPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const createProductAnalyses = useAppSelector(
    (state) => state.company.createProductAnalyses,
  );

  const { searchDict } = useStateWithSearchParams<SearchParams>({
    search: undefined,
  });

  const searchValueDebounce = useDebounceValue(searchDict.search || "");

  const { data, isFetching } = useGetCampaignsQuery(
    { search: searchValueDebounce || undefined },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  useEffect(() => {
    if (pathname === AppRoutes.HOME)
      navigate(AppRoutes.PRODUCTS, { replace: true });
  }, [pathname]);

  if (!searchValueDebounce && !isFetching && data?.length === 0) {
    return (
      <Stack
        sx={{ px: { sm: 8, md: 8, lg: 14, xl: 20 }, pt: 6, pb: 6 }}
        gap="24px"
        minHeight="calc(100vh - 74px)"
        alignItems="center"
        justifyContent="center"
      >
        <NoDataBlock
          title="No products yet"
          text="Start new campaign by adding your product data and importing target accounts and contacts list"
          btnText="Start new campaign"
          btnHandler={() => dispatch(setUploadModal("open"))}
          maxWidth="402px"
          isLoading={createProductAnalyses}
        />
      </Stack>
    );
  }

  return (
    <Stack
      sx={{ px: { sm: 8, md: 8, lg: 14, xl: 20 }, pt: 6, pb: 6 }}
      gap="24px"
    >
      <Typography variant="h4" color="custom.textDarkBlue">
        Products
      </Typography>
      <StartNewCampaignBlock />
      <InputWithQuery maxWidth="350px" />
      {isFetching ? (
        <CustomLoader />
      ) : data && data.length > 0 ? (
        <Stack gap="16px">
          {data.map((product) => (
            <ProductListItem key={product.id} product={product} />
          ))}
        </Stack>
      ) : (
        <Stack flexGrow={1} justifyContent="center" alignItems="center">
          <NoResultFound />
        </Stack>
      )}
      <Integration />
    </Stack>
  );
};
