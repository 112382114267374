// Input: "2024-12-14", "2025-01-17"
// Output: "Dec 14, 2024 - Jan 17, 2025"
export const formatDateRange = (dates: string, isOneDate?: boolean): string => {
  const [startDate, endDate] = dates.split(",");
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formatSingleDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  if (isOneDate) return formatSingleDate(startDate);

  return `${formatSingleDate(startDate)} - ${formatSingleDate(endDate)}`;
};
