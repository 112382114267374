export const FlagComponent = ({ country }: { country: string }) => {
  const style = {
    width: "24px",
    height: "24px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "50%",
    backgroundImage: `url(https://flagcdn.com/w320/${country?.toLowerCase()}.png)`,
  };
  return <span style={style}></span>;
};
