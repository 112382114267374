import { Routes, Route, Navigate } from "react-router-dom";
import {
  AccountPage,
  AccountsEngagementPage,
  AccountsListPage,
  AccountsPenetrationPage,
  ProductPage,
  ProductsPage,
  LoginView,
  SignupView,
  RequirementsPage,
  ForgotPasswordPage,
  VerifyCodePage,
  ResetPasswordPage,
  ProfilePage,
} from "../pages";
import { AccountsLayout, Layout, PrivateLayout, LoginLayout } from "../layout";

export const AppRouter = () => {
  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route path="/login" element={<LoginView />} />
        <Route path="/oauth/callback" element={<LoginView />} />
        <Route
          path="/sign-up/fd88faf3-569d-4201-8f82-ea058b6ff7b0"
          element={<SignupView />}
        />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/verify-code" element={<VerifyCodePage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
      </Route>
      <Route element={<PrivateLayout />}>
        <Route element={<Layout />}>
          <Route path="/" element={<ProductsPage />} />
          <Route path="/accounts" element={<AccountsLayout />}>
            <Route path="list/:campaign_id" element={<AccountsListPage />} />
            <Route
              path="rate/:campaign_id"
              element={<AccountsPenetrationPage />}
            />
            <Route
              path="score/:campaign_id"
              element={<AccountsEngagementPage />}
            />
          </Route>
          <Route path="/accounts/:account_id" element={<AccountPage />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/products/requirements" element={<RequirementsPage />} />
          <Route path="/products/:campaign_id" element={<ProductPage />} />
          <Route path="/profile" element={<ProfilePage />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="404" />} />
    </Routes>
  );
};
