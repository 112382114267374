import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const NoSearchIcon = ({
  width = "34",
  height = "34",
  color = "#1C3C6C",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.8547 18.4167H20.0976L19.8293 18.1579C20.801 17.031 21.335 15.5922 21.3339 14.1042C21.3339 12.4521 20.6776 10.8677 19.5094 9.69948C18.3412 8.53129 16.7568 7.875 15.1047 7.875C11.8272 7.875 9.14388 10.405 8.9043 13.625H10.8401C11.0797 11.4688 12.8814 9.79167 15.1047 9.79167C17.491 9.79167 19.4172 11.7179 19.4172 14.1042C19.4172 16.4904 17.491 18.4167 15.1047 18.4167C14.9418 18.4167 14.7885 18.3879 14.6255 18.3687V20.3046C14.7885 20.3237 14.9418 20.3333 15.1047 20.3333C16.6476 20.3333 18.066 19.7679 19.1585 18.8287L19.4172 19.0971V19.8542L24.2089 24.6362L25.6368 23.2083L20.8547 18.4167Z"
          fill={color}
        />
        <path
          d="M12.1997 15.3711L9.83266 17.7382L7.46557 15.3711L6.78516 16.0515L9.15224 18.4186L6.78516 20.7857L7.46557 21.4661L9.83266 19.099L12.1997 21.4661L12.8802 20.7857L10.5131 18.4186L12.8802 16.0515L12.1997 15.3711Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  );
};
