import React from "react";
import { FormControlLabel, Switch } from "@mui/material";

interface ToggleSwitchProps {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  label,
  checked,
  onChange,
}) => {
  return (
    <FormControlLabel
      label={label}
      labelPlacement="start"
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          sx={{
            "& .MuiSwitch-switchBase": {
              color: "#D9D9D9",
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: "#FE8836",
            },
            "& .MuiSwitch-track": {
              backgroundColor: "#4C545B",
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: "#FE883680",
            },
          }}
        />
      }
      sx={{
        gap: "12px",
        ml: 0,
        ".MuiFormControlLabel-label": {
          fontSize: "16px",
          lineHeight: "24px",
          letterSpacing: "0.2px",
          color: "#1F2932",
        },
      }}
    />
  );
};
