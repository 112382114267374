import { IconProps } from "../../types/genereal";

export const AccordionArrowDown = ({
  width = "24",
  height = "24",
  color = "#1F2932",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
        fill={color}
        fillOpacity="0.6"
      />
    </svg>
  );
};
