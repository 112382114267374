import { AppBar, Toolbar, Box, Link, Stack, Divider } from "@mui/material";
import { CustomLogo } from "components/images/Logo";
import { useLocation } from "react-router-dom";
import { AppRoutes } from "../../types/enums";
import { StartNewAnalysisBtn } from "../ui/StartNewAnalysisBtn";
import React from "react";
import { ProfileButton } from "../profile";

export const Header = () => {
  const location = useLocation();

  return (
    <AppBar
      position="static"
      color="default"
      sx={{
        background: "background.paper",
        borderBottom: "1px solid #EAEAEA",
        height: "74px",
        boxShadow: "0px 5px 8.3px 0px #00000005",
        px: { xs: 2, sm: 4, md: 5, xl: 9 },
        justifyContent: "center",
      }}
    >
      <Toolbar style={{ padding: 0, justifyContent: "space-between" }}>
        <Link href={AppRoutes.PRODUCTS}>
          <CustomLogo />
        </Link>
        <Stack flexDirection="row" gap="16px">
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              visibility: `${
                [AppRoutes.PRODUCTS.toString()].includes(location.pathname)
                  ? "hidden"
                  : "unset"
              }`,
            }}
          >
            <StartNewAnalysisBtn />
            <Divider
              orientation="vertical"
              flexItem
              sx={{ alignSelf: "stretch" }}
            />
          </Box>
          <ProfileButton />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
