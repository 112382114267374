import { Stack, Tooltip as MITooltip } from "@mui/material";
import React, { ReactNode } from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

type Props = {
  header: ReactNode;
  body: ReactNode;
  tooltip: string;
  flexGrow?: number;
};
export const AnalyticCard = ({
  header,
  body,
  tooltip,
  flexGrow = 0,
}: Props) => {
  return (
    <Stack
      sx={{
        padding: "12px 16px",
        border: "1px solid #D9D9D9",
        borderRadius: "8px",
      }}
      gap="16px"
      flexGrow={flexGrow}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        borderBottom="1px solid #E3E3E3"
        minHeight="46px"
      >
        {header}
        <MITooltip
          placement="bottom"
          sx={{
            background: "#596177E5",
            fontSize: "12px",
            lineHeight: "16px",
          }}
          arrow
          title={tooltip}
        >
          <HelpOutlineIcon sx={{ color: "#1C3C6C", transform: "scale(0.8)" }} />
        </MITooltip>
      </Stack>
      {body}
    </Stack>
  );
};
