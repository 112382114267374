import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ToastErrorIcon, ToastSuccessIcon } from "../images";

type Props = {
  type: "error" | "success";
  title: string;
  text: string;
  path?: string;
};

const iconMapper = {
  success: {
    icon: <ToastSuccessIcon />,
    bgcolor: "#d0ebdb",
  },
  error: {
    icon: <ToastErrorIcon />,
    bgcolor: "#f2d1d1",
  },
};
export const CustomToast = ({ type, title, text, path }: Props) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      bgcolor={iconMapper[type].bgcolor}
      gap="16px"
    >
      {iconMapper[type].icon}
      <Stack gap="4px" alignItems="flex-start">
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: 500 }}
          color="custom.textDarkBlue"
        >
          {title}
        </Typography>
        <Typography variant="body1" color="custom.textDarkBlue">
          {text}
        </Typography>
        {path && (
          <Link
            to={path}
            style={{
              textDecoration: "none",
            }}
          >
            <Typography variant="body1" color="#1C3C6C" fontWeight={500}>
              Open product
            </Typography>
          </Link>
        )}
      </Stack>
    </Stack>
  );
};
