import { Box } from "@mui/material";
import { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useOutsideClick } from "../../../hooks/useOutSideClick";
import { CustomButton } from "../CustomButton";
import { CalendarIcon } from "../../images/CalendarIcon";
import { CalendarBody } from "./CalendarBody";
import { formatDateRange } from "../../../utils/formatDateRange";

export const CustomCalendar = ({ date }: { date?: string }) => {
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const closeHandler = () => {
    setIsOpen(false);
  };

  useOutsideClick({
    elementRef: dropdownRef,
    onOutsideClick: closeHandler,
    isOpened: isOpen,
    wrapperRef: dropdownWrapperRef,
  });

  const rect = dropdownWrapperRef.current?.getBoundingClientRect();
  let left = 0;
  let top = 0;

  if (rect) {
    left = rect.left + window.scrollX - (date ? 236 : 386);
    top = rect.top + window.scrollY + 60;
  }

  return (
    <div
      ref={dropdownWrapperRef}
      style={{
        position: "relative",
      }}
    >
      <CustomButton
        onClick={() => setIsOpen(!isOpen)}
        variant="text"
        type="button"
        sx={{
          gap: 0,
          textWrap: "nowrap",
          color: date ? "#1B3967" : "#4C545B",
          backgroundColor: `${isOpen ? "#F1F1F1" : "transparent"}`,
          "&:hover": {
            backgroundColor: "#F1F1F1",
          },
          "& .MuiButton-startIcon": {
            marginRight: "6px",
          },
        }}
        startIcon={<CalendarIcon color={date && "#1B3967"} />}
        customSize="fit"
      >
        {date ? `${formatDateRange(date)}` : "Date"}
      </CustomButton>

      {isOpen &&
        ReactDOM.createPortal(
          <Box
            ref={dropdownRef}
            sx={{
              backgroundColor: "white",
              position: "absolute",
              left,
              top,
              boxShadow: "0px 4px 15px 0px #00000040",
              borderRadius: "8px",
              paddingY: "8px",
              width: "484px",
              height: "477px",
            }}
          >
            <CalendarBody close={() => setIsOpen(false)} />
          </Box>,
          document.body,
        )}
    </div>
  );
};
