import { IconProps } from "../../types/genereal";

export const DataPlusLine = ({
  width = "26",
  height = "26",
  color = "#1C3C6C",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.22 16.312C17.012 15.672 16.412 15.2 15.7 15.2H14.9V12.8C14.9 12.5878 14.8157 12.3843 14.6657 12.2343C14.5157 12.0843 14.3122 12 14.1 12H9.3V10.4H10.9C11.1122 10.4 11.3157 10.3157 11.4657 10.1657C11.6157 10.0157 11.7 9.81217 11.7 9.6V8H13.3C13.7243 8 14.1313 7.83143 14.4314 7.53137C14.7314 7.23131 14.9 6.82435 14.9 6.4V6.072C15.8572 6.45789 16.7066 7.06998 17.3755 7.85587C18.0445 8.64176 18.513 9.57804 18.7411 10.5846C18.9691 11.5911 18.9499 12.6379 18.685 13.6354C18.4201 14.6328 17.9174 15.5512 17.22 16.312ZM11.7 18.344C8.54 17.952 6.1 15.264 6.1 12C6.1 11.504 6.164 11.024 6.268 10.568L10.1 14.4V15.2C10.1 15.6243 10.2686 16.0313 10.5686 16.3314C10.8687 16.6314 11.2757 16.8 11.7 16.8M12.5 4C11.4494 4 10.4091 4.20693 9.43853 4.60896C8.46793 5.011 7.58601 5.60028 6.84315 6.34315C5.34285 7.84344 4.5 9.87827 4.5 12C4.5 14.1217 5.34285 16.1566 6.84315 17.6569C7.58601 18.3997 8.46793 18.989 9.43853 19.391C10.4091 19.7931 11.4494 20 12.5 20C14.6217 20 16.6566 19.1571 18.1569 17.6569C19.6571 16.1566 20.5 14.1217 20.5 12C20.5 10.9494 20.2931 9.90914 19.891 8.93853C19.489 7.96793 18.8997 7.08601 18.1569 6.34315C17.414 5.60028 16.5321 5.011 15.5615 4.60896C14.5909 4.20693 13.5506 4 12.5 4Z"
        fill={color}
      />
    </svg>
  );
};
