import { Divider, Stack, Typography } from "@mui/material";
import { ReuseModal } from "components/ui/ReuseModal";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { EngagementSettingsItem } from "./EngagementSettingsItem";
import {
  EngagementScoreConfig,
  EngagementScoreValues,
} from "../../../../types/apiTypes";
import { QuestionMarkInCircleIcon } from "../../../images/QuestionMarkInCircleIcon";
import { ReuseTooltip } from "../../ReuseTooltip";
import { FieldProgress } from "./FieldProgress";
import { EngagementSettingsDays } from "./EngagementScoreSettingDays";
import { useChangeEngagementScoreConfigMutation } from "../../../../api/companyApi";
import { toast } from "react-toastify";
import { CustomToast } from "../../CustomToast";
import { getErrorMessage } from "../../../../utils/getErrorMessage";

type Fields = { [key: string]: EngagementScoreValues };

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  campaign_id: string;
  settings: EngagementScoreConfig;
  successHandler: () => void;
};

export const EngagementScoreSettingModal = ({
  isOpen,
  setIsOpen,
  campaign_id,
  settings,
  successHandler,
}: Props) => {
  const { recency_days, all_campaigns, ...rest } = settings;
  const [isAllProducts, setIsAllProducts] = useState<boolean>(all_campaigns);
  const [days, setDays] = useState<number>(recency_days);
  const [formData, setFormData] = useState<Fields>(rest);

  const [changeEngagementScore, { isLoading: isChangeEngagementScoreLoading }] =
    useChangeEngagementScoreConfigMutation();

  useEffect(() => {
    if (settings) {
      setFormData(rest);
      setDays(recency_days);
      setIsAllProducts(all_campaigns);
    }
  }, [settings]);

  const onChangeHandler = (field: string, value: EngagementScoreValues) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const onSubmit = (value: EngagementScoreConfig) => {
    changeEngagementScore({
      campaign_id,
      ...value,
    })
      .unwrap()
      .then(() => {
        setIsOpen(false);
        successHandler();
      })
      .catch((err) => {
        toast.error(
          <CustomToast
            type={"error"}
            text={
              getErrorMessage(err).globalError ||
              "Something went wrong. Please try again"
            }
            title={"Change of settings failed"}
          />,
        );
      });
  };

  const percentage = Object.values(formData).reduce(
    (acc, { weight, enabled }) => {
      if (enabled) {
        return acc + weight;
      }
      return acc;
    },
    0,
  );

  const newData = {
    calls_made: formData.calls_made,
    calls_answered: formData.calls_answered,
    emails_sent: formData.emails_sent,
    emails_answered: formData.emails_answered,
    meetings_set: formData.meetings_set,
    meetings_attended: formData.meetings_attended,
    recency: formData.recency,
    recency_days: days,
    all_campaigns: isAllProducts,
  };

  const isNoChanges = JSON.stringify(newData) === JSON.stringify(settings);

  return (
    <ReuseModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title=""
      maxWidth="886px"
      submitBtnText="Apply"
      submitBtnColor="#1C3C6C"
      onSubmit={() => onSubmit(newData)}
      isLoading={isChangeEngagementScoreLoading}
      isDisabled={
        percentage !== 100 || isChangeEngagementScoreLoading || isNoChanges
      }
    >
      <Stack flexDirection="row" gap="32px">
        <Stack flexGrow={1} justifyContent="space-between" width="50%">
          <Stack gap="16px" alignItems="flex-start">
            <Typography variant="h4" color="#1F2932" fontWeight={500}>
              Engagement score settings
            </Typography>
            <Typography
              variant="subtitle1"
              color="#4C545B"
              textAlign="left"
              fontWeight={400}
            >
              Adjust{" "}
              <span style={{ fontWeight: 500, color: "#1F2932" }}>
                the importance
              </span>{" "}
              of each metric in the engagement score calculation and set how
              long activity is considered "recent."
            </Typography>
            <Typography
              variant="subtitle1"
              color="#4C545B"
              textAlign="left"
              fontWeight={400}
            >
              <span style={{ fontWeight: 500, color: "#1F2932" }}>
                How it works?
              </span>
            </Typography>
            <ul
              style={{
                padding: "0 0 0 24px",
                margin: 0,
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <li>
                <Typography
                  variant="subtitle1"
                  color="#4C545B"
                  textAlign="left"
                  fontWeight={400}
                  component="p"
                >
                  Each metric has a weight that{" "}
                  <span style={{ fontWeight: 500, color: "#1F2932" }}>
                    determines its impact
                  </span>{" "}
                  on the engagement score.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="subtitle1"
                  color="#4C545B"
                  textAlign="left"
                  fontWeight={400}
                  component="p"
                >
                  The total weight{" "}
                  <span style={{ fontWeight: 500, color: "#1F2932" }}>
                    must add up to 100%
                  </span>
                </Typography>
              </li>
              <li>
                <Typography
                  variant="subtitle1"
                  color="#4C545B"
                  textAlign="left"
                  fontWeight={400}
                  component="p"
                >
                  If a metric is not important, you can{" "}
                  <span style={{ fontWeight: 500, color: "#1F2932" }}>
                    disable it.
                  </span>
                </Typography>
              </li>
              <li>
                <Typography
                  variant="subtitle1"
                  color="#4C545B"
                  textAlign="left"
                  fontWeight={400}
                  component="p"
                >
                  Recent activity is measured in days (default: 90 days). You
                  can adjust this period and set from 1 to 999 days.
                </Typography>
              </li>
            </ul>
          </Stack>
          <Stack
            onClick={() => setIsAllProducts(!isAllProducts)}
            component="span"
            sx={{
              flexDirection: "row",
              gap: "4px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Checkbox
              checked={isAllProducts}
              sx={{
                color: "#1C3C6C",
                "&.Mui-checked": {
                  color: "#1C3C6C",
                },
              }}
            />
            <Typography variant="body1" color="#1F2932" component="span">
              Set for all products
            </Typography>
          </Stack>
        </Stack>
        <Stack
          sx={{
            padding: "24px 16px",
            border: "1px solid #D9D9D9",
            borderRadius: "8px",
            width: "50%",
            gap: "16px",
          }}
        >
          <Stack gap="4px">
            <Typography variant="button" color="#1F2932">
              Calls impact
            </Typography>

            {["calls_made", "calls_answered"].map((field) => (
              <EngagementSettingsItem
                key={field}
                item={formData[field]}
                field={field}
                onChange={onChangeHandler}
              />
            ))}
          </Stack>
          <Stack gap="4px">
            <Typography variant="button" color="#1F2932">
              Emails impact
            </Typography>

            {["emails_sent", "emails_answered"].map((field) => (
              <EngagementSettingsItem
                key={field}
                item={formData[field]}
                field={field}
                onChange={onChangeHandler}
              />
            ))}
          </Stack>
          <Stack gap="4px">
            <Typography variant="button" color="#1F2932">
              Meetings impact
            </Typography>

            {["meetings_set", "meetings_attended"].map((field) => (
              <EngagementSettingsItem
                key={field}
                item={formData[field]}
                field={field}
                onChange={onChangeHandler}
              />
            ))}
          </Stack>
          <Stack gap="4px" justifyContent="center">
            <Stack flexDirection="row" gap="4px">
              <Typography variant="button" color="#1F2932">
                Recency
              </Typography>
              <ReuseTooltip
                text="Recency determines how quickly past interactions lose value in the
               engagement score. New interactions count fully, with their contribution gradually
                decreasing to minimum by the end of the recency period (90 days by default)."
              >
                <QuestionMarkInCircleIcon />
              </ReuseTooltip>
            </Stack>

            {["recency"].map((field) => (
              <EngagementSettingsItem
                key={field}
                item={formData[field]}
                field={field}
                onChange={onChangeHandler}
              />
            ))}
          </Stack>
          <EngagementSettingsDays days={days} onChange={setDays} />
          <Divider />
          <FieldProgress percentage={percentage} />
        </Stack>
      </Stack>
    </ReuseModal>
  );
};
