import { Box, Stack } from "@mui/material";
import { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useOutsideClick } from "../../../hooks/useOutSideClick";
import { CustomButton } from "../CustomButton";
import { FilterIcon } from "../../images/FilterIcon";
import { AccordionBody } from "./AccordionBody";

type Props = {
  data: { placeholder: string; query: string; items: string[] }[];
  isActive: boolean;
  selected: number;
};

export const DropdownWithAccordion = ({ data, isActive, selected }: Props) => {
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedAccordion, setSelectedAccordion] = useState<string>("");

  const closeHandler = () => {
    setIsOpen(false);
  };

  useOutsideClick({
    elementRef: dropdownRef,
    onOutsideClick: closeHandler,
    isOpened: isOpen,
    wrapperRef: dropdownWrapperRef,
  });

  const rect = dropdownWrapperRef.current?.getBoundingClientRect();
  let left = 0;
  let top = 0;

  if (rect) {
    left = rect.left + window.scrollX - 180;
    top = rect.top + window.scrollY + 60;
  }

  return (
    <div
      ref={dropdownWrapperRef}
      style={{
        position: "relative",
      }}
    >
      <CustomButton
        onClick={() => setIsOpen(!isOpen)}
        variant="text"
        type="button"
        sx={{
          gap: 0,
          textWrap: "nowrap",
          color: "#4C545B",
          backgroundColor: `${isOpen || isActive ? "#F1F1F1" : "transparent"}`,
          "&:hover": {
            backgroundColor: "#F1F1F1",
          },
          "& .MuiButton-startIcon": {
            marginRight: "4px",
          },
        }}
        startIcon={<FilterIcon />}
        customSize="fit"
      >
        <Stack direction="row" alignItems="center" gap="16px">
          Filters
          {!!selected && (
            <Box
              sx={{
                backgroundColor: "#1B3967",
                color: "white",
                borderRadius: "20px",
                fontSize: "12px",
                lineHeight: "20px",
                paddingX: "6px",
                minWidth: "20px",
                textAlign: "center",
              }}
            >
              {selected}
            </Box>
          )}
        </Stack>
      </CustomButton>

      {isOpen &&
        ReactDOM.createPortal(
          <Box
            ref={dropdownRef}
            sx={{
              backgroundColor: "white",
              position: "absolute",
              left,
              top,
              boxShadow: "0px 4px 15px 0px #00000040",
              borderRadius: "8px",
              paddingY: "8px",
              width: "260px",
            }}
          >
            {data.map(({ query, items, placeholder }) => (
              <AccordionBody
                setSelectedAccordion={setSelectedAccordion}
                selectedAccordion={selectedAccordion}
                key={query}
                query={query}
                items={items}
                placeholder={placeholder}
              />
            ))}
          </Box>,
          document.body,
        )}
    </div>
  );
};
