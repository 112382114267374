import { Stack, Typography } from "@mui/material";

export const SubBlock = ({ title, text }: { title: string; text: string }) => {
  return (
    <Stack>
      {title && (
        <Typography
          component="span"
          variant="subtitle1"
          color="custom.textDarkBlue"
          fontWeight={500}
        >
          {title}
        </Typography>
      )}
      <Typography component="span" variant="body1" color="text.secondary">
        {text}
      </Typography>
    </Stack>
  );
};
