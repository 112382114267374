import { Stack } from "@mui/material";
import { ReuseInput } from "components/ui";
import { Fields } from "./ProductDetailsModal";

type Props = {
  formData: Fields;
  errorMessage: Fields;
  inputChangeHandler: (field: string, value: string) => void;
  error: { [key: string]: boolean };
  setErrorHandler: (field: string, value: boolean) => void;
};

export const DescriptionBlock = ({
  formData,
  inputChangeHandler,
  error,
  setErrorHandler,
  errorMessage,
}: Props) => {
  return (
    <Stack gap="42px" pt="42px" pb="32px">
      <ReuseInput
        name="company_name"
        handleInputChange={inputChangeHandler}
        errorMessage={
          errorMessage.company_name ||
          "Must contain only letters, numbers, and basic punctuation"
        }
        value={formData.company_name || ""}
        label="Company name *"
        maxLength={100}
        placeholder=" e.g., INFUSE"
        isError={error.company_name || !!errorMessage.company_name}
        setErrorHandler={setErrorHandler}
      />
      <ReuseInput
        name="product_name"
        handleInputChange={inputChangeHandler}
        errorMessage={
          errorMessage.product_name ||
          "Must contain only letters, numbers, and basic punctuation"
        }
        value={formData.product_name || ""}
        label="Product name *"
        maxLength={100}
        placeholder=" e.g., INFUSE"
        isError={error.product_name || !!errorMessage.product_name}
        setErrorHandler={setErrorHandler}
      />
      <ReuseInput
        name="product_abstract"
        handleInputChange={inputChangeHandler}
        errorMessage={
          errorMessage.product_abstract ||
          "This field is required and cannot include scripts."
        }
        value={formData.product_abstract || ""}
        label="Product description *"
        maxLength={1000}
        multiline={true}
        placeholder=" e.g., INFUSE is a global high-performance demand partner delivering demand strategies,
         programs, and outcomes for the most admired B2B brands"
        isError={error.product_abstract || !!errorMessage.product_abstract}
        setErrorHandler={setErrorHandler}
      />
      <ReuseInput
        name="target_audience"
        handleInputChange={inputChangeHandler}
        errorMessage={
          errorMessage.target_audience ||
          "This field is required and cannot include scripts."
        }
        value={formData.target_audience || ""}
        label="Product target audience *"
        maxLength={500}
        placeholder=" e.g., Demand specialists, marketers, sales, executives. Manager+ seniority.
         Located in NOAM, EMEA, LATAM, APAC."
        multiline={true}
        isError={error.target_audience || !!errorMessage.target_audience}
        setErrorHandler={setErrorHandler}
      />
    </Stack>
  );
};
