export const equals = (
  obj1: Record<string, any>,
  obj2: Record<string, any>,
): boolean => {
  if (obj1 === obj2) return true;

  if (!obj1 || !obj2 || typeof obj1 !== "object" || typeof obj2 !== "object") {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  const keysSet = new Set(keys1);
  if (!keys2.every((key) => keysSet.has(key))) return false;

  for (const key of keys1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (typeof val1 === "object" && typeof val2 === "object") {
      if (!equals(val1, val2)) return false;
    } else if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!arraysEqual(val1, val2)) return false;
    } else if (val1 !== val2) {
      return false;
    }
  }

  return true;
};

export const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  return arr1.every((item, index) => equals(item, arr2[index]));
};
