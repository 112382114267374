import { Stack, Typography } from "@mui/material";
import { CustomTabs } from "../../CustomTabs";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../../types/enums";
import { BASE_API_URL } from "../../../../api";
import { UploadFromHubSpot } from "../../upload-data/UploadFromHubSpot";
import { UploadFromSalesforce } from "../../upload-data/UploadFromSalesforce";
import { CustomButton } from "../../CustomButton";
import { useAppSelector } from "../../../../hooks/useReduxHooks";
import { UploadFromDesktop } from "../../upload-data";

const CRMDescription = () => {
  return (
    <Stack gap="16px">
      <Typography variant="body1" color="#4C545B" fontWeight={500}>
        Use{" "}
        <Link
          to={`${AppRoutes.PRODUCTS_REQUIREMENTS}?tag=contact`}
          target="_blank"
          style={{ textDecoration: "none", color: "#0069C4" }}
        >
          Field Requirements
        </Link>{" "}
        page to check the list of accounts and contact related fields for a
        sync.
      </Typography>
    </Stack>
  );
};

const CSVDescription = () => {
  return (
    <Stack gap="16px">
      <Typography variant="body1" color="#4C545B" fontWeight={500}>
        Use{" "}
        <Link
          to={`${AppRoutes.PRODUCTS_REQUIREMENTS}?tag=contact`}
          target="_blank"
          style={{ textDecoration: "none", color: "#0069C4" }}
        >
          Field Requirements
        </Link>{" "}
        page to check the list of accounts and contacts related fields to be
        provided, or use{" "}
        <Link
          to={`${BASE_API_URL}/examples/input-csv`}
          style={{ textDecoration: "none", color: "#0069C4" }}
        >
          file example
        </Link>{" "}
        to download a template CSV.
      </Typography>
    </Stack>
  );
};

type Props = {
  campaign_id: string;
  onClose: () => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  successHandler: () => void;
};

const tabList = ["Sync CRM", "Upload CSV"];

export const AddContactsDataBody = ({
  campaign_id,
  onClose,
  isLoading,
  setIsLoading,
  successHandler,
}: Props) => {
  const integrationData = useAppSelector(
    (state) => state.company.integrationData,
  );

  const [currentTab, setCurrentTab] = useState<number>(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (isLoading) return;
    setCurrentTab(newValue);
  };

  const onSuccess = () => {
    successHandler;
    onClose();
  };

  const values = useMemo(() => {
    return [
      <>
        <CRMDescription />
        <Stack gap="8px">
          <UploadFromHubSpot
            actionType="add-contacts"
            campaign_id={campaign_id || integrationData?.campaignId}
            startFlow={integrationData?.campaignImportFlow === "hubspot"}
            onSuccess={onSuccess}
            disabled={isLoading}
            setIsLoading={setIsLoading}
          />
          <UploadFromSalesforce
            actionType="add-contacts"
            campaign_id={campaign_id || integrationData?.campaignId}
            startFlow={integrationData?.campaignImportFlow === "salesforce"}
            onSuccess={onSuccess}
            disabled={isLoading}
            setIsLoading={setIsLoading}
          />
        </Stack>
      </>,
      <>
        <CSVDescription />
        <Stack gap="8px">
          <Typography
            variant="body1"
            color="#4C545B"
            fontWeight={500}
            align="center"
          >
            Preferred format: <strong>.csv</strong> or{" "}
            <strong>Excel file</strong>
          </Typography>
          <UploadFromDesktop
            campaign_id={campaign_id || integrationData?.campaignId}
            onSuccess={successHandler}
            setIsLoading={setIsLoading}
            actionType="add-contacts"
          />
        </Stack>
      </>,
    ];
  }, [isLoading]);

  return (
    <Stack gap="16px">
      <Typography variant="body1" color="#4C545B">
        Provide your contacts list for targeted accounts using CRM sync or CSV
        file to enhance the analysis. Utilize INFUSE data and external data
        sources to complete your buying committee discovery.
      </Typography>
      <CustomTabs
        isTabsCenter={true}
        currentTab={currentTab}
        onChange={handleChange}
        tabList={tabList}
      />
      <Stack gap="24px">
        {values[currentTab]}
        <CustomButton
          onClick={onClose}
          variant="contained"
          textColor="#4C545B"
          fullWidth
          type="button"
          customColor="#F1F1F1"
          disabled={isLoading}
        >
          Cancel
        </CustomButton>
      </Stack>
    </Stack>
  );
};
