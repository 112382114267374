import { Box, Divider, Stack, Typography } from "@mui/material";
import { LineWithStarsIcon } from "../images";
import { useCallback, useState } from "react";
import { CustomButton } from "./CustomButton";
import { UploadFromHubSpot } from "./upload-data/UploadFromHubSpot";
import { UploadFromSalesforce } from "./upload-data/UploadFromSalesforce";
import { UploadFromDesktop } from "./upload-data";
import { ReuseModal } from "./ReuseModal";

export const UploadProductDataBlock = ({
  refetch,
  campaign_id,
}: {
  refetch: () => void;
  campaign_id: string;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const successHandler = useCallback(() => {
    setIsOpen(false);
    refetch();
  }, [campaign_id]);

  return (
    <Stack
      justifyContent="space-between"
      alignItems="center"
      borderRadius="6px"
      bgcolor="#ECEEF099"
      p="16px"
      sx={{
        maxWidth: "380px",
        minWidth: "280px",
        width: "100%",
      }}
      gap="32px"
    >
      <Stack gap="8px" alignItems="flex-start">
        <LineWithStarsIcon />
        <Stack gap="4px">
          <Typography
            variant="h4"
            color="custom.textDarkBlue"
            sx={{ fontWeight: 500 }}
          >
            Get buying committee insights just in few clicks!
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Share your target accounts and marketing contact list to merge your
            data with INFUSE insights and external sources, giving you a
            complete view of your buying committee and boosting your chances of
            closing deals.
          </Typography>
        </Stack>
      </Stack>

      <CustomButton
        onClick={() => setIsOpen(true)}
        customColor="#F16300"
        customGradientColor="#FE8836"
        sx={{ height: "38px", minWidth: "160px" }}
        fullWidth
      >
        Upload data
      </CustomButton>

      <ReuseModal
        isOpen={isOpen}
        onClose={successHandler}
        title="Update data"
        text="Update companies and contacts data to keep track of the recent records and communication updates"
        warnText={["We recommend running an update at least once a week"]}
        maxWidth="430px"
      >
        <Stack gap="8px">
          <UploadFromHubSpot
            campaign_id={campaign_id}
            onSuccess={successHandler}
          />
          <UploadFromSalesforce
            campaign_id={campaign_id}
            onSuccess={successHandler}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            <Divider color="#CFCFCF" sx={{ flexGrow: 1 }} />
            <Typography
              variant="button"
              sx={{
                color: "#4C545B",
              }}
            >
              or
            </Typography>
            <Divider color="#CFCFCF" sx={{ flexGrow: 1 }} />
          </Box>
          <Typography
            variant="body2"
            color="custom.textDarkBlue"
            textAlign="center"
          >
            Max file size: <strong>10MB</strong>. Preferred format:{" "}
            <strong>.csv</strong> or <strong>Excel file</strong>
          </Typography>
          <UploadFromDesktop
            campaign_id={campaign_id}
            onSuccess={successHandler}
          />
        </Stack>
      </ReuseModal>
    </Stack>
  );
};
