import { useEffect, useState } from "react";
import { useGetCampaignChartQuery } from "api/companyApi";
import { ChartItem } from "types/companyTypes";

export const useChartData = (campaignId: string | null) => {
  const {
    data: response,
    isLoading,
    isFetching,
    refetch,
    isUninitialized,
  } = useGetCampaignChartQuery(
    { account_id: campaignId! },
    { skip: !campaignId, refetchOnMountOrArgChange: true }
  );

  const [chartData, setChartData] = useState<ChartItem[]>([]);

  useEffect(() => {
    if (response) {
      if (response.items.length > 0) {
        setChartData(response.items);
      } else {
        setChartData([]);
      }
    } else {
      setChartData([]);
    }
  }, [response]);

  return { chartData, isLoading, isFetching, refetch, isUninitialized };
};
