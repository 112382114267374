import { CircularProgress } from "@mui/material";
import { HubspotImg } from "../../images/Hubspot";
import { CustomButton } from "../CustomButton";
import {
  useCreateHubspotImportMutation,
  useGetHubspotUrlMutation,
} from "../../../api/companyApi";
import { toast } from "react-toastify";
import { CustomToast } from "../CustomToast";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../hooks/useReduxHooks";
import {
  setCreateProductAnalyses,
  setIntegrationData,
} from "../../../features/companySlice";
import { AppRoutes } from "../../../types/enums";
import { ActionType } from "../../../types";

type Props = {
  campaign_id?: string;
  onSuccess: () => void;
  startFlow?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  disabled?: boolean;
  actionType?: ActionType;
  getCampaignId?: () => Promise<string | void>;
};

export const UploadFromHubSpot = ({
  campaign_id,
  onSuccess,
  startFlow,
  setIsLoading,
  disabled,
  actionType = "update",
  getCampaignId,
}: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isCurrentLoading, setIsCurrentLoading] = useState<boolean>(false);

  const [createHubspotImport] = useCreateHubspotImportMutation();
  const [getHubspotUrl, { error, isSuccess, data, reset }] =
    useGetHubspotUrlMutation();

  useEffect(() => {
    isCurrentLoading && setIsLoading && setIsLoading(true);
  }, [isCurrentLoading]);

  useEffect(() => {
    if (isSuccess && data) {
      window.location.href = data.url;
      reset();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      toast.error(
        <CustomToast
          type="error"
          text={
            getErrorMessage(error).globalError ||
            "Something went wrong during analysis. Please try again"
          }
          title="Connection to HubSpot failed."
        />,
      );
      dispatch(setIntegrationData(null));
    }
  }, [error]);

  const handleHubspotIntegration = async () => {
    let id = "";
    setIsCurrentLoading(true);
    setIsLoading && setIsLoading(true);
    actionType === "create" && dispatch(setCreateProductAnalyses(true));

    if (!campaign_id && getCampaignId) {
      id = (await getCampaignId()) || "";
    }

    if (!id && !campaign_id) return;

    createHubspotImport({ campaign_id: campaign_id || id })
      .unwrap()
      .then(() => {
        toast.success(
          <CustomToast
            type="success"
            text="Company data uploading was successful."
            title="Upload success"
            path={
              actionType === "create"
                ? `${AppRoutes.PRODUCTS}/${campaign_id || id}`
                : undefined
            }
          />,
        );

        dispatch(setIntegrationData(null));
        onSuccess();
      })
      .catch((error) => {
        if (error?.status === 428) {
          getHubspotUrl({
            campaign_id: campaign_id || id,
            action_type: actionType,
            redirect_path: location.pathname,
          });
        } else {
          toast.error(
            <CustomToast
              type="error"
              text={
                getErrorMessage(error).globalError ||
                "The company data upload failed."
              }
              title="Upload failed"
            />,
          );
          dispatch(setIntegrationData(null));
        }
      })
      .finally(() => {
        setIsLoading && setIsLoading(false);
        setIsCurrentLoading(false);
        dispatch(setCreateProductAnalyses(false));
      });
  };

  useEffect(() => {
    if (startFlow) handleHubspotIntegration();
  }, [startFlow]);

  return (
    <CustomButton
      variant="outlined"
      customColor="#FFFFFF"
      textColor="#1D3E70"
      fullWidth
      onClick={handleHubspotIntegration}
      type="button"
      sx={{ height: "48px", borderColor: "#1D3E70" }}
      disabled={disabled}
    >
      {isCurrentLoading ? (
        <CircularProgress size={20} sx={{ color: "custom.subTitleModal" }} />
      ) : (
        <HubspotImg />
      )}
      Upload from HubSpot
    </CustomButton>
  );
};
