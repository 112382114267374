import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";
import { store, persistor } from "app/store";
import { theme } from "./theme/theme";
import { AppRouter } from "router/AppRouter";
import { ThemeProvider, CssBaseline } from "@mui/material";
import "./styles/base.css";
import "./index.css";
import { ToastContainer } from "react-toastify";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <ToastContainer
              style={{
                marginTop: "78px",
                marginRight: "56px",
              }}
              position="top-right"
              autoClose={5000}
              stacked
              hideProgressBar
              closeOnClick
              limit={4}
              pauseOnHover
            />
            <AppRouter />
          </Router>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
