import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDebounceValue } from "../../../hooks/useDebounceValue";
import { useStateWithSearchParams } from "../../../hooks/useStateWithSearchParams";
import { useOutsideClick } from "../../../hooks/useOutSideClick";
import { TriangleIcon } from "../../images/TriangleIcon";
import { CustomInput } from "../CustomInput";
import { SearchIcon } from "../../images/SearchIcon";
import { CustomButton } from "../CustomButton";

type Props = {
  items: string[];
  query: string;
  placeholder: string;
};

type SearchParams = {
  [key: string]: string | undefined;
};

export const DropdownWithSearch = ({ items, query, placeholder }: Props) => {
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [value, setValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const searchValueDebounce = useDebounceValue(value);
  const { searchDict, setSearchValue } = useStateWithSearchParams<SearchParams>(
    { [query]: undefined },
  );

  const [selectedItemList, setSelectedItemList] = useState<string[]>([]);

  useEffect(() => {
    setSelectedItemList(
      searchDict[query]?.length ? searchDict[query]?.split(",") || [] : [],
    );
  }, [searchDict[query]]);

  const handleChange = (item: string) => {
    let isInList = false;

    const newList = selectedItemList.filter((name) => {
      if (name === item) {
        isInList = true;
        return false;
      }

      return true;
    });

    !isInList && newList.push(item);
    setSelectedItemList(newList);
  };

  const closeHandler = () => {
    setIsOpen(false);
    setSelectedItemList(searchDict[query]?.split(",") || []);
    setValue("");
  };

  const cancelHandler = () => {
    setIsOpen(false);
    setValue("");
    setSearchValue(query, undefined);
  };

  const submitHandler = () => {
    setIsOpen(false);
    setValue("");
    setSearchValue(query, selectedItemList.join(","));
  };

  useOutsideClick({
    elementRef: dropdownRef,
    onOutsideClick: closeHandler,
    isOpened: isOpen,
    wrapperRef: dropdownWrapperRef,
  });

  const rect = dropdownWrapperRef.current?.getBoundingClientRect();
  let left = 0;
  let top = 0;

  if (rect) {
    left = rect.left + window.scrollX - 24;
    top = rect.top + window.scrollY + 60;
  }

  return (
    <div
      ref={dropdownWrapperRef}
      style={{
        position: "relative",
        width: "156px",
        border: "1px solid #CFCFCF",
        borderRadius: "8px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 40px 8px 16px",
          cursor: "pointer",
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography component="span" variant="button" color="custom.darkGrey">
          {placeholder}
        </Typography>
        {!!(searchDict[query]?.split(",")[0] || []).length && (
          <Box
            sx={{
              backgroundColor: "#1B3967",
              color: "white",
              borderRadius: "20px",
              fontSize: "0.75rem",
              lineHeight: "20px",
              paddingX: "6px",
            }}
          >
            {searchDict[query]?.split(",").length}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            right: "16px",
            top: "18px",
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease",
          }}
        >
          <TriangleIcon />
        </Box>
      </Box>
      {isOpen &&
        ReactDOM.createPortal(
          <Box
            ref={dropdownRef}
            sx={{
              backgroundColor: "white",
              position: "absolute",
              zIndex: 3,
              left,
              top,
              boxShadow: "0px 4px 15px 0px #00000040",
              borderRadius: "8px",
              paddingY: "8px",
              width: "280px",
            }}
          >
            <Box padding="8px 16px">
              <CustomInput
                value={value}
                placeholder="Search"
                prefixIcon={<SearchIcon />}
                onChange={setValue}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                maxHeight: "254px",
                overflow: "auto",
                marginRight: "4px",
              }}
              className="scrollable_container"
            >
              {items
                .filter((el) => el.includes(searchValueDebounce))
                .map((name) => (
                  <MenuItem
                    sx={{
                      padding: "4px",
                    }}
                    key={name}
                    value={name}
                    onClick={() => handleChange(name)}
                  >
                    <Checkbox
                      checked={selectedItemList.includes(name)}
                      sx={{
                        color: "#1C3C6C",
                        "&.Mui-checked": {
                          color: "#1C3C6C",
                        },
                      }}
                    />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
            </Box>

            <Stack
              direction="row"
              justifyContent="space-between"
              gap={1}
              padding="8px 16px"
            >
              <CustomButton
                onClick={cancelHandler}
                variant="contained"
                textColor="#4C545B"
                fullWidth
                type="button"
                customColor="#F1F1F1"
              >
                Cancel
              </CustomButton>
              <CustomButton
                onClick={submitHandler}
                variant="contained"
                fullWidth
                type="button"
                customColor="#1C3C6C"
              >
                Apply
              </CustomButton>
            </Stack>
          </Box>,
          document.body,
        )}
    </div>
  );
};
