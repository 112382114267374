import { TrashIcon } from "../images";
import { CustomButton } from "../ui/CustomButton";
import { ReuseModal } from "../ui";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CustomToast } from "../ui/CustomToast";
import { useDeleteProductMutation } from "../../api/companyApi";
import { AppRoutes } from "../../types/enums";
import { useNavigate } from "react-router-dom";

export const DeleteProductBtn = ({ campaign_id }: { campaign_id: string }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [deleteProduct, { isSuccess, isLoading, error }] =
    useDeleteProductMutation();

  useEffect(() => {
    if (error) {
      toast.error(
        <CustomToast
          type={"error"}
          text={"Your last changes are not saved. Please try again"}
          title={"Something went wrong"}
        />,
      );
      setIsOpen(false);
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        <CustomToast
          type={"success"}
          text={"All campaign information was successfully deleted"}
          title={"Product deleted"}
        />,
      );
      navigate(AppRoutes.PRODUCTS);
    }
  }, [isSuccess]);

  return (
    <>
      <CustomButton
        onClick={() => setIsOpen(true)}
        variant="outlined"
        customColor="#FFFFFF"
        textColor="#1D3E70"
        customSize="fit"
        type="button"
        startIcon={<TrashIcon />}
        sx={{ height: "38px", borderColor: "#1D3E70" }}
      >
        Delete product
      </CustomButton>
      <ReuseModal
        icon="warn"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={() => deleteProduct(campaign_id)}
        title="Delete product"
        warnText={["All related analysis data will be deleted"]}
        isLoading={isLoading}
        submitBtnText="Delete"
        maxWidth="430px"
      />
    </>
  );
};
