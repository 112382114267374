import { Box, LinearProgress, Stack, Typography } from "@mui/material";

type Props = {
  percentage: number;
};
export const FieldProgress = ({ percentage }: Props) => {
  const isError = percentage > 100;
  const value = isError ? 100 : percentage;
  return (
    <Stack gap="8px">
      <Box height="16px">
        {isError && (
          <Typography variant="body2" color="#BB2121">
            Make sure that total weight is up to 100%
          </Typography>
        )}
      </Box>
      <Stack sx={{ position: "relative", width: "100%", height: "4px" }}>
        <LinearProgress
          variant="determinate"
          sx={{
            backgroundColor: "#D9D9D9",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 1,
            height: "4px",
            width: "100%",
            borderRadius: "4px",
            "& .MuiLinearProgress-bar": {
              backgroundColor: isError ? "#BB2121" : "#27AE60",
            },
          }}
          value={value}
        />
      </Stack>
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        {percentage === 100 ? (
          <Typography variant="body2" color="#1F2932" fontWeight={500}>
            {percentage}% filled
          </Typography>
        ) : (
          <>
            <Typography variant="body2" color="#1F2932" fontWeight={500}>
              {percentage} of 100% field
            </Typography>
            <Typography variant="body2" color="#1F2932" fontWeight={500}>
              {`${Math.abs(100 - percentage)}% ${isError ? "over" : "left"}`}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};
