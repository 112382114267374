import { AnalyticCard } from "./AnalyticCard";
import { Box, Stack, Typography } from "@mui/material";
import { ProgressBar } from "./ProgressBar";
import { Analytics } from "../../../types/apiTypes";

const AnalyticItem = ({
  title,
  percentage,
  color,
}: {
  title: string;
  percentage: number;
  color: string;
}) => {
  if (!percentage) return null;
  return (
    <Stack gap="4px">
      <Typography variant="body1" color="#1F293299">
        {title}
      </Typography>
      <Stack direction="row" gap="4px" alignItems="center">
        <Typography variant="body1" sx={{ fontSize: "16px" }} color="#051123">
          {percentage}%
        </Typography>
        <Box
          sx={{ width: "12px", height: "12px", backgroundColor: color }}
        ></Box>
      </Stack>
    </Stack>
  );
};

const Progress = ({
  your_data,
  infuse_data,
  external_data,
}: {
  your_data: number;
  infuse_data: number;
  external_data: number;
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "48px",
        backgroundColor: "#F7F7F7",
      }}
    >
      {your_data && (
        <ProgressBar color="#1B3967" value={your_data} zIndex={3} />
      )}
      {infuse_data && (
        <ProgressBar
          color="#FE8836"
          value={your_data + infuse_data}
          zIndex={2}
        />
      )}
      {external_data && (
        <ProgressBar
          color="#F6C19D"
          value={your_data + infuse_data + external_data}
          zIndex={1}
        />
      )}
    </Box>
  );
};

export const DiscoveryRate = ({ analytics }: { analytics: Analytics }) => {
  const { external_data, your_data, infuse_data, total_rate } =
    analytics.discovery_rate;
  return (
    <AnalyticCard
      flexGrow={1}
      tooltip="Potential discovery rate improvement with additional data"
      header={
        <Typography variant="button" color="#1F293299" pt="8px">
          Potential BC members discovery rate
        </Typography>
      }
      body={
        <Stack gap="24px" flexGrow={1} minWidth="300px" justifyContent="center">
          <Stack gap="54px" direction="row">
            <Typography variant="h2" color="#051123" sx={{ fontSize: "32px" }}>
              {total_rate}%
            </Typography>
            <Stack gap="16px" direction="row">
              <AnalyticItem
                title="Your data"
                percentage={your_data}
                color="#1B3967"
              />
              <AnalyticItem
                title="INFUSE data"
                percentage={infuse_data}
                color="#FE8836"
              />
              <AnalyticItem
                title="External data"
                percentage={external_data}
                color="#F6C19D"
              />
            </Stack>
          </Stack>
          <Progress
            external_data={external_data}
            infuse_data={infuse_data}
            your_data={your_data}
          />
        </Stack>
      }
    />
  );
};
