import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "hooks/useReduxHooks";
import { selectAuth } from "features/authSlice";
import { history } from "utils/history";

export const PrivateLayout = () => {
  const isLoggedIn = useAppSelector(selectAuth);
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: history.location }} />;
  }

  return <Outlet />;
};
