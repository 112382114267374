import { IconProps } from "../../types/genereal";

export const TriangleIcon = ({
  width = "10",
  height = "5",
  color = "black",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0L5 5L10 0H0Z" fill={color} />
    </svg>
  );
};
