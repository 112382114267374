export const openLinkHandler = (
  url: string,
  filename?: string,
  newWindow?: boolean,
) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename || "export file";
  link.target = newWindow ? "_blank" : "_self";
  link.click();
};
