import { CircularProgress, Stack, Typography } from "@mui/material";
import { CustomButton } from "../ui/CustomButton";
import { ChangeEvent, useRef } from "react";
import { UploadImageIcon } from "../images/UploadImageIcon";
import { TrashIcon } from "../images";
import { toast } from "react-toastify";
import { CustomToast } from "../ui/CustomToast";
import { ProfileAvatar } from "./ProfileAvatar";
import {
  useChangeUserAvatarMutation,
  useDeleteUserAvatarMutation,
  useLazyGetUserQuery,
} from "../../api";
import { getErrorMessage } from "../../utils/getErrorMessage";

const ALLOWED_IMAGE_TYPES = ["jpeg", "png", "jpg"];

type Props = {
  avatar_url: string;
  first_name: string;
  last_name: string;
  company_name: string;
};

export const ProfileHeader = ({
  avatar_url,
  first_name,
  last_name,
  company_name,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [changeUserAvatar, { isLoading }] = useChangeUserAvatarMutation();
  const [fetchUser] = useLazyGetUserQuery();
  const [deleteUserAvatar, { isLoading: isDeleteAvatarLoading }] =
    useDeleteUserAvatarMutation();

  const uploadFileHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (ALLOWED_IMAGE_TYPES.some((el) => `image/${el}` === file.type)) {
        const formData = new FormData();
        formData.append("file", file);
        changeUserAvatar(formData)
          .unwrap()
          .then(() => {
            toast.success(
              <CustomToast
                type={"success"}
                text={"Your avatar has been successfully changed!"}
                title={"Avatar changed successfully"}
              />,
            );
            fetchUser();
          })
          .catch((err) => {
            toast.error(
              <CustomToast
                type={"error"}
                text={
                  getErrorMessage(err).globalError ||
                  "Something went wrong. Please try again"
                }
                title={"Failed upload file"}
              />,
            );
          });
      } else {
        toast.error(
          <CustomToast
            type={"error"}
            text={
              "You have chosen unsupported file extension. Please choose .jpg, .jpeg, .png file and try again"
            }
            title={"Failed upload file"}
          />,
        );
      }
      if (fileInputRef.current) fileInputRef.current.value = "";
    }
  };

  const deleteImageHandler = () => {
    if (fileInputRef.current) fileInputRef.current.value = "";
    deleteUserAvatar()
      .unwrap()
      .then(() => fetchUser())
      .catch((err) => {
        toast.error(
          <CustomToast
            type={"error"}
            text={
              getErrorMessage(err).globalError ||
              "Something went wrong. Please try again"
            }
            title={"Failed delete avatar"}
          />,
        );
      });
  };

  return (
    <Stack flexDirection="row" gap="24px" alignItems="flex-start">
      <ProfileAvatar avatar_url={avatar_url} height="98px" width="98px" />
      <Stack gap="16px" alignItems="flex-start">
        <Stack gap="4px">
          <Typography variant="h3" color="custom.textDarkBlue">
            {`${first_name} ${last_name}`}
          </Typography>
          <Typography variant="subtitle1" color="custom.textDarkBlue">
            {`${company_name}`}
          </Typography>
        </Stack>

        <Stack flexDirection="row" gap="8px">
          <CustomButton
            variant="contained"
            customColor="#1C3C6C"
            type="button"
            customSize="fit"
            onClick={() => fileInputRef.current?.click()}
            startIcon={
              isLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <UploadImageIcon />
              )
            }
            disabled={isLoading || isDeleteAvatarLoading}
          >
            Upload image
          </CustomButton>
          {avatar_url && (
            <CustomButton
              onClick={deleteImageHandler}
              variant="contained"
              textColor="#4C545B"
              type="button"
              customColor="#F1F1F1"
              customSize="fit"
              disabled={isLoading || isDeleteAvatarLoading}
              startIcon={
                isDeleteAvatarLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <TrashIcon color="#4C545B" />
                )
              }
            >
              Delete
            </CustomButton>
          )}
        </Stack>
      </Stack>
      <input
        type="file"
        hidden
        ref={fileInputRef}
        accept=".jpg,.png,.svg"
        onChange={uploadFileHandler}
      />
    </Stack>
  );
};
