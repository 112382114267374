import { Stack, Typography } from "@mui/material";
import { SnowflakeIcon } from "../components/images";
import { useEffect } from "react";
import { BASE_API_URL } from "../api/baseQuery";
import { useStateWithSearchParams } from "../hooks/useStateWithSearchParams";
import { scrollToBlock } from "../utils/scrollToBlock";
// import { NavigationBreadcrumbs } from "../components/ui";

type Props = {
  column: string;
  description: string;
  example: string;
  withSnowflake?: boolean;
};

const Header = ({ column, description, example }: Props) => {
  return (
    <Stack
      direction="row"
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 2.2fr 2.2fr",
        backgroundColor: "#E1E6EF",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      }}
    >
      {[column, description, example].map((el) => (
        <Typography
          key={el}
          padding="16px"
          variant="body1"
          color="#1F2932"
          fontWeight={500}
        >
          {el}
        </Typography>
      ))}
    </Stack>
  );
};

const Body = ({ column, description, example, withSnowflake }: Props) => {
  return (
    <Stack
      direction="row"
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr 2.2fr 2.2fr",
        gridTemplateRows: "auto",
        borderBottom: "1px solid #E1E6EF",
        alignItems: "flex-start",
      }}
    >
      <Stack
        padding="16px"
        direction="row"
        gap="8px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="body1" color="#1F2932">
          {column}
        </Typography>
        {withSnowflake && <SnowflakeIcon />}
      </Stack>

      <Typography padding="16px" variant="body1" color="#1F2932">
        {description}
      </Typography>
      <Typography padding="16px" variant="body1" color="#1F2932">
        {example}
      </Typography>
    </Stack>
  );
};

const companyRelatedData = [
  {
    column: "Company name",
    description: "The name of the organization",
    example: "Retail Employees Superannuation Pty, LTD",
  },
  {
    column: "Employees min",
    description: "The minimum number of employees in the company",
    example: "200",
  },
  {
    column: "Employees max",
    description: "The maximum number of employees in the company",
    example: "500",
  },
  {
    column: "Industry",
    description: "The industry in which the company operates",
    example: "Finance",
  },
  {
    column: "Subindustry",
    description: "A more specific categorization within the industry",
    example: "Investment Banking within Finance",
  },
  {
    column: "Country",
    description: "The country where the company is located",
    example: "Italy",
  },
];

const contactRelatedData = [
  {
    column: "First name",
    description: "The contact’s given name",
    example: "Andrew",
  },
  {
    column: "Last name",
    description: "The contact’s family name or surname",
    example: "Blake",
  },
  {
    column: "Job title",
    description: "The professional title of the contact within their company",
    example: "Senior Manager, Infrastructure Investments",
  },
  {
    column: "Contact country",
    description: "The country where the individual contact is based",
    example: "Italy",
  },
  {
    column: "Job area",
    description: "The general field of work the contact belongs to",
    example: "Sales",
  },
  {
    column: "Job level",
    description: "The seniority level of the contact within the company",
    example: "Entry-level",
  },
  {
    column: "Job function",
    description: "The specific role or responsibility of the contact",
    example: "Business Development",
  },
  {
    column: "Department",
    description: "The department within the company where the contact works",
    example: "Marketing",
  },
  {
    column: "Activity Type ",
    description: "The type of interaction, such as a call or email",
    example: "Email",
  },
  {
    column: "Activity Date",
    description: "The date when the interaction took place",
    example: "2024-03-17 14:29",
  },
  {
    column: "Email Direction",
    description: "Indicates whether the email was received or sent",
    example: "Incoming",
  },
  {
    column: "Call duration",
    description:
      "The total length of a phone call, that identifies replied calls",
    example: "0:03:03",
  },
];

export const RequirementsPage = () => {
  const { searchDict, setSearchValue } = useStateWithSearchParams<{
    tag: string | undefined;
  }>({ tag: undefined });

  useEffect(() => {
    if (searchDict.tag) {
      scrollToBlock(searchDict.tag);
      setSearchValue("tag", undefined);
    }
  }, [searchDict.tag]);

  return (
    <Stack
      sx={{ px: { sm: 8, md: 8, lg: 14, xl: 20 }, pt: 6, pb: 6 }}
      gap="24px"
    >
      <Stack gap="12px">
        <Typography variant="h2" color="#1F2932" fontWeight={500}>
          Field requirements for CSV upload
        </Typography>
        <Typography variant="subtitle1" color="#4C545B">
          Refer to the tables or check{" "}
          <a
            href={`${BASE_API_URL}/examples/input-csv`}
            style={{ color: "#1C3C6C" }}
          >
            file example
          </a>{" "}
          to make sure that the values you entered in your user data file meet
          the requirements.
        </Typography>
      </Stack>
      <Stack direction="row" gap="8px">
        <SnowflakeIcon />
        <Typography variant="body1" color="#1F2932">
          -
        </Typography>
        <Typography variant="body1" color="#1F2932">
          Required column
        </Typography>
      </Stack>
      <Stack gap="12px">
        <Typography variant="h4" color="#1F2932" fontWeight={500}>
          Company related data
        </Typography>
        <Typography variant="subtitle1" color="#4C545B">
          Covers key company details like name, country, industry, subindustry,
          and employee range for better segmentation and analysis.
        </Typography>
      </Stack>
      <Stack>
        <Header column="Column" description="Description" example="Example" />
        {companyRelatedData.map(({ column, description, example }, ind) => (
          <Body
            key={column}
            withSnowflake={ind < 3}
            column={column}
            description={description}
            example={example}
          />
        ))}
      </Stack>
      <Stack gap="12px" id="contact">
        <Typography variant="h4" color="#1F2932" fontWeight={500}>
          Contact related data
        </Typography>
        <Typography variant="subtitle1" color="#4C545B">
          Includes details about an employee, such as name, job title,
          department, job area, level, function and information based on
          interactions like calls and emails, considering activity type, date,
          email direction, and call duration to measure engagement.
        </Typography>
      </Stack>
      <Stack>
        <Header column="Column" description="Description" example="Example" />
        {contactRelatedData.map(({ column, description, example }, ind) => (
          <Body
            key={column}
            withSnowflake={ind < 3}
            column={column}
            description={description}
            example={example}
          />
        ))}
      </Stack>
    </Stack>
  );
};
