import { useState, useEffect } from "react";
import { Typography, Box, Stack, Link, CircularProgress } from "@mui/material";
import { AppRoutes } from "../types/enums";
import { CustomButton } from "../components/ui/CustomButton";
import { useStateWithSearchParams } from "../hooks/useStateWithSearchParams";
import { useNavigate } from "react-router-dom";
import { useLoginMutation, useResetPasswordMutation } from "../api";
import { getErrorMessage } from "../utils/getErrorMessage";
import { toast } from "react-toastify";
import { CustomToast } from "../components/ui/CustomToast";
import { ReuseInput } from "../components/ui";
import { PASSWORD_REGEX } from "../utils/regex";
import { setAuth } from "../features/authSlice";
import { useAppDispatch } from "../hooks/useReduxHooks";

type Fields = { [key: string]: string };
type Errors = { [key: string]: boolean };

type SearchParams = {
  code: undefined;
  email: undefined;
};

const validation: { [key: string]: (value: string) => boolean } = {
  new_password: (value: string) => !value || !PASSWORD_REGEX.test(value),
  repeat_password: (value: string) => !value,
};

export const ResetPasswordPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<Fields>({
    new_password: "",
    repeat_password: "",
  });

  const [error, setError] = useState<Errors>({
    new_password: false,
    repeat_password: false,
  });

  const [errorMessage, setErrorMessage] = useState<Fields>({
    new_password: "",
    repeat_password: "",
  });

  const [code, setCode] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const { searchDict } = useStateWithSearchParams<SearchParams>({
    code: undefined,
    email: undefined,
  });

  const [
    resetPassword,
    { error: resetPasswordError, isSuccess: resetPasswordSuccess, isLoading },
  ] = useResetPasswordMutation();

  const [login, { isLoading: loginIsLoading, isSuccess, data }] =
    useLoginMutation();

  useEffect(() => {
    if (isSuccess && data) {
      toast.success(
        <CustomToast
          type={"success"}
          text={"You can now use your new password to sign in."}
          title="Password changes successfuly"
        />,
      );
      dispatch(
        setAuth({
          accessToken: data.access_token,
          refreshToken: data.refresh_token,
        }),
      );
      navigate(AppRoutes.PRODUCTS, { replace: true });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (resetPasswordSuccess && email) {
      login({ email, password: formData.new_password });
    }
  }, [resetPasswordSuccess]);

  useEffect(() => {
    if (resetPasswordError) {
      toast.error(
        <CustomToast
          type={"error"}
          text={
            getErrorMessage(resetPasswordError).globalError ||
            "Something went wrong during reset password. Please try again"
          }
          title="Password changes feiled"
        />,
      );
    }
  }, [resetPasswordError]);

  useEffect(() => {
    if ((!searchDict.email && !email) || (!searchDict.code && !code)) {
      navigate(AppRoutes.LOGIN, { replace: true });
    }

    if (searchDict.email || searchDict.code) {
      setCode(searchDict.code);
      setEmail(searchDict.email);

      navigate(AppRoutes.RESET_PASSWORD, { replace: true });
    }
  }, [searchDict.email, searchDict.code]);

  const setErrorHandler = (field: string, value: boolean) => {
    setError((prev) => ({ ...prev, [field]: value }));
  };

  const inputChangeHandler = (field: string, value: string) => {
    setErrorMessage((prev) => ({ ...prev, [field]: "" }));
    setError((prev) => ({ ...prev, [field]: validation[field](value) }));
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const resetPasswordHandler = () => {
    if (formData.new_password !== formData.repeat_password) {
      setErrorMessage((state) => ({
        ...state,
        repeat_password: "Passwords do not match.",
      }));
      return;
    }

    if (!code || !email) return;

    resetPassword({ code, email, new_password: formData.new_password });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="calc(100vh - 74px)"
    >
      <Stack width="368px" textAlign="center" gap="32px">
        <Stack width="368px" textAlign="center" gap="48px">
          <Stack gap={1}>
            <Typography variant="h1" component="h1" textAlign="left">
              New password
            </Typography>
            <Typography
              variant="subtitle1"
              color="#4C545B"
              textAlign="left"
              fontWeight={400}
            >
              Enter new password to continue.
            </Typography>
            <Typography
              variant="subtitle1"
              color="#4C545B"
              textAlign="left"
              fontWeight={400}
            >
              Password should be{" "}
              <span style={{ fontWeight: 500, color: "#1F2932" }}>
                8-32 characters
              </span>{" "}
              and contain{" "}
              <span style={{ fontWeight: 500, color: "#1F2932" }}>
                at least 1 number, 1 uppercase letter, 1 lowercase letter
              </span>{" "}
              and{" "}
              <span style={{ fontWeight: 500, color: "#1F2932" }}>
                1 special character.
              </span>
            </Typography>
          </Stack>

          <Stack gap="42px">
            <ReuseInput
              name="new_password"
              handleInputChange={inputChangeHandler}
              errorMessage={
                errorMessage.new_password ||
                "Password does not meet the required criteria."
              }
              value={formData.new_password || ""}
              label="New password"
              placeholder="New password"
              isError={error.new_password || !!errorMessage.new_password}
              setErrorHandler={setErrorHandler}
              isPassword={true}
            />
            <ReuseInput
              name="repeat_password"
              handleInputChange={inputChangeHandler}
              errorMessage={errorMessage.repeat_password}
              value={formData.repeat_password || ""}
              label="Confirm new password"
              placeholder="Confirm new password"
              isError={error.repeat_password || !!errorMessage.repeat_password}
              setErrorHandler={setErrorHandler}
              isPassword={true}
            />
          </Stack>
        </Stack>

        <Stack gap="8px" alignItems="flex-start">
          <CustomButton
            variant="contained"
            fullWidth
            type="button"
            customColor="#F16300"
            customGradientColor="#FE8836"
            disabled={
              Object.values(formData).some((value) => !value) ||
              Object.values(error).some((value) => value) ||
              Object.values(errorMessage).some((value) => value) ||
              isLoading ||
              loginIsLoading
            }
            onClick={resetPasswordHandler}
            startIcon={
              (isLoading || loginIsLoading) && (
                <CircularProgress size={20} color="inherit" />
              )
            }
          >
            Save and continue
          </CustomButton>
          <Typography variant="body1" color="#4C545B" fontWeight={500}>
            Back to{" "}
            <Link
              href={AppRoutes.LOGIN}
              sx={{
                textDecoration: "none",
                fontWeight: 500,
                color: "#000000DE",
                "&:hover": { color: "#2194D2" },
              }}
            >
              Sign in
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
