import { Stack } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { ReuseInput } from "./ReuseInput";
import { CustomButton } from "./CustomButton";
import { PenIcon } from "../images";

type Props = {
  value: string;
  errorMessage: ReactNode;
  label: string;
  name: string;
  placeholder: string;
  maxLength?: number;
  handleInputChange: (field: string, value: string) => void;
  isError: boolean;
  setErrorHandler: (field: string, value: boolean) => void;
  isPassword?: boolean;
  initValue: string;
  isEditMode: boolean;
};

export const ReuseInputWithEdit = ({
  value,
  label,
  name,
  placeholder,
  errorMessage,
  handleInputChange,
  isError,
  setErrorHandler,
  initValue,
  isEditMode,
}: Props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    isEditMode && setIsEdit(false);
  }, [isEditMode]);

  const cancelHandler = () => {
    setIsEdit(false);
    handleInputChange(name, initValue);
  };

  const editHandler = () => {
    setIsEdit(true);
  };

  return (
    <Stack flexDirection="row" gap="8px" width="100%">
      <ReuseInput
        name={name}
        handleInputChange={handleInputChange}
        errorMessage={errorMessage}
        value={value}
        label={label}
        placeholder={placeholder}
        isError={isError}
        setErrorHandler={setErrorHandler}
        isDisabled={!isEdit}
      />
      {isEdit ? (
        <button
          onClick={cancelHandler}
          style={{
            padding: "8px 14px",
            border: "none",
            cursor: "pointer",
            background: "none",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "26px",
            color: "#BB2121",
            minWidth: "76px",
            height: "40px",
          }}
        >
          Cancel
        </button>
      ) : (
        <CustomButton
          onClick={editHandler}
          variant="outlined"
          customColor="#FFFFFF"
          textColor="#1D3E70"
          customSize="fit"
          type="button"
          sx={{
            height: "40px",
            borderColor: "#1D3E70",
            padding: "8px 12px",
            textWrap: "nowrap",
            gap: "4px",
          }}
        >
          <PenIcon />
          Edit
        </CustomButton>
      )}
    </Stack>
  );
};
