import { Link, Stack, Typography } from "@mui/material";
import { ReuseModal } from "components/ui/ReuseModal";
import { ReuseInput } from "../../ReuseInput";
import React, { useEffect, useState } from "react";
import { PASSWORD_REGEX } from "../../../../utils/regex";
import { AppRoutes } from "../../../../types/enums";
import { useChangeUserPasswordMutation } from "../../../../api";
import { getErrorMessage } from "../../../../utils/getErrorMessage";
import { toast } from "react-toastify";
import { CustomToast } from "../../CustomToast";

type Fields = { [key: string]: string };
type Errors = { [key: string]: boolean };

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  email: string;
};

const validation: { [key: string]: (value: string) => boolean } = {
  old_password: (value: string) => !value || !PASSWORD_REGEX.test(value),
  new_password: (value: string) => !value || !PASSWORD_REGEX.test(value),
  repeat_password: (value: string) => !value,
};

export const ChangePasswordModal = ({ isOpen, setIsOpen, email }: Props) => {
  const [formData, setFormData] = useState<Fields>({
    old_password: "",
    new_password: "",
    repeat_password: "",
  });

  const [error, setError] = useState<Errors>({
    old_password: false,
    new_password: false,
    repeat_password: false,
  });

  const [errorMessage, setErrorMessage] = useState<Fields>({
    old_password: "",
    new_password: "",
    repeat_password: "",
  });

  const [
    changePassword,
    {
      isLoading: isChangePasswordLoading,
      error: isChangePasswordError,
      isSuccess: isChangePasswordLoadingSuccess,
    },
  ] = useChangeUserPasswordMutation();

  useEffect(() => {
    if (isChangePasswordLoadingSuccess) {
      toast.success(
        <CustomToast
          type={"success"}
          text={"Your password has been successfully changed!"}
          title={"Password changed successfully"}
        />,
      );
      setIsOpen(false);
    }
  }, [isChangePasswordLoadingSuccess]);

  useEffect(() => {
    if (isChangePasswordError) {
      if (!getErrorMessage(isChangePasswordError).globalError) {
        setErrorMessage((err) => ({
          ...err,
          ...getErrorMessage(isChangePasswordError).fieldErrors,
        }));
      } else {
        toast.error(
          <CustomToast
            type={"error"}
            text={
              getErrorMessage(isChangePasswordError).globalError ||
              "Something went wrong. Please try again"
            }
            title={"Change password filed"}
          />,
        );
      }
    }
  }, [isChangePasswordError]);

  const setErrorHandler = (field: string, value: boolean) => {
    setError((prev) => ({ ...prev, [field]: value }));
  };

  const inputChangeHandler = (field: string, value: string) => {
    setErrorMessage((prev) => ({ ...prev, [field]: "" }));
    setError((prev) => ({ ...prev, [field]: validation[field](value) }));
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const changePasswordHandler = () => {
    if (formData.new_password !== formData.repeat_password) {
      setErrorMessage((state) => ({
        ...state,
        repeat_password: "Passwords do not match.",
      }));
      return;
    }

    changePassword({
      new_password: formData.new_password,
      old_password: formData.old_password,
    });
  };

  const isDisabled =
    Object.values(formData).some((value) => !value) ||
    Object.values(error).some((value) => value) ||
    Object.values(errorMessage).some((value) => value);

  return (
    <ReuseModal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title="Change password"
      maxWidth="540px"
      submitBtnText="Change password"
      submitBtnColor="#1C3C6C"
      onSubmit={changePasswordHandler}
      isLoading={isChangePasswordLoading}
      isDisabled={isDisabled || isChangePasswordLoading}
    >
      <Stack gap="24px">
        <Stack gap="16px">
          <Typography
            variant="subtitle1"
            color="#4C545B"
            textAlign="left"
            fontWeight={400}
          >
            Please enter your old and new password to continue.
          </Typography>
          <Typography
            variant="subtitle1"
            color="#4C545B"
            textAlign="left"
            fontWeight={400}
          >
            Password should be{" "}
            <span style={{ fontWeight: 500, color: "#1F2932" }}>
              8-32 characters
            </span>{" "}
            and contain{" "}
            <span style={{ fontWeight: 500, color: "#1F2932" }}>
              at least 1 number, 1 uppercase letter, 1 lowercase letter
            </span>{" "}
            and{" "}
            <span style={{ fontWeight: 500, color: "#1F2932" }}>
              1 special character.
            </span>
          </Typography>
        </Stack>
        <Stack gap="42px" paddingTop="16px">
          <ReuseInput
            name="old_password"
            handleInputChange={inputChangeHandler}
            errorMessage={
              errorMessage.new_password ||
              "Password does not meet the required criteria."
            }
            value={formData.old_password || ""}
            label="Old password"
            placeholder="Old password"
            isError={error.old_password || !!errorMessage.old_password}
            setErrorHandler={setErrorHandler}
            isPassword={true}
          />
          <ReuseInput
            name="new_password"
            handleInputChange={inputChangeHandler}
            errorMessage={
              errorMessage.new_password ||
              "Password does not meet the required criteria."
            }
            value={formData.new_password || ""}
            label="New password"
            placeholder="New password"
            isError={error.new_password || !!errorMessage.new_password}
            setErrorHandler={setErrorHandler}
            isPassword={true}
          />
          <ReuseInput
            name="repeat_password"
            handleInputChange={inputChangeHandler}
            errorMessage={errorMessage.repeat_password}
            value={formData.repeat_password || ""}
            label="Confirm new password"
            placeholder="Confirm new password"
            isError={error.repeat_password || !!errorMessage.repeat_password}
            setErrorHandler={setErrorHandler}
            isPassword={true}
          />
        </Stack>
        <Link
          href={`${AppRoutes.FORGOT_PASSWORD}?email=${email}`}
          sx={{
            textDecoration: "none",
            fontWeight: 500,
            color: "#000000DE",
            "&:hover": { color: "#2194D2" },
          }}
        >
          Forgot your password?
        </Link>
      </Stack>
    </ReuseModal>
  );
};
