import { CustomButton } from "../CustomButton";
import React, { useState } from "react";
import { ExportDataIcon } from "../../images/ExportDataIcon";
import { ExportAccountDataModal } from "./ExportAccountDataModal";
import { isTextEllipsed } from "../../../utils/general_utils";
import { Tooltip } from "@mui/material";

export const ExportAccountDataBtn = ({
  account_id,
  campaign_id,
  is_analyzed,
}: {
  account_id: string;
  campaign_id: string;
  is_analyzed: boolean;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <Tooltip
        title={!is_analyzed ? "You haven’t analysed company yet" : null}
        placement="bottom"
        arrow
        sx={{
          background: "#596177E5",
          fontSize: "12px",
          lineHeight: "16px",
          fontWeight: 400,
          "&:hover": {
            visibility: "visible",
            opacity: 1,
          },
        }}
      >
        <div
          style={{ height: "42px" }}
          onMouseEnter={(event: React.MouseEvent<HTMLDivElement>) => {
            isTextEllipsed(event.currentTarget);
          }}
        >
          <CustomButton
            onClick={() => setIsOpen(true)}
            variant="outlined"
            customColor="#FFFFFF"
            textColor="#1D3E70"
            customSize="fit"
            type="button"
            startIcon={
              <ExportDataIcon color={!is_analyzed ? "#BFC1C1" : undefined} />
            }
            sx={{ height: "42px", borderColor: "#1D3E70", minWidth: "168px" }}
            disabled={!is_analyzed}
          >
            Export to CSV
          </CustomButton>
        </div>
      </Tooltip>

      {isOpen && (
        <ExportAccountDataModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          account_id={account_id}
          campaign_id={campaign_id}
        />
      )}
    </>
  );
};
