import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const DoubleChevronLeftIcon = ({
  width = "24",
  height = "24",
  color = "#1F2932",
  opacity = "0.6",
  rotate = "0",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height} rotate={rotate}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.4102 16.59L14.8302 12L19.4102 7.41L18.0002 6L12.0002 12L18.0002 18L19.4102 16.59Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M12 16.59L7.42 12L12 7.41L10.59 6L4.59 12L10.59 18L12 16.59Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    </IconWrapper>
  );
};
