import { IconProps } from "../../types/genereal";
import { IconWrapper } from "components/ui/IconWrapper";

export const LogoutIcon = ({
  width = "24",
  height = "24",
  color = "#4C545B",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 21C4.95 21 4.47933 20.8043 4.088 20.413C3.69667 20.0217 3.50067 19.5507 3.5 19V5C3.5 4.45 3.696 3.97933 4.088 3.588C4.48 3.19667 4.95067 3.00067 5.5 3H11.5C11.7833 3 12.021 3.096 12.213 3.288C12.405 3.48 12.5007 3.71733 12.5 4C12.4993 4.28267 12.4033 4.52033 12.212 4.713C12.0207 4.90567 11.7833 5.00133 11.5 5H5.5V19H11.5C11.7833 19 12.021 19.096 12.213 19.288C12.405 19.48 12.5007 19.7173 12.5 20C12.4993 20.2827 12.4033 20.5203 12.212 20.713C12.0207 20.9057 11.7833 21.0013 11.5 21H5.5ZM17.675 13H10.5C10.2167 13 9.97933 12.904 9.788 12.712C9.59667 12.52 9.50067 12.2827 9.5 12C9.49933 11.7173 9.59533 11.48 9.788 11.288C9.98067 11.096 10.218 11 10.5 11H17.675L15.8 9.125C15.6167 8.94167 15.525 8.71667 15.525 8.45C15.525 8.18333 15.6167 7.95 15.8 7.75C15.9833 7.55 16.2167 7.44567 16.5 7.437C16.7833 7.42833 17.025 7.52433 17.225 7.725L20.8 11.3C21 11.5 21.1 11.7333 21.1 12C21.1 12.2667 21 12.5 20.8 12.7L17.225 16.275C17.025 16.475 16.7877 16.571 16.513 16.563C16.2383 16.555 16.0007 16.4507 15.8 16.25C15.6167 16.05 15.5293 15.8127 15.538 15.538C15.5467 15.2633 15.6423 15.034 15.825 14.85L17.675 13Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  );
};
