import { IconProps } from "../../types/genereal";
import { IconWrapper } from "../ui/IconWrapper";

export const QuestionMarkInCircleIcon = ({
  width = "24",
  height = "24",
  color = "#1C3C6C",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.2 16.8H12.8V15.2H11.2V16.8ZM12 4C10.9494 4 9.90914 4.20693 8.93853 4.60896C7.96793 5.011 7.08601 5.60028 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.08601 18.3997 7.96793 18.989 8.93853 19.391C9.90914 19.7931 10.9494 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 10.9494 19.7931 9.90914 19.391 8.93853C18.989 7.96793 18.3997 7.08601 17.6569 6.34315C16.914 5.60028 16.0321 5.011 15.0615 4.60896C14.0909 4.20693 13.0506 4 12 4ZM12 18.4C8.472 18.4 5.6 15.528 5.6 12C5.6 8.472 8.472 5.6 12 5.6C15.528 5.6 18.4 8.472 18.4 12C18.4 15.528 15.528 18.4 12 18.4ZM12 7.2C11.1513 7.2 10.3374 7.53714 9.73726 8.13726C9.13714 8.73737 8.8 9.55131 8.8 10.4H10.4C10.4 9.97565 10.5686 9.56869 10.8686 9.26863C11.1687 8.96857 11.5757 8.8 12 8.8C12.4243 8.8 12.8313 8.96857 13.1314 9.26863C13.4314 9.56869 13.6 9.97565 13.6 10.4C13.6 12 11.2 11.8 11.2 14.4H12.8C12.8 12.6 15.2 12.4 15.2 10.4C15.2 9.55131 14.8629 8.73737 14.2627 8.13726C13.6626 7.53714 12.8487 7.2 12 7.2Z"
          fill={color}
        />
      </svg>
    </IconWrapper>
  );
};
