import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "./baseQuery";
import {
  Response,
  UrlResponse,
  User,
  UserInfo,
  ChangeUserPassword,
} from "types/apiTypes";
import { RequestMethods } from "types/enums";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => ({
        url: "/me/profile",
        method: RequestMethods.GET,
      }),
    }),
    changeUserInfo: builder.mutation<Response, UserInfo>({
      query: (body) => ({
        url: "/me/profile",
        method: RequestMethods.PATCH,
        body,
      }),
    }),
    changeUserPassword: builder.mutation<Response, ChangeUserPassword>({
      query: (body) => ({
        url: "/me/password",
        method: RequestMethods.PATCH,
        body,
      }),
    }),
    changeUserAvatar: builder.mutation<UrlResponse, FormData>({
      query: (body) => ({
        url: "/me/avatar",
        method: RequestMethods.POST,
        body,
      }),
    }),
    deleteUserAvatar: builder.mutation<Response, void>({
      query: () => ({
        url: "/me/avatar",
        method: RequestMethods.DELETE,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useChangeUserInfoMutation,
  useChangeUserPasswordMutation,
  useChangeUserAvatarMutation,
  useDeleteUserAvatarMutation,
} = userApi;
