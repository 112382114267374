import { useEffect, useState } from "react";
import { useGetCampaignDetailsQuery } from "api/companyApi";
import {
  CompanyInfoProps,
  Summary,
  SummaryData,
  UseCompanyDataResult,
} from "types/companyTypes";

const defaultCompanyInfo: CompanyInfoProps = {
  name: "N/A",
  size: "0 - 0 employees",
  contactsImported: "0 contacts",
  industry: "",
};

const defaultSummaryData: SummaryData = {
  committee_members: 0,
  cluster_members: 0,
  engagement_score: 0,
};

const defaultExpectedData: Summary["expected"] = {
  committee_members: 0,
  cluster_members: 0,
  committee_range: {
    min: 0,
    max: 0,
  },
  cluster_range: {
    min: 0,
    max: 0,
  },
};

export const useCompanyData = (account_id: string): UseCompanyDataResult => {
  const {
    data: response,
    isLoading,
    isFetching,
    refetch: campaignRefetch,
    isError,
    isUninitialized,
  } = useGetCampaignDetailsQuery(
    { account_id: account_id },
    { skip: !account_id, refetchOnMountOrArgChange: true },
  );

  const [companyInfo, setCompanyInfo] =
    useState<CompanyInfoProps>(defaultCompanyInfo);
  const [original, setOriginal] = useState<SummaryData>(defaultSummaryData);
  const [enriched, setEnriched] = useState<SummaryData>(defaultSummaryData);
  const [expected, setExpected] =
    useState<Summary["expected"]>(defaultExpectedData);
  const [status, setStatus] = useState<string>("");
  const [campaignId, setCampaignId] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (response) {
      const { output, input, status, campaign_id, progress } = response;

      setStatus(status);
      setCampaignId(campaign_id);
      setProgress(progress);
      setCompanyInfo({
        name: output.company.name,
        size: ((): string => {
          const min = output.company.employees_min || 0;
          const max = output.company.employees_max || 0;

          if (min === max) {
            return min === 0 ? "-" : `${min}`;
          }

          if (min === 0 && max === 0) {
            return "-";
          }

          return `${min} - ${max}`;
        })(),
        contactsImported: `${input.total_contacts || "-"}`,
        industry: output.company.industry || "-",
      });

      setOriginal(output.summary.total.original);
      setEnriched(output.summary.total.enriched);
      setExpected(output.summary.expected);
    }
  }, [response]);

  if (response) {
    const {
      output,
      input,
      status,
      campaign_id,
      progress,
      product_name,
      is_analyzed,
      user_data,
    } = response;
    return {
      progress,
      product_name,
      isError,
      campaign_id,
      status,
      companyInfo: {
        name: output.company.name,
        size: ((): string => {
          const min = output.company.employees_min || 0;
          const max = output.company.employees_max || 0;

          if (min === max) {
            return min === 0 ? "-" : `${min}`;
          }

          if (min === 0 && max === 0) {
            return "-";
          }

          return `${min} - ${max}`;
        })(),
        contactsImported: `${input.total_contacts || "-"}`,
        industry: output.company.industry || "-",
      },
      percentage: output.summary.percentage,
      original,
      enriched,
      expected,
      isLoading,
      isFetching,
      campaignRefetch,
      isUninitialized,
      is_analyzed,
      user_data,
    };
  }

  return {
    product_name: "Product name",
    progress,
    isError,
    campaign_id: campaignId,
    status,
    companyInfo,
    original,
    enriched,
    expected,
    isLoading,
    isFetching,
    campaignRefetch,
    isUninitialized,
    percentage: {
      cluster: {
        original: 0,
        enriched: 0,
      },
      committee: {
        original: 0,
        enriched: 0,
      },
    },
    is_analyzed: false,
    user_data: false,
  };
};
