// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast {
  padding: 16px;
  background-color: #d0ebdb;
  border-left: 4px solid #27ae60;
  width: 552px;
}

.Toastify__toast.Toastify__toast--info {
  background-color: #d0ebdb;
  border-left: 4px solid #27ae60;
}

.Toastify__toast.Toastify__toast--success {
  background-color: #d0ebdb;
  border-left: 4px solid #27ae60;
}
.Toastify__toast.Toastify__toast--alert {
  background-color: #d0ebdb;
  border-left: 4px solid #27ae60;
}
.Toastify__toast.Toastify__toast--error {
  background-color: #f2d1d1;
  border-left: 4px solid #d32f2f;
}

.Toastify__close-button svg {
  transform: scale(1.2);
}

.Toastify__toast-icon {
  display: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,8BAA8B;AAChC;AACA;EACE,yBAAyB;EACzB,8BAA8B;AAChC;AACA;EACE,yBAAyB;EACzB,8BAA8B;AAChC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".Toastify__toast {\n  padding: 16px;\n  background-color: #d0ebdb;\n  border-left: 4px solid #27ae60;\n  width: 552px;\n}\n\n.Toastify__toast.Toastify__toast--info {\n  background-color: #d0ebdb;\n  border-left: 4px solid #27ae60;\n}\n\n.Toastify__toast.Toastify__toast--success {\n  background-color: #d0ebdb;\n  border-left: 4px solid #27ae60;\n}\n.Toastify__toast.Toastify__toast--alert {\n  background-color: #d0ebdb;\n  border-left: 4px solid #27ae60;\n}\n.Toastify__toast.Toastify__toast--error {\n  background-color: #f2d1d1;\n  border-left: 4px solid #d32f2f;\n}\n\n.Toastify__close-button svg {\n  transform: scale(1.2);\n}\n\n.Toastify__toast-icon {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
