import { Stack, TextField, Typography } from "@mui/material";

type Props = {
  days: number;
  onChange: (value: number) => void;
};

export const EngagementSettingsDays = ({ days, onChange }: Props) => {
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const days = Number(e.target.value);
    if (e.target.value && isNaN(days)) return;

    if (days > 1000) return onChange(999);
    if (days === 0) return onChange(1);
    onChange(days);
  };

  return (
    <Stack
      flexDirection="row"
      gap="16px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body1" color="#1F2932">
        Communication stays recent for
      </Typography>
      <Stack
        flexDirection="row"
        gap="8px"
        alignItems="center"
        justifyContent="space-between"
        maxWidth="96px"
      >
        <TextField
          value={String(days)}
          variant="outlined"
          onChange={onInputChange}
          sx={{
            width: "54px",
            color: "#1F2932",
            borderRadius: "8px",
            textAlign: "center",
            "& .MuiOutlinedInput-root": {
              padding: 0,
            },
            "& input": {
              textAlign: "center",
              padding: "0",
            },
          }}
        />
        <Typography variant="subtitle1" color="#1F2932">
          days
        </Typography>
      </Stack>
    </Stack>
  );
};
