import { ProvideDataStep, UploadPDFStep } from "./modal";
import React, { useEffect, useMemo, useState } from "react";
import { CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CustomButton } from "./CustomButton";
import { useAppDispatch, useAppSelector } from "../../hooks/useReduxHooks";
import { ProductAnalysisResponse } from "../../types/apiTypes";
import { DescriptionStep } from "./modal/NewCompaignModal/DescriptionStep";
import { BaseModal } from "./BaseModal";
import { equals } from "../../utils/equals";
import { ReuseModal } from "./ReuseModal";
import {
  setIntegrationData,
  setUploadModal,
} from "../../features/companySlice";

const initValues: ProductAnalysisResponse = {
  company_name: "",
  product_name: "",
  product_abstract: "",
  target_audience: "",
};

const importOptions = ["hubspot", "salesforce"];

export const StartNewAnalysisBtn = () => {
  const dispatch = useAppDispatch();
  const integrationData = useAppSelector(
    (state) => state.company.integrationData,
  );
  const createProductAnalyses = useAppSelector(
    (state) => state.company.createProductAnalyses,
  );

  const uploadModal = useAppSelector((state) => state.company.uploadModal);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAnalysisPDF, setIsAnalysisPDF] = useState<boolean>(false);
  const [isDiscardModal, setIsDiscardModal] = useState<boolean>(false);

  const [pdfFile, setPdfFile] = useState<File | null>(null);
  const [productUrl, setProductUrl] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [resultData, setResultData] =
    useState<ProductAnalysisResponse>(initValues);

  const isLoading =
    importOptions.includes(integrationData?.campaignImportFlow || "") &&
    integrationData?.actionType === "create";

  useEffect(() => {
    if (uploadModal === "open") setIsOpen(true);
  }, [uploadModal]);

  useEffect(() => {
    if (isLoading) {
      setIsOpen(true);
      setCurrentStep(2);

      const savedData = localStorage.getItem("data");
      if (savedData) {
        const { file, url, data } = JSON.parse(savedData);
        setPdfFile(JSON.parse(file));
        setProductUrl(url);
        setResultData(data);
      }
    }

    if (integrationData?.campaignImportFlow === "open") setIsOpen(true);
  }, [integrationData, isLoading]);

  useEffect(() => {
    if (!isOpen) {
      setPdfFile(null);
      setProductUrl("");
      setCurrentStep(0);
      setResultData(initValues);
      dispatch(setUploadModal(""));
      integrationData?.campaignImportFlow === "open" &&
        dispatch(setIntegrationData(null));
    }
  }, [isOpen]);

  const setResultHandler = (
    data: ProductAnalysisResponse,
    file: File | null,
    url: string,
  ) => {
    setResultData(data);
    setPdfFile(file);
    setProductUrl(url);

    localStorage.setItem(
      "data",
      JSON.stringify({
        data,
        file: JSON.stringify({
          name: file?.name,
          size: file?.size,
          type: file?.type,
          lastModified: file?.lastModified,
        }),
        url,
      }),
    );
  };

  const onCloseHandler = () => {
    if (isLoading || createProductAnalyses) {
      setIsOpen(false);
      return;
    }

    if (currentStep > 0 || !equals(resultData, initValues) || isAnalysisPDF) {
      setIsDiscardModal(true);
      return;
    }
    setIsOpen(false);
  };

  const steps = useMemo(
    () => [
      <UploadPDFStep
        nextStepHandler={() => setCurrentStep(1)}
        setResultData={setResultHandler}
        initProductUrl={productUrl}
        initPdfFile={pdfFile}
        onClose={() => setIsOpen(false)}
        setIsAnalysisPDF={setIsAnalysisPDF}
      />,
      <DescriptionStep
        initData={resultData}
        backHandler={() => setCurrentStep(0)}
        nextStepHandler={() => setCurrentStep(2)}
        setResultData={setResultData}
      />,
      <ProvideDataStep
        backHandler={() => {
          setCurrentStep(1);
        }}
        initData={resultData}
        onClose={() => setIsOpen(false)}
      />,
    ],
    [
      setCurrentStep,
      setResultData,
      productUrl,
      pdfFile,
      isOpen,
      currentStep,
      resultData,
    ],
  );

  return (
    <>
      <CustomButton
        onClick={() => setIsOpen(true)}
        customSize="fit"
        type="submit"
        customColor="#F16300"
        customGradientColor="#FE8836"
        disabled={isLoading || createProductAnalyses}
      >
        {isLoading || createProductAnalyses ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <AddIcon fontSize="small" />
        )}
        Start new analysis
      </CustomButton>

      <BaseModal
        open={isOpen}
        onClose={onCloseHandler}
        title="New Buying Committee Analysis"
        sx={{ padding: "32px", maxWidth: "540px", width: "90%" }}
      >
        {isOpen && steps[currentStep]}
      </BaseModal>

      <ReuseModal
        isOpen={isDiscardModal}
        onClose={() => setIsDiscardModal(false)}
        onSubmit={() => {
          setIsDiscardModal(false);
          setIsOpen(false);
        }}
        title="Discard changes"
        text="Are you sure you want to discard all changes?"
        maxWidth="430px"
      />
    </>
  );
};
