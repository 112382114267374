import { IconProps } from "../../types/genereal";

export const FilterIcon = ({
  width = "24",
  height = "24",
  color = "#4C545B",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1998_11482)">
        <path
          d="M10.9999 20C10.7166 20 10.4792 19.904 10.2879 19.712C10.0966 19.52 10.0006 19.2827 9.99991 19V13L4.19991 5.6C3.94991 5.26667 3.91257 4.91667 4.08791 4.55C4.26324 4.18333 4.56724 4 4.99991 4H18.9999C19.4332 4 19.7376 4.18333 19.9129 4.55C20.0882 4.91667 20.0506 5.26667 19.7999 5.6L13.9999 13V19C13.9999 19.2833 13.9039 19.521 13.7119 19.713C13.5199 19.905 13.2826 20.0007 12.9999 20H10.9999Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1998_11482">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
