import { Divider, Stack, Typography } from "@mui/material";
import { NavigationBreadcrumbs } from "../components/ui";
import {
  ProfileChangePasswordBlock,
  ProfileHeader,
  ProfileData,
} from "../components/profile";
import { useGetUserQuery } from "../api";
import { CustomLoader } from "../components/ui/CustomLoader";

export const ProfilePage = () => {
  const { data: user, isLoading } = useGetUserQuery();

  if (!user || isLoading) return <CustomLoader />;

  const {
    avatar_url,
    first_name,
    last_name,
    company_name,
    email,
    industry,
    has_password,
  } = user;

  const breadcrumbs = [
    {
      title: `${first_name} ${last_name} profile`,
    },
  ];

  return (
    <Stack
      sx={{ px: { sm: 8, md: 8, lg: 14, xl: 20 }, pt: 6, pb: 6 }}
      gap="40px"
    >
      <Stack gap="24px">
        <NavigationBreadcrumbs breadcrumbs={breadcrumbs} />
        <Typography variant="h4" color="custom.textDarkBlue" fontWeight={500}>
          My profile
        </Typography>
        <ProfileHeader
          avatar_url={avatar_url}
          first_name={first_name}
          company_name={company_name}
          last_name={last_name}
        />
        <Divider />
        <ProfileData
          email={email}
          first_name={first_name}
          company_name={company_name}
          last_name={last_name}
          industry={industry}
        />
        <Divider />
        <ProfileChangePasswordBlock email={email} has_password={has_password} />
      </Stack>
    </Stack>
  );
};
