import { IconProps } from "../../types/genereal";
import { IconWrapper } from "components/ui/IconWrapper";

export const ExternalDataIcon = ({
  width = "169",
  height = "145",
}: IconProps) => {
  return (
    <IconWrapper width={width} height={height}>
      <svg
        width="169"
        height="145"
        viewBox="0 0 169 145"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse cx="80.5" cy="116" rx="73.5" ry="29" fill="#ECEEF0" />
        <path
          d="M91.3125 47.1875H118.812V122.812H43.1875V47.1875H70.6875M118.812 91.875H94.75C94.75 95.5217 93.3013 99.0191 90.7227 101.598C88.1441 104.176 84.6467 105.625 81 105.625C77.3533 105.625 73.8559 104.176 71.2773 101.598C68.6987 99.0191 67.25 95.5217 67.25 91.875H43.1875M81 43.75V81.5625M81 81.5625L94.75 67.8125M81 81.5625L67.25 67.8125"
          stroke="#1F2932"
          strokeWidth="8"
          strokeLinejoin="round"
        />
        <circle cx="11" cy="62" r="11" fill="#99A7BD" />
        <circle cx="37.5" cy="15.5" r="5.5" fill="#99A7BD" />
        <circle cx="161.5" cy="87.5" r="7.5" fill="#99A7BD" />
        <line
          x1="136"
          y1="59.5"
          x2="157"
          y2="59.5"
          stroke="#99A7BD"
          strokeWidth="5"
        />
      </svg>
    </IconWrapper>
  );
};
