import { IconProps } from "../../types/genereal";

export const AlertIcon = ({
  width = "32",
  height = "32",
  color = "#CF3E00",
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7322 4.19922L29.2442 24.1379C29.4198 24.4419 29.5122 24.7868 29.5122 25.1379C29.5122 25.4889 29.4198 25.8338 29.2442 26.1379C29.0687 26.4419 28.8162 26.6944 28.5122 26.8699C28.2082 27.0455 27.8633 27.1379 27.5122 27.1379H4.48823C4.13716 27.1379 3.79228 27.0455 3.48825 26.8699C3.18422 26.6944 2.93175 26.4419 2.75622 26.1379C2.58069 25.8338 2.48828 25.4889 2.48828 25.1379C2.48828 24.7868 2.5807 24.4419 2.75623 24.1379L14.2682 4.19922C15.0376 2.86589 16.9616 2.86589 17.7322 4.19922ZM16.0002 6.53255L5.6429 24.4712H26.3576L16.0002 6.53255ZM16.0002 20.0019C16.3539 20.0019 16.693 20.1424 16.943 20.3924C17.1931 20.6425 17.3336 20.9816 17.3336 21.3352C17.3336 21.6888 17.1931 22.028 16.943 22.278C16.693 22.5281 16.3539 22.6686 16.0002 22.6686C15.6466 22.6686 15.3075 22.5281 15.0574 22.278C14.8074 22.028 14.6669 21.6888 14.6669 21.3352C14.6669 20.9816 14.8074 20.6425 15.0574 20.3924C15.3075 20.1424 15.6466 20.0019 16.0002 20.0019ZM16.0002 10.6686C16.3539 10.6686 16.693 10.809 16.943 11.0591C17.1931 11.3091 17.3336 11.6483 17.3336 12.0019V17.3352C17.3336 17.6888 17.1931 18.028 16.943 18.278C16.693 18.5281 16.3539 18.6686 16.0002 18.6686C15.6466 18.6686 15.3075 18.5281 15.0574 18.278C14.8074 18.028 14.6669 17.6888 14.6669 17.3352V12.0019C14.6669 11.6483 14.8074 11.3091 15.0574 11.0591C15.3075 10.809 15.6466 10.6686 16.0002 10.6686Z"
        fill={color}
      />
    </svg>
  );
};
